$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px,
        xxxl: 1720px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1320px,
        xxxl: 1654px
);

$spacer: 1rem;
$spacers: ();
@for $i from 0 through 40 {
  $spacers: map-merge($spacers, ($i: rem($i*5px)));
}

$primary: #FF9400;
$secondary: #1C1230;
$active-bright: #ff471d;
$active-dark: #1C1230;
$success: #198754;
$info: #0dcaf0;
$warning: #ffc107;
$danger: #dc3545;

$theme-colors: (
        "primary": $primary,
        "secondary": $secondary,
        "active-bright": $active-bright,
        "active-dark": $active-dark,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger,
        "white": #fff,
);

$enable-important-utilities: false;

$min-contrast-ratio: 1;
$body-bg: #F8F8F8;
$nav-link-color: #000;

$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;

$font-size-base: calc(20 / 16 * 1rem);
$font-family-sans-serif: 'Telegraf', system-ui, -apple-system, sans-serif;
$input-border-radius: 0;

$grid-gutter-width: rem(32px);

$btn-border-radius: 0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;
$btn-font-weight: 600;

$line-height-base: 1.5;
$line-height-sm: 1.25;
$line-height-lg: 2;

$input-border-color: #000;
$input-font-weight: 400;
$input-btn-font-size: rem(20px);
$input-btn-padding-y: rem(10px);
$input-btn-padding-x: rem(31px);

$dropdown-min-width: rem(160px);
$dropdown-padding-x: 0;
$dropdown-padding-y: .5rem;
$dropdown-font-size: rem(16px);
$dropdown-border-radius: 0;
$dropdown-border-width: 0;

$dropdown-item-padding-y: $spacer * .5;
$dropdown-item-padding-x: $spacer;

$pagination-border-radius: 0;
$pagination-border-color: #fff;
$pagination-active-color: #fff;
$pagination-active-bg: $secondary;
$pagination-active-border-color: $secondary;

$table-cell-padding-y: rem(23px);
$table-cell-padding-x: rem(15px);
$table-border-color: fade-out(#262626, .7);
$table-group-separator-color: fade-out(#262626, .7);

$breadcrumb-font-size: rem(16px);
$breadcrumb-item-padding-x: rem(24px);
$breadcrumb-margin-bottom: 1rem;
$breadcrumb-active-color: #000;
