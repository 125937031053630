/*@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/Telegraf-UltraLight.otf') format('opentype'),
  url('../fonts/Telegraf-UltraLight.woff2') format('woff2'),
  url('../fonts/Telegraf-UltraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}*/

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/Telegraf-Light.otf') format('opentype'), 
  url('../fonts/Telegraf-Light.woff2') format('woff2'),
  url('../fonts/Telegraf-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/Telegraf-Regular.otf') format('opentype'),
  url('../fonts/Telegraf-Regular.woff2') format('woff2'),
  url('../fonts/Telegraf-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/Telegraf-Medium.otf') format('opentype'),
  url('../fonts/Telegraf-Medium.woff2') format('woff2'),
  url('../fonts/Telegraf-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/Telegraf-SemiBold.otf') format('opentype'), 
  url('../fonts/Telegraf-SemiBold.woff2') format('woff2'),
  url('../fonts/Telegraf-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/Telegraf-Bold.otf') format('opentype'),
  url('../fonts/Telegraf-Bold.woff2') format('woff2'),
  url('../fonts/Telegraf-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/Telegraf-UltraBold.otf') format('opentype'),
  url('../fonts/Telegraf-UltraBold.woff2') format('woff2'),
  url('../fonts/Telegraf-UltraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/Telegraf-Black.otf') format('opentype'),
  url('../fonts/Telegraf-Black.woff2') format('woff2'),
  url('../fonts/Telegraf-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

/*@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/Telegraf-UltraLight.woff2') format('woff2'),
  url('../fonts/Telegraf-UltraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/Telegraf-Light.woff2') format('woff2'),
  url('../fonts/Telegraf-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/Telegraf-Regular.woff2') format('woff2'),
  url('../fonts/Telegraf-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/Telegraf-Medium.woff2') format('woff2'),
  url('../fonts/Telegraf-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/Telegraf-SemiBold.woff2') format('woff2'),
  url('../fonts/Telegraf-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/Telegraf-Bold.woff2') format('woff2'),
  url('../fonts/Telegraf-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/Telegraf-UltraBold.woff2') format('woff2'),
  url('../fonts/Telegraf-UltraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/Telegraf-Black.woff2') format('woff2'),
  url('../fonts/Telegraf-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
*/
