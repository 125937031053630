@charset "UTF-8";
/*@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/Telegraf-UltraLight.otf') format('opentype'),
  url('../fonts/Telegraf-UltraLight.woff2') format('woff2'),
  url('../fonts/Telegraf-UltraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}*/
@font-face {
  font-family: "Telegraf";
  src: url("../fonts/Telegraf-Light.otf") format("opentype"), url("../fonts/Telegraf-Light.woff2") format("woff2"), url("../fonts/Telegraf-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Telegraf";
  src: url("../fonts/Telegraf-Regular.otf") format("opentype"), url("../fonts/Telegraf-Regular.woff2") format("woff2"), url("../fonts/Telegraf-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Telegraf";
  src: url("../fonts/Telegraf-Medium.otf") format("opentype"), url("../fonts/Telegraf-Medium.woff2") format("woff2"), url("../fonts/Telegraf-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Telegraf";
  src: url("../fonts/Telegraf-SemiBold.otf") format("opentype"), url("../fonts/Telegraf-SemiBold.woff2") format("woff2"), url("../fonts/Telegraf-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Telegraf";
  src: url("../fonts/Telegraf-Bold.otf") format("opentype"), url("../fonts/Telegraf-Bold.woff2") format("woff2"), url("../fonts/Telegraf-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Telegraf";
  src: url("../fonts/Telegraf-UltraBold.otf") format("opentype"), url("../fonts/Telegraf-UltraBold.woff2") format("woff2"), url("../fonts/Telegraf-UltraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Telegraf";
  src: url("../fonts/Telegraf-Black.otf") format("opentype"), url("../fonts/Telegraf-Black.woff2") format("woff2"), url("../fonts/Telegraf-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
/*@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/Telegraf-UltraLight.woff2') format('woff2'),
  url('../fonts/Telegraf-UltraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/Telegraf-Light.woff2') format('woff2'),
  url('../fonts/Telegraf-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/Telegraf-Regular.woff2') format('woff2'),
  url('../fonts/Telegraf-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/Telegraf-Medium.woff2') format('woff2'),
  url('../fonts/Telegraf-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/Telegraf-SemiBold.woff2') format('woff2'),
  url('../fonts/Telegraf-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/Telegraf-Bold.woff2') format('woff2'),
  url('../fonts/Telegraf-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/Telegraf-UltraBold.woff2') format('woff2'),
  url('../fonts/Telegraf-UltraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/Telegraf-Black.woff2') format('woff2'),
  url('../fonts/Telegraf-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
*/
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #FF9400;
  --bs-secondary: #1C1230;
  --bs-active-bright: #ff471d;
  --bs-active-dark: #1C1230;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-white: #fff;
  --bs-primary-rgb: 255, 148, 0;
  --bs-secondary-rgb: 28, 18, 48;
  --bs-active-bright-rgb: 255, 71, 29;
  --bs-active-dark-rgb: 28, 18, 48;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-white-rgb: 255, 255, 255;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 248, 248, 248;
  --bs-font-sans-serif: "Telegraf", system-ui, -apple-system, sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1.25rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #F8F8F8;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.4375rem + 2.25vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 3.125rem;
  }
}

h2, .h2 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2.5rem;
  }
}

h3, .h3 {
  font-size: calc(1.34375rem + 1.125vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 2.1875rem;
  }
}

h4, .h4 {
  font-size: calc(1.3125rem + 0.75vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.875rem;
  }
}

h5, .h5 {
  font-size: calc(1.28125rem + 0.375vw);
}
@media (min-width: 1200px) {
  h5, .h5 {
    font-size: 1.5625rem;
  }
}

h6, .h6 {
  font-size: 1.25rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #FF9400;
  text-decoration: underline;
}
a:hover {
  color: #cc7600;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 1.4375rem;
  padding-bottom: 1.4375rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: calc(1.28125rem + 0.375vw);
  font-weight: 300;
}
@media (min-width: 1200px) {
  .lead {
    font-size: 1.5625rem;
  }
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: calc(1.28125rem + 0.375vw);
}
@media (min-width: 1200px) {
  .blockquote {
    font-size: 1.5625rem;
  }
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #F8F8F8;
  border: 1px solid #dee2e6;
  border-radius: 0;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xxxl,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 1rem);
  padding-left: var(--bs-gutter-x, 1rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
@media (min-width: 1720px) {
  .container-xxxl, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1654px;
  }
}
.row {
  --bs-gutter-x: 2rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0rem;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0rem;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.3125rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.3125rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.625rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.625rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 0.9375rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 0.9375rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.25rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.25rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 1.5625rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 1.5625rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 1.875rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 1.875rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 2.1875rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 2.1875rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 2.5rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 2.5rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 2.8125rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 2.8125rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 3.125rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 3.125rem;
}

.g-11,
.gx-11 {
  --bs-gutter-x: 3.4375rem;
}

.g-11,
.gy-11 {
  --bs-gutter-y: 3.4375rem;
}

.g-12,
.gx-12 {
  --bs-gutter-x: 3.75rem;
}

.g-12,
.gy-12 {
  --bs-gutter-y: 3.75rem;
}

.g-13,
.gx-13 {
  --bs-gutter-x: 4.0625rem;
}

.g-13,
.gy-13 {
  --bs-gutter-y: 4.0625rem;
}

.g-14,
.gx-14 {
  --bs-gutter-x: 4.375rem;
}

.g-14,
.gy-14 {
  --bs-gutter-y: 4.375rem;
}

.g-15,
.gx-15 {
  --bs-gutter-x: 4.6875rem;
}

.g-15,
.gy-15 {
  --bs-gutter-y: 4.6875rem;
}

.g-16,
.gx-16 {
  --bs-gutter-x: 5rem;
}

.g-16,
.gy-16 {
  --bs-gutter-y: 5rem;
}

.g-17,
.gx-17 {
  --bs-gutter-x: 5.3125rem;
}

.g-17,
.gy-17 {
  --bs-gutter-y: 5.3125rem;
}

.g-18,
.gx-18 {
  --bs-gutter-x: 5.625rem;
}

.g-18,
.gy-18 {
  --bs-gutter-y: 5.625rem;
}

.g-19,
.gx-19 {
  --bs-gutter-x: 5.9375rem;
}

.g-19,
.gy-19 {
  --bs-gutter-y: 5.9375rem;
}

.g-20,
.gx-20 {
  --bs-gutter-x: 6.25rem;
}

.g-20,
.gy-20 {
  --bs-gutter-y: 6.25rem;
}

.g-21,
.gx-21 {
  --bs-gutter-x: 6.5625rem;
}

.g-21,
.gy-21 {
  --bs-gutter-y: 6.5625rem;
}

.g-22,
.gx-22 {
  --bs-gutter-x: 6.875rem;
}

.g-22,
.gy-22 {
  --bs-gutter-y: 6.875rem;
}

.g-23,
.gx-23 {
  --bs-gutter-x: 7.1875rem;
}

.g-23,
.gy-23 {
  --bs-gutter-y: 7.1875rem;
}

.g-24,
.gx-24 {
  --bs-gutter-x: 7.5rem;
}

.g-24,
.gy-24 {
  --bs-gutter-y: 7.5rem;
}

.g-25,
.gx-25 {
  --bs-gutter-x: 7.8125rem;
}

.g-25,
.gy-25 {
  --bs-gutter-y: 7.8125rem;
}

.g-26,
.gx-26 {
  --bs-gutter-x: 8.125rem;
}

.g-26,
.gy-26 {
  --bs-gutter-y: 8.125rem;
}

.g-27,
.gx-27 {
  --bs-gutter-x: 8.4375rem;
}

.g-27,
.gy-27 {
  --bs-gutter-y: 8.4375rem;
}

.g-28,
.gx-28 {
  --bs-gutter-x: 8.75rem;
}

.g-28,
.gy-28 {
  --bs-gutter-y: 8.75rem;
}

.g-29,
.gx-29 {
  --bs-gutter-x: 9.0625rem;
}

.g-29,
.gy-29 {
  --bs-gutter-y: 9.0625rem;
}

.g-30,
.gx-30 {
  --bs-gutter-x: 9.375rem;
}

.g-30,
.gy-30 {
  --bs-gutter-y: 9.375rem;
}

.g-31,
.gx-31 {
  --bs-gutter-x: 9.6875rem;
}

.g-31,
.gy-31 {
  --bs-gutter-y: 9.6875rem;
}

.g-32,
.gx-32 {
  --bs-gutter-x: 10rem;
}

.g-32,
.gy-32 {
  --bs-gutter-y: 10rem;
}

.g-33,
.gx-33 {
  --bs-gutter-x: 10.3125rem;
}

.g-33,
.gy-33 {
  --bs-gutter-y: 10.3125rem;
}

.g-34,
.gx-34 {
  --bs-gutter-x: 10.625rem;
}

.g-34,
.gy-34 {
  --bs-gutter-y: 10.625rem;
}

.g-35,
.gx-35 {
  --bs-gutter-x: 10.9375rem;
}

.g-35,
.gy-35 {
  --bs-gutter-y: 10.9375rem;
}

.g-36,
.gx-36 {
  --bs-gutter-x: 11.25rem;
}

.g-36,
.gy-36 {
  --bs-gutter-y: 11.25rem;
}

.g-37,
.gx-37 {
  --bs-gutter-x: 11.5625rem;
}

.g-37,
.gy-37 {
  --bs-gutter-y: 11.5625rem;
}

.g-38,
.gx-38 {
  --bs-gutter-x: 11.875rem;
}

.g-38,
.gy-38 {
  --bs-gutter-y: 11.875rem;
}

.g-39,
.gx-39 {
  --bs-gutter-x: 12.1875rem;
}

.g-39,
.gy-39 {
  --bs-gutter-y: 12.1875rem;
}

.g-40,
.gx-40 {
  --bs-gutter-x: 12.5rem;
}

.g-40,
.gy-40 {
  --bs-gutter-y: 12.5rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0rem;
  }
  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0rem;
  }
  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 1.5625rem;
  }
  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 1.5625rem;
  }
  .g-sm-6,
.gx-sm-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-sm-6,
.gy-sm-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-sm-7,
.gx-sm-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-sm-7,
.gy-sm-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-sm-8,
.gx-sm-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-sm-8,
.gy-sm-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-sm-9,
.gx-sm-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-sm-9,
.gy-sm-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-sm-10,
.gx-sm-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-sm-10,
.gy-sm-10 {
    --bs-gutter-y: 3.125rem;
  }
  .g-sm-11,
.gx-sm-11 {
    --bs-gutter-x: 3.4375rem;
  }
  .g-sm-11,
.gy-sm-11 {
    --bs-gutter-y: 3.4375rem;
  }
  .g-sm-12,
.gx-sm-12 {
    --bs-gutter-x: 3.75rem;
  }
  .g-sm-12,
.gy-sm-12 {
    --bs-gutter-y: 3.75rem;
  }
  .g-sm-13,
.gx-sm-13 {
    --bs-gutter-x: 4.0625rem;
  }
  .g-sm-13,
.gy-sm-13 {
    --bs-gutter-y: 4.0625rem;
  }
  .g-sm-14,
.gx-sm-14 {
    --bs-gutter-x: 4.375rem;
  }
  .g-sm-14,
.gy-sm-14 {
    --bs-gutter-y: 4.375rem;
  }
  .g-sm-15,
.gx-sm-15 {
    --bs-gutter-x: 4.6875rem;
  }
  .g-sm-15,
.gy-sm-15 {
    --bs-gutter-y: 4.6875rem;
  }
  .g-sm-16,
.gx-sm-16 {
    --bs-gutter-x: 5rem;
  }
  .g-sm-16,
.gy-sm-16 {
    --bs-gutter-y: 5rem;
  }
  .g-sm-17,
.gx-sm-17 {
    --bs-gutter-x: 5.3125rem;
  }
  .g-sm-17,
.gy-sm-17 {
    --bs-gutter-y: 5.3125rem;
  }
  .g-sm-18,
.gx-sm-18 {
    --bs-gutter-x: 5.625rem;
  }
  .g-sm-18,
.gy-sm-18 {
    --bs-gutter-y: 5.625rem;
  }
  .g-sm-19,
.gx-sm-19 {
    --bs-gutter-x: 5.9375rem;
  }
  .g-sm-19,
.gy-sm-19 {
    --bs-gutter-y: 5.9375rem;
  }
  .g-sm-20,
.gx-sm-20 {
    --bs-gutter-x: 6.25rem;
  }
  .g-sm-20,
.gy-sm-20 {
    --bs-gutter-y: 6.25rem;
  }
  .g-sm-21,
.gx-sm-21 {
    --bs-gutter-x: 6.5625rem;
  }
  .g-sm-21,
.gy-sm-21 {
    --bs-gutter-y: 6.5625rem;
  }
  .g-sm-22,
.gx-sm-22 {
    --bs-gutter-x: 6.875rem;
  }
  .g-sm-22,
.gy-sm-22 {
    --bs-gutter-y: 6.875rem;
  }
  .g-sm-23,
.gx-sm-23 {
    --bs-gutter-x: 7.1875rem;
  }
  .g-sm-23,
.gy-sm-23 {
    --bs-gutter-y: 7.1875rem;
  }
  .g-sm-24,
.gx-sm-24 {
    --bs-gutter-x: 7.5rem;
  }
  .g-sm-24,
.gy-sm-24 {
    --bs-gutter-y: 7.5rem;
  }
  .g-sm-25,
.gx-sm-25 {
    --bs-gutter-x: 7.8125rem;
  }
  .g-sm-25,
.gy-sm-25 {
    --bs-gutter-y: 7.8125rem;
  }
  .g-sm-26,
.gx-sm-26 {
    --bs-gutter-x: 8.125rem;
  }
  .g-sm-26,
.gy-sm-26 {
    --bs-gutter-y: 8.125rem;
  }
  .g-sm-27,
.gx-sm-27 {
    --bs-gutter-x: 8.4375rem;
  }
  .g-sm-27,
.gy-sm-27 {
    --bs-gutter-y: 8.4375rem;
  }
  .g-sm-28,
.gx-sm-28 {
    --bs-gutter-x: 8.75rem;
  }
  .g-sm-28,
.gy-sm-28 {
    --bs-gutter-y: 8.75rem;
  }
  .g-sm-29,
.gx-sm-29 {
    --bs-gutter-x: 9.0625rem;
  }
  .g-sm-29,
.gy-sm-29 {
    --bs-gutter-y: 9.0625rem;
  }
  .g-sm-30,
.gx-sm-30 {
    --bs-gutter-x: 9.375rem;
  }
  .g-sm-30,
.gy-sm-30 {
    --bs-gutter-y: 9.375rem;
  }
  .g-sm-31,
.gx-sm-31 {
    --bs-gutter-x: 9.6875rem;
  }
  .g-sm-31,
.gy-sm-31 {
    --bs-gutter-y: 9.6875rem;
  }
  .g-sm-32,
.gx-sm-32 {
    --bs-gutter-x: 10rem;
  }
  .g-sm-32,
.gy-sm-32 {
    --bs-gutter-y: 10rem;
  }
  .g-sm-33,
.gx-sm-33 {
    --bs-gutter-x: 10.3125rem;
  }
  .g-sm-33,
.gy-sm-33 {
    --bs-gutter-y: 10.3125rem;
  }
  .g-sm-34,
.gx-sm-34 {
    --bs-gutter-x: 10.625rem;
  }
  .g-sm-34,
.gy-sm-34 {
    --bs-gutter-y: 10.625rem;
  }
  .g-sm-35,
.gx-sm-35 {
    --bs-gutter-x: 10.9375rem;
  }
  .g-sm-35,
.gy-sm-35 {
    --bs-gutter-y: 10.9375rem;
  }
  .g-sm-36,
.gx-sm-36 {
    --bs-gutter-x: 11.25rem;
  }
  .g-sm-36,
.gy-sm-36 {
    --bs-gutter-y: 11.25rem;
  }
  .g-sm-37,
.gx-sm-37 {
    --bs-gutter-x: 11.5625rem;
  }
  .g-sm-37,
.gy-sm-37 {
    --bs-gutter-y: 11.5625rem;
  }
  .g-sm-38,
.gx-sm-38 {
    --bs-gutter-x: 11.875rem;
  }
  .g-sm-38,
.gy-sm-38 {
    --bs-gutter-y: 11.875rem;
  }
  .g-sm-39,
.gx-sm-39 {
    --bs-gutter-x: 12.1875rem;
  }
  .g-sm-39,
.gy-sm-39 {
    --bs-gutter-y: 12.1875rem;
  }
  .g-sm-40,
.gx-sm-40 {
    --bs-gutter-x: 12.5rem;
  }
  .g-sm-40,
.gy-sm-40 {
    --bs-gutter-y: 12.5rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0rem;
  }
  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0rem;
  }
  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 1.5625rem;
  }
  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 1.5625rem;
  }
  .g-md-6,
.gx-md-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-md-6,
.gy-md-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-md-7,
.gx-md-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-md-7,
.gy-md-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-md-8,
.gx-md-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-md-8,
.gy-md-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-md-9,
.gx-md-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-md-9,
.gy-md-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-md-10,
.gx-md-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-md-10,
.gy-md-10 {
    --bs-gutter-y: 3.125rem;
  }
  .g-md-11,
.gx-md-11 {
    --bs-gutter-x: 3.4375rem;
  }
  .g-md-11,
.gy-md-11 {
    --bs-gutter-y: 3.4375rem;
  }
  .g-md-12,
.gx-md-12 {
    --bs-gutter-x: 3.75rem;
  }
  .g-md-12,
.gy-md-12 {
    --bs-gutter-y: 3.75rem;
  }
  .g-md-13,
.gx-md-13 {
    --bs-gutter-x: 4.0625rem;
  }
  .g-md-13,
.gy-md-13 {
    --bs-gutter-y: 4.0625rem;
  }
  .g-md-14,
.gx-md-14 {
    --bs-gutter-x: 4.375rem;
  }
  .g-md-14,
.gy-md-14 {
    --bs-gutter-y: 4.375rem;
  }
  .g-md-15,
.gx-md-15 {
    --bs-gutter-x: 4.6875rem;
  }
  .g-md-15,
.gy-md-15 {
    --bs-gutter-y: 4.6875rem;
  }
  .g-md-16,
.gx-md-16 {
    --bs-gutter-x: 5rem;
  }
  .g-md-16,
.gy-md-16 {
    --bs-gutter-y: 5rem;
  }
  .g-md-17,
.gx-md-17 {
    --bs-gutter-x: 5.3125rem;
  }
  .g-md-17,
.gy-md-17 {
    --bs-gutter-y: 5.3125rem;
  }
  .g-md-18,
.gx-md-18 {
    --bs-gutter-x: 5.625rem;
  }
  .g-md-18,
.gy-md-18 {
    --bs-gutter-y: 5.625rem;
  }
  .g-md-19,
.gx-md-19 {
    --bs-gutter-x: 5.9375rem;
  }
  .g-md-19,
.gy-md-19 {
    --bs-gutter-y: 5.9375rem;
  }
  .g-md-20,
.gx-md-20 {
    --bs-gutter-x: 6.25rem;
  }
  .g-md-20,
.gy-md-20 {
    --bs-gutter-y: 6.25rem;
  }
  .g-md-21,
.gx-md-21 {
    --bs-gutter-x: 6.5625rem;
  }
  .g-md-21,
.gy-md-21 {
    --bs-gutter-y: 6.5625rem;
  }
  .g-md-22,
.gx-md-22 {
    --bs-gutter-x: 6.875rem;
  }
  .g-md-22,
.gy-md-22 {
    --bs-gutter-y: 6.875rem;
  }
  .g-md-23,
.gx-md-23 {
    --bs-gutter-x: 7.1875rem;
  }
  .g-md-23,
.gy-md-23 {
    --bs-gutter-y: 7.1875rem;
  }
  .g-md-24,
.gx-md-24 {
    --bs-gutter-x: 7.5rem;
  }
  .g-md-24,
.gy-md-24 {
    --bs-gutter-y: 7.5rem;
  }
  .g-md-25,
.gx-md-25 {
    --bs-gutter-x: 7.8125rem;
  }
  .g-md-25,
.gy-md-25 {
    --bs-gutter-y: 7.8125rem;
  }
  .g-md-26,
.gx-md-26 {
    --bs-gutter-x: 8.125rem;
  }
  .g-md-26,
.gy-md-26 {
    --bs-gutter-y: 8.125rem;
  }
  .g-md-27,
.gx-md-27 {
    --bs-gutter-x: 8.4375rem;
  }
  .g-md-27,
.gy-md-27 {
    --bs-gutter-y: 8.4375rem;
  }
  .g-md-28,
.gx-md-28 {
    --bs-gutter-x: 8.75rem;
  }
  .g-md-28,
.gy-md-28 {
    --bs-gutter-y: 8.75rem;
  }
  .g-md-29,
.gx-md-29 {
    --bs-gutter-x: 9.0625rem;
  }
  .g-md-29,
.gy-md-29 {
    --bs-gutter-y: 9.0625rem;
  }
  .g-md-30,
.gx-md-30 {
    --bs-gutter-x: 9.375rem;
  }
  .g-md-30,
.gy-md-30 {
    --bs-gutter-y: 9.375rem;
  }
  .g-md-31,
.gx-md-31 {
    --bs-gutter-x: 9.6875rem;
  }
  .g-md-31,
.gy-md-31 {
    --bs-gutter-y: 9.6875rem;
  }
  .g-md-32,
.gx-md-32 {
    --bs-gutter-x: 10rem;
  }
  .g-md-32,
.gy-md-32 {
    --bs-gutter-y: 10rem;
  }
  .g-md-33,
.gx-md-33 {
    --bs-gutter-x: 10.3125rem;
  }
  .g-md-33,
.gy-md-33 {
    --bs-gutter-y: 10.3125rem;
  }
  .g-md-34,
.gx-md-34 {
    --bs-gutter-x: 10.625rem;
  }
  .g-md-34,
.gy-md-34 {
    --bs-gutter-y: 10.625rem;
  }
  .g-md-35,
.gx-md-35 {
    --bs-gutter-x: 10.9375rem;
  }
  .g-md-35,
.gy-md-35 {
    --bs-gutter-y: 10.9375rem;
  }
  .g-md-36,
.gx-md-36 {
    --bs-gutter-x: 11.25rem;
  }
  .g-md-36,
.gy-md-36 {
    --bs-gutter-y: 11.25rem;
  }
  .g-md-37,
.gx-md-37 {
    --bs-gutter-x: 11.5625rem;
  }
  .g-md-37,
.gy-md-37 {
    --bs-gutter-y: 11.5625rem;
  }
  .g-md-38,
.gx-md-38 {
    --bs-gutter-x: 11.875rem;
  }
  .g-md-38,
.gy-md-38 {
    --bs-gutter-y: 11.875rem;
  }
  .g-md-39,
.gx-md-39 {
    --bs-gutter-x: 12.1875rem;
  }
  .g-md-39,
.gy-md-39 {
    --bs-gutter-y: 12.1875rem;
  }
  .g-md-40,
.gx-md-40 {
    --bs-gutter-x: 12.5rem;
  }
  .g-md-40,
.gy-md-40 {
    --bs-gutter-y: 12.5rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0rem;
  }
  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0rem;
  }
  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 1.5625rem;
  }
  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 1.5625rem;
  }
  .g-lg-6,
.gx-lg-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-lg-6,
.gy-lg-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-lg-7,
.gx-lg-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-lg-7,
.gy-lg-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-lg-8,
.gx-lg-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-lg-8,
.gy-lg-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-lg-9,
.gx-lg-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-lg-9,
.gy-lg-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-lg-10,
.gx-lg-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-lg-10,
.gy-lg-10 {
    --bs-gutter-y: 3.125rem;
  }
  .g-lg-11,
.gx-lg-11 {
    --bs-gutter-x: 3.4375rem;
  }
  .g-lg-11,
.gy-lg-11 {
    --bs-gutter-y: 3.4375rem;
  }
  .g-lg-12,
.gx-lg-12 {
    --bs-gutter-x: 3.75rem;
  }
  .g-lg-12,
.gy-lg-12 {
    --bs-gutter-y: 3.75rem;
  }
  .g-lg-13,
.gx-lg-13 {
    --bs-gutter-x: 4.0625rem;
  }
  .g-lg-13,
.gy-lg-13 {
    --bs-gutter-y: 4.0625rem;
  }
  .g-lg-14,
.gx-lg-14 {
    --bs-gutter-x: 4.375rem;
  }
  .g-lg-14,
.gy-lg-14 {
    --bs-gutter-y: 4.375rem;
  }
  .g-lg-15,
.gx-lg-15 {
    --bs-gutter-x: 4.6875rem;
  }
  .g-lg-15,
.gy-lg-15 {
    --bs-gutter-y: 4.6875rem;
  }
  .g-lg-16,
.gx-lg-16 {
    --bs-gutter-x: 5rem;
  }
  .g-lg-16,
.gy-lg-16 {
    --bs-gutter-y: 5rem;
  }
  .g-lg-17,
.gx-lg-17 {
    --bs-gutter-x: 5.3125rem;
  }
  .g-lg-17,
.gy-lg-17 {
    --bs-gutter-y: 5.3125rem;
  }
  .g-lg-18,
.gx-lg-18 {
    --bs-gutter-x: 5.625rem;
  }
  .g-lg-18,
.gy-lg-18 {
    --bs-gutter-y: 5.625rem;
  }
  .g-lg-19,
.gx-lg-19 {
    --bs-gutter-x: 5.9375rem;
  }
  .g-lg-19,
.gy-lg-19 {
    --bs-gutter-y: 5.9375rem;
  }
  .g-lg-20,
.gx-lg-20 {
    --bs-gutter-x: 6.25rem;
  }
  .g-lg-20,
.gy-lg-20 {
    --bs-gutter-y: 6.25rem;
  }
  .g-lg-21,
.gx-lg-21 {
    --bs-gutter-x: 6.5625rem;
  }
  .g-lg-21,
.gy-lg-21 {
    --bs-gutter-y: 6.5625rem;
  }
  .g-lg-22,
.gx-lg-22 {
    --bs-gutter-x: 6.875rem;
  }
  .g-lg-22,
.gy-lg-22 {
    --bs-gutter-y: 6.875rem;
  }
  .g-lg-23,
.gx-lg-23 {
    --bs-gutter-x: 7.1875rem;
  }
  .g-lg-23,
.gy-lg-23 {
    --bs-gutter-y: 7.1875rem;
  }
  .g-lg-24,
.gx-lg-24 {
    --bs-gutter-x: 7.5rem;
  }
  .g-lg-24,
.gy-lg-24 {
    --bs-gutter-y: 7.5rem;
  }
  .g-lg-25,
.gx-lg-25 {
    --bs-gutter-x: 7.8125rem;
  }
  .g-lg-25,
.gy-lg-25 {
    --bs-gutter-y: 7.8125rem;
  }
  .g-lg-26,
.gx-lg-26 {
    --bs-gutter-x: 8.125rem;
  }
  .g-lg-26,
.gy-lg-26 {
    --bs-gutter-y: 8.125rem;
  }
  .g-lg-27,
.gx-lg-27 {
    --bs-gutter-x: 8.4375rem;
  }
  .g-lg-27,
.gy-lg-27 {
    --bs-gutter-y: 8.4375rem;
  }
  .g-lg-28,
.gx-lg-28 {
    --bs-gutter-x: 8.75rem;
  }
  .g-lg-28,
.gy-lg-28 {
    --bs-gutter-y: 8.75rem;
  }
  .g-lg-29,
.gx-lg-29 {
    --bs-gutter-x: 9.0625rem;
  }
  .g-lg-29,
.gy-lg-29 {
    --bs-gutter-y: 9.0625rem;
  }
  .g-lg-30,
.gx-lg-30 {
    --bs-gutter-x: 9.375rem;
  }
  .g-lg-30,
.gy-lg-30 {
    --bs-gutter-y: 9.375rem;
  }
  .g-lg-31,
.gx-lg-31 {
    --bs-gutter-x: 9.6875rem;
  }
  .g-lg-31,
.gy-lg-31 {
    --bs-gutter-y: 9.6875rem;
  }
  .g-lg-32,
.gx-lg-32 {
    --bs-gutter-x: 10rem;
  }
  .g-lg-32,
.gy-lg-32 {
    --bs-gutter-y: 10rem;
  }
  .g-lg-33,
.gx-lg-33 {
    --bs-gutter-x: 10.3125rem;
  }
  .g-lg-33,
.gy-lg-33 {
    --bs-gutter-y: 10.3125rem;
  }
  .g-lg-34,
.gx-lg-34 {
    --bs-gutter-x: 10.625rem;
  }
  .g-lg-34,
.gy-lg-34 {
    --bs-gutter-y: 10.625rem;
  }
  .g-lg-35,
.gx-lg-35 {
    --bs-gutter-x: 10.9375rem;
  }
  .g-lg-35,
.gy-lg-35 {
    --bs-gutter-y: 10.9375rem;
  }
  .g-lg-36,
.gx-lg-36 {
    --bs-gutter-x: 11.25rem;
  }
  .g-lg-36,
.gy-lg-36 {
    --bs-gutter-y: 11.25rem;
  }
  .g-lg-37,
.gx-lg-37 {
    --bs-gutter-x: 11.5625rem;
  }
  .g-lg-37,
.gy-lg-37 {
    --bs-gutter-y: 11.5625rem;
  }
  .g-lg-38,
.gx-lg-38 {
    --bs-gutter-x: 11.875rem;
  }
  .g-lg-38,
.gy-lg-38 {
    --bs-gutter-y: 11.875rem;
  }
  .g-lg-39,
.gx-lg-39 {
    --bs-gutter-x: 12.1875rem;
  }
  .g-lg-39,
.gy-lg-39 {
    --bs-gutter-y: 12.1875rem;
  }
  .g-lg-40,
.gx-lg-40 {
    --bs-gutter-x: 12.5rem;
  }
  .g-lg-40,
.gy-lg-40 {
    --bs-gutter-y: 12.5rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0rem;
  }
  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0rem;
  }
  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 1.5625rem;
  }
  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 1.5625rem;
  }
  .g-xl-6,
.gx-xl-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-xl-6,
.gy-xl-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-xl-7,
.gx-xl-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-xl-7,
.gy-xl-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-xl-8,
.gx-xl-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xl-8,
.gy-xl-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xl-9,
.gx-xl-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-xl-9,
.gy-xl-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-xl-10,
.gx-xl-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-xl-10,
.gy-xl-10 {
    --bs-gutter-y: 3.125rem;
  }
  .g-xl-11,
.gx-xl-11 {
    --bs-gutter-x: 3.4375rem;
  }
  .g-xl-11,
.gy-xl-11 {
    --bs-gutter-y: 3.4375rem;
  }
  .g-xl-12,
.gx-xl-12 {
    --bs-gutter-x: 3.75rem;
  }
  .g-xl-12,
.gy-xl-12 {
    --bs-gutter-y: 3.75rem;
  }
  .g-xl-13,
.gx-xl-13 {
    --bs-gutter-x: 4.0625rem;
  }
  .g-xl-13,
.gy-xl-13 {
    --bs-gutter-y: 4.0625rem;
  }
  .g-xl-14,
.gx-xl-14 {
    --bs-gutter-x: 4.375rem;
  }
  .g-xl-14,
.gy-xl-14 {
    --bs-gutter-y: 4.375rem;
  }
  .g-xl-15,
.gx-xl-15 {
    --bs-gutter-x: 4.6875rem;
  }
  .g-xl-15,
.gy-xl-15 {
    --bs-gutter-y: 4.6875rem;
  }
  .g-xl-16,
.gx-xl-16 {
    --bs-gutter-x: 5rem;
  }
  .g-xl-16,
.gy-xl-16 {
    --bs-gutter-y: 5rem;
  }
  .g-xl-17,
.gx-xl-17 {
    --bs-gutter-x: 5.3125rem;
  }
  .g-xl-17,
.gy-xl-17 {
    --bs-gutter-y: 5.3125rem;
  }
  .g-xl-18,
.gx-xl-18 {
    --bs-gutter-x: 5.625rem;
  }
  .g-xl-18,
.gy-xl-18 {
    --bs-gutter-y: 5.625rem;
  }
  .g-xl-19,
.gx-xl-19 {
    --bs-gutter-x: 5.9375rem;
  }
  .g-xl-19,
.gy-xl-19 {
    --bs-gutter-y: 5.9375rem;
  }
  .g-xl-20,
.gx-xl-20 {
    --bs-gutter-x: 6.25rem;
  }
  .g-xl-20,
.gy-xl-20 {
    --bs-gutter-y: 6.25rem;
  }
  .g-xl-21,
.gx-xl-21 {
    --bs-gutter-x: 6.5625rem;
  }
  .g-xl-21,
.gy-xl-21 {
    --bs-gutter-y: 6.5625rem;
  }
  .g-xl-22,
.gx-xl-22 {
    --bs-gutter-x: 6.875rem;
  }
  .g-xl-22,
.gy-xl-22 {
    --bs-gutter-y: 6.875rem;
  }
  .g-xl-23,
.gx-xl-23 {
    --bs-gutter-x: 7.1875rem;
  }
  .g-xl-23,
.gy-xl-23 {
    --bs-gutter-y: 7.1875rem;
  }
  .g-xl-24,
.gx-xl-24 {
    --bs-gutter-x: 7.5rem;
  }
  .g-xl-24,
.gy-xl-24 {
    --bs-gutter-y: 7.5rem;
  }
  .g-xl-25,
.gx-xl-25 {
    --bs-gutter-x: 7.8125rem;
  }
  .g-xl-25,
.gy-xl-25 {
    --bs-gutter-y: 7.8125rem;
  }
  .g-xl-26,
.gx-xl-26 {
    --bs-gutter-x: 8.125rem;
  }
  .g-xl-26,
.gy-xl-26 {
    --bs-gutter-y: 8.125rem;
  }
  .g-xl-27,
.gx-xl-27 {
    --bs-gutter-x: 8.4375rem;
  }
  .g-xl-27,
.gy-xl-27 {
    --bs-gutter-y: 8.4375rem;
  }
  .g-xl-28,
.gx-xl-28 {
    --bs-gutter-x: 8.75rem;
  }
  .g-xl-28,
.gy-xl-28 {
    --bs-gutter-y: 8.75rem;
  }
  .g-xl-29,
.gx-xl-29 {
    --bs-gutter-x: 9.0625rem;
  }
  .g-xl-29,
.gy-xl-29 {
    --bs-gutter-y: 9.0625rem;
  }
  .g-xl-30,
.gx-xl-30 {
    --bs-gutter-x: 9.375rem;
  }
  .g-xl-30,
.gy-xl-30 {
    --bs-gutter-y: 9.375rem;
  }
  .g-xl-31,
.gx-xl-31 {
    --bs-gutter-x: 9.6875rem;
  }
  .g-xl-31,
.gy-xl-31 {
    --bs-gutter-y: 9.6875rem;
  }
  .g-xl-32,
.gx-xl-32 {
    --bs-gutter-x: 10rem;
  }
  .g-xl-32,
.gy-xl-32 {
    --bs-gutter-y: 10rem;
  }
  .g-xl-33,
.gx-xl-33 {
    --bs-gutter-x: 10.3125rem;
  }
  .g-xl-33,
.gy-xl-33 {
    --bs-gutter-y: 10.3125rem;
  }
  .g-xl-34,
.gx-xl-34 {
    --bs-gutter-x: 10.625rem;
  }
  .g-xl-34,
.gy-xl-34 {
    --bs-gutter-y: 10.625rem;
  }
  .g-xl-35,
.gx-xl-35 {
    --bs-gutter-x: 10.9375rem;
  }
  .g-xl-35,
.gy-xl-35 {
    --bs-gutter-y: 10.9375rem;
  }
  .g-xl-36,
.gx-xl-36 {
    --bs-gutter-x: 11.25rem;
  }
  .g-xl-36,
.gy-xl-36 {
    --bs-gutter-y: 11.25rem;
  }
  .g-xl-37,
.gx-xl-37 {
    --bs-gutter-x: 11.5625rem;
  }
  .g-xl-37,
.gy-xl-37 {
    --bs-gutter-y: 11.5625rem;
  }
  .g-xl-38,
.gx-xl-38 {
    --bs-gutter-x: 11.875rem;
  }
  .g-xl-38,
.gy-xl-38 {
    --bs-gutter-y: 11.875rem;
  }
  .g-xl-39,
.gx-xl-39 {
    --bs-gutter-x: 12.1875rem;
  }
  .g-xl-39,
.gy-xl-39 {
    --bs-gutter-y: 12.1875rem;
  }
  .g-xl-40,
.gx-xl-40 {
    --bs-gutter-x: 12.5rem;
  }
  .g-xl-40,
.gy-xl-40 {
    --bs-gutter-y: 12.5rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0rem;
  }
  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0rem;
  }
  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 1.5625rem;
  }
  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 1.5625rem;
  }
  .g-xxl-6,
.gx-xxl-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-xxl-6,
.gy-xxl-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-xxl-7,
.gx-xxl-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-xxl-7,
.gy-xxl-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-xxl-8,
.gx-xxl-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xxl-8,
.gy-xxl-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xxl-9,
.gx-xxl-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-xxl-9,
.gy-xxl-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-xxl-10,
.gx-xxl-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-xxl-10,
.gy-xxl-10 {
    --bs-gutter-y: 3.125rem;
  }
  .g-xxl-11,
.gx-xxl-11 {
    --bs-gutter-x: 3.4375rem;
  }
  .g-xxl-11,
.gy-xxl-11 {
    --bs-gutter-y: 3.4375rem;
  }
  .g-xxl-12,
.gx-xxl-12 {
    --bs-gutter-x: 3.75rem;
  }
  .g-xxl-12,
.gy-xxl-12 {
    --bs-gutter-y: 3.75rem;
  }
  .g-xxl-13,
.gx-xxl-13 {
    --bs-gutter-x: 4.0625rem;
  }
  .g-xxl-13,
.gy-xxl-13 {
    --bs-gutter-y: 4.0625rem;
  }
  .g-xxl-14,
.gx-xxl-14 {
    --bs-gutter-x: 4.375rem;
  }
  .g-xxl-14,
.gy-xxl-14 {
    --bs-gutter-y: 4.375rem;
  }
  .g-xxl-15,
.gx-xxl-15 {
    --bs-gutter-x: 4.6875rem;
  }
  .g-xxl-15,
.gy-xxl-15 {
    --bs-gutter-y: 4.6875rem;
  }
  .g-xxl-16,
.gx-xxl-16 {
    --bs-gutter-x: 5rem;
  }
  .g-xxl-16,
.gy-xxl-16 {
    --bs-gutter-y: 5rem;
  }
  .g-xxl-17,
.gx-xxl-17 {
    --bs-gutter-x: 5.3125rem;
  }
  .g-xxl-17,
.gy-xxl-17 {
    --bs-gutter-y: 5.3125rem;
  }
  .g-xxl-18,
.gx-xxl-18 {
    --bs-gutter-x: 5.625rem;
  }
  .g-xxl-18,
.gy-xxl-18 {
    --bs-gutter-y: 5.625rem;
  }
  .g-xxl-19,
.gx-xxl-19 {
    --bs-gutter-x: 5.9375rem;
  }
  .g-xxl-19,
.gy-xxl-19 {
    --bs-gutter-y: 5.9375rem;
  }
  .g-xxl-20,
.gx-xxl-20 {
    --bs-gutter-x: 6.25rem;
  }
  .g-xxl-20,
.gy-xxl-20 {
    --bs-gutter-y: 6.25rem;
  }
  .g-xxl-21,
.gx-xxl-21 {
    --bs-gutter-x: 6.5625rem;
  }
  .g-xxl-21,
.gy-xxl-21 {
    --bs-gutter-y: 6.5625rem;
  }
  .g-xxl-22,
.gx-xxl-22 {
    --bs-gutter-x: 6.875rem;
  }
  .g-xxl-22,
.gy-xxl-22 {
    --bs-gutter-y: 6.875rem;
  }
  .g-xxl-23,
.gx-xxl-23 {
    --bs-gutter-x: 7.1875rem;
  }
  .g-xxl-23,
.gy-xxl-23 {
    --bs-gutter-y: 7.1875rem;
  }
  .g-xxl-24,
.gx-xxl-24 {
    --bs-gutter-x: 7.5rem;
  }
  .g-xxl-24,
.gy-xxl-24 {
    --bs-gutter-y: 7.5rem;
  }
  .g-xxl-25,
.gx-xxl-25 {
    --bs-gutter-x: 7.8125rem;
  }
  .g-xxl-25,
.gy-xxl-25 {
    --bs-gutter-y: 7.8125rem;
  }
  .g-xxl-26,
.gx-xxl-26 {
    --bs-gutter-x: 8.125rem;
  }
  .g-xxl-26,
.gy-xxl-26 {
    --bs-gutter-y: 8.125rem;
  }
  .g-xxl-27,
.gx-xxl-27 {
    --bs-gutter-x: 8.4375rem;
  }
  .g-xxl-27,
.gy-xxl-27 {
    --bs-gutter-y: 8.4375rem;
  }
  .g-xxl-28,
.gx-xxl-28 {
    --bs-gutter-x: 8.75rem;
  }
  .g-xxl-28,
.gy-xxl-28 {
    --bs-gutter-y: 8.75rem;
  }
  .g-xxl-29,
.gx-xxl-29 {
    --bs-gutter-x: 9.0625rem;
  }
  .g-xxl-29,
.gy-xxl-29 {
    --bs-gutter-y: 9.0625rem;
  }
  .g-xxl-30,
.gx-xxl-30 {
    --bs-gutter-x: 9.375rem;
  }
  .g-xxl-30,
.gy-xxl-30 {
    --bs-gutter-y: 9.375rem;
  }
  .g-xxl-31,
.gx-xxl-31 {
    --bs-gutter-x: 9.6875rem;
  }
  .g-xxl-31,
.gy-xxl-31 {
    --bs-gutter-y: 9.6875rem;
  }
  .g-xxl-32,
.gx-xxl-32 {
    --bs-gutter-x: 10rem;
  }
  .g-xxl-32,
.gy-xxl-32 {
    --bs-gutter-y: 10rem;
  }
  .g-xxl-33,
.gx-xxl-33 {
    --bs-gutter-x: 10.3125rem;
  }
  .g-xxl-33,
.gy-xxl-33 {
    --bs-gutter-y: 10.3125rem;
  }
  .g-xxl-34,
.gx-xxl-34 {
    --bs-gutter-x: 10.625rem;
  }
  .g-xxl-34,
.gy-xxl-34 {
    --bs-gutter-y: 10.625rem;
  }
  .g-xxl-35,
.gx-xxl-35 {
    --bs-gutter-x: 10.9375rem;
  }
  .g-xxl-35,
.gy-xxl-35 {
    --bs-gutter-y: 10.9375rem;
  }
  .g-xxl-36,
.gx-xxl-36 {
    --bs-gutter-x: 11.25rem;
  }
  .g-xxl-36,
.gy-xxl-36 {
    --bs-gutter-y: 11.25rem;
  }
  .g-xxl-37,
.gx-xxl-37 {
    --bs-gutter-x: 11.5625rem;
  }
  .g-xxl-37,
.gy-xxl-37 {
    --bs-gutter-y: 11.5625rem;
  }
  .g-xxl-38,
.gx-xxl-38 {
    --bs-gutter-x: 11.875rem;
  }
  .g-xxl-38,
.gy-xxl-38 {
    --bs-gutter-y: 11.875rem;
  }
  .g-xxl-39,
.gx-xxl-39 {
    --bs-gutter-x: 12.1875rem;
  }
  .g-xxl-39,
.gy-xxl-39 {
    --bs-gutter-y: 12.1875rem;
  }
  .g-xxl-40,
.gx-xxl-40 {
    --bs-gutter-x: 12.5rem;
  }
  .g-xxl-40,
.gy-xxl-40 {
    --bs-gutter-y: 12.5rem;
  }
}
@media (min-width: 1720px) {
  .col-xxxl {
    flex: 1 0 0%;
  }
  .row-cols-xxxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxxl-0 {
    margin-left: 0;
  }
  .offset-xxxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxxl-3 {
    margin-left: 25%;
  }
  .offset-xxxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxxl-6 {
    margin-left: 50%;
  }
  .offset-xxxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxxl-9 {
    margin-left: 75%;
  }
  .offset-xxxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxxl-0,
.gx-xxxl-0 {
    --bs-gutter-x: 0rem;
  }
  .g-xxxl-0,
.gy-xxxl-0 {
    --bs-gutter-y: 0rem;
  }
  .g-xxxl-1,
.gx-xxxl-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-xxxl-1,
.gy-xxxl-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-xxxl-2,
.gx-xxxl-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-xxxl-2,
.gy-xxxl-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-xxxl-3,
.gx-xxxl-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-xxxl-3,
.gy-xxxl-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-xxxl-4,
.gx-xxxl-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-xxxl-4,
.gy-xxxl-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-xxxl-5,
.gx-xxxl-5 {
    --bs-gutter-x: 1.5625rem;
  }
  .g-xxxl-5,
.gy-xxxl-5 {
    --bs-gutter-y: 1.5625rem;
  }
  .g-xxxl-6,
.gx-xxxl-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-xxxl-6,
.gy-xxxl-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-xxxl-7,
.gx-xxxl-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-xxxl-7,
.gy-xxxl-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-xxxl-8,
.gx-xxxl-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xxxl-8,
.gy-xxxl-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xxxl-9,
.gx-xxxl-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-xxxl-9,
.gy-xxxl-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-xxxl-10,
.gx-xxxl-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-xxxl-10,
.gy-xxxl-10 {
    --bs-gutter-y: 3.125rem;
  }
  .g-xxxl-11,
.gx-xxxl-11 {
    --bs-gutter-x: 3.4375rem;
  }
  .g-xxxl-11,
.gy-xxxl-11 {
    --bs-gutter-y: 3.4375rem;
  }
  .g-xxxl-12,
.gx-xxxl-12 {
    --bs-gutter-x: 3.75rem;
  }
  .g-xxxl-12,
.gy-xxxl-12 {
    --bs-gutter-y: 3.75rem;
  }
  .g-xxxl-13,
.gx-xxxl-13 {
    --bs-gutter-x: 4.0625rem;
  }
  .g-xxxl-13,
.gy-xxxl-13 {
    --bs-gutter-y: 4.0625rem;
  }
  .g-xxxl-14,
.gx-xxxl-14 {
    --bs-gutter-x: 4.375rem;
  }
  .g-xxxl-14,
.gy-xxxl-14 {
    --bs-gutter-y: 4.375rem;
  }
  .g-xxxl-15,
.gx-xxxl-15 {
    --bs-gutter-x: 4.6875rem;
  }
  .g-xxxl-15,
.gy-xxxl-15 {
    --bs-gutter-y: 4.6875rem;
  }
  .g-xxxl-16,
.gx-xxxl-16 {
    --bs-gutter-x: 5rem;
  }
  .g-xxxl-16,
.gy-xxxl-16 {
    --bs-gutter-y: 5rem;
  }
  .g-xxxl-17,
.gx-xxxl-17 {
    --bs-gutter-x: 5.3125rem;
  }
  .g-xxxl-17,
.gy-xxxl-17 {
    --bs-gutter-y: 5.3125rem;
  }
  .g-xxxl-18,
.gx-xxxl-18 {
    --bs-gutter-x: 5.625rem;
  }
  .g-xxxl-18,
.gy-xxxl-18 {
    --bs-gutter-y: 5.625rem;
  }
  .g-xxxl-19,
.gx-xxxl-19 {
    --bs-gutter-x: 5.9375rem;
  }
  .g-xxxl-19,
.gy-xxxl-19 {
    --bs-gutter-y: 5.9375rem;
  }
  .g-xxxl-20,
.gx-xxxl-20 {
    --bs-gutter-x: 6.25rem;
  }
  .g-xxxl-20,
.gy-xxxl-20 {
    --bs-gutter-y: 6.25rem;
  }
  .g-xxxl-21,
.gx-xxxl-21 {
    --bs-gutter-x: 6.5625rem;
  }
  .g-xxxl-21,
.gy-xxxl-21 {
    --bs-gutter-y: 6.5625rem;
  }
  .g-xxxl-22,
.gx-xxxl-22 {
    --bs-gutter-x: 6.875rem;
  }
  .g-xxxl-22,
.gy-xxxl-22 {
    --bs-gutter-y: 6.875rem;
  }
  .g-xxxl-23,
.gx-xxxl-23 {
    --bs-gutter-x: 7.1875rem;
  }
  .g-xxxl-23,
.gy-xxxl-23 {
    --bs-gutter-y: 7.1875rem;
  }
  .g-xxxl-24,
.gx-xxxl-24 {
    --bs-gutter-x: 7.5rem;
  }
  .g-xxxl-24,
.gy-xxxl-24 {
    --bs-gutter-y: 7.5rem;
  }
  .g-xxxl-25,
.gx-xxxl-25 {
    --bs-gutter-x: 7.8125rem;
  }
  .g-xxxl-25,
.gy-xxxl-25 {
    --bs-gutter-y: 7.8125rem;
  }
  .g-xxxl-26,
.gx-xxxl-26 {
    --bs-gutter-x: 8.125rem;
  }
  .g-xxxl-26,
.gy-xxxl-26 {
    --bs-gutter-y: 8.125rem;
  }
  .g-xxxl-27,
.gx-xxxl-27 {
    --bs-gutter-x: 8.4375rem;
  }
  .g-xxxl-27,
.gy-xxxl-27 {
    --bs-gutter-y: 8.4375rem;
  }
  .g-xxxl-28,
.gx-xxxl-28 {
    --bs-gutter-x: 8.75rem;
  }
  .g-xxxl-28,
.gy-xxxl-28 {
    --bs-gutter-y: 8.75rem;
  }
  .g-xxxl-29,
.gx-xxxl-29 {
    --bs-gutter-x: 9.0625rem;
  }
  .g-xxxl-29,
.gy-xxxl-29 {
    --bs-gutter-y: 9.0625rem;
  }
  .g-xxxl-30,
.gx-xxxl-30 {
    --bs-gutter-x: 9.375rem;
  }
  .g-xxxl-30,
.gy-xxxl-30 {
    --bs-gutter-y: 9.375rem;
  }
  .g-xxxl-31,
.gx-xxxl-31 {
    --bs-gutter-x: 9.6875rem;
  }
  .g-xxxl-31,
.gy-xxxl-31 {
    --bs-gutter-y: 9.6875rem;
  }
  .g-xxxl-32,
.gx-xxxl-32 {
    --bs-gutter-x: 10rem;
  }
  .g-xxxl-32,
.gy-xxxl-32 {
    --bs-gutter-y: 10rem;
  }
  .g-xxxl-33,
.gx-xxxl-33 {
    --bs-gutter-x: 10.3125rem;
  }
  .g-xxxl-33,
.gy-xxxl-33 {
    --bs-gutter-y: 10.3125rem;
  }
  .g-xxxl-34,
.gx-xxxl-34 {
    --bs-gutter-x: 10.625rem;
  }
  .g-xxxl-34,
.gy-xxxl-34 {
    --bs-gutter-y: 10.625rem;
  }
  .g-xxxl-35,
.gx-xxxl-35 {
    --bs-gutter-x: 10.9375rem;
  }
  .g-xxxl-35,
.gy-xxxl-35 {
    --bs-gutter-y: 10.9375rem;
  }
  .g-xxxl-36,
.gx-xxxl-36 {
    --bs-gutter-x: 11.25rem;
  }
  .g-xxxl-36,
.gy-xxxl-36 {
    --bs-gutter-y: 11.25rem;
  }
  .g-xxxl-37,
.gx-xxxl-37 {
    --bs-gutter-x: 11.5625rem;
  }
  .g-xxxl-37,
.gy-xxxl-37 {
    --bs-gutter-y: 11.5625rem;
  }
  .g-xxxl-38,
.gx-xxxl-38 {
    --bs-gutter-x: 11.875rem;
  }
  .g-xxxl-38,
.gy-xxxl-38 {
    --bs-gutter-y: 11.875rem;
  }
  .g-xxxl-39,
.gx-xxxl-39 {
    --bs-gutter-x: 12.1875rem;
  }
  .g-xxxl-39,
.gy-xxxl-39 {
    --bs-gutter-y: 12.1875rem;
  }
  .g-xxxl-40,
.gx-xxxl-40 {
    --bs-gutter-x: 12.5rem;
  }
  .g-xxxl-40,
.gy-xxxl-40 {
    --bs-gutter-y: 12.5rem;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: rgba(38, 38, 38, 0.3);
}
.table > :not(caption) > * > * {
  padding: 1.4375rem 0.9375rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:first-child) {
  border-top: 2px solid rgba(38, 38, 38, 0.3);
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #ffeacc;
  --bs-table-striped-bg: #ffebcf;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #ffecd1;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #ffecd0;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #ffecd1;
}

.table-secondary {
  --bs-table-bg: #d2d0d6;
  --bs-table-striped-bg: #d4d2d8;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #d7d5da;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #d5d4d9;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #d7d5da;
}

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #d3e8df;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #d6e9e0;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #d4e9e0;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #d6e9e0;
}

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #d1f5fc;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #d4f5fc;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #d3f5fc;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #d4f5fc;
}

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #fff4d0;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #fff4d2;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #fff4d1;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #fff4d2;
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #f8d9dc;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #f9dbde;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #f9dadd;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #f9dbde;
}

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #f8f9fa;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #f9fafb;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #f9f9fa;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #f9fafb;
}

.table-dark {
  --bs-table-bg: #212529;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #373b3e;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1719.98px) {
  .table-responsive-xxxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.625rem + 1px);
  padding-bottom: calc(0.625rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: calc(1.28125rem + 0.375vw);
}
@media (min-width: 1200px) {
  .col-form-label-lg {
    font-size: 1.5625rem;
  }
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 1.09375rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.625rem 1.9375rem;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #F8F8F8;
  background-clip: padding-box;
  border: 1px solid #000;
  appearance: none;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #212529;
  background-color: #F8F8F8;
  border-color: #ffca80;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 148, 0, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.625rem 1.9375rem;
  margin: -0.625rem -1.9375rem;
  margin-inline-end: 1.9375rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}
.form-control::-webkit-file-upload-button {
  padding: 0.625rem 1.9375rem;
  margin: -0.625rem -1.9375rem;
  margin-inline-end: 1.9375rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.625rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 1.09375rem;
  border-radius: 0;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: calc(1.28125rem + 0.375vw);
  border-radius: 0;
}
@media (min-width: 1200px) {
  .form-control-lg {
    font-size: 1.5625rem;
  }
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 1.25rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.625rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.625rem 5.8125rem 0.625rem 1.9375rem;
  -moz-padding-start: calc(1.9375rem - 3px);
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #F8F8F8;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1.9375rem center;
  background-size: 16px 12px;
  border: 1px solid #000;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #ffca80;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 148, 0, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1.9375rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 1.09375rem;
  border-radius: 0;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: calc(1.28125rem + 0.375vw);
  border-radius: 0;
}
@media (min-width: 1200px) {
  .form-select-lg {
    font-size: 1.5625rem;
  }
}

.form-check {
  display: block;
  min-height: 1.875rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #F8F8F8;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #ffca80;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 148, 0, 0.25);
}
.form-check-input:checked {
  background-color: #FF9400;
  border-color: #FF9400;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #FF9400;
  border-color: #FF9400;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffca80'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #F8F8F8, 0 0 0 0.25rem rgba(255, 148, 0, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #F8F8F8, 0 0 0 0.25rem rgba(255, 148, 0, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #FF9400;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #ffdfb3;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #FF9400;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #ffdfb3;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 1.9375rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 1.9375rem;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.625rem 1.9375rem;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #000;
  border-radius: 0;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: calc(1.28125rem + 0.375vw);
  border-radius: 0;
}
@media (min-width: 1200px) {
  .input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
    font-size: 1.5625rem;
  }
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 1.09375rem;
  border-radius: 0;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 7.75rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 1.09375rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 1.25rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.3125rem) center;
  background-size: calc(0.75em + 0.625rem) calc(0.75em + 0.625rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.25rem);
  background-position: top calc(0.375em + 0.3125rem) right calc(0.375em + 0.3125rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 10.65625rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 1.9375rem center, center right 5.8125rem;
  background-size: 16px 12px, calc(0.75em + 0.625rem) calc(0.75em + 0.625rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 1.09375rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 1.25rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.3125rem) center;
  background-size: calc(0.75em + 0.625rem) calc(0.75em + 0.625rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.25rem);
  background-position: top calc(0.375em + 0.3125rem) right calc(0.375em + 0.3125rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 10.65625rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 1.9375rem center, center right 5.8125rem;
  background-size: 16px 12px, calc(0.75em + 0.625rem) calc(0.75em + 0.625rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 600;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.625rem 1.9375rem;
  font-size: 1.25rem;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 148, 0, 0.25);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #FF9400;
  border-color: #FF9400;
}
.btn-primary:hover {
  color: #fff;
  background-color: #d97e00;
  border-color: #cc7600;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #d97e00;
  border-color: #cc7600;
  box-shadow: 0 0 0 0.25rem rgba(255, 164, 38, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #cc7600;
  border-color: #bf6f00;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 164, 38, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #FF9400;
  border-color: #FF9400;
}

.btn-secondary {
  color: #fff;
  background-color: #1C1230;
  border-color: #1C1230;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #180f29;
  border-color: #160e26;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #180f29;
  border-color: #160e26;
  box-shadow: 0 0 0 0.25rem rgba(62, 54, 79, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #160e26;
  border-color: #150e24;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(62, 54, 79, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #fff;
  background-color: #1C1230;
  border-color: #1C1230;
}

.btn-active-bright {
  color: #fff;
  background-color: #ff471d;
  border-color: #ff471d;
}
.btn-active-bright:hover {
  color: #fff;
  background-color: #d93c19;
  border-color: #cc3917;
}
.btn-check:focus + .btn-active-bright, .btn-active-bright:focus {
  color: #fff;
  background-color: #d93c19;
  border-color: #cc3917;
  box-shadow: 0 0 0 0.25rem rgba(255, 99, 63, 0.5);
}
.btn-check:checked + .btn-active-bright, .btn-check:active + .btn-active-bright, .btn-active-bright:active, .btn-active-bright.active, .show > .btn-active-bright.dropdown-toggle {
  color: #fff;
  background-color: #cc3917;
  border-color: #bf3516;
}
.btn-check:checked + .btn-active-bright:focus, .btn-check:active + .btn-active-bright:focus, .btn-active-bright:active:focus, .btn-active-bright.active:focus, .show > .btn-active-bright.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 99, 63, 0.5);
}
.btn-active-bright:disabled, .btn-active-bright.disabled {
  color: #fff;
  background-color: #ff471d;
  border-color: #ff471d;
}

.btn-active-dark {
  color: #fff;
  background-color: #1C1230;
  border-color: #1C1230;
}
.btn-active-dark:hover {
  color: #fff;
  background-color: #180f29;
  border-color: #160e26;
}
.btn-check:focus + .btn-active-dark, .btn-active-dark:focus {
  color: #fff;
  background-color: #180f29;
  border-color: #160e26;
  box-shadow: 0 0 0 0.25rem rgba(62, 54, 79, 0.5);
}
.btn-check:checked + .btn-active-dark, .btn-check:active + .btn-active-dark, .btn-active-dark:active, .btn-active-dark.active, .show > .btn-active-dark.dropdown-toggle {
  color: #fff;
  background-color: #160e26;
  border-color: #150e24;
}
.btn-check:checked + .btn-active-dark:focus, .btn-check:active + .btn-active-dark:focus, .btn-active-dark:active:focus, .btn-active-dark.active:focus, .show > .btn-active-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(62, 54, 79, 0.5);
}
.btn-active-dark:disabled, .btn-active-dark.disabled {
  color: #fff;
  background-color: #1C1230;
  border-color: #1C1230;
}

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-success:hover {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #146c43;
  border-color: #13653f;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-info {
  color: #fff;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-info:hover {
  color: #fff;
  background-color: #0baccc;
  border-color: #0aa2c0;
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #fff;
  background-color: #0baccc;
  border-color: #0aa2c0;
  box-shadow: 0 0 0 0.25rem rgba(49, 210, 242, 0.5);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #0aa2c0;
  border-color: #0a98b4;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(49, 210, 242, 0.5);
}
.btn-info:disabled, .btn-info.disabled {
  color: #fff;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-warning {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #fff;
  background-color: #d9a406;
  border-color: #cc9a06;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #fff;
  background-color: #d9a406;
  border-color: #cc9a06;
  box-shadow: 0 0 0 0.25rem rgba(255, 202, 44, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #cc9a06;
  border-color: #bf9105;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 202, 44, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-white {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.btn-white:hover {
  color: #000;
  background-color: white;
  border-color: white;
}
.btn-check:focus + .btn-white, .btn-white:focus {
  color: #000;
  background-color: white;
  border-color: white;
  box-shadow: 0 0 0 0.25rem rgba(217, 217, 217, 0.5);
}
.btn-check:checked + .btn-white, .btn-check:active + .btn-white, .btn-white:active, .btn-white.active, .show > .btn-white.dropdown-toggle {
  color: #000;
  background-color: white;
  border-color: white;
}
.btn-check:checked + .btn-white:focus, .btn-check:active + .btn-white:focus, .btn-white:active:focus, .btn-white.active:focus, .show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 217, 217, 0.5);
}
.btn-white:disabled, .btn-white.disabled {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-primary {
  color: #FF9400;
  border-color: #FF9400;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #FF9400;
  border-color: #FF9400;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 148, 0, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #FF9400;
  border-color: #FF9400;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 148, 0, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #FF9400;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #1C1230;
  border-color: #1C1230;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #1C1230;
  border-color: #1C1230;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(28, 18, 48, 0.5);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #1C1230;
  border-color: #1C1230;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(28, 18, 48, 0.5);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #1C1230;
  background-color: transparent;
}

.btn-outline-active-bright {
  color: #ff471d;
  border-color: #ff471d;
}
.btn-outline-active-bright:hover {
  color: #fff;
  background-color: #ff471d;
  border-color: #ff471d;
}
.btn-check:focus + .btn-outline-active-bright, .btn-outline-active-bright:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 71, 29, 0.5);
}
.btn-check:checked + .btn-outline-active-bright, .btn-check:active + .btn-outline-active-bright, .btn-outline-active-bright:active, .btn-outline-active-bright.active, .btn-outline-active-bright.dropdown-toggle.show {
  color: #fff;
  background-color: #ff471d;
  border-color: #ff471d;
}
.btn-check:checked + .btn-outline-active-bright:focus, .btn-check:active + .btn-outline-active-bright:focus, .btn-outline-active-bright:active:focus, .btn-outline-active-bright.active:focus, .btn-outline-active-bright.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 71, 29, 0.5);
}
.btn-outline-active-bright:disabled, .btn-outline-active-bright.disabled {
  color: #ff471d;
  background-color: transparent;
}

.btn-outline-active-dark {
  color: #1C1230;
  border-color: #1C1230;
}
.btn-outline-active-dark:hover {
  color: #fff;
  background-color: #1C1230;
  border-color: #1C1230;
}
.btn-check:focus + .btn-outline-active-dark, .btn-outline-active-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(28, 18, 48, 0.5);
}
.btn-check:checked + .btn-outline-active-dark, .btn-check:active + .btn-outline-active-dark, .btn-outline-active-dark:active, .btn-outline-active-dark.active, .btn-outline-active-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #1C1230;
  border-color: #1C1230;
}
.btn-check:checked + .btn-outline-active-dark:focus, .btn-check:active + .btn-outline-active-dark:focus, .btn-outline-active-dark:active:focus, .btn-outline-active-dark.active:focus, .btn-outline-active-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(28, 18, 48, 0.5);
}
.btn-outline-active-dark:disabled, .btn-outline-active-dark.disabled {
  color: #1C1230;
  background-color: transparent;
}

.btn-outline-success {
  color: #198754;
  border-color: #198754;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #198754;
  background-color: transparent;
}

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #fff;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #0dcaf0;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-white {
  color: #fff;
  border-color: #fff;
}
.btn-outline-white:hover {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.btn-check:focus + .btn-outline-white, .btn-outline-white:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-outline-white, .btn-check:active + .btn-outline-white, .btn-outline-white:active, .btn-outline-white.active, .btn-outline-white.dropdown-toggle.show {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.btn-check:checked + .btn-outline-white:focus, .btn-check:active + .btn-outline-white:focus, .btn-outline-white:active:focus, .btn-outline-white.active:focus, .btn-outline-white.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-outline-white:disabled, .btn-outline-white.disabled {
  color: #fff;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #FF9400;
  text-decoration: underline;
}
.btn-link:hover {
  color: #cc7600;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: calc(1.28125rem + 0.375vw);
  border-radius: 0;
}
@media (min-width: 1200px) {
  .btn-lg, .btn-group-lg > .btn {
    font-size: 1.5625rem;
  }
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 1.09375rem;
  border-radius: 0;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1720px) {
  .dropdown-menu-xxxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #1e2125;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #FF9400;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1.09375rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #FF9400;
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 1.453125rem;
  padding-left: 1.453125rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #000;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #cc7600;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #F8F8F8;
  border-color: #dee2e6 #dee2e6 #F8F8F8;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #FF9400;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1.25rem;
  color: #212529;
  text-align: left;
  background-color: #F8F8F8;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #e68500;
  background-color: #fff4e6;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e68500'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #ffca80;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 148, 0, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #F8F8F8;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  font-size: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 1.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 1.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: #000;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #FF9400;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #fff;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #cc7600;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  color: #cc7600;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 148, 0, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #1C1230;
  border-color: #1C1230;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: calc(1.28125rem + 0.375vw);
}
@media (min-width: 1200px) {
  .pagination-lg .page-link {
    font-size: 1.5625rem;
  }
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 1.09375rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #995900;
  background-color: #ffeacc;
  border-color: #ffdfb3;
}
.alert-primary .alert-link {
  color: #7a4700;
}

.alert-secondary {
  color: #110b1d;
  background-color: #d2d0d6;
  border-color: #bbb8c1;
}
.alert-secondary .alert-link {
  color: #0e0917;
}

.alert-active-bright {
  color: #992b11;
  background-color: #ffdad2;
  border-color: #ffc8bb;
}
.alert-active-bright .alert-link {
  color: #7a220e;
}

.alert-active-dark {
  color: #110b1d;
  background-color: #d2d0d6;
  border-color: #bbb8c1;
}
.alert-active-dark .alert-link {
  color: #0e0917;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}
.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  color: #087990;
  background-color: #cff4fc;
  border-color: #b6effb;
}
.alert-info .alert-link {
  color: #066173;
}

.alert-warning {
  color: #997404;
  background-color: #fff3cd;
  border-color: #ffecb5;
}
.alert-warning .alert-link {
  color: #7a5d03;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-white {
  color: #999999;
  background-color: white;
  border-color: white;
}
.alert-white .alert-link {
  color: #7a7a7a;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1719.98px) {
  .modal-fullscreen-xxxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxxl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1.09375rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #FF9400;
}
.link-primary:hover, .link-primary:focus {
  color: #cc7600;
}

.link-secondary {
  color: #1C1230;
}
.link-secondary:hover, .link-secondary:focus {
  color: #160e26;
}

.link-active-bright {
  color: #ff471d;
}
.link-active-bright:hover, .link-active-bright:focus {
  color: #cc3917;
}

.link-active-dark {
  color: #1C1230;
}
.link-active-dark:hover, .link-active-dark:focus {
  color: #160e26;
}

.link-success {
  color: #198754;
}
.link-success:hover, .link-success:focus {
  color: #146c43;
}

.link-info {
  color: #0dcaf0;
}
.link-info:hover, .link-info:focus {
  color: #0aa2c0;
}

.link-warning {
  color: #ffc107;
}
.link-warning:hover, .link-warning:focus {
  color: #cc9a06;
}

.link-danger {
  color: #dc3545;
}
.link-danger:hover, .link-danger:focus {
  color: #b02a37;
}

.link-white {
  color: #fff;
}
.link-white:hover, .link-white:focus {
  color: white;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1720px) {
  .sticky-xxxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.align-text-bottom {
  vertical-align: text-bottom;
}

.align-text-top {
  vertical-align: text-top;
}

.float-start {
  float: left;
}

.float-end {
  float: right;
}

.float-none {
  float: none;
}

.opacity-0 {
  opacity: 0;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-100 {
  opacity: 1;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.d-grid {
  display: grid;
}

.d-table {
  display: table;
}

.d-table-row {
  display: table-row;
}

.d-table-cell {
  display: table-cell;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-none {
  display: none;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
}

.shadow-none {
  box-shadow: none;
}

.position-static {
  position: static;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.top-50 {
  top: 50%;
}

.top-100 {
  top: 100%;
}

.bottom-0 {
  bottom: 0;
}

.bottom-50 {
  bottom: 50%;
}

.bottom-100 {
  bottom: 100%;
}

.start-0 {
  left: 0;
}

.start-50 {
  left: 50%;
}

.start-100 {
  left: 100%;
}

.end-0 {
  right: 0;
}

.end-50 {
  right: 50%;
}

.end-100 {
  right: 100%;
}

.translate-middle {
  transform: translate(-50%, -50%);
}

.translate-middle-x {
  transform: translateX(-50%);
}

.translate-middle-y {
  transform: translateY(-50%);
}

.border {
  border: 1px solid #dee2e6;
}

.border-0 {
  border: 0;
}

.border-top {
  border-top: 1px solid #dee2e6;
}

.border-top-0 {
  border-top: 0;
}

.border-end {
  border-right: 1px solid #dee2e6;
}

.border-end-0 {
  border-right: 0;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6;
}

.border-bottom-0 {
  border-bottom: 0;
}

.border-start {
  border-left: 1px solid #dee2e6;
}

.border-start-0 {
  border-left: 0;
}

.border-primary {
  border-color: #FF9400;
}

.border-secondary {
  border-color: #1C1230;
}

.border-active-bright {
  border-color: #ff471d;
}

.border-active-dark {
  border-color: #1C1230;
}

.border-success {
  border-color: #198754;
}

.border-info {
  border-color: #0dcaf0;
}

.border-warning {
  border-color: #ffc107;
}

.border-danger {
  border-color: #dc3545;
}

.border-white {
  border-color: #fff;
}

.border-1 {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-3 {
  border-width: 3px;
}

.border-4 {
  border-width: 4px;
}

.border-5 {
  border-width: 5px;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}

.mw-100 {
  max-width: 100%;
}

.vw-100 {
  width: 100vw;
}

.min-vw-100 {
  min-width: 100vw;
}

.h-25 {
  height: 25%;
}

.h-50 {
  height: 50%;
}

.h-75 {
  height: 75%;
}

.h-100 {
  height: 100%;
}

.h-auto {
  height: auto;
}

.mh-100 {
  max-height: 100%;
}

.vh-100 {
  height: 100vh;
}

.min-vh-100 {
  min-height: 100vh;
}

.flex-fill {
  flex: 1 1 auto;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.gap-0 {
  gap: 0rem;
}

.gap-1 {
  gap: 0.3125rem;
}

.gap-2 {
  gap: 0.625rem;
}

.gap-3 {
  gap: 0.9375rem;
}

.gap-4 {
  gap: 1.25rem;
}

.gap-5 {
  gap: 1.5625rem;
}

.gap-6 {
  gap: 1.875rem;
}

.gap-7 {
  gap: 2.1875rem;
}

.gap-8 {
  gap: 2.5rem;
}

.gap-9 {
  gap: 2.8125rem;
}

.gap-10 {
  gap: 3.125rem;
}

.gap-11 {
  gap: 3.4375rem;
}

.gap-12 {
  gap: 3.75rem;
}

.gap-13 {
  gap: 4.0625rem;
}

.gap-14 {
  gap: 4.375rem;
}

.gap-15 {
  gap: 4.6875rem;
}

.gap-16 {
  gap: 5rem;
}

.gap-17 {
  gap: 5.3125rem;
}

.gap-18 {
  gap: 5.625rem;
}

.gap-19 {
  gap: 5.9375rem;
}

.gap-20 {
  gap: 6.25rem;
}

.gap-21 {
  gap: 6.5625rem;
}

.gap-22 {
  gap: 6.875rem;
}

.gap-23 {
  gap: 7.1875rem;
}

.gap-24 {
  gap: 7.5rem;
}

.gap-25 {
  gap: 7.8125rem;
}

.gap-26 {
  gap: 8.125rem;
}

.gap-27 {
  gap: 8.4375rem;
}

.gap-28 {
  gap: 8.75rem;
}

.gap-29 {
  gap: 9.0625rem;
}

.gap-30 {
  gap: 9.375rem;
}

.gap-31 {
  gap: 9.6875rem;
}

.gap-32 {
  gap: 10rem;
}

.gap-33 {
  gap: 10.3125rem;
}

.gap-34 {
  gap: 10.625rem;
}

.gap-35 {
  gap: 10.9375rem;
}

.gap-36 {
  gap: 11.25rem;
}

.gap-37 {
  gap: 11.5625rem;
}

.gap-38 {
  gap: 11.875rem;
}

.gap-39 {
  gap: 12.1875rem;
}

.gap-40 {
  gap: 12.5rem;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-stretch {
  align-items: stretch;
}

.align-content-start {
  align-content: flex-start;
}

.align-content-end {
  align-content: flex-end;
}

.align-content-center {
  align-content: center;
}

.align-content-between {
  align-content: space-between;
}

.align-content-around {
  align-content: space-around;
}

.align-content-stretch {
  align-content: stretch;
}

.align-self-auto {
  align-self: auto;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-baseline {
  align-self: baseline;
}

.align-self-stretch {
  align-self: stretch;
}

.order-first {
  order: -1;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-last {
  order: 6;
}

.m-0 {
  margin: 0rem;
}

.m-1 {
  margin: 0.3125rem;
}

.m-2 {
  margin: 0.625rem;
}

.m-3 {
  margin: 0.9375rem;
}

.m-4 {
  margin: 1.25rem;
}

.m-5 {
  margin: 1.5625rem;
}

.m-6 {
  margin: 1.875rem;
}

.m-7 {
  margin: 2.1875rem;
}

.m-8 {
  margin: 2.5rem;
}

.m-9 {
  margin: 2.8125rem;
}

.m-10 {
  margin: 3.125rem;
}

.m-11 {
  margin: 3.4375rem;
}

.m-12 {
  margin: 3.75rem;
}

.m-13 {
  margin: 4.0625rem;
}

.m-14 {
  margin: 4.375rem;
}

.m-15 {
  margin: 4.6875rem;
}

.m-16 {
  margin: 5rem;
}

.m-17 {
  margin: 5.3125rem;
}

.m-18 {
  margin: 5.625rem;
}

.m-19 {
  margin: 5.9375rem;
}

.m-20 {
  margin: 6.25rem;
}

.m-21 {
  margin: 6.5625rem;
}

.m-22 {
  margin: 6.875rem;
}

.m-23 {
  margin: 7.1875rem;
}

.m-24 {
  margin: 7.5rem;
}

.m-25 {
  margin: 7.8125rem;
}

.m-26 {
  margin: 8.125rem;
}

.m-27 {
  margin: 8.4375rem;
}

.m-28 {
  margin: 8.75rem;
}

.m-29 {
  margin: 9.0625rem;
}

.m-30 {
  margin: 9.375rem;
}

.m-31 {
  margin: 9.6875rem;
}

.m-32 {
  margin: 10rem;
}

.m-33 {
  margin: 10.3125rem;
}

.m-34 {
  margin: 10.625rem;
}

.m-35 {
  margin: 10.9375rem;
}

.m-36 {
  margin: 11.25rem;
}

.m-37 {
  margin: 11.5625rem;
}

.m-38 {
  margin: 11.875rem;
}

.m-39 {
  margin: 12.1875rem;
}

.m-40 {
  margin: 12.5rem;
}

.m-auto {
  margin: auto;
}

.mx-0 {
  margin-right: 0rem;
  margin-left: 0rem;
}

.mx-1 {
  margin-right: 0.3125rem;
  margin-left: 0.3125rem;
}

.mx-2 {
  margin-right: 0.625rem;
  margin-left: 0.625rem;
}

.mx-3 {
  margin-right: 0.9375rem;
  margin-left: 0.9375rem;
}

.mx-4 {
  margin-right: 1.25rem;
  margin-left: 1.25rem;
}

.mx-5 {
  margin-right: 1.5625rem;
  margin-left: 1.5625rem;
}

.mx-6 {
  margin-right: 1.875rem;
  margin-left: 1.875rem;
}

.mx-7 {
  margin-right: 2.1875rem;
  margin-left: 2.1875rem;
}

.mx-8 {
  margin-right: 2.5rem;
  margin-left: 2.5rem;
}

.mx-9 {
  margin-right: 2.8125rem;
  margin-left: 2.8125rem;
}

.mx-10 {
  margin-right: 3.125rem;
  margin-left: 3.125rem;
}

.mx-11 {
  margin-right: 3.4375rem;
  margin-left: 3.4375rem;
}

.mx-12 {
  margin-right: 3.75rem;
  margin-left: 3.75rem;
}

.mx-13 {
  margin-right: 4.0625rem;
  margin-left: 4.0625rem;
}

.mx-14 {
  margin-right: 4.375rem;
  margin-left: 4.375rem;
}

.mx-15 {
  margin-right: 4.6875rem;
  margin-left: 4.6875rem;
}

.mx-16 {
  margin-right: 5rem;
  margin-left: 5rem;
}

.mx-17 {
  margin-right: 5.3125rem;
  margin-left: 5.3125rem;
}

.mx-18 {
  margin-right: 5.625rem;
  margin-left: 5.625rem;
}

.mx-19 {
  margin-right: 5.9375rem;
  margin-left: 5.9375rem;
}

.mx-20 {
  margin-right: 6.25rem;
  margin-left: 6.25rem;
}

.mx-21 {
  margin-right: 6.5625rem;
  margin-left: 6.5625rem;
}

.mx-22 {
  margin-right: 6.875rem;
  margin-left: 6.875rem;
}

.mx-23 {
  margin-right: 7.1875rem;
  margin-left: 7.1875rem;
}

.mx-24 {
  margin-right: 7.5rem;
  margin-left: 7.5rem;
}

.mx-25 {
  margin-right: 7.8125rem;
  margin-left: 7.8125rem;
}

.mx-26 {
  margin-right: 8.125rem;
  margin-left: 8.125rem;
}

.mx-27 {
  margin-right: 8.4375rem;
  margin-left: 8.4375rem;
}

.mx-28 {
  margin-right: 8.75rem;
  margin-left: 8.75rem;
}

.mx-29 {
  margin-right: 9.0625rem;
  margin-left: 9.0625rem;
}

.mx-30 {
  margin-right: 9.375rem;
  margin-left: 9.375rem;
}

.mx-31 {
  margin-right: 9.6875rem;
  margin-left: 9.6875rem;
}

.mx-32 {
  margin-right: 10rem;
  margin-left: 10rem;
}

.mx-33 {
  margin-right: 10.3125rem;
  margin-left: 10.3125rem;
}

.mx-34 {
  margin-right: 10.625rem;
  margin-left: 10.625rem;
}

.mx-35 {
  margin-right: 10.9375rem;
  margin-left: 10.9375rem;
}

.mx-36 {
  margin-right: 11.25rem;
  margin-left: 11.25rem;
}

.mx-37 {
  margin-right: 11.5625rem;
  margin-left: 11.5625rem;
}

.mx-38 {
  margin-right: 11.875rem;
  margin-left: 11.875rem;
}

.mx-39 {
  margin-right: 12.1875rem;
  margin-left: 12.1875rem;
}

.mx-40 {
  margin-right: 12.5rem;
  margin-left: 12.5rem;
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.my-0 {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.my-1 {
  margin-top: 0.3125rem;
  margin-bottom: 0.3125rem;
}

.my-2 {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.my-3 {
  margin-top: 0.9375rem;
  margin-bottom: 0.9375rem;
}

.my-4 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-5 {
  margin-top: 1.5625rem;
  margin-bottom: 1.5625rem;
}

.my-6 {
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
}

.my-7 {
  margin-top: 2.1875rem;
  margin-bottom: 2.1875rem;
}

.my-8 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-9 {
  margin-top: 2.8125rem;
  margin-bottom: 2.8125rem;
}

.my-10 {
  margin-top: 3.125rem;
  margin-bottom: 3.125rem;
}

.my-11 {
  margin-top: 3.4375rem;
  margin-bottom: 3.4375rem;
}

.my-12 {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
}

.my-13 {
  margin-top: 4.0625rem;
  margin-bottom: 4.0625rem;
}

.my-14 {
  margin-top: 4.375rem;
  margin-bottom: 4.375rem;
}

.my-15 {
  margin-top: 4.6875rem;
  margin-bottom: 4.6875rem;
}

.my-16 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.my-17 {
  margin-top: 5.3125rem;
  margin-bottom: 5.3125rem;
}

.my-18 {
  margin-top: 5.625rem;
  margin-bottom: 5.625rem;
}

.my-19 {
  margin-top: 5.9375rem;
  margin-bottom: 5.9375rem;
}

.my-20 {
  margin-top: 6.25rem;
  margin-bottom: 6.25rem;
}

.my-21 {
  margin-top: 6.5625rem;
  margin-bottom: 6.5625rem;
}

.my-22 {
  margin-top: 6.875rem;
  margin-bottom: 6.875rem;
}

.my-23 {
  margin-top: 7.1875rem;
  margin-bottom: 7.1875rem;
}

.my-24 {
  margin-top: 7.5rem;
  margin-bottom: 7.5rem;
}

.my-25 {
  margin-top: 7.8125rem;
  margin-bottom: 7.8125rem;
}

.my-26 {
  margin-top: 8.125rem;
  margin-bottom: 8.125rem;
}

.my-27 {
  margin-top: 8.4375rem;
  margin-bottom: 8.4375rem;
}

.my-28 {
  margin-top: 8.75rem;
  margin-bottom: 8.75rem;
}

.my-29 {
  margin-top: 9.0625rem;
  margin-bottom: 9.0625rem;
}

.my-30 {
  margin-top: 9.375rem;
  margin-bottom: 9.375rem;
}

.my-31 {
  margin-top: 9.6875rem;
  margin-bottom: 9.6875rem;
}

.my-32 {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.my-33 {
  margin-top: 10.3125rem;
  margin-bottom: 10.3125rem;
}

.my-34 {
  margin-top: 10.625rem;
  margin-bottom: 10.625rem;
}

.my-35 {
  margin-top: 10.9375rem;
  margin-bottom: 10.9375rem;
}

.my-36 {
  margin-top: 11.25rem;
  margin-bottom: 11.25rem;
}

.my-37 {
  margin-top: 11.5625rem;
  margin-bottom: 11.5625rem;
}

.my-38 {
  margin-top: 11.875rem;
  margin-bottom: 11.875rem;
}

.my-39 {
  margin-top: 12.1875rem;
  margin-bottom: 12.1875rem;
}

.my-40 {
  margin-top: 12.5rem;
  margin-bottom: 12.5rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mt-0 {
  margin-top: 0rem;
}

.mt-1 {
  margin-top: 0.3125rem;
}

.mt-2 {
  margin-top: 0.625rem;
}

.mt-3 {
  margin-top: 0.9375rem;
}

.mt-4 {
  margin-top: 1.25rem;
}

.mt-5 {
  margin-top: 1.5625rem;
}

.mt-6 {
  margin-top: 1.875rem;
}

.mt-7 {
  margin-top: 2.1875rem;
}

.mt-8 {
  margin-top: 2.5rem;
}

.mt-9 {
  margin-top: 2.8125rem;
}

.mt-10 {
  margin-top: 3.125rem;
}

.mt-11 {
  margin-top: 3.4375rem;
}

.mt-12 {
  margin-top: 3.75rem;
}

.mt-13 {
  margin-top: 4.0625rem;
}

.mt-14 {
  margin-top: 4.375rem;
}

.mt-15 {
  margin-top: 4.6875rem;
}

.mt-16 {
  margin-top: 5rem;
}

.mt-17 {
  margin-top: 5.3125rem;
}

.mt-18 {
  margin-top: 5.625rem;
}

.mt-19 {
  margin-top: 5.9375rem;
}

.mt-20 {
  margin-top: 6.25rem;
}

.mt-21 {
  margin-top: 6.5625rem;
}

.mt-22 {
  margin-top: 6.875rem;
}

.mt-23 {
  margin-top: 7.1875rem;
}

.mt-24 {
  margin-top: 7.5rem;
}

.mt-25 {
  margin-top: 7.8125rem;
}

.mt-26 {
  margin-top: 8.125rem;
}

.mt-27 {
  margin-top: 8.4375rem;
}

.mt-28 {
  margin-top: 8.75rem;
}

.mt-29 {
  margin-top: 9.0625rem;
}

.mt-30 {
  margin-top: 9.375rem;
}

.mt-31 {
  margin-top: 9.6875rem;
}

.mt-32 {
  margin-top: 10rem;
}

.mt-33 {
  margin-top: 10.3125rem;
}

.mt-34 {
  margin-top: 10.625rem;
}

.mt-35 {
  margin-top: 10.9375rem;
}

.mt-36 {
  margin-top: 11.25rem;
}

.mt-37 {
  margin-top: 11.5625rem;
}

.mt-38 {
  margin-top: 11.875rem;
}

.mt-39 {
  margin-top: 12.1875rem;
}

.mt-40 {
  margin-top: 12.5rem;
}

.mt-auto {
  margin-top: auto;
}

.me-0 {
  margin-right: 0rem;
}

.me-1 {
  margin-right: 0.3125rem;
}

.me-2 {
  margin-right: 0.625rem;
}

.me-3 {
  margin-right: 0.9375rem;
}

.me-4 {
  margin-right: 1.25rem;
}

.me-5 {
  margin-right: 1.5625rem;
}

.me-6 {
  margin-right: 1.875rem;
}

.me-7 {
  margin-right: 2.1875rem;
}

.me-8 {
  margin-right: 2.5rem;
}

.me-9 {
  margin-right: 2.8125rem;
}

.me-10 {
  margin-right: 3.125rem;
}

.me-11 {
  margin-right: 3.4375rem;
}

.me-12 {
  margin-right: 3.75rem;
}

.me-13 {
  margin-right: 4.0625rem;
}

.me-14 {
  margin-right: 4.375rem;
}

.me-15 {
  margin-right: 4.6875rem;
}

.me-16 {
  margin-right: 5rem;
}

.me-17 {
  margin-right: 5.3125rem;
}

.me-18 {
  margin-right: 5.625rem;
}

.me-19 {
  margin-right: 5.9375rem;
}

.me-20 {
  margin-right: 6.25rem;
}

.me-21 {
  margin-right: 6.5625rem;
}

.me-22 {
  margin-right: 6.875rem;
}

.me-23 {
  margin-right: 7.1875rem;
}

.me-24 {
  margin-right: 7.5rem;
}

.me-25 {
  margin-right: 7.8125rem;
}

.me-26 {
  margin-right: 8.125rem;
}

.me-27 {
  margin-right: 8.4375rem;
}

.me-28 {
  margin-right: 8.75rem;
}

.me-29 {
  margin-right: 9.0625rem;
}

.me-30 {
  margin-right: 9.375rem;
}

.me-31 {
  margin-right: 9.6875rem;
}

.me-32 {
  margin-right: 10rem;
}

.me-33 {
  margin-right: 10.3125rem;
}

.me-34 {
  margin-right: 10.625rem;
}

.me-35 {
  margin-right: 10.9375rem;
}

.me-36 {
  margin-right: 11.25rem;
}

.me-37 {
  margin-right: 11.5625rem;
}

.me-38 {
  margin-right: 11.875rem;
}

.me-39 {
  margin-right: 12.1875rem;
}

.me-40 {
  margin-right: 12.5rem;
}

.me-auto {
  margin-right: auto;
}

.mb-0 {
  margin-bottom: 0rem;
}

.mb-1 {
  margin-bottom: 0.3125rem;
}

.mb-2 {
  margin-bottom: 0.625rem;
}

.mb-3 {
  margin-bottom: 0.9375rem;
}

.mb-4 {
  margin-bottom: 1.25rem;
}

.mb-5 {
  margin-bottom: 1.5625rem;
}

.mb-6 {
  margin-bottom: 1.875rem;
}

.mb-7 {
  margin-bottom: 2.1875rem;
}

.mb-8 {
  margin-bottom: 2.5rem;
}

.mb-9 {
  margin-bottom: 2.8125rem;
}

.mb-10 {
  margin-bottom: 3.125rem;
}

.mb-11 {
  margin-bottom: 3.4375rem;
}

.mb-12 {
  margin-bottom: 3.75rem;
}

.mb-13 {
  margin-bottom: 4.0625rem;
}

.mb-14 {
  margin-bottom: 4.375rem;
}

.mb-15 {
  margin-bottom: 4.6875rem;
}

.mb-16 {
  margin-bottom: 5rem;
}

.mb-17 {
  margin-bottom: 5.3125rem;
}

.mb-18 {
  margin-bottom: 5.625rem;
}

.mb-19 {
  margin-bottom: 5.9375rem;
}

.mb-20 {
  margin-bottom: 6.25rem;
}

.mb-21 {
  margin-bottom: 6.5625rem;
}

.mb-22 {
  margin-bottom: 6.875rem;
}

.mb-23 {
  margin-bottom: 7.1875rem;
}

.mb-24 {
  margin-bottom: 7.5rem;
}

.mb-25 {
  margin-bottom: 7.8125rem;
}

.mb-26 {
  margin-bottom: 8.125rem;
}

.mb-27 {
  margin-bottom: 8.4375rem;
}

.mb-28 {
  margin-bottom: 8.75rem;
}

.mb-29 {
  margin-bottom: 9.0625rem;
}

.mb-30 {
  margin-bottom: 9.375rem;
}

.mb-31 {
  margin-bottom: 9.6875rem;
}

.mb-32 {
  margin-bottom: 10rem;
}

.mb-33 {
  margin-bottom: 10.3125rem;
}

.mb-34 {
  margin-bottom: 10.625rem;
}

.mb-35 {
  margin-bottom: 10.9375rem;
}

.mb-36 {
  margin-bottom: 11.25rem;
}

.mb-37 {
  margin-bottom: 11.5625rem;
}

.mb-38 {
  margin-bottom: 11.875rem;
}

.mb-39 {
  margin-bottom: 12.1875rem;
}

.mb-40 {
  margin-bottom: 12.5rem;
}

.mb-auto {
  margin-bottom: auto;
}

.ms-0 {
  margin-left: 0rem;
}

.ms-1 {
  margin-left: 0.3125rem;
}

.ms-2 {
  margin-left: 0.625rem;
}

.ms-3 {
  margin-left: 0.9375rem;
}

.ms-4 {
  margin-left: 1.25rem;
}

.ms-5 {
  margin-left: 1.5625rem;
}

.ms-6 {
  margin-left: 1.875rem;
}

.ms-7 {
  margin-left: 2.1875rem;
}

.ms-8 {
  margin-left: 2.5rem;
}

.ms-9 {
  margin-left: 2.8125rem;
}

.ms-10 {
  margin-left: 3.125rem;
}

.ms-11 {
  margin-left: 3.4375rem;
}

.ms-12 {
  margin-left: 3.75rem;
}

.ms-13 {
  margin-left: 4.0625rem;
}

.ms-14 {
  margin-left: 4.375rem;
}

.ms-15 {
  margin-left: 4.6875rem;
}

.ms-16 {
  margin-left: 5rem;
}

.ms-17 {
  margin-left: 5.3125rem;
}

.ms-18 {
  margin-left: 5.625rem;
}

.ms-19 {
  margin-left: 5.9375rem;
}

.ms-20 {
  margin-left: 6.25rem;
}

.ms-21 {
  margin-left: 6.5625rem;
}

.ms-22 {
  margin-left: 6.875rem;
}

.ms-23 {
  margin-left: 7.1875rem;
}

.ms-24 {
  margin-left: 7.5rem;
}

.ms-25 {
  margin-left: 7.8125rem;
}

.ms-26 {
  margin-left: 8.125rem;
}

.ms-27 {
  margin-left: 8.4375rem;
}

.ms-28 {
  margin-left: 8.75rem;
}

.ms-29 {
  margin-left: 9.0625rem;
}

.ms-30 {
  margin-left: 9.375rem;
}

.ms-31 {
  margin-left: 9.6875rem;
}

.ms-32 {
  margin-left: 10rem;
}

.ms-33 {
  margin-left: 10.3125rem;
}

.ms-34 {
  margin-left: 10.625rem;
}

.ms-35 {
  margin-left: 10.9375rem;
}

.ms-36 {
  margin-left: 11.25rem;
}

.ms-37 {
  margin-left: 11.5625rem;
}

.ms-38 {
  margin-left: 11.875rem;
}

.ms-39 {
  margin-left: 12.1875rem;
}

.ms-40 {
  margin-left: 12.5rem;
}

.ms-auto {
  margin-left: auto;
}

.p-0 {
  padding: 0rem;
}

.p-1 {
  padding: 0.3125rem;
}

.p-2 {
  padding: 0.625rem;
}

.p-3 {
  padding: 0.9375rem;
}

.p-4 {
  padding: 1.25rem;
}

.p-5 {
  padding: 1.5625rem;
}

.p-6 {
  padding: 1.875rem;
}

.p-7 {
  padding: 2.1875rem;
}

.p-8 {
  padding: 2.5rem;
}

.p-9 {
  padding: 2.8125rem;
}

.p-10 {
  padding: 3.125rem;
}

.p-11 {
  padding: 3.4375rem;
}

.p-12 {
  padding: 3.75rem;
}

.p-13 {
  padding: 4.0625rem;
}

.p-14 {
  padding: 4.375rem;
}

.p-15 {
  padding: 4.6875rem;
}

.p-16 {
  padding: 5rem;
}

.p-17 {
  padding: 5.3125rem;
}

.p-18 {
  padding: 5.625rem;
}

.p-19 {
  padding: 5.9375rem;
}

.p-20 {
  padding: 6.25rem;
}

.p-21 {
  padding: 6.5625rem;
}

.p-22 {
  padding: 6.875rem;
}

.p-23 {
  padding: 7.1875rem;
}

.p-24 {
  padding: 7.5rem;
}

.p-25 {
  padding: 7.8125rem;
}

.p-26 {
  padding: 8.125rem;
}

.p-27 {
  padding: 8.4375rem;
}

.p-28 {
  padding: 8.75rem;
}

.p-29 {
  padding: 9.0625rem;
}

.p-30 {
  padding: 9.375rem;
}

.p-31 {
  padding: 9.6875rem;
}

.p-32 {
  padding: 10rem;
}

.p-33 {
  padding: 10.3125rem;
}

.p-34 {
  padding: 10.625rem;
}

.p-35 {
  padding: 10.9375rem;
}

.p-36 {
  padding: 11.25rem;
}

.p-37 {
  padding: 11.5625rem;
}

.p-38 {
  padding: 11.875rem;
}

.p-39 {
  padding: 12.1875rem;
}

.p-40 {
  padding: 12.5rem;
}

.px-0 {
  padding-right: 0rem;
  padding-left: 0rem;
}

.px-1 {
  padding-right: 0.3125rem;
  padding-left: 0.3125rem;
}

.px-2 {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

.px-3 {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

.px-4 {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}

.px-5 {
  padding-right: 1.5625rem;
  padding-left: 1.5625rem;
}

.px-6 {
  padding-right: 1.875rem;
  padding-left: 1.875rem;
}

.px-7 {
  padding-right: 2.1875rem;
  padding-left: 2.1875rem;
}

.px-8 {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

.px-9 {
  padding-right: 2.8125rem;
  padding-left: 2.8125rem;
}

.px-10 {
  padding-right: 3.125rem;
  padding-left: 3.125rem;
}

.px-11 {
  padding-right: 3.4375rem;
  padding-left: 3.4375rem;
}

.px-12 {
  padding-right: 3.75rem;
  padding-left: 3.75rem;
}

.px-13 {
  padding-right: 4.0625rem;
  padding-left: 4.0625rem;
}

.px-14 {
  padding-right: 4.375rem;
  padding-left: 4.375rem;
}

.px-15 {
  padding-right: 4.6875rem;
  padding-left: 4.6875rem;
}

.px-16 {
  padding-right: 5rem;
  padding-left: 5rem;
}

.px-17 {
  padding-right: 5.3125rem;
  padding-left: 5.3125rem;
}

.px-18 {
  padding-right: 5.625rem;
  padding-left: 5.625rem;
}

.px-19 {
  padding-right: 5.9375rem;
  padding-left: 5.9375rem;
}

.px-20 {
  padding-right: 6.25rem;
  padding-left: 6.25rem;
}

.px-21 {
  padding-right: 6.5625rem;
  padding-left: 6.5625rem;
}

.px-22 {
  padding-right: 6.875rem;
  padding-left: 6.875rem;
}

.px-23 {
  padding-right: 7.1875rem;
  padding-left: 7.1875rem;
}

.px-24 {
  padding-right: 7.5rem;
  padding-left: 7.5rem;
}

.px-25 {
  padding-right: 7.8125rem;
  padding-left: 7.8125rem;
}

.px-26 {
  padding-right: 8.125rem;
  padding-left: 8.125rem;
}

.px-27 {
  padding-right: 8.4375rem;
  padding-left: 8.4375rem;
}

.px-28 {
  padding-right: 8.75rem;
  padding-left: 8.75rem;
}

.px-29 {
  padding-right: 9.0625rem;
  padding-left: 9.0625rem;
}

.px-30 {
  padding-right: 9.375rem;
  padding-left: 9.375rem;
}

.px-31 {
  padding-right: 9.6875rem;
  padding-left: 9.6875rem;
}

.px-32 {
  padding-right: 10rem;
  padding-left: 10rem;
}

.px-33 {
  padding-right: 10.3125rem;
  padding-left: 10.3125rem;
}

.px-34 {
  padding-right: 10.625rem;
  padding-left: 10.625rem;
}

.px-35 {
  padding-right: 10.9375rem;
  padding-left: 10.9375rem;
}

.px-36 {
  padding-right: 11.25rem;
  padding-left: 11.25rem;
}

.px-37 {
  padding-right: 11.5625rem;
  padding-left: 11.5625rem;
}

.px-38 {
  padding-right: 11.875rem;
  padding-left: 11.875rem;
}

.px-39 {
  padding-right: 12.1875rem;
  padding-left: 12.1875rem;
}

.px-40 {
  padding-right: 12.5rem;
  padding-left: 12.5rem;
}

.py-0 {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.py-1 {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
}

.py-2 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.py-3 {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
}

.py-4 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-5 {
  padding-top: 1.5625rem;
  padding-bottom: 1.5625rem;
}

.py-6 {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}

.py-7 {
  padding-top: 2.1875rem;
  padding-bottom: 2.1875rem;
}

.py-8 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-9 {
  padding-top: 2.8125rem;
  padding-bottom: 2.8125rem;
}

.py-10 {
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
}

.py-11 {
  padding-top: 3.4375rem;
  padding-bottom: 3.4375rem;
}

.py-12 {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
}

.py-13 {
  padding-top: 4.0625rem;
  padding-bottom: 4.0625rem;
}

.py-14 {
  padding-top: 4.375rem;
  padding-bottom: 4.375rem;
}

.py-15 {
  padding-top: 4.6875rem;
  padding-bottom: 4.6875rem;
}

.py-16 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-17 {
  padding-top: 5.3125rem;
  padding-bottom: 5.3125rem;
}

.py-18 {
  padding-top: 5.625rem;
  padding-bottom: 5.625rem;
}

.py-19 {
  padding-top: 5.9375rem;
  padding-bottom: 5.9375rem;
}

.py-20 {
  padding-top: 6.25rem;
  padding-bottom: 6.25rem;
}

.py-21 {
  padding-top: 6.5625rem;
  padding-bottom: 6.5625rem;
}

.py-22 {
  padding-top: 6.875rem;
  padding-bottom: 6.875rem;
}

.py-23 {
  padding-top: 7.1875rem;
  padding-bottom: 7.1875rem;
}

.py-24 {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
}

.py-25 {
  padding-top: 7.8125rem;
  padding-bottom: 7.8125rem;
}

.py-26 {
  padding-top: 8.125rem;
  padding-bottom: 8.125rem;
}

.py-27 {
  padding-top: 8.4375rem;
  padding-bottom: 8.4375rem;
}

.py-28 {
  padding-top: 8.75rem;
  padding-bottom: 8.75rem;
}

.py-29 {
  padding-top: 9.0625rem;
  padding-bottom: 9.0625rem;
}

.py-30 {
  padding-top: 9.375rem;
  padding-bottom: 9.375rem;
}

.py-31 {
  padding-top: 9.6875rem;
  padding-bottom: 9.6875rem;
}

.py-32 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.py-33 {
  padding-top: 10.3125rem;
  padding-bottom: 10.3125rem;
}

.py-34 {
  padding-top: 10.625rem;
  padding-bottom: 10.625rem;
}

.py-35 {
  padding-top: 10.9375rem;
  padding-bottom: 10.9375rem;
}

.py-36 {
  padding-top: 11.25rem;
  padding-bottom: 11.25rem;
}

.py-37 {
  padding-top: 11.5625rem;
  padding-bottom: 11.5625rem;
}

.py-38 {
  padding-top: 11.875rem;
  padding-bottom: 11.875rem;
}

.py-39 {
  padding-top: 12.1875rem;
  padding-bottom: 12.1875rem;
}

.py-40 {
  padding-top: 12.5rem;
  padding-bottom: 12.5rem;
}

.pt-0 {
  padding-top: 0rem;
}

.pt-1 {
  padding-top: 0.3125rem;
}

.pt-2 {
  padding-top: 0.625rem;
}

.pt-3 {
  padding-top: 0.9375rem;
}

.pt-4 {
  padding-top: 1.25rem;
}

.pt-5 {
  padding-top: 1.5625rem;
}

.pt-6 {
  padding-top: 1.875rem;
}

.pt-7 {
  padding-top: 2.1875rem;
}

.pt-8 {
  padding-top: 2.5rem;
}

.pt-9 {
  padding-top: 2.8125rem;
}

.pt-10 {
  padding-top: 3.125rem;
}

.pt-11 {
  padding-top: 3.4375rem;
}

.pt-12 {
  padding-top: 3.75rem;
}

.pt-13 {
  padding-top: 4.0625rem;
}

.pt-14 {
  padding-top: 4.375rem;
}

.pt-15 {
  padding-top: 4.6875rem;
}

.pt-16 {
  padding-top: 5rem;
}

.pt-17 {
  padding-top: 5.3125rem;
}

.pt-18 {
  padding-top: 5.625rem;
}

.pt-19 {
  padding-top: 5.9375rem;
}

.pt-20 {
  padding-top: 6.25rem;
}

.pt-21 {
  padding-top: 6.5625rem;
}

.pt-22 {
  padding-top: 6.875rem;
}

.pt-23 {
  padding-top: 7.1875rem;
}

.pt-24 {
  padding-top: 7.5rem;
}

.pt-25 {
  padding-top: 7.8125rem;
}

.pt-26 {
  padding-top: 8.125rem;
}

.pt-27 {
  padding-top: 8.4375rem;
}

.pt-28 {
  padding-top: 8.75rem;
}

.pt-29 {
  padding-top: 9.0625rem;
}

.pt-30 {
  padding-top: 9.375rem;
}

.pt-31 {
  padding-top: 9.6875rem;
}

.pt-32 {
  padding-top: 10rem;
}

.pt-33 {
  padding-top: 10.3125rem;
}

.pt-34 {
  padding-top: 10.625rem;
}

.pt-35 {
  padding-top: 10.9375rem;
}

.pt-36 {
  padding-top: 11.25rem;
}

.pt-37 {
  padding-top: 11.5625rem;
}

.pt-38 {
  padding-top: 11.875rem;
}

.pt-39 {
  padding-top: 12.1875rem;
}

.pt-40 {
  padding-top: 12.5rem;
}

.pe-0 {
  padding-right: 0rem;
}

.pe-1 {
  padding-right: 0.3125rem;
}

.pe-2 {
  padding-right: 0.625rem;
}

.pe-3 {
  padding-right: 0.9375rem;
}

.pe-4 {
  padding-right: 1.25rem;
}

.pe-5 {
  padding-right: 1.5625rem;
}

.pe-6 {
  padding-right: 1.875rem;
}

.pe-7 {
  padding-right: 2.1875rem;
}

.pe-8 {
  padding-right: 2.5rem;
}

.pe-9 {
  padding-right: 2.8125rem;
}

.pe-10 {
  padding-right: 3.125rem;
}

.pe-11 {
  padding-right: 3.4375rem;
}

.pe-12 {
  padding-right: 3.75rem;
}

.pe-13 {
  padding-right: 4.0625rem;
}

.pe-14 {
  padding-right: 4.375rem;
}

.pe-15 {
  padding-right: 4.6875rem;
}

.pe-16 {
  padding-right: 5rem;
}

.pe-17 {
  padding-right: 5.3125rem;
}

.pe-18 {
  padding-right: 5.625rem;
}

.pe-19 {
  padding-right: 5.9375rem;
}

.pe-20 {
  padding-right: 6.25rem;
}

.pe-21 {
  padding-right: 6.5625rem;
}

.pe-22 {
  padding-right: 6.875rem;
}

.pe-23 {
  padding-right: 7.1875rem;
}

.pe-24 {
  padding-right: 7.5rem;
}

.pe-25 {
  padding-right: 7.8125rem;
}

.pe-26 {
  padding-right: 8.125rem;
}

.pe-27 {
  padding-right: 8.4375rem;
}

.pe-28 {
  padding-right: 8.75rem;
}

.pe-29 {
  padding-right: 9.0625rem;
}

.pe-30 {
  padding-right: 9.375rem;
}

.pe-31 {
  padding-right: 9.6875rem;
}

.pe-32 {
  padding-right: 10rem;
}

.pe-33 {
  padding-right: 10.3125rem;
}

.pe-34 {
  padding-right: 10.625rem;
}

.pe-35 {
  padding-right: 10.9375rem;
}

.pe-36 {
  padding-right: 11.25rem;
}

.pe-37 {
  padding-right: 11.5625rem;
}

.pe-38 {
  padding-right: 11.875rem;
}

.pe-39 {
  padding-right: 12.1875rem;
}

.pe-40 {
  padding-right: 12.5rem;
}

.pb-0 {
  padding-bottom: 0rem;
}

.pb-1 {
  padding-bottom: 0.3125rem;
}

.pb-2 {
  padding-bottom: 0.625rem;
}

.pb-3 {
  padding-bottom: 0.9375rem;
}

.pb-4 {
  padding-bottom: 1.25rem;
}

.pb-5 {
  padding-bottom: 1.5625rem;
}

.pb-6 {
  padding-bottom: 1.875rem;
}

.pb-7 {
  padding-bottom: 2.1875rem;
}

.pb-8 {
  padding-bottom: 2.5rem;
}

.pb-9 {
  padding-bottom: 2.8125rem;
}

.pb-10 {
  padding-bottom: 3.125rem;
}

.pb-11 {
  padding-bottom: 3.4375rem;
}

.pb-12 {
  padding-bottom: 3.75rem;
}

.pb-13 {
  padding-bottom: 4.0625rem;
}

.pb-14 {
  padding-bottom: 4.375rem;
}

.pb-15 {
  padding-bottom: 4.6875rem;
}

.pb-16 {
  padding-bottom: 5rem;
}

.pb-17 {
  padding-bottom: 5.3125rem;
}

.pb-18 {
  padding-bottom: 5.625rem;
}

.pb-19 {
  padding-bottom: 5.9375rem;
}

.pb-20 {
  padding-bottom: 6.25rem;
}

.pb-21 {
  padding-bottom: 6.5625rem;
}

.pb-22 {
  padding-bottom: 6.875rem;
}

.pb-23 {
  padding-bottom: 7.1875rem;
}

.pb-24 {
  padding-bottom: 7.5rem;
}

.pb-25 {
  padding-bottom: 7.8125rem;
}

.pb-26 {
  padding-bottom: 8.125rem;
}

.pb-27 {
  padding-bottom: 8.4375rem;
}

.pb-28 {
  padding-bottom: 8.75rem;
}

.pb-29 {
  padding-bottom: 9.0625rem;
}

.pb-30 {
  padding-bottom: 9.375rem;
}

.pb-31 {
  padding-bottom: 9.6875rem;
}

.pb-32 {
  padding-bottom: 10rem;
}

.pb-33 {
  padding-bottom: 10.3125rem;
}

.pb-34 {
  padding-bottom: 10.625rem;
}

.pb-35 {
  padding-bottom: 10.9375rem;
}

.pb-36 {
  padding-bottom: 11.25rem;
}

.pb-37 {
  padding-bottom: 11.5625rem;
}

.pb-38 {
  padding-bottom: 11.875rem;
}

.pb-39 {
  padding-bottom: 12.1875rem;
}

.pb-40 {
  padding-bottom: 12.5rem;
}

.ps-0 {
  padding-left: 0rem;
}

.ps-1 {
  padding-left: 0.3125rem;
}

.ps-2 {
  padding-left: 0.625rem;
}

.ps-3 {
  padding-left: 0.9375rem;
}

.ps-4 {
  padding-left: 1.25rem;
}

.ps-5 {
  padding-left: 1.5625rem;
}

.ps-6 {
  padding-left: 1.875rem;
}

.ps-7 {
  padding-left: 2.1875rem;
}

.ps-8 {
  padding-left: 2.5rem;
}

.ps-9 {
  padding-left: 2.8125rem;
}

.ps-10 {
  padding-left: 3.125rem;
}

.ps-11 {
  padding-left: 3.4375rem;
}

.ps-12 {
  padding-left: 3.75rem;
}

.ps-13 {
  padding-left: 4.0625rem;
}

.ps-14 {
  padding-left: 4.375rem;
}

.ps-15 {
  padding-left: 4.6875rem;
}

.ps-16 {
  padding-left: 5rem;
}

.ps-17 {
  padding-left: 5.3125rem;
}

.ps-18 {
  padding-left: 5.625rem;
}

.ps-19 {
  padding-left: 5.9375rem;
}

.ps-20 {
  padding-left: 6.25rem;
}

.ps-21 {
  padding-left: 6.5625rem;
}

.ps-22 {
  padding-left: 6.875rem;
}

.ps-23 {
  padding-left: 7.1875rem;
}

.ps-24 {
  padding-left: 7.5rem;
}

.ps-25 {
  padding-left: 7.8125rem;
}

.ps-26 {
  padding-left: 8.125rem;
}

.ps-27 {
  padding-left: 8.4375rem;
}

.ps-28 {
  padding-left: 8.75rem;
}

.ps-29 {
  padding-left: 9.0625rem;
}

.ps-30 {
  padding-left: 9.375rem;
}

.ps-31 {
  padding-left: 9.6875rem;
}

.ps-32 {
  padding-left: 10rem;
}

.ps-33 {
  padding-left: 10.3125rem;
}

.ps-34 {
  padding-left: 10.625rem;
}

.ps-35 {
  padding-left: 10.9375rem;
}

.ps-36 {
  padding-left: 11.25rem;
}

.ps-37 {
  padding-left: 11.5625rem;
}

.ps-38 {
  padding-left: 11.875rem;
}

.ps-39 {
  padding-left: 12.1875rem;
}

.ps-40 {
  padding-left: 12.5rem;
}

.font-monospace {
  font-family: var(--bs-font-monospace);
}

.fs-1 {
  font-size: calc(1.4375rem + 2.25vw);
}

.fs-2 {
  font-size: calc(1.375rem + 1.5vw);
}

.fs-3 {
  font-size: calc(1.34375rem + 1.125vw);
}

.fs-4 {
  font-size: calc(1.3125rem + 0.75vw);
}

.fs-5 {
  font-size: calc(1.28125rem + 0.375vw);
}

.fs-6 {
  font-size: 1.25rem;
}

.fst-italic {
  font-style: italic;
}

.fst-normal {
  font-style: normal;
}

.fw-light {
  font-weight: 300;
}

.fw-lighter {
  font-weight: lighter;
}

.fw-normal {
  font-weight: 400;
}

.fw-bold {
  font-weight: 700;
}

.fw-bolder {
  font-weight: bolder;
}

.lh-1 {
  line-height: 1;
}

.lh-sm {
  line-height: 1.25;
}

.lh-base {
  line-height: 1.5;
}

.lh-lg {
  line-height: 2;
}

.text-start {
  text-align: left;
}

.text-end {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-decoration-none {
  text-decoration: none;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-wrap {
  white-space: normal;
}

.text-nowrap {
  white-space: nowrap;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word;
  word-break: break-word;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity));
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity));
}

.text-active-bright {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-active-bright-rgb), var(--bs-text-opacity));
}

.text-active-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-active-dark-rgb), var(--bs-text-opacity));
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity));
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity));
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity));
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity));
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity));
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity));
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity));
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5);
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5);
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity));
}

.bg-active-bright {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-active-bright-rgb), var(--bs-bg-opacity));
}

.bg-active-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-active-dark-rgb), var(--bs-bg-opacity));
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity));
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity));
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity));
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity));
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity));
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity));
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity));
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient);
}

.user-select-all {
  user-select: all;
}

.user-select-auto {
  user-select: auto;
}

.user-select-none {
  user-select: none;
}

.pe-none {
  pointer-events: none;
}

.pe-auto {
  pointer-events: auto;
}

.rounded {
  border-radius: 0;
}

.rounded-0 {
  border-radius: 0;
}

.rounded-1 {
  border-radius: 0;
}

.rounded-2 {
  border-radius: 0;
}

.rounded-3 {
  border-radius: 0;
}

.rounded-circle {
  border-radius: 50%;
}

.rounded-pill {
  border-radius: 50rem;
}

.rounded-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rounded-end {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-start {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left;
  }
  .float-sm-end {
    float: right;
  }
  .float-sm-none {
    float: none;
  }
  .d-sm-inline {
    display: inline;
  }
  .d-sm-inline-block {
    display: inline-block;
  }
  .d-sm-block {
    display: block;
  }
  .d-sm-grid {
    display: grid;
  }
  .d-sm-table {
    display: table;
  }
  .d-sm-table-row {
    display: table-row;
  }
  .d-sm-table-cell {
    display: table-cell;
  }
  .d-sm-flex {
    display: flex;
  }
  .d-sm-inline-flex {
    display: inline-flex;
  }
  .d-sm-none {
    display: none;
  }
  .flex-sm-fill {
    flex: 1 1 auto;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-sm-grow-0 {
    flex-grow: 0;
  }
  .flex-sm-grow-1 {
    flex-grow: 1;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1;
  }
  .flex-sm-wrap {
    flex-wrap: wrap;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .gap-sm-0 {
    gap: 0rem;
  }
  .gap-sm-1 {
    gap: 0.3125rem;
  }
  .gap-sm-2 {
    gap: 0.625rem;
  }
  .gap-sm-3 {
    gap: 0.9375rem;
  }
  .gap-sm-4 {
    gap: 1.25rem;
  }
  .gap-sm-5 {
    gap: 1.5625rem;
  }
  .gap-sm-6 {
    gap: 1.875rem;
  }
  .gap-sm-7 {
    gap: 2.1875rem;
  }
  .gap-sm-8 {
    gap: 2.5rem;
  }
  .gap-sm-9 {
    gap: 2.8125rem;
  }
  .gap-sm-10 {
    gap: 3.125rem;
  }
  .gap-sm-11 {
    gap: 3.4375rem;
  }
  .gap-sm-12 {
    gap: 3.75rem;
  }
  .gap-sm-13 {
    gap: 4.0625rem;
  }
  .gap-sm-14 {
    gap: 4.375rem;
  }
  .gap-sm-15 {
    gap: 4.6875rem;
  }
  .gap-sm-16 {
    gap: 5rem;
  }
  .gap-sm-17 {
    gap: 5.3125rem;
  }
  .gap-sm-18 {
    gap: 5.625rem;
  }
  .gap-sm-19 {
    gap: 5.9375rem;
  }
  .gap-sm-20 {
    gap: 6.25rem;
  }
  .gap-sm-21 {
    gap: 6.5625rem;
  }
  .gap-sm-22 {
    gap: 6.875rem;
  }
  .gap-sm-23 {
    gap: 7.1875rem;
  }
  .gap-sm-24 {
    gap: 7.5rem;
  }
  .gap-sm-25 {
    gap: 7.8125rem;
  }
  .gap-sm-26 {
    gap: 8.125rem;
  }
  .gap-sm-27 {
    gap: 8.4375rem;
  }
  .gap-sm-28 {
    gap: 8.75rem;
  }
  .gap-sm-29 {
    gap: 9.0625rem;
  }
  .gap-sm-30 {
    gap: 9.375rem;
  }
  .gap-sm-31 {
    gap: 9.6875rem;
  }
  .gap-sm-32 {
    gap: 10rem;
  }
  .gap-sm-33 {
    gap: 10.3125rem;
  }
  .gap-sm-34 {
    gap: 10.625rem;
  }
  .gap-sm-35 {
    gap: 10.9375rem;
  }
  .gap-sm-36 {
    gap: 11.25rem;
  }
  .gap-sm-37 {
    gap: 11.5625rem;
  }
  .gap-sm-38 {
    gap: 11.875rem;
  }
  .gap-sm-39 {
    gap: 12.1875rem;
  }
  .gap-sm-40 {
    gap: 12.5rem;
  }
  .justify-content-sm-start {
    justify-content: flex-start;
  }
  .justify-content-sm-end {
    justify-content: flex-end;
  }
  .justify-content-sm-center {
    justify-content: center;
  }
  .justify-content-sm-between {
    justify-content: space-between;
  }
  .justify-content-sm-around {
    justify-content: space-around;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly;
  }
  .align-items-sm-start {
    align-items: flex-start;
  }
  .align-items-sm-end {
    align-items: flex-end;
  }
  .align-items-sm-center {
    align-items: center;
  }
  .align-items-sm-baseline {
    align-items: baseline;
  }
  .align-items-sm-stretch {
    align-items: stretch;
  }
  .align-content-sm-start {
    align-content: flex-start;
  }
  .align-content-sm-end {
    align-content: flex-end;
  }
  .align-content-sm-center {
    align-content: center;
  }
  .align-content-sm-between {
    align-content: space-between;
  }
  .align-content-sm-around {
    align-content: space-around;
  }
  .align-content-sm-stretch {
    align-content: stretch;
  }
  .align-self-sm-auto {
    align-self: auto;
  }
  .align-self-sm-start {
    align-self: flex-start;
  }
  .align-self-sm-end {
    align-self: flex-end;
  }
  .align-self-sm-center {
    align-self: center;
  }
  .align-self-sm-baseline {
    align-self: baseline;
  }
  .align-self-sm-stretch {
    align-self: stretch;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-last {
    order: 6;
  }
  .m-sm-0 {
    margin: 0rem;
  }
  .m-sm-1 {
    margin: 0.3125rem;
  }
  .m-sm-2 {
    margin: 0.625rem;
  }
  .m-sm-3 {
    margin: 0.9375rem;
  }
  .m-sm-4 {
    margin: 1.25rem;
  }
  .m-sm-5 {
    margin: 1.5625rem;
  }
  .m-sm-6 {
    margin: 1.875rem;
  }
  .m-sm-7 {
    margin: 2.1875rem;
  }
  .m-sm-8 {
    margin: 2.5rem;
  }
  .m-sm-9 {
    margin: 2.8125rem;
  }
  .m-sm-10 {
    margin: 3.125rem;
  }
  .m-sm-11 {
    margin: 3.4375rem;
  }
  .m-sm-12 {
    margin: 3.75rem;
  }
  .m-sm-13 {
    margin: 4.0625rem;
  }
  .m-sm-14 {
    margin: 4.375rem;
  }
  .m-sm-15 {
    margin: 4.6875rem;
  }
  .m-sm-16 {
    margin: 5rem;
  }
  .m-sm-17 {
    margin: 5.3125rem;
  }
  .m-sm-18 {
    margin: 5.625rem;
  }
  .m-sm-19 {
    margin: 5.9375rem;
  }
  .m-sm-20 {
    margin: 6.25rem;
  }
  .m-sm-21 {
    margin: 6.5625rem;
  }
  .m-sm-22 {
    margin: 6.875rem;
  }
  .m-sm-23 {
    margin: 7.1875rem;
  }
  .m-sm-24 {
    margin: 7.5rem;
  }
  .m-sm-25 {
    margin: 7.8125rem;
  }
  .m-sm-26 {
    margin: 8.125rem;
  }
  .m-sm-27 {
    margin: 8.4375rem;
  }
  .m-sm-28 {
    margin: 8.75rem;
  }
  .m-sm-29 {
    margin: 9.0625rem;
  }
  .m-sm-30 {
    margin: 9.375rem;
  }
  .m-sm-31 {
    margin: 9.6875rem;
  }
  .m-sm-32 {
    margin: 10rem;
  }
  .m-sm-33 {
    margin: 10.3125rem;
  }
  .m-sm-34 {
    margin: 10.625rem;
  }
  .m-sm-35 {
    margin: 10.9375rem;
  }
  .m-sm-36 {
    margin: 11.25rem;
  }
  .m-sm-37 {
    margin: 11.5625rem;
  }
  .m-sm-38 {
    margin: 11.875rem;
  }
  .m-sm-39 {
    margin: 12.1875rem;
  }
  .m-sm-40 {
    margin: 12.5rem;
  }
  .m-sm-auto {
    margin: auto;
  }
  .mx-sm-0 {
    margin-right: 0rem;
    margin-left: 0rem;
  }
  .mx-sm-1 {
    margin-right: 0.3125rem;
    margin-left: 0.3125rem;
  }
  .mx-sm-2 {
    margin-right: 0.625rem;
    margin-left: 0.625rem;
  }
  .mx-sm-3 {
    margin-right: 0.9375rem;
    margin-left: 0.9375rem;
  }
  .mx-sm-4 {
    margin-right: 1.25rem;
    margin-left: 1.25rem;
  }
  .mx-sm-5 {
    margin-right: 1.5625rem;
    margin-left: 1.5625rem;
  }
  .mx-sm-6 {
    margin-right: 1.875rem;
    margin-left: 1.875rem;
  }
  .mx-sm-7 {
    margin-right: 2.1875rem;
    margin-left: 2.1875rem;
  }
  .mx-sm-8 {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }
  .mx-sm-9 {
    margin-right: 2.8125rem;
    margin-left: 2.8125rem;
  }
  .mx-sm-10 {
    margin-right: 3.125rem;
    margin-left: 3.125rem;
  }
  .mx-sm-11 {
    margin-right: 3.4375rem;
    margin-left: 3.4375rem;
  }
  .mx-sm-12 {
    margin-right: 3.75rem;
    margin-left: 3.75rem;
  }
  .mx-sm-13 {
    margin-right: 4.0625rem;
    margin-left: 4.0625rem;
  }
  .mx-sm-14 {
    margin-right: 4.375rem;
    margin-left: 4.375rem;
  }
  .mx-sm-15 {
    margin-right: 4.6875rem;
    margin-left: 4.6875rem;
  }
  .mx-sm-16 {
    margin-right: 5rem;
    margin-left: 5rem;
  }
  .mx-sm-17 {
    margin-right: 5.3125rem;
    margin-left: 5.3125rem;
  }
  .mx-sm-18 {
    margin-right: 5.625rem;
    margin-left: 5.625rem;
  }
  .mx-sm-19 {
    margin-right: 5.9375rem;
    margin-left: 5.9375rem;
  }
  .mx-sm-20 {
    margin-right: 6.25rem;
    margin-left: 6.25rem;
  }
  .mx-sm-21 {
    margin-right: 6.5625rem;
    margin-left: 6.5625rem;
  }
  .mx-sm-22 {
    margin-right: 6.875rem;
    margin-left: 6.875rem;
  }
  .mx-sm-23 {
    margin-right: 7.1875rem;
    margin-left: 7.1875rem;
  }
  .mx-sm-24 {
    margin-right: 7.5rem;
    margin-left: 7.5rem;
  }
  .mx-sm-25 {
    margin-right: 7.8125rem;
    margin-left: 7.8125rem;
  }
  .mx-sm-26 {
    margin-right: 8.125rem;
    margin-left: 8.125rem;
  }
  .mx-sm-27 {
    margin-right: 8.4375rem;
    margin-left: 8.4375rem;
  }
  .mx-sm-28 {
    margin-right: 8.75rem;
    margin-left: 8.75rem;
  }
  .mx-sm-29 {
    margin-right: 9.0625rem;
    margin-left: 9.0625rem;
  }
  .mx-sm-30 {
    margin-right: 9.375rem;
    margin-left: 9.375rem;
  }
  .mx-sm-31 {
    margin-right: 9.6875rem;
    margin-left: 9.6875rem;
  }
  .mx-sm-32 {
    margin-right: 10rem;
    margin-left: 10rem;
  }
  .mx-sm-33 {
    margin-right: 10.3125rem;
    margin-left: 10.3125rem;
  }
  .mx-sm-34 {
    margin-right: 10.625rem;
    margin-left: 10.625rem;
  }
  .mx-sm-35 {
    margin-right: 10.9375rem;
    margin-left: 10.9375rem;
  }
  .mx-sm-36 {
    margin-right: 11.25rem;
    margin-left: 11.25rem;
  }
  .mx-sm-37 {
    margin-right: 11.5625rem;
    margin-left: 11.5625rem;
  }
  .mx-sm-38 {
    margin-right: 11.875rem;
    margin-left: 11.875rem;
  }
  .mx-sm-39 {
    margin-right: 12.1875rem;
    margin-left: 12.1875rem;
  }
  .mx-sm-40 {
    margin-right: 12.5rem;
    margin-left: 12.5rem;
  }
  .mx-sm-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-sm-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .my-sm-1 {
    margin-top: 0.3125rem;
    margin-bottom: 0.3125rem;
  }
  .my-sm-2 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .my-sm-3 {
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
  }
  .my-sm-4 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .my-sm-5 {
    margin-top: 1.5625rem;
    margin-bottom: 1.5625rem;
  }
  .my-sm-6 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }
  .my-sm-7 {
    margin-top: 2.1875rem;
    margin-bottom: 2.1875rem;
  }
  .my-sm-8 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .my-sm-9 {
    margin-top: 2.8125rem;
    margin-bottom: 2.8125rem;
  }
  .my-sm-10 {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }
  .my-sm-11 {
    margin-top: 3.4375rem;
    margin-bottom: 3.4375rem;
  }
  .my-sm-12 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }
  .my-sm-13 {
    margin-top: 4.0625rem;
    margin-bottom: 4.0625rem;
  }
  .my-sm-14 {
    margin-top: 4.375rem;
    margin-bottom: 4.375rem;
  }
  .my-sm-15 {
    margin-top: 4.6875rem;
    margin-bottom: 4.6875rem;
  }
  .my-sm-16 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .my-sm-17 {
    margin-top: 5.3125rem;
    margin-bottom: 5.3125rem;
  }
  .my-sm-18 {
    margin-top: 5.625rem;
    margin-bottom: 5.625rem;
  }
  .my-sm-19 {
    margin-top: 5.9375rem;
    margin-bottom: 5.9375rem;
  }
  .my-sm-20 {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
  }
  .my-sm-21 {
    margin-top: 6.5625rem;
    margin-bottom: 6.5625rem;
  }
  .my-sm-22 {
    margin-top: 6.875rem;
    margin-bottom: 6.875rem;
  }
  .my-sm-23 {
    margin-top: 7.1875rem;
    margin-bottom: 7.1875rem;
  }
  .my-sm-24 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }
  .my-sm-25 {
    margin-top: 7.8125rem;
    margin-bottom: 7.8125rem;
  }
  .my-sm-26 {
    margin-top: 8.125rem;
    margin-bottom: 8.125rem;
  }
  .my-sm-27 {
    margin-top: 8.4375rem;
    margin-bottom: 8.4375rem;
  }
  .my-sm-28 {
    margin-top: 8.75rem;
    margin-bottom: 8.75rem;
  }
  .my-sm-29 {
    margin-top: 9.0625rem;
    margin-bottom: 9.0625rem;
  }
  .my-sm-30 {
    margin-top: 9.375rem;
    margin-bottom: 9.375rem;
  }
  .my-sm-31 {
    margin-top: 9.6875rem;
    margin-bottom: 9.6875rem;
  }
  .my-sm-32 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .my-sm-33 {
    margin-top: 10.3125rem;
    margin-bottom: 10.3125rem;
  }
  .my-sm-34 {
    margin-top: 10.625rem;
    margin-bottom: 10.625rem;
  }
  .my-sm-35 {
    margin-top: 10.9375rem;
    margin-bottom: 10.9375rem;
  }
  .my-sm-36 {
    margin-top: 11.25rem;
    margin-bottom: 11.25rem;
  }
  .my-sm-37 {
    margin-top: 11.5625rem;
    margin-bottom: 11.5625rem;
  }
  .my-sm-38 {
    margin-top: 11.875rem;
    margin-bottom: 11.875rem;
  }
  .my-sm-39 {
    margin-top: 12.1875rem;
    margin-bottom: 12.1875rem;
  }
  .my-sm-40 {
    margin-top: 12.5rem;
    margin-bottom: 12.5rem;
  }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-sm-0 {
    margin-top: 0rem;
  }
  .mt-sm-1 {
    margin-top: 0.3125rem;
  }
  .mt-sm-2 {
    margin-top: 0.625rem;
  }
  .mt-sm-3 {
    margin-top: 0.9375rem;
  }
  .mt-sm-4 {
    margin-top: 1.25rem;
  }
  .mt-sm-5 {
    margin-top: 1.5625rem;
  }
  .mt-sm-6 {
    margin-top: 1.875rem;
  }
  .mt-sm-7 {
    margin-top: 2.1875rem;
  }
  .mt-sm-8 {
    margin-top: 2.5rem;
  }
  .mt-sm-9 {
    margin-top: 2.8125rem;
  }
  .mt-sm-10 {
    margin-top: 3.125rem;
  }
  .mt-sm-11 {
    margin-top: 3.4375rem;
  }
  .mt-sm-12 {
    margin-top: 3.75rem;
  }
  .mt-sm-13 {
    margin-top: 4.0625rem;
  }
  .mt-sm-14 {
    margin-top: 4.375rem;
  }
  .mt-sm-15 {
    margin-top: 4.6875rem;
  }
  .mt-sm-16 {
    margin-top: 5rem;
  }
  .mt-sm-17 {
    margin-top: 5.3125rem;
  }
  .mt-sm-18 {
    margin-top: 5.625rem;
  }
  .mt-sm-19 {
    margin-top: 5.9375rem;
  }
  .mt-sm-20 {
    margin-top: 6.25rem;
  }
  .mt-sm-21 {
    margin-top: 6.5625rem;
  }
  .mt-sm-22 {
    margin-top: 6.875rem;
  }
  .mt-sm-23 {
    margin-top: 7.1875rem;
  }
  .mt-sm-24 {
    margin-top: 7.5rem;
  }
  .mt-sm-25 {
    margin-top: 7.8125rem;
  }
  .mt-sm-26 {
    margin-top: 8.125rem;
  }
  .mt-sm-27 {
    margin-top: 8.4375rem;
  }
  .mt-sm-28 {
    margin-top: 8.75rem;
  }
  .mt-sm-29 {
    margin-top: 9.0625rem;
  }
  .mt-sm-30 {
    margin-top: 9.375rem;
  }
  .mt-sm-31 {
    margin-top: 9.6875rem;
  }
  .mt-sm-32 {
    margin-top: 10rem;
  }
  .mt-sm-33 {
    margin-top: 10.3125rem;
  }
  .mt-sm-34 {
    margin-top: 10.625rem;
  }
  .mt-sm-35 {
    margin-top: 10.9375rem;
  }
  .mt-sm-36 {
    margin-top: 11.25rem;
  }
  .mt-sm-37 {
    margin-top: 11.5625rem;
  }
  .mt-sm-38 {
    margin-top: 11.875rem;
  }
  .mt-sm-39 {
    margin-top: 12.1875rem;
  }
  .mt-sm-40 {
    margin-top: 12.5rem;
  }
  .mt-sm-auto {
    margin-top: auto;
  }
  .me-sm-0 {
    margin-right: 0rem;
  }
  .me-sm-1 {
    margin-right: 0.3125rem;
  }
  .me-sm-2 {
    margin-right: 0.625rem;
  }
  .me-sm-3 {
    margin-right: 0.9375rem;
  }
  .me-sm-4 {
    margin-right: 1.25rem;
  }
  .me-sm-5 {
    margin-right: 1.5625rem;
  }
  .me-sm-6 {
    margin-right: 1.875rem;
  }
  .me-sm-7 {
    margin-right: 2.1875rem;
  }
  .me-sm-8 {
    margin-right: 2.5rem;
  }
  .me-sm-9 {
    margin-right: 2.8125rem;
  }
  .me-sm-10 {
    margin-right: 3.125rem;
  }
  .me-sm-11 {
    margin-right: 3.4375rem;
  }
  .me-sm-12 {
    margin-right: 3.75rem;
  }
  .me-sm-13 {
    margin-right: 4.0625rem;
  }
  .me-sm-14 {
    margin-right: 4.375rem;
  }
  .me-sm-15 {
    margin-right: 4.6875rem;
  }
  .me-sm-16 {
    margin-right: 5rem;
  }
  .me-sm-17 {
    margin-right: 5.3125rem;
  }
  .me-sm-18 {
    margin-right: 5.625rem;
  }
  .me-sm-19 {
    margin-right: 5.9375rem;
  }
  .me-sm-20 {
    margin-right: 6.25rem;
  }
  .me-sm-21 {
    margin-right: 6.5625rem;
  }
  .me-sm-22 {
    margin-right: 6.875rem;
  }
  .me-sm-23 {
    margin-right: 7.1875rem;
  }
  .me-sm-24 {
    margin-right: 7.5rem;
  }
  .me-sm-25 {
    margin-right: 7.8125rem;
  }
  .me-sm-26 {
    margin-right: 8.125rem;
  }
  .me-sm-27 {
    margin-right: 8.4375rem;
  }
  .me-sm-28 {
    margin-right: 8.75rem;
  }
  .me-sm-29 {
    margin-right: 9.0625rem;
  }
  .me-sm-30 {
    margin-right: 9.375rem;
  }
  .me-sm-31 {
    margin-right: 9.6875rem;
  }
  .me-sm-32 {
    margin-right: 10rem;
  }
  .me-sm-33 {
    margin-right: 10.3125rem;
  }
  .me-sm-34 {
    margin-right: 10.625rem;
  }
  .me-sm-35 {
    margin-right: 10.9375rem;
  }
  .me-sm-36 {
    margin-right: 11.25rem;
  }
  .me-sm-37 {
    margin-right: 11.5625rem;
  }
  .me-sm-38 {
    margin-right: 11.875rem;
  }
  .me-sm-39 {
    margin-right: 12.1875rem;
  }
  .me-sm-40 {
    margin-right: 12.5rem;
  }
  .me-sm-auto {
    margin-right: auto;
  }
  .mb-sm-0 {
    margin-bottom: 0rem;
  }
  .mb-sm-1 {
    margin-bottom: 0.3125rem;
  }
  .mb-sm-2 {
    margin-bottom: 0.625rem;
  }
  .mb-sm-3 {
    margin-bottom: 0.9375rem;
  }
  .mb-sm-4 {
    margin-bottom: 1.25rem;
  }
  .mb-sm-5 {
    margin-bottom: 1.5625rem;
  }
  .mb-sm-6 {
    margin-bottom: 1.875rem;
  }
  .mb-sm-7 {
    margin-bottom: 2.1875rem;
  }
  .mb-sm-8 {
    margin-bottom: 2.5rem;
  }
  .mb-sm-9 {
    margin-bottom: 2.8125rem;
  }
  .mb-sm-10 {
    margin-bottom: 3.125rem;
  }
  .mb-sm-11 {
    margin-bottom: 3.4375rem;
  }
  .mb-sm-12 {
    margin-bottom: 3.75rem;
  }
  .mb-sm-13 {
    margin-bottom: 4.0625rem;
  }
  .mb-sm-14 {
    margin-bottom: 4.375rem;
  }
  .mb-sm-15 {
    margin-bottom: 4.6875rem;
  }
  .mb-sm-16 {
    margin-bottom: 5rem;
  }
  .mb-sm-17 {
    margin-bottom: 5.3125rem;
  }
  .mb-sm-18 {
    margin-bottom: 5.625rem;
  }
  .mb-sm-19 {
    margin-bottom: 5.9375rem;
  }
  .mb-sm-20 {
    margin-bottom: 6.25rem;
  }
  .mb-sm-21 {
    margin-bottom: 6.5625rem;
  }
  .mb-sm-22 {
    margin-bottom: 6.875rem;
  }
  .mb-sm-23 {
    margin-bottom: 7.1875rem;
  }
  .mb-sm-24 {
    margin-bottom: 7.5rem;
  }
  .mb-sm-25 {
    margin-bottom: 7.8125rem;
  }
  .mb-sm-26 {
    margin-bottom: 8.125rem;
  }
  .mb-sm-27 {
    margin-bottom: 8.4375rem;
  }
  .mb-sm-28 {
    margin-bottom: 8.75rem;
  }
  .mb-sm-29 {
    margin-bottom: 9.0625rem;
  }
  .mb-sm-30 {
    margin-bottom: 9.375rem;
  }
  .mb-sm-31 {
    margin-bottom: 9.6875rem;
  }
  .mb-sm-32 {
    margin-bottom: 10rem;
  }
  .mb-sm-33 {
    margin-bottom: 10.3125rem;
  }
  .mb-sm-34 {
    margin-bottom: 10.625rem;
  }
  .mb-sm-35 {
    margin-bottom: 10.9375rem;
  }
  .mb-sm-36 {
    margin-bottom: 11.25rem;
  }
  .mb-sm-37 {
    margin-bottom: 11.5625rem;
  }
  .mb-sm-38 {
    margin-bottom: 11.875rem;
  }
  .mb-sm-39 {
    margin-bottom: 12.1875rem;
  }
  .mb-sm-40 {
    margin-bottom: 12.5rem;
  }
  .mb-sm-auto {
    margin-bottom: auto;
  }
  .ms-sm-0 {
    margin-left: 0rem;
  }
  .ms-sm-1 {
    margin-left: 0.3125rem;
  }
  .ms-sm-2 {
    margin-left: 0.625rem;
  }
  .ms-sm-3 {
    margin-left: 0.9375rem;
  }
  .ms-sm-4 {
    margin-left: 1.25rem;
  }
  .ms-sm-5 {
    margin-left: 1.5625rem;
  }
  .ms-sm-6 {
    margin-left: 1.875rem;
  }
  .ms-sm-7 {
    margin-left: 2.1875rem;
  }
  .ms-sm-8 {
    margin-left: 2.5rem;
  }
  .ms-sm-9 {
    margin-left: 2.8125rem;
  }
  .ms-sm-10 {
    margin-left: 3.125rem;
  }
  .ms-sm-11 {
    margin-left: 3.4375rem;
  }
  .ms-sm-12 {
    margin-left: 3.75rem;
  }
  .ms-sm-13 {
    margin-left: 4.0625rem;
  }
  .ms-sm-14 {
    margin-left: 4.375rem;
  }
  .ms-sm-15 {
    margin-left: 4.6875rem;
  }
  .ms-sm-16 {
    margin-left: 5rem;
  }
  .ms-sm-17 {
    margin-left: 5.3125rem;
  }
  .ms-sm-18 {
    margin-left: 5.625rem;
  }
  .ms-sm-19 {
    margin-left: 5.9375rem;
  }
  .ms-sm-20 {
    margin-left: 6.25rem;
  }
  .ms-sm-21 {
    margin-left: 6.5625rem;
  }
  .ms-sm-22 {
    margin-left: 6.875rem;
  }
  .ms-sm-23 {
    margin-left: 7.1875rem;
  }
  .ms-sm-24 {
    margin-left: 7.5rem;
  }
  .ms-sm-25 {
    margin-left: 7.8125rem;
  }
  .ms-sm-26 {
    margin-left: 8.125rem;
  }
  .ms-sm-27 {
    margin-left: 8.4375rem;
  }
  .ms-sm-28 {
    margin-left: 8.75rem;
  }
  .ms-sm-29 {
    margin-left: 9.0625rem;
  }
  .ms-sm-30 {
    margin-left: 9.375rem;
  }
  .ms-sm-31 {
    margin-left: 9.6875rem;
  }
  .ms-sm-32 {
    margin-left: 10rem;
  }
  .ms-sm-33 {
    margin-left: 10.3125rem;
  }
  .ms-sm-34 {
    margin-left: 10.625rem;
  }
  .ms-sm-35 {
    margin-left: 10.9375rem;
  }
  .ms-sm-36 {
    margin-left: 11.25rem;
  }
  .ms-sm-37 {
    margin-left: 11.5625rem;
  }
  .ms-sm-38 {
    margin-left: 11.875rem;
  }
  .ms-sm-39 {
    margin-left: 12.1875rem;
  }
  .ms-sm-40 {
    margin-left: 12.5rem;
  }
  .ms-sm-auto {
    margin-left: auto;
  }
  .p-sm-0 {
    padding: 0rem;
  }
  .p-sm-1 {
    padding: 0.3125rem;
  }
  .p-sm-2 {
    padding: 0.625rem;
  }
  .p-sm-3 {
    padding: 0.9375rem;
  }
  .p-sm-4 {
    padding: 1.25rem;
  }
  .p-sm-5 {
    padding: 1.5625rem;
  }
  .p-sm-6 {
    padding: 1.875rem;
  }
  .p-sm-7 {
    padding: 2.1875rem;
  }
  .p-sm-8 {
    padding: 2.5rem;
  }
  .p-sm-9 {
    padding: 2.8125rem;
  }
  .p-sm-10 {
    padding: 3.125rem;
  }
  .p-sm-11 {
    padding: 3.4375rem;
  }
  .p-sm-12 {
    padding: 3.75rem;
  }
  .p-sm-13 {
    padding: 4.0625rem;
  }
  .p-sm-14 {
    padding: 4.375rem;
  }
  .p-sm-15 {
    padding: 4.6875rem;
  }
  .p-sm-16 {
    padding: 5rem;
  }
  .p-sm-17 {
    padding: 5.3125rem;
  }
  .p-sm-18 {
    padding: 5.625rem;
  }
  .p-sm-19 {
    padding: 5.9375rem;
  }
  .p-sm-20 {
    padding: 6.25rem;
  }
  .p-sm-21 {
    padding: 6.5625rem;
  }
  .p-sm-22 {
    padding: 6.875rem;
  }
  .p-sm-23 {
    padding: 7.1875rem;
  }
  .p-sm-24 {
    padding: 7.5rem;
  }
  .p-sm-25 {
    padding: 7.8125rem;
  }
  .p-sm-26 {
    padding: 8.125rem;
  }
  .p-sm-27 {
    padding: 8.4375rem;
  }
  .p-sm-28 {
    padding: 8.75rem;
  }
  .p-sm-29 {
    padding: 9.0625rem;
  }
  .p-sm-30 {
    padding: 9.375rem;
  }
  .p-sm-31 {
    padding: 9.6875rem;
  }
  .p-sm-32 {
    padding: 10rem;
  }
  .p-sm-33 {
    padding: 10.3125rem;
  }
  .p-sm-34 {
    padding: 10.625rem;
  }
  .p-sm-35 {
    padding: 10.9375rem;
  }
  .p-sm-36 {
    padding: 11.25rem;
  }
  .p-sm-37 {
    padding: 11.5625rem;
  }
  .p-sm-38 {
    padding: 11.875rem;
  }
  .p-sm-39 {
    padding: 12.1875rem;
  }
  .p-sm-40 {
    padding: 12.5rem;
  }
  .px-sm-0 {
    padding-right: 0rem;
    padding-left: 0rem;
  }
  .px-sm-1 {
    padding-right: 0.3125rem;
    padding-left: 0.3125rem;
  }
  .px-sm-2 {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
  .px-sm-3 {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
  .px-sm-4 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .px-sm-5 {
    padding-right: 1.5625rem;
    padding-left: 1.5625rem;
  }
  .px-sm-6 {
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }
  .px-sm-7 {
    padding-right: 2.1875rem;
    padding-left: 2.1875rem;
  }
  .px-sm-8 {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .px-sm-9 {
    padding-right: 2.8125rem;
    padding-left: 2.8125rem;
  }
  .px-sm-10 {
    padding-right: 3.125rem;
    padding-left: 3.125rem;
  }
  .px-sm-11 {
    padding-right: 3.4375rem;
    padding-left: 3.4375rem;
  }
  .px-sm-12 {
    padding-right: 3.75rem;
    padding-left: 3.75rem;
  }
  .px-sm-13 {
    padding-right: 4.0625rem;
    padding-left: 4.0625rem;
  }
  .px-sm-14 {
    padding-right: 4.375rem;
    padding-left: 4.375rem;
  }
  .px-sm-15 {
    padding-right: 4.6875rem;
    padding-left: 4.6875rem;
  }
  .px-sm-16 {
    padding-right: 5rem;
    padding-left: 5rem;
  }
  .px-sm-17 {
    padding-right: 5.3125rem;
    padding-left: 5.3125rem;
  }
  .px-sm-18 {
    padding-right: 5.625rem;
    padding-left: 5.625rem;
  }
  .px-sm-19 {
    padding-right: 5.9375rem;
    padding-left: 5.9375rem;
  }
  .px-sm-20 {
    padding-right: 6.25rem;
    padding-left: 6.25rem;
  }
  .px-sm-21 {
    padding-right: 6.5625rem;
    padding-left: 6.5625rem;
  }
  .px-sm-22 {
    padding-right: 6.875rem;
    padding-left: 6.875rem;
  }
  .px-sm-23 {
    padding-right: 7.1875rem;
    padding-left: 7.1875rem;
  }
  .px-sm-24 {
    padding-right: 7.5rem;
    padding-left: 7.5rem;
  }
  .px-sm-25 {
    padding-right: 7.8125rem;
    padding-left: 7.8125rem;
  }
  .px-sm-26 {
    padding-right: 8.125rem;
    padding-left: 8.125rem;
  }
  .px-sm-27 {
    padding-right: 8.4375rem;
    padding-left: 8.4375rem;
  }
  .px-sm-28 {
    padding-right: 8.75rem;
    padding-left: 8.75rem;
  }
  .px-sm-29 {
    padding-right: 9.0625rem;
    padding-left: 9.0625rem;
  }
  .px-sm-30 {
    padding-right: 9.375rem;
    padding-left: 9.375rem;
  }
  .px-sm-31 {
    padding-right: 9.6875rem;
    padding-left: 9.6875rem;
  }
  .px-sm-32 {
    padding-right: 10rem;
    padding-left: 10rem;
  }
  .px-sm-33 {
    padding-right: 10.3125rem;
    padding-left: 10.3125rem;
  }
  .px-sm-34 {
    padding-right: 10.625rem;
    padding-left: 10.625rem;
  }
  .px-sm-35 {
    padding-right: 10.9375rem;
    padding-left: 10.9375rem;
  }
  .px-sm-36 {
    padding-right: 11.25rem;
    padding-left: 11.25rem;
  }
  .px-sm-37 {
    padding-right: 11.5625rem;
    padding-left: 11.5625rem;
  }
  .px-sm-38 {
    padding-right: 11.875rem;
    padding-left: 11.875rem;
  }
  .px-sm-39 {
    padding-right: 12.1875rem;
    padding-left: 12.1875rem;
  }
  .px-sm-40 {
    padding-right: 12.5rem;
    padding-left: 12.5rem;
  }
  .py-sm-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .py-sm-1 {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }
  .py-sm-2 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .py-sm-3 {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
  }
  .py-sm-4 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .py-sm-5 {
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem;
  }
  .py-sm-6 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }
  .py-sm-7 {
    padding-top: 2.1875rem;
    padding-bottom: 2.1875rem;
  }
  .py-sm-8 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .py-sm-9 {
    padding-top: 2.8125rem;
    padding-bottom: 2.8125rem;
  }
  .py-sm-10 {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
  }
  .py-sm-11 {
    padding-top: 3.4375rem;
    padding-bottom: 3.4375rem;
  }
  .py-sm-12 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
  .py-sm-13 {
    padding-top: 4.0625rem;
    padding-bottom: 4.0625rem;
  }
  .py-sm-14 {
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
  }
  .py-sm-15 {
    padding-top: 4.6875rem;
    padding-bottom: 4.6875rem;
  }
  .py-sm-16 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .py-sm-17 {
    padding-top: 5.3125rem;
    padding-bottom: 5.3125rem;
  }
  .py-sm-18 {
    padding-top: 5.625rem;
    padding-bottom: 5.625rem;
  }
  .py-sm-19 {
    padding-top: 5.9375rem;
    padding-bottom: 5.9375rem;
  }
  .py-sm-20 {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
  .py-sm-21 {
    padding-top: 6.5625rem;
    padding-bottom: 6.5625rem;
  }
  .py-sm-22 {
    padding-top: 6.875rem;
    padding-bottom: 6.875rem;
  }
  .py-sm-23 {
    padding-top: 7.1875rem;
    padding-bottom: 7.1875rem;
  }
  .py-sm-24 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .py-sm-25 {
    padding-top: 7.8125rem;
    padding-bottom: 7.8125rem;
  }
  .py-sm-26 {
    padding-top: 8.125rem;
    padding-bottom: 8.125rem;
  }
  .py-sm-27 {
    padding-top: 8.4375rem;
    padding-bottom: 8.4375rem;
  }
  .py-sm-28 {
    padding-top: 8.75rem;
    padding-bottom: 8.75rem;
  }
  .py-sm-29 {
    padding-top: 9.0625rem;
    padding-bottom: 9.0625rem;
  }
  .py-sm-30 {
    padding-top: 9.375rem;
    padding-bottom: 9.375rem;
  }
  .py-sm-31 {
    padding-top: 9.6875rem;
    padding-bottom: 9.6875rem;
  }
  .py-sm-32 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .py-sm-33 {
    padding-top: 10.3125rem;
    padding-bottom: 10.3125rem;
  }
  .py-sm-34 {
    padding-top: 10.625rem;
    padding-bottom: 10.625rem;
  }
  .py-sm-35 {
    padding-top: 10.9375rem;
    padding-bottom: 10.9375rem;
  }
  .py-sm-36 {
    padding-top: 11.25rem;
    padding-bottom: 11.25rem;
  }
  .py-sm-37 {
    padding-top: 11.5625rem;
    padding-bottom: 11.5625rem;
  }
  .py-sm-38 {
    padding-top: 11.875rem;
    padding-bottom: 11.875rem;
  }
  .py-sm-39 {
    padding-top: 12.1875rem;
    padding-bottom: 12.1875rem;
  }
  .py-sm-40 {
    padding-top: 12.5rem;
    padding-bottom: 12.5rem;
  }
  .pt-sm-0 {
    padding-top: 0rem;
  }
  .pt-sm-1 {
    padding-top: 0.3125rem;
  }
  .pt-sm-2 {
    padding-top: 0.625rem;
  }
  .pt-sm-3 {
    padding-top: 0.9375rem;
  }
  .pt-sm-4 {
    padding-top: 1.25rem;
  }
  .pt-sm-5 {
    padding-top: 1.5625rem;
  }
  .pt-sm-6 {
    padding-top: 1.875rem;
  }
  .pt-sm-7 {
    padding-top: 2.1875rem;
  }
  .pt-sm-8 {
    padding-top: 2.5rem;
  }
  .pt-sm-9 {
    padding-top: 2.8125rem;
  }
  .pt-sm-10 {
    padding-top: 3.125rem;
  }
  .pt-sm-11 {
    padding-top: 3.4375rem;
  }
  .pt-sm-12 {
    padding-top: 3.75rem;
  }
  .pt-sm-13 {
    padding-top: 4.0625rem;
  }
  .pt-sm-14 {
    padding-top: 4.375rem;
  }
  .pt-sm-15 {
    padding-top: 4.6875rem;
  }
  .pt-sm-16 {
    padding-top: 5rem;
  }
  .pt-sm-17 {
    padding-top: 5.3125rem;
  }
  .pt-sm-18 {
    padding-top: 5.625rem;
  }
  .pt-sm-19 {
    padding-top: 5.9375rem;
  }
  .pt-sm-20 {
    padding-top: 6.25rem;
  }
  .pt-sm-21 {
    padding-top: 6.5625rem;
  }
  .pt-sm-22 {
    padding-top: 6.875rem;
  }
  .pt-sm-23 {
    padding-top: 7.1875rem;
  }
  .pt-sm-24 {
    padding-top: 7.5rem;
  }
  .pt-sm-25 {
    padding-top: 7.8125rem;
  }
  .pt-sm-26 {
    padding-top: 8.125rem;
  }
  .pt-sm-27 {
    padding-top: 8.4375rem;
  }
  .pt-sm-28 {
    padding-top: 8.75rem;
  }
  .pt-sm-29 {
    padding-top: 9.0625rem;
  }
  .pt-sm-30 {
    padding-top: 9.375rem;
  }
  .pt-sm-31 {
    padding-top: 9.6875rem;
  }
  .pt-sm-32 {
    padding-top: 10rem;
  }
  .pt-sm-33 {
    padding-top: 10.3125rem;
  }
  .pt-sm-34 {
    padding-top: 10.625rem;
  }
  .pt-sm-35 {
    padding-top: 10.9375rem;
  }
  .pt-sm-36 {
    padding-top: 11.25rem;
  }
  .pt-sm-37 {
    padding-top: 11.5625rem;
  }
  .pt-sm-38 {
    padding-top: 11.875rem;
  }
  .pt-sm-39 {
    padding-top: 12.1875rem;
  }
  .pt-sm-40 {
    padding-top: 12.5rem;
  }
  .pe-sm-0 {
    padding-right: 0rem;
  }
  .pe-sm-1 {
    padding-right: 0.3125rem;
  }
  .pe-sm-2 {
    padding-right: 0.625rem;
  }
  .pe-sm-3 {
    padding-right: 0.9375rem;
  }
  .pe-sm-4 {
    padding-right: 1.25rem;
  }
  .pe-sm-5 {
    padding-right: 1.5625rem;
  }
  .pe-sm-6 {
    padding-right: 1.875rem;
  }
  .pe-sm-7 {
    padding-right: 2.1875rem;
  }
  .pe-sm-8 {
    padding-right: 2.5rem;
  }
  .pe-sm-9 {
    padding-right: 2.8125rem;
  }
  .pe-sm-10 {
    padding-right: 3.125rem;
  }
  .pe-sm-11 {
    padding-right: 3.4375rem;
  }
  .pe-sm-12 {
    padding-right: 3.75rem;
  }
  .pe-sm-13 {
    padding-right: 4.0625rem;
  }
  .pe-sm-14 {
    padding-right: 4.375rem;
  }
  .pe-sm-15 {
    padding-right: 4.6875rem;
  }
  .pe-sm-16 {
    padding-right: 5rem;
  }
  .pe-sm-17 {
    padding-right: 5.3125rem;
  }
  .pe-sm-18 {
    padding-right: 5.625rem;
  }
  .pe-sm-19 {
    padding-right: 5.9375rem;
  }
  .pe-sm-20 {
    padding-right: 6.25rem;
  }
  .pe-sm-21 {
    padding-right: 6.5625rem;
  }
  .pe-sm-22 {
    padding-right: 6.875rem;
  }
  .pe-sm-23 {
    padding-right: 7.1875rem;
  }
  .pe-sm-24 {
    padding-right: 7.5rem;
  }
  .pe-sm-25 {
    padding-right: 7.8125rem;
  }
  .pe-sm-26 {
    padding-right: 8.125rem;
  }
  .pe-sm-27 {
    padding-right: 8.4375rem;
  }
  .pe-sm-28 {
    padding-right: 8.75rem;
  }
  .pe-sm-29 {
    padding-right: 9.0625rem;
  }
  .pe-sm-30 {
    padding-right: 9.375rem;
  }
  .pe-sm-31 {
    padding-right: 9.6875rem;
  }
  .pe-sm-32 {
    padding-right: 10rem;
  }
  .pe-sm-33 {
    padding-right: 10.3125rem;
  }
  .pe-sm-34 {
    padding-right: 10.625rem;
  }
  .pe-sm-35 {
    padding-right: 10.9375rem;
  }
  .pe-sm-36 {
    padding-right: 11.25rem;
  }
  .pe-sm-37 {
    padding-right: 11.5625rem;
  }
  .pe-sm-38 {
    padding-right: 11.875rem;
  }
  .pe-sm-39 {
    padding-right: 12.1875rem;
  }
  .pe-sm-40 {
    padding-right: 12.5rem;
  }
  .pb-sm-0 {
    padding-bottom: 0rem;
  }
  .pb-sm-1 {
    padding-bottom: 0.3125rem;
  }
  .pb-sm-2 {
    padding-bottom: 0.625rem;
  }
  .pb-sm-3 {
    padding-bottom: 0.9375rem;
  }
  .pb-sm-4 {
    padding-bottom: 1.25rem;
  }
  .pb-sm-5 {
    padding-bottom: 1.5625rem;
  }
  .pb-sm-6 {
    padding-bottom: 1.875rem;
  }
  .pb-sm-7 {
    padding-bottom: 2.1875rem;
  }
  .pb-sm-8 {
    padding-bottom: 2.5rem;
  }
  .pb-sm-9 {
    padding-bottom: 2.8125rem;
  }
  .pb-sm-10 {
    padding-bottom: 3.125rem;
  }
  .pb-sm-11 {
    padding-bottom: 3.4375rem;
  }
  .pb-sm-12 {
    padding-bottom: 3.75rem;
  }
  .pb-sm-13 {
    padding-bottom: 4.0625rem;
  }
  .pb-sm-14 {
    padding-bottom: 4.375rem;
  }
  .pb-sm-15 {
    padding-bottom: 4.6875rem;
  }
  .pb-sm-16 {
    padding-bottom: 5rem;
  }
  .pb-sm-17 {
    padding-bottom: 5.3125rem;
  }
  .pb-sm-18 {
    padding-bottom: 5.625rem;
  }
  .pb-sm-19 {
    padding-bottom: 5.9375rem;
  }
  .pb-sm-20 {
    padding-bottom: 6.25rem;
  }
  .pb-sm-21 {
    padding-bottom: 6.5625rem;
  }
  .pb-sm-22 {
    padding-bottom: 6.875rem;
  }
  .pb-sm-23 {
    padding-bottom: 7.1875rem;
  }
  .pb-sm-24 {
    padding-bottom: 7.5rem;
  }
  .pb-sm-25 {
    padding-bottom: 7.8125rem;
  }
  .pb-sm-26 {
    padding-bottom: 8.125rem;
  }
  .pb-sm-27 {
    padding-bottom: 8.4375rem;
  }
  .pb-sm-28 {
    padding-bottom: 8.75rem;
  }
  .pb-sm-29 {
    padding-bottom: 9.0625rem;
  }
  .pb-sm-30 {
    padding-bottom: 9.375rem;
  }
  .pb-sm-31 {
    padding-bottom: 9.6875rem;
  }
  .pb-sm-32 {
    padding-bottom: 10rem;
  }
  .pb-sm-33 {
    padding-bottom: 10.3125rem;
  }
  .pb-sm-34 {
    padding-bottom: 10.625rem;
  }
  .pb-sm-35 {
    padding-bottom: 10.9375rem;
  }
  .pb-sm-36 {
    padding-bottom: 11.25rem;
  }
  .pb-sm-37 {
    padding-bottom: 11.5625rem;
  }
  .pb-sm-38 {
    padding-bottom: 11.875rem;
  }
  .pb-sm-39 {
    padding-bottom: 12.1875rem;
  }
  .pb-sm-40 {
    padding-bottom: 12.5rem;
  }
  .ps-sm-0 {
    padding-left: 0rem;
  }
  .ps-sm-1 {
    padding-left: 0.3125rem;
  }
  .ps-sm-2 {
    padding-left: 0.625rem;
  }
  .ps-sm-3 {
    padding-left: 0.9375rem;
  }
  .ps-sm-4 {
    padding-left: 1.25rem;
  }
  .ps-sm-5 {
    padding-left: 1.5625rem;
  }
  .ps-sm-6 {
    padding-left: 1.875rem;
  }
  .ps-sm-7 {
    padding-left: 2.1875rem;
  }
  .ps-sm-8 {
    padding-left: 2.5rem;
  }
  .ps-sm-9 {
    padding-left: 2.8125rem;
  }
  .ps-sm-10 {
    padding-left: 3.125rem;
  }
  .ps-sm-11 {
    padding-left: 3.4375rem;
  }
  .ps-sm-12 {
    padding-left: 3.75rem;
  }
  .ps-sm-13 {
    padding-left: 4.0625rem;
  }
  .ps-sm-14 {
    padding-left: 4.375rem;
  }
  .ps-sm-15 {
    padding-left: 4.6875rem;
  }
  .ps-sm-16 {
    padding-left: 5rem;
  }
  .ps-sm-17 {
    padding-left: 5.3125rem;
  }
  .ps-sm-18 {
    padding-left: 5.625rem;
  }
  .ps-sm-19 {
    padding-left: 5.9375rem;
  }
  .ps-sm-20 {
    padding-left: 6.25rem;
  }
  .ps-sm-21 {
    padding-left: 6.5625rem;
  }
  .ps-sm-22 {
    padding-left: 6.875rem;
  }
  .ps-sm-23 {
    padding-left: 7.1875rem;
  }
  .ps-sm-24 {
    padding-left: 7.5rem;
  }
  .ps-sm-25 {
    padding-left: 7.8125rem;
  }
  .ps-sm-26 {
    padding-left: 8.125rem;
  }
  .ps-sm-27 {
    padding-left: 8.4375rem;
  }
  .ps-sm-28 {
    padding-left: 8.75rem;
  }
  .ps-sm-29 {
    padding-left: 9.0625rem;
  }
  .ps-sm-30 {
    padding-left: 9.375rem;
  }
  .ps-sm-31 {
    padding-left: 9.6875rem;
  }
  .ps-sm-32 {
    padding-left: 10rem;
  }
  .ps-sm-33 {
    padding-left: 10.3125rem;
  }
  .ps-sm-34 {
    padding-left: 10.625rem;
  }
  .ps-sm-35 {
    padding-left: 10.9375rem;
  }
  .ps-sm-36 {
    padding-left: 11.25rem;
  }
  .ps-sm-37 {
    padding-left: 11.5625rem;
  }
  .ps-sm-38 {
    padding-left: 11.875rem;
  }
  .ps-sm-39 {
    padding-left: 12.1875rem;
  }
  .ps-sm-40 {
    padding-left: 12.5rem;
  }
  .text-sm-start {
    text-align: left;
  }
  .text-sm-end {
    text-align: right;
  }
  .text-sm-center {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left;
  }
  .float-md-end {
    float: right;
  }
  .float-md-none {
    float: none;
  }
  .d-md-inline {
    display: inline;
  }
  .d-md-inline-block {
    display: inline-block;
  }
  .d-md-block {
    display: block;
  }
  .d-md-grid {
    display: grid;
  }
  .d-md-table {
    display: table;
  }
  .d-md-table-row {
    display: table-row;
  }
  .d-md-table-cell {
    display: table-cell;
  }
  .d-md-flex {
    display: flex;
  }
  .d-md-inline-flex {
    display: inline-flex;
  }
  .d-md-none {
    display: none;
  }
  .flex-md-fill {
    flex: 1 1 auto;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-grow-0 {
    flex-grow: 0;
  }
  .flex-md-grow-1 {
    flex-grow: 1;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1;
  }
  .flex-md-wrap {
    flex-wrap: wrap;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .gap-md-0 {
    gap: 0rem;
  }
  .gap-md-1 {
    gap: 0.3125rem;
  }
  .gap-md-2 {
    gap: 0.625rem;
  }
  .gap-md-3 {
    gap: 0.9375rem;
  }
  .gap-md-4 {
    gap: 1.25rem;
  }
  .gap-md-5 {
    gap: 1.5625rem;
  }
  .gap-md-6 {
    gap: 1.875rem;
  }
  .gap-md-7 {
    gap: 2.1875rem;
  }
  .gap-md-8 {
    gap: 2.5rem;
  }
  .gap-md-9 {
    gap: 2.8125rem;
  }
  .gap-md-10 {
    gap: 3.125rem;
  }
  .gap-md-11 {
    gap: 3.4375rem;
  }
  .gap-md-12 {
    gap: 3.75rem;
  }
  .gap-md-13 {
    gap: 4.0625rem;
  }
  .gap-md-14 {
    gap: 4.375rem;
  }
  .gap-md-15 {
    gap: 4.6875rem;
  }
  .gap-md-16 {
    gap: 5rem;
  }
  .gap-md-17 {
    gap: 5.3125rem;
  }
  .gap-md-18 {
    gap: 5.625rem;
  }
  .gap-md-19 {
    gap: 5.9375rem;
  }
  .gap-md-20 {
    gap: 6.25rem;
  }
  .gap-md-21 {
    gap: 6.5625rem;
  }
  .gap-md-22 {
    gap: 6.875rem;
  }
  .gap-md-23 {
    gap: 7.1875rem;
  }
  .gap-md-24 {
    gap: 7.5rem;
  }
  .gap-md-25 {
    gap: 7.8125rem;
  }
  .gap-md-26 {
    gap: 8.125rem;
  }
  .gap-md-27 {
    gap: 8.4375rem;
  }
  .gap-md-28 {
    gap: 8.75rem;
  }
  .gap-md-29 {
    gap: 9.0625rem;
  }
  .gap-md-30 {
    gap: 9.375rem;
  }
  .gap-md-31 {
    gap: 9.6875rem;
  }
  .gap-md-32 {
    gap: 10rem;
  }
  .gap-md-33 {
    gap: 10.3125rem;
  }
  .gap-md-34 {
    gap: 10.625rem;
  }
  .gap-md-35 {
    gap: 10.9375rem;
  }
  .gap-md-36 {
    gap: 11.25rem;
  }
  .gap-md-37 {
    gap: 11.5625rem;
  }
  .gap-md-38 {
    gap: 11.875rem;
  }
  .gap-md-39 {
    gap: 12.1875rem;
  }
  .gap-md-40 {
    gap: 12.5rem;
  }
  .justify-content-md-start {
    justify-content: flex-start;
  }
  .justify-content-md-end {
    justify-content: flex-end;
  }
  .justify-content-md-center {
    justify-content: center;
  }
  .justify-content-md-between {
    justify-content: space-between;
  }
  .justify-content-md-around {
    justify-content: space-around;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly;
  }
  .align-items-md-start {
    align-items: flex-start;
  }
  .align-items-md-end {
    align-items: flex-end;
  }
  .align-items-md-center {
    align-items: center;
  }
  .align-items-md-baseline {
    align-items: baseline;
  }
  .align-items-md-stretch {
    align-items: stretch;
  }
  .align-content-md-start {
    align-content: flex-start;
  }
  .align-content-md-end {
    align-content: flex-end;
  }
  .align-content-md-center {
    align-content: center;
  }
  .align-content-md-between {
    align-content: space-between;
  }
  .align-content-md-around {
    align-content: space-around;
  }
  .align-content-md-stretch {
    align-content: stretch;
  }
  .align-self-md-auto {
    align-self: auto;
  }
  .align-self-md-start {
    align-self: flex-start;
  }
  .align-self-md-end {
    align-self: flex-end;
  }
  .align-self-md-center {
    align-self: center;
  }
  .align-self-md-baseline {
    align-self: baseline;
  }
  .align-self-md-stretch {
    align-self: stretch;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-last {
    order: 6;
  }
  .m-md-0 {
    margin: 0rem;
  }
  .m-md-1 {
    margin: 0.3125rem;
  }
  .m-md-2 {
    margin: 0.625rem;
  }
  .m-md-3 {
    margin: 0.9375rem;
  }
  .m-md-4 {
    margin: 1.25rem;
  }
  .m-md-5 {
    margin: 1.5625rem;
  }
  .m-md-6 {
    margin: 1.875rem;
  }
  .m-md-7 {
    margin: 2.1875rem;
  }
  .m-md-8 {
    margin: 2.5rem;
  }
  .m-md-9 {
    margin: 2.8125rem;
  }
  .m-md-10 {
    margin: 3.125rem;
  }
  .m-md-11 {
    margin: 3.4375rem;
  }
  .m-md-12 {
    margin: 3.75rem;
  }
  .m-md-13 {
    margin: 4.0625rem;
  }
  .m-md-14 {
    margin: 4.375rem;
  }
  .m-md-15 {
    margin: 4.6875rem;
  }
  .m-md-16 {
    margin: 5rem;
  }
  .m-md-17 {
    margin: 5.3125rem;
  }
  .m-md-18 {
    margin: 5.625rem;
  }
  .m-md-19 {
    margin: 5.9375rem;
  }
  .m-md-20 {
    margin: 6.25rem;
  }
  .m-md-21 {
    margin: 6.5625rem;
  }
  .m-md-22 {
    margin: 6.875rem;
  }
  .m-md-23 {
    margin: 7.1875rem;
  }
  .m-md-24 {
    margin: 7.5rem;
  }
  .m-md-25 {
    margin: 7.8125rem;
  }
  .m-md-26 {
    margin: 8.125rem;
  }
  .m-md-27 {
    margin: 8.4375rem;
  }
  .m-md-28 {
    margin: 8.75rem;
  }
  .m-md-29 {
    margin: 9.0625rem;
  }
  .m-md-30 {
    margin: 9.375rem;
  }
  .m-md-31 {
    margin: 9.6875rem;
  }
  .m-md-32 {
    margin: 10rem;
  }
  .m-md-33 {
    margin: 10.3125rem;
  }
  .m-md-34 {
    margin: 10.625rem;
  }
  .m-md-35 {
    margin: 10.9375rem;
  }
  .m-md-36 {
    margin: 11.25rem;
  }
  .m-md-37 {
    margin: 11.5625rem;
  }
  .m-md-38 {
    margin: 11.875rem;
  }
  .m-md-39 {
    margin: 12.1875rem;
  }
  .m-md-40 {
    margin: 12.5rem;
  }
  .m-md-auto {
    margin: auto;
  }
  .mx-md-0 {
    margin-right: 0rem;
    margin-left: 0rem;
  }
  .mx-md-1 {
    margin-right: 0.3125rem;
    margin-left: 0.3125rem;
  }
  .mx-md-2 {
    margin-right: 0.625rem;
    margin-left: 0.625rem;
  }
  .mx-md-3 {
    margin-right: 0.9375rem;
    margin-left: 0.9375rem;
  }
  .mx-md-4 {
    margin-right: 1.25rem;
    margin-left: 1.25rem;
  }
  .mx-md-5 {
    margin-right: 1.5625rem;
    margin-left: 1.5625rem;
  }
  .mx-md-6 {
    margin-right: 1.875rem;
    margin-left: 1.875rem;
  }
  .mx-md-7 {
    margin-right: 2.1875rem;
    margin-left: 2.1875rem;
  }
  .mx-md-8 {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }
  .mx-md-9 {
    margin-right: 2.8125rem;
    margin-left: 2.8125rem;
  }
  .mx-md-10 {
    margin-right: 3.125rem;
    margin-left: 3.125rem;
  }
  .mx-md-11 {
    margin-right: 3.4375rem;
    margin-left: 3.4375rem;
  }
  .mx-md-12 {
    margin-right: 3.75rem;
    margin-left: 3.75rem;
  }
  .mx-md-13 {
    margin-right: 4.0625rem;
    margin-left: 4.0625rem;
  }
  .mx-md-14 {
    margin-right: 4.375rem;
    margin-left: 4.375rem;
  }
  .mx-md-15 {
    margin-right: 4.6875rem;
    margin-left: 4.6875rem;
  }
  .mx-md-16 {
    margin-right: 5rem;
    margin-left: 5rem;
  }
  .mx-md-17 {
    margin-right: 5.3125rem;
    margin-left: 5.3125rem;
  }
  .mx-md-18 {
    margin-right: 5.625rem;
    margin-left: 5.625rem;
  }
  .mx-md-19 {
    margin-right: 5.9375rem;
    margin-left: 5.9375rem;
  }
  .mx-md-20 {
    margin-right: 6.25rem;
    margin-left: 6.25rem;
  }
  .mx-md-21 {
    margin-right: 6.5625rem;
    margin-left: 6.5625rem;
  }
  .mx-md-22 {
    margin-right: 6.875rem;
    margin-left: 6.875rem;
  }
  .mx-md-23 {
    margin-right: 7.1875rem;
    margin-left: 7.1875rem;
  }
  .mx-md-24 {
    margin-right: 7.5rem;
    margin-left: 7.5rem;
  }
  .mx-md-25 {
    margin-right: 7.8125rem;
    margin-left: 7.8125rem;
  }
  .mx-md-26 {
    margin-right: 8.125rem;
    margin-left: 8.125rem;
  }
  .mx-md-27 {
    margin-right: 8.4375rem;
    margin-left: 8.4375rem;
  }
  .mx-md-28 {
    margin-right: 8.75rem;
    margin-left: 8.75rem;
  }
  .mx-md-29 {
    margin-right: 9.0625rem;
    margin-left: 9.0625rem;
  }
  .mx-md-30 {
    margin-right: 9.375rem;
    margin-left: 9.375rem;
  }
  .mx-md-31 {
    margin-right: 9.6875rem;
    margin-left: 9.6875rem;
  }
  .mx-md-32 {
    margin-right: 10rem;
    margin-left: 10rem;
  }
  .mx-md-33 {
    margin-right: 10.3125rem;
    margin-left: 10.3125rem;
  }
  .mx-md-34 {
    margin-right: 10.625rem;
    margin-left: 10.625rem;
  }
  .mx-md-35 {
    margin-right: 10.9375rem;
    margin-left: 10.9375rem;
  }
  .mx-md-36 {
    margin-right: 11.25rem;
    margin-left: 11.25rem;
  }
  .mx-md-37 {
    margin-right: 11.5625rem;
    margin-left: 11.5625rem;
  }
  .mx-md-38 {
    margin-right: 11.875rem;
    margin-left: 11.875rem;
  }
  .mx-md-39 {
    margin-right: 12.1875rem;
    margin-left: 12.1875rem;
  }
  .mx-md-40 {
    margin-right: 12.5rem;
    margin-left: 12.5rem;
  }
  .mx-md-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-md-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .my-md-1 {
    margin-top: 0.3125rem;
    margin-bottom: 0.3125rem;
  }
  .my-md-2 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .my-md-3 {
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
  }
  .my-md-4 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .my-md-5 {
    margin-top: 1.5625rem;
    margin-bottom: 1.5625rem;
  }
  .my-md-6 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }
  .my-md-7 {
    margin-top: 2.1875rem;
    margin-bottom: 2.1875rem;
  }
  .my-md-8 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .my-md-9 {
    margin-top: 2.8125rem;
    margin-bottom: 2.8125rem;
  }
  .my-md-10 {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }
  .my-md-11 {
    margin-top: 3.4375rem;
    margin-bottom: 3.4375rem;
  }
  .my-md-12 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }
  .my-md-13 {
    margin-top: 4.0625rem;
    margin-bottom: 4.0625rem;
  }
  .my-md-14 {
    margin-top: 4.375rem;
    margin-bottom: 4.375rem;
  }
  .my-md-15 {
    margin-top: 4.6875rem;
    margin-bottom: 4.6875rem;
  }
  .my-md-16 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .my-md-17 {
    margin-top: 5.3125rem;
    margin-bottom: 5.3125rem;
  }
  .my-md-18 {
    margin-top: 5.625rem;
    margin-bottom: 5.625rem;
  }
  .my-md-19 {
    margin-top: 5.9375rem;
    margin-bottom: 5.9375rem;
  }
  .my-md-20 {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
  }
  .my-md-21 {
    margin-top: 6.5625rem;
    margin-bottom: 6.5625rem;
  }
  .my-md-22 {
    margin-top: 6.875rem;
    margin-bottom: 6.875rem;
  }
  .my-md-23 {
    margin-top: 7.1875rem;
    margin-bottom: 7.1875rem;
  }
  .my-md-24 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }
  .my-md-25 {
    margin-top: 7.8125rem;
    margin-bottom: 7.8125rem;
  }
  .my-md-26 {
    margin-top: 8.125rem;
    margin-bottom: 8.125rem;
  }
  .my-md-27 {
    margin-top: 8.4375rem;
    margin-bottom: 8.4375rem;
  }
  .my-md-28 {
    margin-top: 8.75rem;
    margin-bottom: 8.75rem;
  }
  .my-md-29 {
    margin-top: 9.0625rem;
    margin-bottom: 9.0625rem;
  }
  .my-md-30 {
    margin-top: 9.375rem;
    margin-bottom: 9.375rem;
  }
  .my-md-31 {
    margin-top: 9.6875rem;
    margin-bottom: 9.6875rem;
  }
  .my-md-32 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .my-md-33 {
    margin-top: 10.3125rem;
    margin-bottom: 10.3125rem;
  }
  .my-md-34 {
    margin-top: 10.625rem;
    margin-bottom: 10.625rem;
  }
  .my-md-35 {
    margin-top: 10.9375rem;
    margin-bottom: 10.9375rem;
  }
  .my-md-36 {
    margin-top: 11.25rem;
    margin-bottom: 11.25rem;
  }
  .my-md-37 {
    margin-top: 11.5625rem;
    margin-bottom: 11.5625rem;
  }
  .my-md-38 {
    margin-top: 11.875rem;
    margin-bottom: 11.875rem;
  }
  .my-md-39 {
    margin-top: 12.1875rem;
    margin-bottom: 12.1875rem;
  }
  .my-md-40 {
    margin-top: 12.5rem;
    margin-bottom: 12.5rem;
  }
  .my-md-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-md-0 {
    margin-top: 0rem;
  }
  .mt-md-1 {
    margin-top: 0.3125rem;
  }
  .mt-md-2 {
    margin-top: 0.625rem;
  }
  .mt-md-3 {
    margin-top: 0.9375rem;
  }
  .mt-md-4 {
    margin-top: 1.25rem;
  }
  .mt-md-5 {
    margin-top: 1.5625rem;
  }
  .mt-md-6 {
    margin-top: 1.875rem;
  }
  .mt-md-7 {
    margin-top: 2.1875rem;
  }
  .mt-md-8 {
    margin-top: 2.5rem;
  }
  .mt-md-9 {
    margin-top: 2.8125rem;
  }
  .mt-md-10 {
    margin-top: 3.125rem;
  }
  .mt-md-11 {
    margin-top: 3.4375rem;
  }
  .mt-md-12 {
    margin-top: 3.75rem;
  }
  .mt-md-13 {
    margin-top: 4.0625rem;
  }
  .mt-md-14 {
    margin-top: 4.375rem;
  }
  .mt-md-15 {
    margin-top: 4.6875rem;
  }
  .mt-md-16 {
    margin-top: 5rem;
  }
  .mt-md-17 {
    margin-top: 5.3125rem;
  }
  .mt-md-18 {
    margin-top: 5.625rem;
  }
  .mt-md-19 {
    margin-top: 5.9375rem;
  }
  .mt-md-20 {
    margin-top: 6.25rem;
  }
  .mt-md-21 {
    margin-top: 6.5625rem;
  }
  .mt-md-22 {
    margin-top: 6.875rem;
  }
  .mt-md-23 {
    margin-top: 7.1875rem;
  }
  .mt-md-24 {
    margin-top: 7.5rem;
  }
  .mt-md-25 {
    margin-top: 7.8125rem;
  }
  .mt-md-26 {
    margin-top: 8.125rem;
  }
  .mt-md-27 {
    margin-top: 8.4375rem;
  }
  .mt-md-28 {
    margin-top: 8.75rem;
  }
  .mt-md-29 {
    margin-top: 9.0625rem;
  }
  .mt-md-30 {
    margin-top: 9.375rem;
  }
  .mt-md-31 {
    margin-top: 9.6875rem;
  }
  .mt-md-32 {
    margin-top: 10rem;
  }
  .mt-md-33 {
    margin-top: 10.3125rem;
  }
  .mt-md-34 {
    margin-top: 10.625rem;
  }
  .mt-md-35 {
    margin-top: 10.9375rem;
  }
  .mt-md-36 {
    margin-top: 11.25rem;
  }
  .mt-md-37 {
    margin-top: 11.5625rem;
  }
  .mt-md-38 {
    margin-top: 11.875rem;
  }
  .mt-md-39 {
    margin-top: 12.1875rem;
  }
  .mt-md-40 {
    margin-top: 12.5rem;
  }
  .mt-md-auto {
    margin-top: auto;
  }
  .me-md-0 {
    margin-right: 0rem;
  }
  .me-md-1 {
    margin-right: 0.3125rem;
  }
  .me-md-2 {
    margin-right: 0.625rem;
  }
  .me-md-3 {
    margin-right: 0.9375rem;
  }
  .me-md-4 {
    margin-right: 1.25rem;
  }
  .me-md-5 {
    margin-right: 1.5625rem;
  }
  .me-md-6 {
    margin-right: 1.875rem;
  }
  .me-md-7 {
    margin-right: 2.1875rem;
  }
  .me-md-8 {
    margin-right: 2.5rem;
  }
  .me-md-9 {
    margin-right: 2.8125rem;
  }
  .me-md-10 {
    margin-right: 3.125rem;
  }
  .me-md-11 {
    margin-right: 3.4375rem;
  }
  .me-md-12 {
    margin-right: 3.75rem;
  }
  .me-md-13 {
    margin-right: 4.0625rem;
  }
  .me-md-14 {
    margin-right: 4.375rem;
  }
  .me-md-15 {
    margin-right: 4.6875rem;
  }
  .me-md-16 {
    margin-right: 5rem;
  }
  .me-md-17 {
    margin-right: 5.3125rem;
  }
  .me-md-18 {
    margin-right: 5.625rem;
  }
  .me-md-19 {
    margin-right: 5.9375rem;
  }
  .me-md-20 {
    margin-right: 6.25rem;
  }
  .me-md-21 {
    margin-right: 6.5625rem;
  }
  .me-md-22 {
    margin-right: 6.875rem;
  }
  .me-md-23 {
    margin-right: 7.1875rem;
  }
  .me-md-24 {
    margin-right: 7.5rem;
  }
  .me-md-25 {
    margin-right: 7.8125rem;
  }
  .me-md-26 {
    margin-right: 8.125rem;
  }
  .me-md-27 {
    margin-right: 8.4375rem;
  }
  .me-md-28 {
    margin-right: 8.75rem;
  }
  .me-md-29 {
    margin-right: 9.0625rem;
  }
  .me-md-30 {
    margin-right: 9.375rem;
  }
  .me-md-31 {
    margin-right: 9.6875rem;
  }
  .me-md-32 {
    margin-right: 10rem;
  }
  .me-md-33 {
    margin-right: 10.3125rem;
  }
  .me-md-34 {
    margin-right: 10.625rem;
  }
  .me-md-35 {
    margin-right: 10.9375rem;
  }
  .me-md-36 {
    margin-right: 11.25rem;
  }
  .me-md-37 {
    margin-right: 11.5625rem;
  }
  .me-md-38 {
    margin-right: 11.875rem;
  }
  .me-md-39 {
    margin-right: 12.1875rem;
  }
  .me-md-40 {
    margin-right: 12.5rem;
  }
  .me-md-auto {
    margin-right: auto;
  }
  .mb-md-0 {
    margin-bottom: 0rem;
  }
  .mb-md-1 {
    margin-bottom: 0.3125rem;
  }
  .mb-md-2 {
    margin-bottom: 0.625rem;
  }
  .mb-md-3 {
    margin-bottom: 0.9375rem;
  }
  .mb-md-4 {
    margin-bottom: 1.25rem;
  }
  .mb-md-5 {
    margin-bottom: 1.5625rem;
  }
  .mb-md-6 {
    margin-bottom: 1.875rem;
  }
  .mb-md-7 {
    margin-bottom: 2.1875rem;
  }
  .mb-md-8 {
    margin-bottom: 2.5rem;
  }
  .mb-md-9 {
    margin-bottom: 2.8125rem;
  }
  .mb-md-10 {
    margin-bottom: 3.125rem;
  }
  .mb-md-11 {
    margin-bottom: 3.4375rem;
  }
  .mb-md-12 {
    margin-bottom: 3.75rem;
  }
  .mb-md-13 {
    margin-bottom: 4.0625rem;
  }
  .mb-md-14 {
    margin-bottom: 4.375rem;
  }
  .mb-md-15 {
    margin-bottom: 4.6875rem;
  }
  .mb-md-16 {
    margin-bottom: 5rem;
  }
  .mb-md-17 {
    margin-bottom: 5.3125rem;
  }
  .mb-md-18 {
    margin-bottom: 5.625rem;
  }
  .mb-md-19 {
    margin-bottom: 5.9375rem;
  }
  .mb-md-20 {
    margin-bottom: 6.25rem;
  }
  .mb-md-21 {
    margin-bottom: 6.5625rem;
  }
  .mb-md-22 {
    margin-bottom: 6.875rem;
  }
  .mb-md-23 {
    margin-bottom: 7.1875rem;
  }
  .mb-md-24 {
    margin-bottom: 7.5rem;
  }
  .mb-md-25 {
    margin-bottom: 7.8125rem;
  }
  .mb-md-26 {
    margin-bottom: 8.125rem;
  }
  .mb-md-27 {
    margin-bottom: 8.4375rem;
  }
  .mb-md-28 {
    margin-bottom: 8.75rem;
  }
  .mb-md-29 {
    margin-bottom: 9.0625rem;
  }
  .mb-md-30 {
    margin-bottom: 9.375rem;
  }
  .mb-md-31 {
    margin-bottom: 9.6875rem;
  }
  .mb-md-32 {
    margin-bottom: 10rem;
  }
  .mb-md-33 {
    margin-bottom: 10.3125rem;
  }
  .mb-md-34 {
    margin-bottom: 10.625rem;
  }
  .mb-md-35 {
    margin-bottom: 10.9375rem;
  }
  .mb-md-36 {
    margin-bottom: 11.25rem;
  }
  .mb-md-37 {
    margin-bottom: 11.5625rem;
  }
  .mb-md-38 {
    margin-bottom: 11.875rem;
  }
  .mb-md-39 {
    margin-bottom: 12.1875rem;
  }
  .mb-md-40 {
    margin-bottom: 12.5rem;
  }
  .mb-md-auto {
    margin-bottom: auto;
  }
  .ms-md-0 {
    margin-left: 0rem;
  }
  .ms-md-1 {
    margin-left: 0.3125rem;
  }
  .ms-md-2 {
    margin-left: 0.625rem;
  }
  .ms-md-3 {
    margin-left: 0.9375rem;
  }
  .ms-md-4 {
    margin-left: 1.25rem;
  }
  .ms-md-5 {
    margin-left: 1.5625rem;
  }
  .ms-md-6 {
    margin-left: 1.875rem;
  }
  .ms-md-7 {
    margin-left: 2.1875rem;
  }
  .ms-md-8 {
    margin-left: 2.5rem;
  }
  .ms-md-9 {
    margin-left: 2.8125rem;
  }
  .ms-md-10 {
    margin-left: 3.125rem;
  }
  .ms-md-11 {
    margin-left: 3.4375rem;
  }
  .ms-md-12 {
    margin-left: 3.75rem;
  }
  .ms-md-13 {
    margin-left: 4.0625rem;
  }
  .ms-md-14 {
    margin-left: 4.375rem;
  }
  .ms-md-15 {
    margin-left: 4.6875rem;
  }
  .ms-md-16 {
    margin-left: 5rem;
  }
  .ms-md-17 {
    margin-left: 5.3125rem;
  }
  .ms-md-18 {
    margin-left: 5.625rem;
  }
  .ms-md-19 {
    margin-left: 5.9375rem;
  }
  .ms-md-20 {
    margin-left: 6.25rem;
  }
  .ms-md-21 {
    margin-left: 6.5625rem;
  }
  .ms-md-22 {
    margin-left: 6.875rem;
  }
  .ms-md-23 {
    margin-left: 7.1875rem;
  }
  .ms-md-24 {
    margin-left: 7.5rem;
  }
  .ms-md-25 {
    margin-left: 7.8125rem;
  }
  .ms-md-26 {
    margin-left: 8.125rem;
  }
  .ms-md-27 {
    margin-left: 8.4375rem;
  }
  .ms-md-28 {
    margin-left: 8.75rem;
  }
  .ms-md-29 {
    margin-left: 9.0625rem;
  }
  .ms-md-30 {
    margin-left: 9.375rem;
  }
  .ms-md-31 {
    margin-left: 9.6875rem;
  }
  .ms-md-32 {
    margin-left: 10rem;
  }
  .ms-md-33 {
    margin-left: 10.3125rem;
  }
  .ms-md-34 {
    margin-left: 10.625rem;
  }
  .ms-md-35 {
    margin-left: 10.9375rem;
  }
  .ms-md-36 {
    margin-left: 11.25rem;
  }
  .ms-md-37 {
    margin-left: 11.5625rem;
  }
  .ms-md-38 {
    margin-left: 11.875rem;
  }
  .ms-md-39 {
    margin-left: 12.1875rem;
  }
  .ms-md-40 {
    margin-left: 12.5rem;
  }
  .ms-md-auto {
    margin-left: auto;
  }
  .p-md-0 {
    padding: 0rem;
  }
  .p-md-1 {
    padding: 0.3125rem;
  }
  .p-md-2 {
    padding: 0.625rem;
  }
  .p-md-3 {
    padding: 0.9375rem;
  }
  .p-md-4 {
    padding: 1.25rem;
  }
  .p-md-5 {
    padding: 1.5625rem;
  }
  .p-md-6 {
    padding: 1.875rem;
  }
  .p-md-7 {
    padding: 2.1875rem;
  }
  .p-md-8 {
    padding: 2.5rem;
  }
  .p-md-9 {
    padding: 2.8125rem;
  }
  .p-md-10 {
    padding: 3.125rem;
  }
  .p-md-11 {
    padding: 3.4375rem;
  }
  .p-md-12 {
    padding: 3.75rem;
  }
  .p-md-13 {
    padding: 4.0625rem;
  }
  .p-md-14 {
    padding: 4.375rem;
  }
  .p-md-15 {
    padding: 4.6875rem;
  }
  .p-md-16 {
    padding: 5rem;
  }
  .p-md-17 {
    padding: 5.3125rem;
  }
  .p-md-18 {
    padding: 5.625rem;
  }
  .p-md-19 {
    padding: 5.9375rem;
  }
  .p-md-20 {
    padding: 6.25rem;
  }
  .p-md-21 {
    padding: 6.5625rem;
  }
  .p-md-22 {
    padding: 6.875rem;
  }
  .p-md-23 {
    padding: 7.1875rem;
  }
  .p-md-24 {
    padding: 7.5rem;
  }
  .p-md-25 {
    padding: 7.8125rem;
  }
  .p-md-26 {
    padding: 8.125rem;
  }
  .p-md-27 {
    padding: 8.4375rem;
  }
  .p-md-28 {
    padding: 8.75rem;
  }
  .p-md-29 {
    padding: 9.0625rem;
  }
  .p-md-30 {
    padding: 9.375rem;
  }
  .p-md-31 {
    padding: 9.6875rem;
  }
  .p-md-32 {
    padding: 10rem;
  }
  .p-md-33 {
    padding: 10.3125rem;
  }
  .p-md-34 {
    padding: 10.625rem;
  }
  .p-md-35 {
    padding: 10.9375rem;
  }
  .p-md-36 {
    padding: 11.25rem;
  }
  .p-md-37 {
    padding: 11.5625rem;
  }
  .p-md-38 {
    padding: 11.875rem;
  }
  .p-md-39 {
    padding: 12.1875rem;
  }
  .p-md-40 {
    padding: 12.5rem;
  }
  .px-md-0 {
    padding-right: 0rem;
    padding-left: 0rem;
  }
  .px-md-1 {
    padding-right: 0.3125rem;
    padding-left: 0.3125rem;
  }
  .px-md-2 {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
  .px-md-3 {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
  .px-md-4 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .px-md-5 {
    padding-right: 1.5625rem;
    padding-left: 1.5625rem;
  }
  .px-md-6 {
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }
  .px-md-7 {
    padding-right: 2.1875rem;
    padding-left: 2.1875rem;
  }
  .px-md-8 {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .px-md-9 {
    padding-right: 2.8125rem;
    padding-left: 2.8125rem;
  }
  .px-md-10 {
    padding-right: 3.125rem;
    padding-left: 3.125rem;
  }
  .px-md-11 {
    padding-right: 3.4375rem;
    padding-left: 3.4375rem;
  }
  .px-md-12 {
    padding-right: 3.75rem;
    padding-left: 3.75rem;
  }
  .px-md-13 {
    padding-right: 4.0625rem;
    padding-left: 4.0625rem;
  }
  .px-md-14 {
    padding-right: 4.375rem;
    padding-left: 4.375rem;
  }
  .px-md-15 {
    padding-right: 4.6875rem;
    padding-left: 4.6875rem;
  }
  .px-md-16 {
    padding-right: 5rem;
    padding-left: 5rem;
  }
  .px-md-17 {
    padding-right: 5.3125rem;
    padding-left: 5.3125rem;
  }
  .px-md-18 {
    padding-right: 5.625rem;
    padding-left: 5.625rem;
  }
  .px-md-19 {
    padding-right: 5.9375rem;
    padding-left: 5.9375rem;
  }
  .px-md-20 {
    padding-right: 6.25rem;
    padding-left: 6.25rem;
  }
  .px-md-21 {
    padding-right: 6.5625rem;
    padding-left: 6.5625rem;
  }
  .px-md-22 {
    padding-right: 6.875rem;
    padding-left: 6.875rem;
  }
  .px-md-23 {
    padding-right: 7.1875rem;
    padding-left: 7.1875rem;
  }
  .px-md-24 {
    padding-right: 7.5rem;
    padding-left: 7.5rem;
  }
  .px-md-25 {
    padding-right: 7.8125rem;
    padding-left: 7.8125rem;
  }
  .px-md-26 {
    padding-right: 8.125rem;
    padding-left: 8.125rem;
  }
  .px-md-27 {
    padding-right: 8.4375rem;
    padding-left: 8.4375rem;
  }
  .px-md-28 {
    padding-right: 8.75rem;
    padding-left: 8.75rem;
  }
  .px-md-29 {
    padding-right: 9.0625rem;
    padding-left: 9.0625rem;
  }
  .px-md-30 {
    padding-right: 9.375rem;
    padding-left: 9.375rem;
  }
  .px-md-31 {
    padding-right: 9.6875rem;
    padding-left: 9.6875rem;
  }
  .px-md-32 {
    padding-right: 10rem;
    padding-left: 10rem;
  }
  .px-md-33 {
    padding-right: 10.3125rem;
    padding-left: 10.3125rem;
  }
  .px-md-34 {
    padding-right: 10.625rem;
    padding-left: 10.625rem;
  }
  .px-md-35 {
    padding-right: 10.9375rem;
    padding-left: 10.9375rem;
  }
  .px-md-36 {
    padding-right: 11.25rem;
    padding-left: 11.25rem;
  }
  .px-md-37 {
    padding-right: 11.5625rem;
    padding-left: 11.5625rem;
  }
  .px-md-38 {
    padding-right: 11.875rem;
    padding-left: 11.875rem;
  }
  .px-md-39 {
    padding-right: 12.1875rem;
    padding-left: 12.1875rem;
  }
  .px-md-40 {
    padding-right: 12.5rem;
    padding-left: 12.5rem;
  }
  .py-md-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .py-md-1 {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }
  .py-md-2 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .py-md-3 {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
  }
  .py-md-4 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .py-md-5 {
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem;
  }
  .py-md-6 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }
  .py-md-7 {
    padding-top: 2.1875rem;
    padding-bottom: 2.1875rem;
  }
  .py-md-8 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .py-md-9 {
    padding-top: 2.8125rem;
    padding-bottom: 2.8125rem;
  }
  .py-md-10 {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
  }
  .py-md-11 {
    padding-top: 3.4375rem;
    padding-bottom: 3.4375rem;
  }
  .py-md-12 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
  .py-md-13 {
    padding-top: 4.0625rem;
    padding-bottom: 4.0625rem;
  }
  .py-md-14 {
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
  }
  .py-md-15 {
    padding-top: 4.6875rem;
    padding-bottom: 4.6875rem;
  }
  .py-md-16 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .py-md-17 {
    padding-top: 5.3125rem;
    padding-bottom: 5.3125rem;
  }
  .py-md-18 {
    padding-top: 5.625rem;
    padding-bottom: 5.625rem;
  }
  .py-md-19 {
    padding-top: 5.9375rem;
    padding-bottom: 5.9375rem;
  }
  .py-md-20 {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
  .py-md-21 {
    padding-top: 6.5625rem;
    padding-bottom: 6.5625rem;
  }
  .py-md-22 {
    padding-top: 6.875rem;
    padding-bottom: 6.875rem;
  }
  .py-md-23 {
    padding-top: 7.1875rem;
    padding-bottom: 7.1875rem;
  }
  .py-md-24 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .py-md-25 {
    padding-top: 7.8125rem;
    padding-bottom: 7.8125rem;
  }
  .py-md-26 {
    padding-top: 8.125rem;
    padding-bottom: 8.125rem;
  }
  .py-md-27 {
    padding-top: 8.4375rem;
    padding-bottom: 8.4375rem;
  }
  .py-md-28 {
    padding-top: 8.75rem;
    padding-bottom: 8.75rem;
  }
  .py-md-29 {
    padding-top: 9.0625rem;
    padding-bottom: 9.0625rem;
  }
  .py-md-30 {
    padding-top: 9.375rem;
    padding-bottom: 9.375rem;
  }
  .py-md-31 {
    padding-top: 9.6875rem;
    padding-bottom: 9.6875rem;
  }
  .py-md-32 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .py-md-33 {
    padding-top: 10.3125rem;
    padding-bottom: 10.3125rem;
  }
  .py-md-34 {
    padding-top: 10.625rem;
    padding-bottom: 10.625rem;
  }
  .py-md-35 {
    padding-top: 10.9375rem;
    padding-bottom: 10.9375rem;
  }
  .py-md-36 {
    padding-top: 11.25rem;
    padding-bottom: 11.25rem;
  }
  .py-md-37 {
    padding-top: 11.5625rem;
    padding-bottom: 11.5625rem;
  }
  .py-md-38 {
    padding-top: 11.875rem;
    padding-bottom: 11.875rem;
  }
  .py-md-39 {
    padding-top: 12.1875rem;
    padding-bottom: 12.1875rem;
  }
  .py-md-40 {
    padding-top: 12.5rem;
    padding-bottom: 12.5rem;
  }
  .pt-md-0 {
    padding-top: 0rem;
  }
  .pt-md-1 {
    padding-top: 0.3125rem;
  }
  .pt-md-2 {
    padding-top: 0.625rem;
  }
  .pt-md-3 {
    padding-top: 0.9375rem;
  }
  .pt-md-4 {
    padding-top: 1.25rem;
  }
  .pt-md-5 {
    padding-top: 1.5625rem;
  }
  .pt-md-6 {
    padding-top: 1.875rem;
  }
  .pt-md-7 {
    padding-top: 2.1875rem;
  }
  .pt-md-8 {
    padding-top: 2.5rem;
  }
  .pt-md-9 {
    padding-top: 2.8125rem;
  }
  .pt-md-10 {
    padding-top: 3.125rem;
  }
  .pt-md-11 {
    padding-top: 3.4375rem;
  }
  .pt-md-12 {
    padding-top: 3.75rem;
  }
  .pt-md-13 {
    padding-top: 4.0625rem;
  }
  .pt-md-14 {
    padding-top: 4.375rem;
  }
  .pt-md-15 {
    padding-top: 4.6875rem;
  }
  .pt-md-16 {
    padding-top: 5rem;
  }
  .pt-md-17 {
    padding-top: 5.3125rem;
  }
  .pt-md-18 {
    padding-top: 5.625rem;
  }
  .pt-md-19 {
    padding-top: 5.9375rem;
  }
  .pt-md-20 {
    padding-top: 6.25rem;
  }
  .pt-md-21 {
    padding-top: 6.5625rem;
  }
  .pt-md-22 {
    padding-top: 6.875rem;
  }
  .pt-md-23 {
    padding-top: 7.1875rem;
  }
  .pt-md-24 {
    padding-top: 7.5rem;
  }
  .pt-md-25 {
    padding-top: 7.8125rem;
  }
  .pt-md-26 {
    padding-top: 8.125rem;
  }
  .pt-md-27 {
    padding-top: 8.4375rem;
  }
  .pt-md-28 {
    padding-top: 8.75rem;
  }
  .pt-md-29 {
    padding-top: 9.0625rem;
  }
  .pt-md-30 {
    padding-top: 9.375rem;
  }
  .pt-md-31 {
    padding-top: 9.6875rem;
  }
  .pt-md-32 {
    padding-top: 10rem;
  }
  .pt-md-33 {
    padding-top: 10.3125rem;
  }
  .pt-md-34 {
    padding-top: 10.625rem;
  }
  .pt-md-35 {
    padding-top: 10.9375rem;
  }
  .pt-md-36 {
    padding-top: 11.25rem;
  }
  .pt-md-37 {
    padding-top: 11.5625rem;
  }
  .pt-md-38 {
    padding-top: 11.875rem;
  }
  .pt-md-39 {
    padding-top: 12.1875rem;
  }
  .pt-md-40 {
    padding-top: 12.5rem;
  }
  .pe-md-0 {
    padding-right: 0rem;
  }
  .pe-md-1 {
    padding-right: 0.3125rem;
  }
  .pe-md-2 {
    padding-right: 0.625rem;
  }
  .pe-md-3 {
    padding-right: 0.9375rem;
  }
  .pe-md-4 {
    padding-right: 1.25rem;
  }
  .pe-md-5 {
    padding-right: 1.5625rem;
  }
  .pe-md-6 {
    padding-right: 1.875rem;
  }
  .pe-md-7 {
    padding-right: 2.1875rem;
  }
  .pe-md-8 {
    padding-right: 2.5rem;
  }
  .pe-md-9 {
    padding-right: 2.8125rem;
  }
  .pe-md-10 {
    padding-right: 3.125rem;
  }
  .pe-md-11 {
    padding-right: 3.4375rem;
  }
  .pe-md-12 {
    padding-right: 3.75rem;
  }
  .pe-md-13 {
    padding-right: 4.0625rem;
  }
  .pe-md-14 {
    padding-right: 4.375rem;
  }
  .pe-md-15 {
    padding-right: 4.6875rem;
  }
  .pe-md-16 {
    padding-right: 5rem;
  }
  .pe-md-17 {
    padding-right: 5.3125rem;
  }
  .pe-md-18 {
    padding-right: 5.625rem;
  }
  .pe-md-19 {
    padding-right: 5.9375rem;
  }
  .pe-md-20 {
    padding-right: 6.25rem;
  }
  .pe-md-21 {
    padding-right: 6.5625rem;
  }
  .pe-md-22 {
    padding-right: 6.875rem;
  }
  .pe-md-23 {
    padding-right: 7.1875rem;
  }
  .pe-md-24 {
    padding-right: 7.5rem;
  }
  .pe-md-25 {
    padding-right: 7.8125rem;
  }
  .pe-md-26 {
    padding-right: 8.125rem;
  }
  .pe-md-27 {
    padding-right: 8.4375rem;
  }
  .pe-md-28 {
    padding-right: 8.75rem;
  }
  .pe-md-29 {
    padding-right: 9.0625rem;
  }
  .pe-md-30 {
    padding-right: 9.375rem;
  }
  .pe-md-31 {
    padding-right: 9.6875rem;
  }
  .pe-md-32 {
    padding-right: 10rem;
  }
  .pe-md-33 {
    padding-right: 10.3125rem;
  }
  .pe-md-34 {
    padding-right: 10.625rem;
  }
  .pe-md-35 {
    padding-right: 10.9375rem;
  }
  .pe-md-36 {
    padding-right: 11.25rem;
  }
  .pe-md-37 {
    padding-right: 11.5625rem;
  }
  .pe-md-38 {
    padding-right: 11.875rem;
  }
  .pe-md-39 {
    padding-right: 12.1875rem;
  }
  .pe-md-40 {
    padding-right: 12.5rem;
  }
  .pb-md-0 {
    padding-bottom: 0rem;
  }
  .pb-md-1 {
    padding-bottom: 0.3125rem;
  }
  .pb-md-2 {
    padding-bottom: 0.625rem;
  }
  .pb-md-3 {
    padding-bottom: 0.9375rem;
  }
  .pb-md-4 {
    padding-bottom: 1.25rem;
  }
  .pb-md-5 {
    padding-bottom: 1.5625rem;
  }
  .pb-md-6 {
    padding-bottom: 1.875rem;
  }
  .pb-md-7 {
    padding-bottom: 2.1875rem;
  }
  .pb-md-8 {
    padding-bottom: 2.5rem;
  }
  .pb-md-9 {
    padding-bottom: 2.8125rem;
  }
  .pb-md-10 {
    padding-bottom: 3.125rem;
  }
  .pb-md-11 {
    padding-bottom: 3.4375rem;
  }
  .pb-md-12 {
    padding-bottom: 3.75rem;
  }
  .pb-md-13 {
    padding-bottom: 4.0625rem;
  }
  .pb-md-14 {
    padding-bottom: 4.375rem;
  }
  .pb-md-15 {
    padding-bottom: 4.6875rem;
  }
  .pb-md-16 {
    padding-bottom: 5rem;
  }
  .pb-md-17 {
    padding-bottom: 5.3125rem;
  }
  .pb-md-18 {
    padding-bottom: 5.625rem;
  }
  .pb-md-19 {
    padding-bottom: 5.9375rem;
  }
  .pb-md-20 {
    padding-bottom: 6.25rem;
  }
  .pb-md-21 {
    padding-bottom: 6.5625rem;
  }
  .pb-md-22 {
    padding-bottom: 6.875rem;
  }
  .pb-md-23 {
    padding-bottom: 7.1875rem;
  }
  .pb-md-24 {
    padding-bottom: 7.5rem;
  }
  .pb-md-25 {
    padding-bottom: 7.8125rem;
  }
  .pb-md-26 {
    padding-bottom: 8.125rem;
  }
  .pb-md-27 {
    padding-bottom: 8.4375rem;
  }
  .pb-md-28 {
    padding-bottom: 8.75rem;
  }
  .pb-md-29 {
    padding-bottom: 9.0625rem;
  }
  .pb-md-30 {
    padding-bottom: 9.375rem;
  }
  .pb-md-31 {
    padding-bottom: 9.6875rem;
  }
  .pb-md-32 {
    padding-bottom: 10rem;
  }
  .pb-md-33 {
    padding-bottom: 10.3125rem;
  }
  .pb-md-34 {
    padding-bottom: 10.625rem;
  }
  .pb-md-35 {
    padding-bottom: 10.9375rem;
  }
  .pb-md-36 {
    padding-bottom: 11.25rem;
  }
  .pb-md-37 {
    padding-bottom: 11.5625rem;
  }
  .pb-md-38 {
    padding-bottom: 11.875rem;
  }
  .pb-md-39 {
    padding-bottom: 12.1875rem;
  }
  .pb-md-40 {
    padding-bottom: 12.5rem;
  }
  .ps-md-0 {
    padding-left: 0rem;
  }
  .ps-md-1 {
    padding-left: 0.3125rem;
  }
  .ps-md-2 {
    padding-left: 0.625rem;
  }
  .ps-md-3 {
    padding-left: 0.9375rem;
  }
  .ps-md-4 {
    padding-left: 1.25rem;
  }
  .ps-md-5 {
    padding-left: 1.5625rem;
  }
  .ps-md-6 {
    padding-left: 1.875rem;
  }
  .ps-md-7 {
    padding-left: 2.1875rem;
  }
  .ps-md-8 {
    padding-left: 2.5rem;
  }
  .ps-md-9 {
    padding-left: 2.8125rem;
  }
  .ps-md-10 {
    padding-left: 3.125rem;
  }
  .ps-md-11 {
    padding-left: 3.4375rem;
  }
  .ps-md-12 {
    padding-left: 3.75rem;
  }
  .ps-md-13 {
    padding-left: 4.0625rem;
  }
  .ps-md-14 {
    padding-left: 4.375rem;
  }
  .ps-md-15 {
    padding-left: 4.6875rem;
  }
  .ps-md-16 {
    padding-left: 5rem;
  }
  .ps-md-17 {
    padding-left: 5.3125rem;
  }
  .ps-md-18 {
    padding-left: 5.625rem;
  }
  .ps-md-19 {
    padding-left: 5.9375rem;
  }
  .ps-md-20 {
    padding-left: 6.25rem;
  }
  .ps-md-21 {
    padding-left: 6.5625rem;
  }
  .ps-md-22 {
    padding-left: 6.875rem;
  }
  .ps-md-23 {
    padding-left: 7.1875rem;
  }
  .ps-md-24 {
    padding-left: 7.5rem;
  }
  .ps-md-25 {
    padding-left: 7.8125rem;
  }
  .ps-md-26 {
    padding-left: 8.125rem;
  }
  .ps-md-27 {
    padding-left: 8.4375rem;
  }
  .ps-md-28 {
    padding-left: 8.75rem;
  }
  .ps-md-29 {
    padding-left: 9.0625rem;
  }
  .ps-md-30 {
    padding-left: 9.375rem;
  }
  .ps-md-31 {
    padding-left: 9.6875rem;
  }
  .ps-md-32 {
    padding-left: 10rem;
  }
  .ps-md-33 {
    padding-left: 10.3125rem;
  }
  .ps-md-34 {
    padding-left: 10.625rem;
  }
  .ps-md-35 {
    padding-left: 10.9375rem;
  }
  .ps-md-36 {
    padding-left: 11.25rem;
  }
  .ps-md-37 {
    padding-left: 11.5625rem;
  }
  .ps-md-38 {
    padding-left: 11.875rem;
  }
  .ps-md-39 {
    padding-left: 12.1875rem;
  }
  .ps-md-40 {
    padding-left: 12.5rem;
  }
  .text-md-start {
    text-align: left;
  }
  .text-md-end {
    text-align: right;
  }
  .text-md-center {
    text-align: center;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left;
  }
  .float-lg-end {
    float: right;
  }
  .float-lg-none {
    float: none;
  }
  .d-lg-inline {
    display: inline;
  }
  .d-lg-inline-block {
    display: inline-block;
  }
  .d-lg-block {
    display: block;
  }
  .d-lg-grid {
    display: grid;
  }
  .d-lg-table {
    display: table;
  }
  .d-lg-table-row {
    display: table-row;
  }
  .d-lg-table-cell {
    display: table-cell;
  }
  .d-lg-flex {
    display: flex;
  }
  .d-lg-inline-flex {
    display: inline-flex;
  }
  .d-lg-none {
    display: none;
  }
  .flex-lg-fill {
    flex: 1 1 auto;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-grow-0 {
    flex-grow: 0;
  }
  .flex-lg-grow-1 {
    flex-grow: 1;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1;
  }
  .flex-lg-wrap {
    flex-wrap: wrap;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .gap-lg-0 {
    gap: 0rem;
  }
  .gap-lg-1 {
    gap: 0.3125rem;
  }
  .gap-lg-2 {
    gap: 0.625rem;
  }
  .gap-lg-3 {
    gap: 0.9375rem;
  }
  .gap-lg-4 {
    gap: 1.25rem;
  }
  .gap-lg-5 {
    gap: 1.5625rem;
  }
  .gap-lg-6 {
    gap: 1.875rem;
  }
  .gap-lg-7 {
    gap: 2.1875rem;
  }
  .gap-lg-8 {
    gap: 2.5rem;
  }
  .gap-lg-9 {
    gap: 2.8125rem;
  }
  .gap-lg-10 {
    gap: 3.125rem;
  }
  .gap-lg-11 {
    gap: 3.4375rem;
  }
  .gap-lg-12 {
    gap: 3.75rem;
  }
  .gap-lg-13 {
    gap: 4.0625rem;
  }
  .gap-lg-14 {
    gap: 4.375rem;
  }
  .gap-lg-15 {
    gap: 4.6875rem;
  }
  .gap-lg-16 {
    gap: 5rem;
  }
  .gap-lg-17 {
    gap: 5.3125rem;
  }
  .gap-lg-18 {
    gap: 5.625rem;
  }
  .gap-lg-19 {
    gap: 5.9375rem;
  }
  .gap-lg-20 {
    gap: 6.25rem;
  }
  .gap-lg-21 {
    gap: 6.5625rem;
  }
  .gap-lg-22 {
    gap: 6.875rem;
  }
  .gap-lg-23 {
    gap: 7.1875rem;
  }
  .gap-lg-24 {
    gap: 7.5rem;
  }
  .gap-lg-25 {
    gap: 7.8125rem;
  }
  .gap-lg-26 {
    gap: 8.125rem;
  }
  .gap-lg-27 {
    gap: 8.4375rem;
  }
  .gap-lg-28 {
    gap: 8.75rem;
  }
  .gap-lg-29 {
    gap: 9.0625rem;
  }
  .gap-lg-30 {
    gap: 9.375rem;
  }
  .gap-lg-31 {
    gap: 9.6875rem;
  }
  .gap-lg-32 {
    gap: 10rem;
  }
  .gap-lg-33 {
    gap: 10.3125rem;
  }
  .gap-lg-34 {
    gap: 10.625rem;
  }
  .gap-lg-35 {
    gap: 10.9375rem;
  }
  .gap-lg-36 {
    gap: 11.25rem;
  }
  .gap-lg-37 {
    gap: 11.5625rem;
  }
  .gap-lg-38 {
    gap: 11.875rem;
  }
  .gap-lg-39 {
    gap: 12.1875rem;
  }
  .gap-lg-40 {
    gap: 12.5rem;
  }
  .justify-content-lg-start {
    justify-content: flex-start;
  }
  .justify-content-lg-end {
    justify-content: flex-end;
  }
  .justify-content-lg-center {
    justify-content: center;
  }
  .justify-content-lg-between {
    justify-content: space-between;
  }
  .justify-content-lg-around {
    justify-content: space-around;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly;
  }
  .align-items-lg-start {
    align-items: flex-start;
  }
  .align-items-lg-end {
    align-items: flex-end;
  }
  .align-items-lg-center {
    align-items: center;
  }
  .align-items-lg-baseline {
    align-items: baseline;
  }
  .align-items-lg-stretch {
    align-items: stretch;
  }
  .align-content-lg-start {
    align-content: flex-start;
  }
  .align-content-lg-end {
    align-content: flex-end;
  }
  .align-content-lg-center {
    align-content: center;
  }
  .align-content-lg-between {
    align-content: space-between;
  }
  .align-content-lg-around {
    align-content: space-around;
  }
  .align-content-lg-stretch {
    align-content: stretch;
  }
  .align-self-lg-auto {
    align-self: auto;
  }
  .align-self-lg-start {
    align-self: flex-start;
  }
  .align-self-lg-end {
    align-self: flex-end;
  }
  .align-self-lg-center {
    align-self: center;
  }
  .align-self-lg-baseline {
    align-self: baseline;
  }
  .align-self-lg-stretch {
    align-self: stretch;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-last {
    order: 6;
  }
  .m-lg-0 {
    margin: 0rem;
  }
  .m-lg-1 {
    margin: 0.3125rem;
  }
  .m-lg-2 {
    margin: 0.625rem;
  }
  .m-lg-3 {
    margin: 0.9375rem;
  }
  .m-lg-4 {
    margin: 1.25rem;
  }
  .m-lg-5 {
    margin: 1.5625rem;
  }
  .m-lg-6 {
    margin: 1.875rem;
  }
  .m-lg-7 {
    margin: 2.1875rem;
  }
  .m-lg-8 {
    margin: 2.5rem;
  }
  .m-lg-9 {
    margin: 2.8125rem;
  }
  .m-lg-10 {
    margin: 3.125rem;
  }
  .m-lg-11 {
    margin: 3.4375rem;
  }
  .m-lg-12 {
    margin: 3.75rem;
  }
  .m-lg-13 {
    margin: 4.0625rem;
  }
  .m-lg-14 {
    margin: 4.375rem;
  }
  .m-lg-15 {
    margin: 4.6875rem;
  }
  .m-lg-16 {
    margin: 5rem;
  }
  .m-lg-17 {
    margin: 5.3125rem;
  }
  .m-lg-18 {
    margin: 5.625rem;
  }
  .m-lg-19 {
    margin: 5.9375rem;
  }
  .m-lg-20 {
    margin: 6.25rem;
  }
  .m-lg-21 {
    margin: 6.5625rem;
  }
  .m-lg-22 {
    margin: 6.875rem;
  }
  .m-lg-23 {
    margin: 7.1875rem;
  }
  .m-lg-24 {
    margin: 7.5rem;
  }
  .m-lg-25 {
    margin: 7.8125rem;
  }
  .m-lg-26 {
    margin: 8.125rem;
  }
  .m-lg-27 {
    margin: 8.4375rem;
  }
  .m-lg-28 {
    margin: 8.75rem;
  }
  .m-lg-29 {
    margin: 9.0625rem;
  }
  .m-lg-30 {
    margin: 9.375rem;
  }
  .m-lg-31 {
    margin: 9.6875rem;
  }
  .m-lg-32 {
    margin: 10rem;
  }
  .m-lg-33 {
    margin: 10.3125rem;
  }
  .m-lg-34 {
    margin: 10.625rem;
  }
  .m-lg-35 {
    margin: 10.9375rem;
  }
  .m-lg-36 {
    margin: 11.25rem;
  }
  .m-lg-37 {
    margin: 11.5625rem;
  }
  .m-lg-38 {
    margin: 11.875rem;
  }
  .m-lg-39 {
    margin: 12.1875rem;
  }
  .m-lg-40 {
    margin: 12.5rem;
  }
  .m-lg-auto {
    margin: auto;
  }
  .mx-lg-0 {
    margin-right: 0rem;
    margin-left: 0rem;
  }
  .mx-lg-1 {
    margin-right: 0.3125rem;
    margin-left: 0.3125rem;
  }
  .mx-lg-2 {
    margin-right: 0.625rem;
    margin-left: 0.625rem;
  }
  .mx-lg-3 {
    margin-right: 0.9375rem;
    margin-left: 0.9375rem;
  }
  .mx-lg-4 {
    margin-right: 1.25rem;
    margin-left: 1.25rem;
  }
  .mx-lg-5 {
    margin-right: 1.5625rem;
    margin-left: 1.5625rem;
  }
  .mx-lg-6 {
    margin-right: 1.875rem;
    margin-left: 1.875rem;
  }
  .mx-lg-7 {
    margin-right: 2.1875rem;
    margin-left: 2.1875rem;
  }
  .mx-lg-8 {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }
  .mx-lg-9 {
    margin-right: 2.8125rem;
    margin-left: 2.8125rem;
  }
  .mx-lg-10 {
    margin-right: 3.125rem;
    margin-left: 3.125rem;
  }
  .mx-lg-11 {
    margin-right: 3.4375rem;
    margin-left: 3.4375rem;
  }
  .mx-lg-12 {
    margin-right: 3.75rem;
    margin-left: 3.75rem;
  }
  .mx-lg-13 {
    margin-right: 4.0625rem;
    margin-left: 4.0625rem;
  }
  .mx-lg-14 {
    margin-right: 4.375rem;
    margin-left: 4.375rem;
  }
  .mx-lg-15 {
    margin-right: 4.6875rem;
    margin-left: 4.6875rem;
  }
  .mx-lg-16 {
    margin-right: 5rem;
    margin-left: 5rem;
  }
  .mx-lg-17 {
    margin-right: 5.3125rem;
    margin-left: 5.3125rem;
  }
  .mx-lg-18 {
    margin-right: 5.625rem;
    margin-left: 5.625rem;
  }
  .mx-lg-19 {
    margin-right: 5.9375rem;
    margin-left: 5.9375rem;
  }
  .mx-lg-20 {
    margin-right: 6.25rem;
    margin-left: 6.25rem;
  }
  .mx-lg-21 {
    margin-right: 6.5625rem;
    margin-left: 6.5625rem;
  }
  .mx-lg-22 {
    margin-right: 6.875rem;
    margin-left: 6.875rem;
  }
  .mx-lg-23 {
    margin-right: 7.1875rem;
    margin-left: 7.1875rem;
  }
  .mx-lg-24 {
    margin-right: 7.5rem;
    margin-left: 7.5rem;
  }
  .mx-lg-25 {
    margin-right: 7.8125rem;
    margin-left: 7.8125rem;
  }
  .mx-lg-26 {
    margin-right: 8.125rem;
    margin-left: 8.125rem;
  }
  .mx-lg-27 {
    margin-right: 8.4375rem;
    margin-left: 8.4375rem;
  }
  .mx-lg-28 {
    margin-right: 8.75rem;
    margin-left: 8.75rem;
  }
  .mx-lg-29 {
    margin-right: 9.0625rem;
    margin-left: 9.0625rem;
  }
  .mx-lg-30 {
    margin-right: 9.375rem;
    margin-left: 9.375rem;
  }
  .mx-lg-31 {
    margin-right: 9.6875rem;
    margin-left: 9.6875rem;
  }
  .mx-lg-32 {
    margin-right: 10rem;
    margin-left: 10rem;
  }
  .mx-lg-33 {
    margin-right: 10.3125rem;
    margin-left: 10.3125rem;
  }
  .mx-lg-34 {
    margin-right: 10.625rem;
    margin-left: 10.625rem;
  }
  .mx-lg-35 {
    margin-right: 10.9375rem;
    margin-left: 10.9375rem;
  }
  .mx-lg-36 {
    margin-right: 11.25rem;
    margin-left: 11.25rem;
  }
  .mx-lg-37 {
    margin-right: 11.5625rem;
    margin-left: 11.5625rem;
  }
  .mx-lg-38 {
    margin-right: 11.875rem;
    margin-left: 11.875rem;
  }
  .mx-lg-39 {
    margin-right: 12.1875rem;
    margin-left: 12.1875rem;
  }
  .mx-lg-40 {
    margin-right: 12.5rem;
    margin-left: 12.5rem;
  }
  .mx-lg-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-lg-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .my-lg-1 {
    margin-top: 0.3125rem;
    margin-bottom: 0.3125rem;
  }
  .my-lg-2 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .my-lg-3 {
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
  }
  .my-lg-4 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .my-lg-5 {
    margin-top: 1.5625rem;
    margin-bottom: 1.5625rem;
  }
  .my-lg-6 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }
  .my-lg-7 {
    margin-top: 2.1875rem;
    margin-bottom: 2.1875rem;
  }
  .my-lg-8 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .my-lg-9 {
    margin-top: 2.8125rem;
    margin-bottom: 2.8125rem;
  }
  .my-lg-10 {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }
  .my-lg-11 {
    margin-top: 3.4375rem;
    margin-bottom: 3.4375rem;
  }
  .my-lg-12 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }
  .my-lg-13 {
    margin-top: 4.0625rem;
    margin-bottom: 4.0625rem;
  }
  .my-lg-14 {
    margin-top: 4.375rem;
    margin-bottom: 4.375rem;
  }
  .my-lg-15 {
    margin-top: 4.6875rem;
    margin-bottom: 4.6875rem;
  }
  .my-lg-16 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .my-lg-17 {
    margin-top: 5.3125rem;
    margin-bottom: 5.3125rem;
  }
  .my-lg-18 {
    margin-top: 5.625rem;
    margin-bottom: 5.625rem;
  }
  .my-lg-19 {
    margin-top: 5.9375rem;
    margin-bottom: 5.9375rem;
  }
  .my-lg-20 {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
  }
  .my-lg-21 {
    margin-top: 6.5625rem;
    margin-bottom: 6.5625rem;
  }
  .my-lg-22 {
    margin-top: 6.875rem;
    margin-bottom: 6.875rem;
  }
  .my-lg-23 {
    margin-top: 7.1875rem;
    margin-bottom: 7.1875rem;
  }
  .my-lg-24 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }
  .my-lg-25 {
    margin-top: 7.8125rem;
    margin-bottom: 7.8125rem;
  }
  .my-lg-26 {
    margin-top: 8.125rem;
    margin-bottom: 8.125rem;
  }
  .my-lg-27 {
    margin-top: 8.4375rem;
    margin-bottom: 8.4375rem;
  }
  .my-lg-28 {
    margin-top: 8.75rem;
    margin-bottom: 8.75rem;
  }
  .my-lg-29 {
    margin-top: 9.0625rem;
    margin-bottom: 9.0625rem;
  }
  .my-lg-30 {
    margin-top: 9.375rem;
    margin-bottom: 9.375rem;
  }
  .my-lg-31 {
    margin-top: 9.6875rem;
    margin-bottom: 9.6875rem;
  }
  .my-lg-32 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .my-lg-33 {
    margin-top: 10.3125rem;
    margin-bottom: 10.3125rem;
  }
  .my-lg-34 {
    margin-top: 10.625rem;
    margin-bottom: 10.625rem;
  }
  .my-lg-35 {
    margin-top: 10.9375rem;
    margin-bottom: 10.9375rem;
  }
  .my-lg-36 {
    margin-top: 11.25rem;
    margin-bottom: 11.25rem;
  }
  .my-lg-37 {
    margin-top: 11.5625rem;
    margin-bottom: 11.5625rem;
  }
  .my-lg-38 {
    margin-top: 11.875rem;
    margin-bottom: 11.875rem;
  }
  .my-lg-39 {
    margin-top: 12.1875rem;
    margin-bottom: 12.1875rem;
  }
  .my-lg-40 {
    margin-top: 12.5rem;
    margin-bottom: 12.5rem;
  }
  .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-lg-0 {
    margin-top: 0rem;
  }
  .mt-lg-1 {
    margin-top: 0.3125rem;
  }
  .mt-lg-2 {
    margin-top: 0.625rem;
  }
  .mt-lg-3 {
    margin-top: 0.9375rem;
  }
  .mt-lg-4 {
    margin-top: 1.25rem;
  }
  .mt-lg-5 {
    margin-top: 1.5625rem;
  }
  .mt-lg-6 {
    margin-top: 1.875rem;
  }
  .mt-lg-7 {
    margin-top: 2.1875rem;
  }
  .mt-lg-8 {
    margin-top: 2.5rem;
  }
  .mt-lg-9 {
    margin-top: 2.8125rem;
  }
  .mt-lg-10 {
    margin-top: 3.125rem;
  }
  .mt-lg-11 {
    margin-top: 3.4375rem;
  }
  .mt-lg-12 {
    margin-top: 3.75rem;
  }
  .mt-lg-13 {
    margin-top: 4.0625rem;
  }
  .mt-lg-14 {
    margin-top: 4.375rem;
  }
  .mt-lg-15 {
    margin-top: 4.6875rem;
  }
  .mt-lg-16 {
    margin-top: 5rem;
  }
  .mt-lg-17 {
    margin-top: 5.3125rem;
  }
  .mt-lg-18 {
    margin-top: 5.625rem;
  }
  .mt-lg-19 {
    margin-top: 5.9375rem;
  }
  .mt-lg-20 {
    margin-top: 6.25rem;
  }
  .mt-lg-21 {
    margin-top: 6.5625rem;
  }
  .mt-lg-22 {
    margin-top: 6.875rem;
  }
  .mt-lg-23 {
    margin-top: 7.1875rem;
  }
  .mt-lg-24 {
    margin-top: 7.5rem;
  }
  .mt-lg-25 {
    margin-top: 7.8125rem;
  }
  .mt-lg-26 {
    margin-top: 8.125rem;
  }
  .mt-lg-27 {
    margin-top: 8.4375rem;
  }
  .mt-lg-28 {
    margin-top: 8.75rem;
  }
  .mt-lg-29 {
    margin-top: 9.0625rem;
  }
  .mt-lg-30 {
    margin-top: 9.375rem;
  }
  .mt-lg-31 {
    margin-top: 9.6875rem;
  }
  .mt-lg-32 {
    margin-top: 10rem;
  }
  .mt-lg-33 {
    margin-top: 10.3125rem;
  }
  .mt-lg-34 {
    margin-top: 10.625rem;
  }
  .mt-lg-35 {
    margin-top: 10.9375rem;
  }
  .mt-lg-36 {
    margin-top: 11.25rem;
  }
  .mt-lg-37 {
    margin-top: 11.5625rem;
  }
  .mt-lg-38 {
    margin-top: 11.875rem;
  }
  .mt-lg-39 {
    margin-top: 12.1875rem;
  }
  .mt-lg-40 {
    margin-top: 12.5rem;
  }
  .mt-lg-auto {
    margin-top: auto;
  }
  .me-lg-0 {
    margin-right: 0rem;
  }
  .me-lg-1 {
    margin-right: 0.3125rem;
  }
  .me-lg-2 {
    margin-right: 0.625rem;
  }
  .me-lg-3 {
    margin-right: 0.9375rem;
  }
  .me-lg-4 {
    margin-right: 1.25rem;
  }
  .me-lg-5 {
    margin-right: 1.5625rem;
  }
  .me-lg-6 {
    margin-right: 1.875rem;
  }
  .me-lg-7 {
    margin-right: 2.1875rem;
  }
  .me-lg-8 {
    margin-right: 2.5rem;
  }
  .me-lg-9 {
    margin-right: 2.8125rem;
  }
  .me-lg-10 {
    margin-right: 3.125rem;
  }
  .me-lg-11 {
    margin-right: 3.4375rem;
  }
  .me-lg-12 {
    margin-right: 3.75rem;
  }
  .me-lg-13 {
    margin-right: 4.0625rem;
  }
  .me-lg-14 {
    margin-right: 4.375rem;
  }
  .me-lg-15 {
    margin-right: 4.6875rem;
  }
  .me-lg-16 {
    margin-right: 5rem;
  }
  .me-lg-17 {
    margin-right: 5.3125rem;
  }
  .me-lg-18 {
    margin-right: 5.625rem;
  }
  .me-lg-19 {
    margin-right: 5.9375rem;
  }
  .me-lg-20 {
    margin-right: 6.25rem;
  }
  .me-lg-21 {
    margin-right: 6.5625rem;
  }
  .me-lg-22 {
    margin-right: 6.875rem;
  }
  .me-lg-23 {
    margin-right: 7.1875rem;
  }
  .me-lg-24 {
    margin-right: 7.5rem;
  }
  .me-lg-25 {
    margin-right: 7.8125rem;
  }
  .me-lg-26 {
    margin-right: 8.125rem;
  }
  .me-lg-27 {
    margin-right: 8.4375rem;
  }
  .me-lg-28 {
    margin-right: 8.75rem;
  }
  .me-lg-29 {
    margin-right: 9.0625rem;
  }
  .me-lg-30 {
    margin-right: 9.375rem;
  }
  .me-lg-31 {
    margin-right: 9.6875rem;
  }
  .me-lg-32 {
    margin-right: 10rem;
  }
  .me-lg-33 {
    margin-right: 10.3125rem;
  }
  .me-lg-34 {
    margin-right: 10.625rem;
  }
  .me-lg-35 {
    margin-right: 10.9375rem;
  }
  .me-lg-36 {
    margin-right: 11.25rem;
  }
  .me-lg-37 {
    margin-right: 11.5625rem;
  }
  .me-lg-38 {
    margin-right: 11.875rem;
  }
  .me-lg-39 {
    margin-right: 12.1875rem;
  }
  .me-lg-40 {
    margin-right: 12.5rem;
  }
  .me-lg-auto {
    margin-right: auto;
  }
  .mb-lg-0 {
    margin-bottom: 0rem;
  }
  .mb-lg-1 {
    margin-bottom: 0.3125rem;
  }
  .mb-lg-2 {
    margin-bottom: 0.625rem;
  }
  .mb-lg-3 {
    margin-bottom: 0.9375rem;
  }
  .mb-lg-4 {
    margin-bottom: 1.25rem;
  }
  .mb-lg-5 {
    margin-bottom: 1.5625rem;
  }
  .mb-lg-6 {
    margin-bottom: 1.875rem;
  }
  .mb-lg-7 {
    margin-bottom: 2.1875rem;
  }
  .mb-lg-8 {
    margin-bottom: 2.5rem;
  }
  .mb-lg-9 {
    margin-bottom: 2.8125rem;
  }
  .mb-lg-10 {
    margin-bottom: 3.125rem;
  }
  .mb-lg-11 {
    margin-bottom: 3.4375rem;
  }
  .mb-lg-12 {
    margin-bottom: 3.75rem;
  }
  .mb-lg-13 {
    margin-bottom: 4.0625rem;
  }
  .mb-lg-14 {
    margin-bottom: 4.375rem;
  }
  .mb-lg-15 {
    margin-bottom: 4.6875rem;
  }
  .mb-lg-16 {
    margin-bottom: 5rem;
  }
  .mb-lg-17 {
    margin-bottom: 5.3125rem;
  }
  .mb-lg-18 {
    margin-bottom: 5.625rem;
  }
  .mb-lg-19 {
    margin-bottom: 5.9375rem;
  }
  .mb-lg-20 {
    margin-bottom: 6.25rem;
  }
  .mb-lg-21 {
    margin-bottom: 6.5625rem;
  }
  .mb-lg-22 {
    margin-bottom: 6.875rem;
  }
  .mb-lg-23 {
    margin-bottom: 7.1875rem;
  }
  .mb-lg-24 {
    margin-bottom: 7.5rem;
  }
  .mb-lg-25 {
    margin-bottom: 7.8125rem;
  }
  .mb-lg-26 {
    margin-bottom: 8.125rem;
  }
  .mb-lg-27 {
    margin-bottom: 8.4375rem;
  }
  .mb-lg-28 {
    margin-bottom: 8.75rem;
  }
  .mb-lg-29 {
    margin-bottom: 9.0625rem;
  }
  .mb-lg-30 {
    margin-bottom: 9.375rem;
  }
  .mb-lg-31 {
    margin-bottom: 9.6875rem;
  }
  .mb-lg-32 {
    margin-bottom: 10rem;
  }
  .mb-lg-33 {
    margin-bottom: 10.3125rem;
  }
  .mb-lg-34 {
    margin-bottom: 10.625rem;
  }
  .mb-lg-35 {
    margin-bottom: 10.9375rem;
  }
  .mb-lg-36 {
    margin-bottom: 11.25rem;
  }
  .mb-lg-37 {
    margin-bottom: 11.5625rem;
  }
  .mb-lg-38 {
    margin-bottom: 11.875rem;
  }
  .mb-lg-39 {
    margin-bottom: 12.1875rem;
  }
  .mb-lg-40 {
    margin-bottom: 12.5rem;
  }
  .mb-lg-auto {
    margin-bottom: auto;
  }
  .ms-lg-0 {
    margin-left: 0rem;
  }
  .ms-lg-1 {
    margin-left: 0.3125rem;
  }
  .ms-lg-2 {
    margin-left: 0.625rem;
  }
  .ms-lg-3 {
    margin-left: 0.9375rem;
  }
  .ms-lg-4 {
    margin-left: 1.25rem;
  }
  .ms-lg-5 {
    margin-left: 1.5625rem;
  }
  .ms-lg-6 {
    margin-left: 1.875rem;
  }
  .ms-lg-7 {
    margin-left: 2.1875rem;
  }
  .ms-lg-8 {
    margin-left: 2.5rem;
  }
  .ms-lg-9 {
    margin-left: 2.8125rem;
  }
  .ms-lg-10 {
    margin-left: 3.125rem;
  }
  .ms-lg-11 {
    margin-left: 3.4375rem;
  }
  .ms-lg-12 {
    margin-left: 3.75rem;
  }
  .ms-lg-13 {
    margin-left: 4.0625rem;
  }
  .ms-lg-14 {
    margin-left: 4.375rem;
  }
  .ms-lg-15 {
    margin-left: 4.6875rem;
  }
  .ms-lg-16 {
    margin-left: 5rem;
  }
  .ms-lg-17 {
    margin-left: 5.3125rem;
  }
  .ms-lg-18 {
    margin-left: 5.625rem;
  }
  .ms-lg-19 {
    margin-left: 5.9375rem;
  }
  .ms-lg-20 {
    margin-left: 6.25rem;
  }
  .ms-lg-21 {
    margin-left: 6.5625rem;
  }
  .ms-lg-22 {
    margin-left: 6.875rem;
  }
  .ms-lg-23 {
    margin-left: 7.1875rem;
  }
  .ms-lg-24 {
    margin-left: 7.5rem;
  }
  .ms-lg-25 {
    margin-left: 7.8125rem;
  }
  .ms-lg-26 {
    margin-left: 8.125rem;
  }
  .ms-lg-27 {
    margin-left: 8.4375rem;
  }
  .ms-lg-28 {
    margin-left: 8.75rem;
  }
  .ms-lg-29 {
    margin-left: 9.0625rem;
  }
  .ms-lg-30 {
    margin-left: 9.375rem;
  }
  .ms-lg-31 {
    margin-left: 9.6875rem;
  }
  .ms-lg-32 {
    margin-left: 10rem;
  }
  .ms-lg-33 {
    margin-left: 10.3125rem;
  }
  .ms-lg-34 {
    margin-left: 10.625rem;
  }
  .ms-lg-35 {
    margin-left: 10.9375rem;
  }
  .ms-lg-36 {
    margin-left: 11.25rem;
  }
  .ms-lg-37 {
    margin-left: 11.5625rem;
  }
  .ms-lg-38 {
    margin-left: 11.875rem;
  }
  .ms-lg-39 {
    margin-left: 12.1875rem;
  }
  .ms-lg-40 {
    margin-left: 12.5rem;
  }
  .ms-lg-auto {
    margin-left: auto;
  }
  .p-lg-0 {
    padding: 0rem;
  }
  .p-lg-1 {
    padding: 0.3125rem;
  }
  .p-lg-2 {
    padding: 0.625rem;
  }
  .p-lg-3 {
    padding: 0.9375rem;
  }
  .p-lg-4 {
    padding: 1.25rem;
  }
  .p-lg-5 {
    padding: 1.5625rem;
  }
  .p-lg-6 {
    padding: 1.875rem;
  }
  .p-lg-7 {
    padding: 2.1875rem;
  }
  .p-lg-8 {
    padding: 2.5rem;
  }
  .p-lg-9 {
    padding: 2.8125rem;
  }
  .p-lg-10 {
    padding: 3.125rem;
  }
  .p-lg-11 {
    padding: 3.4375rem;
  }
  .p-lg-12 {
    padding: 3.75rem;
  }
  .p-lg-13 {
    padding: 4.0625rem;
  }
  .p-lg-14 {
    padding: 4.375rem;
  }
  .p-lg-15 {
    padding: 4.6875rem;
  }
  .p-lg-16 {
    padding: 5rem;
  }
  .p-lg-17 {
    padding: 5.3125rem;
  }
  .p-lg-18 {
    padding: 5.625rem;
  }
  .p-lg-19 {
    padding: 5.9375rem;
  }
  .p-lg-20 {
    padding: 6.25rem;
  }
  .p-lg-21 {
    padding: 6.5625rem;
  }
  .p-lg-22 {
    padding: 6.875rem;
  }
  .p-lg-23 {
    padding: 7.1875rem;
  }
  .p-lg-24 {
    padding: 7.5rem;
  }
  .p-lg-25 {
    padding: 7.8125rem;
  }
  .p-lg-26 {
    padding: 8.125rem;
  }
  .p-lg-27 {
    padding: 8.4375rem;
  }
  .p-lg-28 {
    padding: 8.75rem;
  }
  .p-lg-29 {
    padding: 9.0625rem;
  }
  .p-lg-30 {
    padding: 9.375rem;
  }
  .p-lg-31 {
    padding: 9.6875rem;
  }
  .p-lg-32 {
    padding: 10rem;
  }
  .p-lg-33 {
    padding: 10.3125rem;
  }
  .p-lg-34 {
    padding: 10.625rem;
  }
  .p-lg-35 {
    padding: 10.9375rem;
  }
  .p-lg-36 {
    padding: 11.25rem;
  }
  .p-lg-37 {
    padding: 11.5625rem;
  }
  .p-lg-38 {
    padding: 11.875rem;
  }
  .p-lg-39 {
    padding: 12.1875rem;
  }
  .p-lg-40 {
    padding: 12.5rem;
  }
  .px-lg-0 {
    padding-right: 0rem;
    padding-left: 0rem;
  }
  .px-lg-1 {
    padding-right: 0.3125rem;
    padding-left: 0.3125rem;
  }
  .px-lg-2 {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
  .px-lg-3 {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
  .px-lg-4 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .px-lg-5 {
    padding-right: 1.5625rem;
    padding-left: 1.5625rem;
  }
  .px-lg-6 {
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }
  .px-lg-7 {
    padding-right: 2.1875rem;
    padding-left: 2.1875rem;
  }
  .px-lg-8 {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .px-lg-9 {
    padding-right: 2.8125rem;
    padding-left: 2.8125rem;
  }
  .px-lg-10 {
    padding-right: 3.125rem;
    padding-left: 3.125rem;
  }
  .px-lg-11 {
    padding-right: 3.4375rem;
    padding-left: 3.4375rem;
  }
  .px-lg-12 {
    padding-right: 3.75rem;
    padding-left: 3.75rem;
  }
  .px-lg-13 {
    padding-right: 4.0625rem;
    padding-left: 4.0625rem;
  }
  .px-lg-14 {
    padding-right: 4.375rem;
    padding-left: 4.375rem;
  }
  .px-lg-15 {
    padding-right: 4.6875rem;
    padding-left: 4.6875rem;
  }
  .px-lg-16 {
    padding-right: 5rem;
    padding-left: 5rem;
  }
  .px-lg-17 {
    padding-right: 5.3125rem;
    padding-left: 5.3125rem;
  }
  .px-lg-18 {
    padding-right: 5.625rem;
    padding-left: 5.625rem;
  }
  .px-lg-19 {
    padding-right: 5.9375rem;
    padding-left: 5.9375rem;
  }
  .px-lg-20 {
    padding-right: 6.25rem;
    padding-left: 6.25rem;
  }
  .px-lg-21 {
    padding-right: 6.5625rem;
    padding-left: 6.5625rem;
  }
  .px-lg-22 {
    padding-right: 6.875rem;
    padding-left: 6.875rem;
  }
  .px-lg-23 {
    padding-right: 7.1875rem;
    padding-left: 7.1875rem;
  }
  .px-lg-24 {
    padding-right: 7.5rem;
    padding-left: 7.5rem;
  }
  .px-lg-25 {
    padding-right: 7.8125rem;
    padding-left: 7.8125rem;
  }
  .px-lg-26 {
    padding-right: 8.125rem;
    padding-left: 8.125rem;
  }
  .px-lg-27 {
    padding-right: 8.4375rem;
    padding-left: 8.4375rem;
  }
  .px-lg-28 {
    padding-right: 8.75rem;
    padding-left: 8.75rem;
  }
  .px-lg-29 {
    padding-right: 9.0625rem;
    padding-left: 9.0625rem;
  }
  .px-lg-30 {
    padding-right: 9.375rem;
    padding-left: 9.375rem;
  }
  .px-lg-31 {
    padding-right: 9.6875rem;
    padding-left: 9.6875rem;
  }
  .px-lg-32 {
    padding-right: 10rem;
    padding-left: 10rem;
  }
  .px-lg-33 {
    padding-right: 10.3125rem;
    padding-left: 10.3125rem;
  }
  .px-lg-34 {
    padding-right: 10.625rem;
    padding-left: 10.625rem;
  }
  .px-lg-35 {
    padding-right: 10.9375rem;
    padding-left: 10.9375rem;
  }
  .px-lg-36 {
    padding-right: 11.25rem;
    padding-left: 11.25rem;
  }
  .px-lg-37 {
    padding-right: 11.5625rem;
    padding-left: 11.5625rem;
  }
  .px-lg-38 {
    padding-right: 11.875rem;
    padding-left: 11.875rem;
  }
  .px-lg-39 {
    padding-right: 12.1875rem;
    padding-left: 12.1875rem;
  }
  .px-lg-40 {
    padding-right: 12.5rem;
    padding-left: 12.5rem;
  }
  .py-lg-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .py-lg-1 {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }
  .py-lg-2 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .py-lg-3 {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
  }
  .py-lg-4 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .py-lg-5 {
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem;
  }
  .py-lg-6 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }
  .py-lg-7 {
    padding-top: 2.1875rem;
    padding-bottom: 2.1875rem;
  }
  .py-lg-8 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .py-lg-9 {
    padding-top: 2.8125rem;
    padding-bottom: 2.8125rem;
  }
  .py-lg-10 {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
  }
  .py-lg-11 {
    padding-top: 3.4375rem;
    padding-bottom: 3.4375rem;
  }
  .py-lg-12 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
  .py-lg-13 {
    padding-top: 4.0625rem;
    padding-bottom: 4.0625rem;
  }
  .py-lg-14 {
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
  }
  .py-lg-15 {
    padding-top: 4.6875rem;
    padding-bottom: 4.6875rem;
  }
  .py-lg-16 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .py-lg-17 {
    padding-top: 5.3125rem;
    padding-bottom: 5.3125rem;
  }
  .py-lg-18 {
    padding-top: 5.625rem;
    padding-bottom: 5.625rem;
  }
  .py-lg-19 {
    padding-top: 5.9375rem;
    padding-bottom: 5.9375rem;
  }
  .py-lg-20 {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
  .py-lg-21 {
    padding-top: 6.5625rem;
    padding-bottom: 6.5625rem;
  }
  .py-lg-22 {
    padding-top: 6.875rem;
    padding-bottom: 6.875rem;
  }
  .py-lg-23 {
    padding-top: 7.1875rem;
    padding-bottom: 7.1875rem;
  }
  .py-lg-24 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .py-lg-25 {
    padding-top: 7.8125rem;
    padding-bottom: 7.8125rem;
  }
  .py-lg-26 {
    padding-top: 8.125rem;
    padding-bottom: 8.125rem;
  }
  .py-lg-27 {
    padding-top: 8.4375rem;
    padding-bottom: 8.4375rem;
  }
  .py-lg-28 {
    padding-top: 8.75rem;
    padding-bottom: 8.75rem;
  }
  .py-lg-29 {
    padding-top: 9.0625rem;
    padding-bottom: 9.0625rem;
  }
  .py-lg-30 {
    padding-top: 9.375rem;
    padding-bottom: 9.375rem;
  }
  .py-lg-31 {
    padding-top: 9.6875rem;
    padding-bottom: 9.6875rem;
  }
  .py-lg-32 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .py-lg-33 {
    padding-top: 10.3125rem;
    padding-bottom: 10.3125rem;
  }
  .py-lg-34 {
    padding-top: 10.625rem;
    padding-bottom: 10.625rem;
  }
  .py-lg-35 {
    padding-top: 10.9375rem;
    padding-bottom: 10.9375rem;
  }
  .py-lg-36 {
    padding-top: 11.25rem;
    padding-bottom: 11.25rem;
  }
  .py-lg-37 {
    padding-top: 11.5625rem;
    padding-bottom: 11.5625rem;
  }
  .py-lg-38 {
    padding-top: 11.875rem;
    padding-bottom: 11.875rem;
  }
  .py-lg-39 {
    padding-top: 12.1875rem;
    padding-bottom: 12.1875rem;
  }
  .py-lg-40 {
    padding-top: 12.5rem;
    padding-bottom: 12.5rem;
  }
  .pt-lg-0 {
    padding-top: 0rem;
  }
  .pt-lg-1 {
    padding-top: 0.3125rem;
  }
  .pt-lg-2 {
    padding-top: 0.625rem;
  }
  .pt-lg-3 {
    padding-top: 0.9375rem;
  }
  .pt-lg-4 {
    padding-top: 1.25rem;
  }
  .pt-lg-5 {
    padding-top: 1.5625rem;
  }
  .pt-lg-6 {
    padding-top: 1.875rem;
  }
  .pt-lg-7 {
    padding-top: 2.1875rem;
  }
  .pt-lg-8 {
    padding-top: 2.5rem;
  }
  .pt-lg-9 {
    padding-top: 2.8125rem;
  }
  .pt-lg-10 {
    padding-top: 3.125rem;
  }
  .pt-lg-11 {
    padding-top: 3.4375rem;
  }
  .pt-lg-12 {
    padding-top: 3.75rem;
  }
  .pt-lg-13 {
    padding-top: 4.0625rem;
  }
  .pt-lg-14 {
    padding-top: 4.375rem;
  }
  .pt-lg-15 {
    padding-top: 4.6875rem;
  }
  .pt-lg-16 {
    padding-top: 5rem;
  }
  .pt-lg-17 {
    padding-top: 5.3125rem;
  }
  .pt-lg-18 {
    padding-top: 5.625rem;
  }
  .pt-lg-19 {
    padding-top: 5.9375rem;
  }
  .pt-lg-20 {
    padding-top: 6.25rem;
  }
  .pt-lg-21 {
    padding-top: 6.5625rem;
  }
  .pt-lg-22 {
    padding-top: 6.875rem;
  }
  .pt-lg-23 {
    padding-top: 7.1875rem;
  }
  .pt-lg-24 {
    padding-top: 7.5rem;
  }
  .pt-lg-25 {
    padding-top: 7.8125rem;
  }
  .pt-lg-26 {
    padding-top: 8.125rem;
  }
  .pt-lg-27 {
    padding-top: 8.4375rem;
  }
  .pt-lg-28 {
    padding-top: 8.75rem;
  }
  .pt-lg-29 {
    padding-top: 9.0625rem;
  }
  .pt-lg-30 {
    padding-top: 9.375rem;
  }
  .pt-lg-31 {
    padding-top: 9.6875rem;
  }
  .pt-lg-32 {
    padding-top: 10rem;
  }
  .pt-lg-33 {
    padding-top: 10.3125rem;
  }
  .pt-lg-34 {
    padding-top: 10.625rem;
  }
  .pt-lg-35 {
    padding-top: 10.9375rem;
  }
  .pt-lg-36 {
    padding-top: 11.25rem;
  }
  .pt-lg-37 {
    padding-top: 11.5625rem;
  }
  .pt-lg-38 {
    padding-top: 11.875rem;
  }
  .pt-lg-39 {
    padding-top: 12.1875rem;
  }
  .pt-lg-40 {
    padding-top: 12.5rem;
  }
  .pe-lg-0 {
    padding-right: 0rem;
  }
  .pe-lg-1 {
    padding-right: 0.3125rem;
  }
  .pe-lg-2 {
    padding-right: 0.625rem;
  }
  .pe-lg-3 {
    padding-right: 0.9375rem;
  }
  .pe-lg-4 {
    padding-right: 1.25rem;
  }
  .pe-lg-5 {
    padding-right: 1.5625rem;
  }
  .pe-lg-6 {
    padding-right: 1.875rem;
  }
  .pe-lg-7 {
    padding-right: 2.1875rem;
  }
  .pe-lg-8 {
    padding-right: 2.5rem;
  }
  .pe-lg-9 {
    padding-right: 2.8125rem;
  }
  .pe-lg-10 {
    padding-right: 3.125rem;
  }
  .pe-lg-11 {
    padding-right: 3.4375rem;
  }
  .pe-lg-12 {
    padding-right: 3.75rem;
  }
  .pe-lg-13 {
    padding-right: 4.0625rem;
  }
  .pe-lg-14 {
    padding-right: 4.375rem;
  }
  .pe-lg-15 {
    padding-right: 4.6875rem;
  }
  .pe-lg-16 {
    padding-right: 5rem;
  }
  .pe-lg-17 {
    padding-right: 5.3125rem;
  }
  .pe-lg-18 {
    padding-right: 5.625rem;
  }
  .pe-lg-19 {
    padding-right: 5.9375rem;
  }
  .pe-lg-20 {
    padding-right: 6.25rem;
  }
  .pe-lg-21 {
    padding-right: 6.5625rem;
  }
  .pe-lg-22 {
    padding-right: 6.875rem;
  }
  .pe-lg-23 {
    padding-right: 7.1875rem;
  }
  .pe-lg-24 {
    padding-right: 7.5rem;
  }
  .pe-lg-25 {
    padding-right: 7.8125rem;
  }
  .pe-lg-26 {
    padding-right: 8.125rem;
  }
  .pe-lg-27 {
    padding-right: 8.4375rem;
  }
  .pe-lg-28 {
    padding-right: 8.75rem;
  }
  .pe-lg-29 {
    padding-right: 9.0625rem;
  }
  .pe-lg-30 {
    padding-right: 9.375rem;
  }
  .pe-lg-31 {
    padding-right: 9.6875rem;
  }
  .pe-lg-32 {
    padding-right: 10rem;
  }
  .pe-lg-33 {
    padding-right: 10.3125rem;
  }
  .pe-lg-34 {
    padding-right: 10.625rem;
  }
  .pe-lg-35 {
    padding-right: 10.9375rem;
  }
  .pe-lg-36 {
    padding-right: 11.25rem;
  }
  .pe-lg-37 {
    padding-right: 11.5625rem;
  }
  .pe-lg-38 {
    padding-right: 11.875rem;
  }
  .pe-lg-39 {
    padding-right: 12.1875rem;
  }
  .pe-lg-40 {
    padding-right: 12.5rem;
  }
  .pb-lg-0 {
    padding-bottom: 0rem;
  }
  .pb-lg-1 {
    padding-bottom: 0.3125rem;
  }
  .pb-lg-2 {
    padding-bottom: 0.625rem;
  }
  .pb-lg-3 {
    padding-bottom: 0.9375rem;
  }
  .pb-lg-4 {
    padding-bottom: 1.25rem;
  }
  .pb-lg-5 {
    padding-bottom: 1.5625rem;
  }
  .pb-lg-6 {
    padding-bottom: 1.875rem;
  }
  .pb-lg-7 {
    padding-bottom: 2.1875rem;
  }
  .pb-lg-8 {
    padding-bottom: 2.5rem;
  }
  .pb-lg-9 {
    padding-bottom: 2.8125rem;
  }
  .pb-lg-10 {
    padding-bottom: 3.125rem;
  }
  .pb-lg-11 {
    padding-bottom: 3.4375rem;
  }
  .pb-lg-12 {
    padding-bottom: 3.75rem;
  }
  .pb-lg-13 {
    padding-bottom: 4.0625rem;
  }
  .pb-lg-14 {
    padding-bottom: 4.375rem;
  }
  .pb-lg-15 {
    padding-bottom: 4.6875rem;
  }
  .pb-lg-16 {
    padding-bottom: 5rem;
  }
  .pb-lg-17 {
    padding-bottom: 5.3125rem;
  }
  .pb-lg-18 {
    padding-bottom: 5.625rem;
  }
  .pb-lg-19 {
    padding-bottom: 5.9375rem;
  }
  .pb-lg-20 {
    padding-bottom: 6.25rem;
  }
  .pb-lg-21 {
    padding-bottom: 6.5625rem;
  }
  .pb-lg-22 {
    padding-bottom: 6.875rem;
  }
  .pb-lg-23 {
    padding-bottom: 7.1875rem;
  }
  .pb-lg-24 {
    padding-bottom: 7.5rem;
  }
  .pb-lg-25 {
    padding-bottom: 7.8125rem;
  }
  .pb-lg-26 {
    padding-bottom: 8.125rem;
  }
  .pb-lg-27 {
    padding-bottom: 8.4375rem;
  }
  .pb-lg-28 {
    padding-bottom: 8.75rem;
  }
  .pb-lg-29 {
    padding-bottom: 9.0625rem;
  }
  .pb-lg-30 {
    padding-bottom: 9.375rem;
  }
  .pb-lg-31 {
    padding-bottom: 9.6875rem;
  }
  .pb-lg-32 {
    padding-bottom: 10rem;
  }
  .pb-lg-33 {
    padding-bottom: 10.3125rem;
  }
  .pb-lg-34 {
    padding-bottom: 10.625rem;
  }
  .pb-lg-35 {
    padding-bottom: 10.9375rem;
  }
  .pb-lg-36 {
    padding-bottom: 11.25rem;
  }
  .pb-lg-37 {
    padding-bottom: 11.5625rem;
  }
  .pb-lg-38 {
    padding-bottom: 11.875rem;
  }
  .pb-lg-39 {
    padding-bottom: 12.1875rem;
  }
  .pb-lg-40 {
    padding-bottom: 12.5rem;
  }
  .ps-lg-0 {
    padding-left: 0rem;
  }
  .ps-lg-1 {
    padding-left: 0.3125rem;
  }
  .ps-lg-2 {
    padding-left: 0.625rem;
  }
  .ps-lg-3 {
    padding-left: 0.9375rem;
  }
  .ps-lg-4 {
    padding-left: 1.25rem;
  }
  .ps-lg-5 {
    padding-left: 1.5625rem;
  }
  .ps-lg-6 {
    padding-left: 1.875rem;
  }
  .ps-lg-7 {
    padding-left: 2.1875rem;
  }
  .ps-lg-8 {
    padding-left: 2.5rem;
  }
  .ps-lg-9 {
    padding-left: 2.8125rem;
  }
  .ps-lg-10 {
    padding-left: 3.125rem;
  }
  .ps-lg-11 {
    padding-left: 3.4375rem;
  }
  .ps-lg-12 {
    padding-left: 3.75rem;
  }
  .ps-lg-13 {
    padding-left: 4.0625rem;
  }
  .ps-lg-14 {
    padding-left: 4.375rem;
  }
  .ps-lg-15 {
    padding-left: 4.6875rem;
  }
  .ps-lg-16 {
    padding-left: 5rem;
  }
  .ps-lg-17 {
    padding-left: 5.3125rem;
  }
  .ps-lg-18 {
    padding-left: 5.625rem;
  }
  .ps-lg-19 {
    padding-left: 5.9375rem;
  }
  .ps-lg-20 {
    padding-left: 6.25rem;
  }
  .ps-lg-21 {
    padding-left: 6.5625rem;
  }
  .ps-lg-22 {
    padding-left: 6.875rem;
  }
  .ps-lg-23 {
    padding-left: 7.1875rem;
  }
  .ps-lg-24 {
    padding-left: 7.5rem;
  }
  .ps-lg-25 {
    padding-left: 7.8125rem;
  }
  .ps-lg-26 {
    padding-left: 8.125rem;
  }
  .ps-lg-27 {
    padding-left: 8.4375rem;
  }
  .ps-lg-28 {
    padding-left: 8.75rem;
  }
  .ps-lg-29 {
    padding-left: 9.0625rem;
  }
  .ps-lg-30 {
    padding-left: 9.375rem;
  }
  .ps-lg-31 {
    padding-left: 9.6875rem;
  }
  .ps-lg-32 {
    padding-left: 10rem;
  }
  .ps-lg-33 {
    padding-left: 10.3125rem;
  }
  .ps-lg-34 {
    padding-left: 10.625rem;
  }
  .ps-lg-35 {
    padding-left: 10.9375rem;
  }
  .ps-lg-36 {
    padding-left: 11.25rem;
  }
  .ps-lg-37 {
    padding-left: 11.5625rem;
  }
  .ps-lg-38 {
    padding-left: 11.875rem;
  }
  .ps-lg-39 {
    padding-left: 12.1875rem;
  }
  .ps-lg-40 {
    padding-left: 12.5rem;
  }
  .text-lg-start {
    text-align: left;
  }
  .text-lg-end {
    text-align: right;
  }
  .text-lg-center {
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left;
  }
  .float-xl-end {
    float: right;
  }
  .float-xl-none {
    float: none;
  }
  .d-xl-inline {
    display: inline;
  }
  .d-xl-inline-block {
    display: inline-block;
  }
  .d-xl-block {
    display: block;
  }
  .d-xl-grid {
    display: grid;
  }
  .d-xl-table {
    display: table;
  }
  .d-xl-table-row {
    display: table-row;
  }
  .d-xl-table-cell {
    display: table-cell;
  }
  .d-xl-flex {
    display: flex;
  }
  .d-xl-inline-flex {
    display: inline-flex;
  }
  .d-xl-none {
    display: none;
  }
  .flex-xl-fill {
    flex: 1 1 auto;
  }
  .flex-xl-row {
    flex-direction: row;
  }
  .flex-xl-column {
    flex-direction: column;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-xl-grow-0 {
    flex-grow: 0;
  }
  .flex-xl-grow-1 {
    flex-grow: 1;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1;
  }
  .flex-xl-wrap {
    flex-wrap: wrap;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .gap-xl-0 {
    gap: 0rem;
  }
  .gap-xl-1 {
    gap: 0.3125rem;
  }
  .gap-xl-2 {
    gap: 0.625rem;
  }
  .gap-xl-3 {
    gap: 0.9375rem;
  }
  .gap-xl-4 {
    gap: 1.25rem;
  }
  .gap-xl-5 {
    gap: 1.5625rem;
  }
  .gap-xl-6 {
    gap: 1.875rem;
  }
  .gap-xl-7 {
    gap: 2.1875rem;
  }
  .gap-xl-8 {
    gap: 2.5rem;
  }
  .gap-xl-9 {
    gap: 2.8125rem;
  }
  .gap-xl-10 {
    gap: 3.125rem;
  }
  .gap-xl-11 {
    gap: 3.4375rem;
  }
  .gap-xl-12 {
    gap: 3.75rem;
  }
  .gap-xl-13 {
    gap: 4.0625rem;
  }
  .gap-xl-14 {
    gap: 4.375rem;
  }
  .gap-xl-15 {
    gap: 4.6875rem;
  }
  .gap-xl-16 {
    gap: 5rem;
  }
  .gap-xl-17 {
    gap: 5.3125rem;
  }
  .gap-xl-18 {
    gap: 5.625rem;
  }
  .gap-xl-19 {
    gap: 5.9375rem;
  }
  .gap-xl-20 {
    gap: 6.25rem;
  }
  .gap-xl-21 {
    gap: 6.5625rem;
  }
  .gap-xl-22 {
    gap: 6.875rem;
  }
  .gap-xl-23 {
    gap: 7.1875rem;
  }
  .gap-xl-24 {
    gap: 7.5rem;
  }
  .gap-xl-25 {
    gap: 7.8125rem;
  }
  .gap-xl-26 {
    gap: 8.125rem;
  }
  .gap-xl-27 {
    gap: 8.4375rem;
  }
  .gap-xl-28 {
    gap: 8.75rem;
  }
  .gap-xl-29 {
    gap: 9.0625rem;
  }
  .gap-xl-30 {
    gap: 9.375rem;
  }
  .gap-xl-31 {
    gap: 9.6875rem;
  }
  .gap-xl-32 {
    gap: 10rem;
  }
  .gap-xl-33 {
    gap: 10.3125rem;
  }
  .gap-xl-34 {
    gap: 10.625rem;
  }
  .gap-xl-35 {
    gap: 10.9375rem;
  }
  .gap-xl-36 {
    gap: 11.25rem;
  }
  .gap-xl-37 {
    gap: 11.5625rem;
  }
  .gap-xl-38 {
    gap: 11.875rem;
  }
  .gap-xl-39 {
    gap: 12.1875rem;
  }
  .gap-xl-40 {
    gap: 12.5rem;
  }
  .justify-content-xl-start {
    justify-content: flex-start;
  }
  .justify-content-xl-end {
    justify-content: flex-end;
  }
  .justify-content-xl-center {
    justify-content: center;
  }
  .justify-content-xl-between {
    justify-content: space-between;
  }
  .justify-content-xl-around {
    justify-content: space-around;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly;
  }
  .align-items-xl-start {
    align-items: flex-start;
  }
  .align-items-xl-end {
    align-items: flex-end;
  }
  .align-items-xl-center {
    align-items: center;
  }
  .align-items-xl-baseline {
    align-items: baseline;
  }
  .align-items-xl-stretch {
    align-items: stretch;
  }
  .align-content-xl-start {
    align-content: flex-start;
  }
  .align-content-xl-end {
    align-content: flex-end;
  }
  .align-content-xl-center {
    align-content: center;
  }
  .align-content-xl-between {
    align-content: space-between;
  }
  .align-content-xl-around {
    align-content: space-around;
  }
  .align-content-xl-stretch {
    align-content: stretch;
  }
  .align-self-xl-auto {
    align-self: auto;
  }
  .align-self-xl-start {
    align-self: flex-start;
  }
  .align-self-xl-end {
    align-self: flex-end;
  }
  .align-self-xl-center {
    align-self: center;
  }
  .align-self-xl-baseline {
    align-self: baseline;
  }
  .align-self-xl-stretch {
    align-self: stretch;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-last {
    order: 6;
  }
  .m-xl-0 {
    margin: 0rem;
  }
  .m-xl-1 {
    margin: 0.3125rem;
  }
  .m-xl-2 {
    margin: 0.625rem;
  }
  .m-xl-3 {
    margin: 0.9375rem;
  }
  .m-xl-4 {
    margin: 1.25rem;
  }
  .m-xl-5 {
    margin: 1.5625rem;
  }
  .m-xl-6 {
    margin: 1.875rem;
  }
  .m-xl-7 {
    margin: 2.1875rem;
  }
  .m-xl-8 {
    margin: 2.5rem;
  }
  .m-xl-9 {
    margin: 2.8125rem;
  }
  .m-xl-10 {
    margin: 3.125rem;
  }
  .m-xl-11 {
    margin: 3.4375rem;
  }
  .m-xl-12 {
    margin: 3.75rem;
  }
  .m-xl-13 {
    margin: 4.0625rem;
  }
  .m-xl-14 {
    margin: 4.375rem;
  }
  .m-xl-15 {
    margin: 4.6875rem;
  }
  .m-xl-16 {
    margin: 5rem;
  }
  .m-xl-17 {
    margin: 5.3125rem;
  }
  .m-xl-18 {
    margin: 5.625rem;
  }
  .m-xl-19 {
    margin: 5.9375rem;
  }
  .m-xl-20 {
    margin: 6.25rem;
  }
  .m-xl-21 {
    margin: 6.5625rem;
  }
  .m-xl-22 {
    margin: 6.875rem;
  }
  .m-xl-23 {
    margin: 7.1875rem;
  }
  .m-xl-24 {
    margin: 7.5rem;
  }
  .m-xl-25 {
    margin: 7.8125rem;
  }
  .m-xl-26 {
    margin: 8.125rem;
  }
  .m-xl-27 {
    margin: 8.4375rem;
  }
  .m-xl-28 {
    margin: 8.75rem;
  }
  .m-xl-29 {
    margin: 9.0625rem;
  }
  .m-xl-30 {
    margin: 9.375rem;
  }
  .m-xl-31 {
    margin: 9.6875rem;
  }
  .m-xl-32 {
    margin: 10rem;
  }
  .m-xl-33 {
    margin: 10.3125rem;
  }
  .m-xl-34 {
    margin: 10.625rem;
  }
  .m-xl-35 {
    margin: 10.9375rem;
  }
  .m-xl-36 {
    margin: 11.25rem;
  }
  .m-xl-37 {
    margin: 11.5625rem;
  }
  .m-xl-38 {
    margin: 11.875rem;
  }
  .m-xl-39 {
    margin: 12.1875rem;
  }
  .m-xl-40 {
    margin: 12.5rem;
  }
  .m-xl-auto {
    margin: auto;
  }
  .mx-xl-0 {
    margin-right: 0rem;
    margin-left: 0rem;
  }
  .mx-xl-1 {
    margin-right: 0.3125rem;
    margin-left: 0.3125rem;
  }
  .mx-xl-2 {
    margin-right: 0.625rem;
    margin-left: 0.625rem;
  }
  .mx-xl-3 {
    margin-right: 0.9375rem;
    margin-left: 0.9375rem;
  }
  .mx-xl-4 {
    margin-right: 1.25rem;
    margin-left: 1.25rem;
  }
  .mx-xl-5 {
    margin-right: 1.5625rem;
    margin-left: 1.5625rem;
  }
  .mx-xl-6 {
    margin-right: 1.875rem;
    margin-left: 1.875rem;
  }
  .mx-xl-7 {
    margin-right: 2.1875rem;
    margin-left: 2.1875rem;
  }
  .mx-xl-8 {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }
  .mx-xl-9 {
    margin-right: 2.8125rem;
    margin-left: 2.8125rem;
  }
  .mx-xl-10 {
    margin-right: 3.125rem;
    margin-left: 3.125rem;
  }
  .mx-xl-11 {
    margin-right: 3.4375rem;
    margin-left: 3.4375rem;
  }
  .mx-xl-12 {
    margin-right: 3.75rem;
    margin-left: 3.75rem;
  }
  .mx-xl-13 {
    margin-right: 4.0625rem;
    margin-left: 4.0625rem;
  }
  .mx-xl-14 {
    margin-right: 4.375rem;
    margin-left: 4.375rem;
  }
  .mx-xl-15 {
    margin-right: 4.6875rem;
    margin-left: 4.6875rem;
  }
  .mx-xl-16 {
    margin-right: 5rem;
    margin-left: 5rem;
  }
  .mx-xl-17 {
    margin-right: 5.3125rem;
    margin-left: 5.3125rem;
  }
  .mx-xl-18 {
    margin-right: 5.625rem;
    margin-left: 5.625rem;
  }
  .mx-xl-19 {
    margin-right: 5.9375rem;
    margin-left: 5.9375rem;
  }
  .mx-xl-20 {
    margin-right: 6.25rem;
    margin-left: 6.25rem;
  }
  .mx-xl-21 {
    margin-right: 6.5625rem;
    margin-left: 6.5625rem;
  }
  .mx-xl-22 {
    margin-right: 6.875rem;
    margin-left: 6.875rem;
  }
  .mx-xl-23 {
    margin-right: 7.1875rem;
    margin-left: 7.1875rem;
  }
  .mx-xl-24 {
    margin-right: 7.5rem;
    margin-left: 7.5rem;
  }
  .mx-xl-25 {
    margin-right: 7.8125rem;
    margin-left: 7.8125rem;
  }
  .mx-xl-26 {
    margin-right: 8.125rem;
    margin-left: 8.125rem;
  }
  .mx-xl-27 {
    margin-right: 8.4375rem;
    margin-left: 8.4375rem;
  }
  .mx-xl-28 {
    margin-right: 8.75rem;
    margin-left: 8.75rem;
  }
  .mx-xl-29 {
    margin-right: 9.0625rem;
    margin-left: 9.0625rem;
  }
  .mx-xl-30 {
    margin-right: 9.375rem;
    margin-left: 9.375rem;
  }
  .mx-xl-31 {
    margin-right: 9.6875rem;
    margin-left: 9.6875rem;
  }
  .mx-xl-32 {
    margin-right: 10rem;
    margin-left: 10rem;
  }
  .mx-xl-33 {
    margin-right: 10.3125rem;
    margin-left: 10.3125rem;
  }
  .mx-xl-34 {
    margin-right: 10.625rem;
    margin-left: 10.625rem;
  }
  .mx-xl-35 {
    margin-right: 10.9375rem;
    margin-left: 10.9375rem;
  }
  .mx-xl-36 {
    margin-right: 11.25rem;
    margin-left: 11.25rem;
  }
  .mx-xl-37 {
    margin-right: 11.5625rem;
    margin-left: 11.5625rem;
  }
  .mx-xl-38 {
    margin-right: 11.875rem;
    margin-left: 11.875rem;
  }
  .mx-xl-39 {
    margin-right: 12.1875rem;
    margin-left: 12.1875rem;
  }
  .mx-xl-40 {
    margin-right: 12.5rem;
    margin-left: 12.5rem;
  }
  .mx-xl-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-xl-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .my-xl-1 {
    margin-top: 0.3125rem;
    margin-bottom: 0.3125rem;
  }
  .my-xl-2 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .my-xl-3 {
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
  }
  .my-xl-4 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .my-xl-5 {
    margin-top: 1.5625rem;
    margin-bottom: 1.5625rem;
  }
  .my-xl-6 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }
  .my-xl-7 {
    margin-top: 2.1875rem;
    margin-bottom: 2.1875rem;
  }
  .my-xl-8 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .my-xl-9 {
    margin-top: 2.8125rem;
    margin-bottom: 2.8125rem;
  }
  .my-xl-10 {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }
  .my-xl-11 {
    margin-top: 3.4375rem;
    margin-bottom: 3.4375rem;
  }
  .my-xl-12 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }
  .my-xl-13 {
    margin-top: 4.0625rem;
    margin-bottom: 4.0625rem;
  }
  .my-xl-14 {
    margin-top: 4.375rem;
    margin-bottom: 4.375rem;
  }
  .my-xl-15 {
    margin-top: 4.6875rem;
    margin-bottom: 4.6875rem;
  }
  .my-xl-16 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .my-xl-17 {
    margin-top: 5.3125rem;
    margin-bottom: 5.3125rem;
  }
  .my-xl-18 {
    margin-top: 5.625rem;
    margin-bottom: 5.625rem;
  }
  .my-xl-19 {
    margin-top: 5.9375rem;
    margin-bottom: 5.9375rem;
  }
  .my-xl-20 {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
  }
  .my-xl-21 {
    margin-top: 6.5625rem;
    margin-bottom: 6.5625rem;
  }
  .my-xl-22 {
    margin-top: 6.875rem;
    margin-bottom: 6.875rem;
  }
  .my-xl-23 {
    margin-top: 7.1875rem;
    margin-bottom: 7.1875rem;
  }
  .my-xl-24 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }
  .my-xl-25 {
    margin-top: 7.8125rem;
    margin-bottom: 7.8125rem;
  }
  .my-xl-26 {
    margin-top: 8.125rem;
    margin-bottom: 8.125rem;
  }
  .my-xl-27 {
    margin-top: 8.4375rem;
    margin-bottom: 8.4375rem;
  }
  .my-xl-28 {
    margin-top: 8.75rem;
    margin-bottom: 8.75rem;
  }
  .my-xl-29 {
    margin-top: 9.0625rem;
    margin-bottom: 9.0625rem;
  }
  .my-xl-30 {
    margin-top: 9.375rem;
    margin-bottom: 9.375rem;
  }
  .my-xl-31 {
    margin-top: 9.6875rem;
    margin-bottom: 9.6875rem;
  }
  .my-xl-32 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .my-xl-33 {
    margin-top: 10.3125rem;
    margin-bottom: 10.3125rem;
  }
  .my-xl-34 {
    margin-top: 10.625rem;
    margin-bottom: 10.625rem;
  }
  .my-xl-35 {
    margin-top: 10.9375rem;
    margin-bottom: 10.9375rem;
  }
  .my-xl-36 {
    margin-top: 11.25rem;
    margin-bottom: 11.25rem;
  }
  .my-xl-37 {
    margin-top: 11.5625rem;
    margin-bottom: 11.5625rem;
  }
  .my-xl-38 {
    margin-top: 11.875rem;
    margin-bottom: 11.875rem;
  }
  .my-xl-39 {
    margin-top: 12.1875rem;
    margin-bottom: 12.1875rem;
  }
  .my-xl-40 {
    margin-top: 12.5rem;
    margin-bottom: 12.5rem;
  }
  .my-xl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-xl-0 {
    margin-top: 0rem;
  }
  .mt-xl-1 {
    margin-top: 0.3125rem;
  }
  .mt-xl-2 {
    margin-top: 0.625rem;
  }
  .mt-xl-3 {
    margin-top: 0.9375rem;
  }
  .mt-xl-4 {
    margin-top: 1.25rem;
  }
  .mt-xl-5 {
    margin-top: 1.5625rem;
  }
  .mt-xl-6 {
    margin-top: 1.875rem;
  }
  .mt-xl-7 {
    margin-top: 2.1875rem;
  }
  .mt-xl-8 {
    margin-top: 2.5rem;
  }
  .mt-xl-9 {
    margin-top: 2.8125rem;
  }
  .mt-xl-10 {
    margin-top: 3.125rem;
  }
  .mt-xl-11 {
    margin-top: 3.4375rem;
  }
  .mt-xl-12 {
    margin-top: 3.75rem;
  }
  .mt-xl-13 {
    margin-top: 4.0625rem;
  }
  .mt-xl-14 {
    margin-top: 4.375rem;
  }
  .mt-xl-15 {
    margin-top: 4.6875rem;
  }
  .mt-xl-16 {
    margin-top: 5rem;
  }
  .mt-xl-17 {
    margin-top: 5.3125rem;
  }
  .mt-xl-18 {
    margin-top: 5.625rem;
  }
  .mt-xl-19 {
    margin-top: 5.9375rem;
  }
  .mt-xl-20 {
    margin-top: 6.25rem;
  }
  .mt-xl-21 {
    margin-top: 6.5625rem;
  }
  .mt-xl-22 {
    margin-top: 6.875rem;
  }
  .mt-xl-23 {
    margin-top: 7.1875rem;
  }
  .mt-xl-24 {
    margin-top: 7.5rem;
  }
  .mt-xl-25 {
    margin-top: 7.8125rem;
  }
  .mt-xl-26 {
    margin-top: 8.125rem;
  }
  .mt-xl-27 {
    margin-top: 8.4375rem;
  }
  .mt-xl-28 {
    margin-top: 8.75rem;
  }
  .mt-xl-29 {
    margin-top: 9.0625rem;
  }
  .mt-xl-30 {
    margin-top: 9.375rem;
  }
  .mt-xl-31 {
    margin-top: 9.6875rem;
  }
  .mt-xl-32 {
    margin-top: 10rem;
  }
  .mt-xl-33 {
    margin-top: 10.3125rem;
  }
  .mt-xl-34 {
    margin-top: 10.625rem;
  }
  .mt-xl-35 {
    margin-top: 10.9375rem;
  }
  .mt-xl-36 {
    margin-top: 11.25rem;
  }
  .mt-xl-37 {
    margin-top: 11.5625rem;
  }
  .mt-xl-38 {
    margin-top: 11.875rem;
  }
  .mt-xl-39 {
    margin-top: 12.1875rem;
  }
  .mt-xl-40 {
    margin-top: 12.5rem;
  }
  .mt-xl-auto {
    margin-top: auto;
  }
  .me-xl-0 {
    margin-right: 0rem;
  }
  .me-xl-1 {
    margin-right: 0.3125rem;
  }
  .me-xl-2 {
    margin-right: 0.625rem;
  }
  .me-xl-3 {
    margin-right: 0.9375rem;
  }
  .me-xl-4 {
    margin-right: 1.25rem;
  }
  .me-xl-5 {
    margin-right: 1.5625rem;
  }
  .me-xl-6 {
    margin-right: 1.875rem;
  }
  .me-xl-7 {
    margin-right: 2.1875rem;
  }
  .me-xl-8 {
    margin-right: 2.5rem;
  }
  .me-xl-9 {
    margin-right: 2.8125rem;
  }
  .me-xl-10 {
    margin-right: 3.125rem;
  }
  .me-xl-11 {
    margin-right: 3.4375rem;
  }
  .me-xl-12 {
    margin-right: 3.75rem;
  }
  .me-xl-13 {
    margin-right: 4.0625rem;
  }
  .me-xl-14 {
    margin-right: 4.375rem;
  }
  .me-xl-15 {
    margin-right: 4.6875rem;
  }
  .me-xl-16 {
    margin-right: 5rem;
  }
  .me-xl-17 {
    margin-right: 5.3125rem;
  }
  .me-xl-18 {
    margin-right: 5.625rem;
  }
  .me-xl-19 {
    margin-right: 5.9375rem;
  }
  .me-xl-20 {
    margin-right: 6.25rem;
  }
  .me-xl-21 {
    margin-right: 6.5625rem;
  }
  .me-xl-22 {
    margin-right: 6.875rem;
  }
  .me-xl-23 {
    margin-right: 7.1875rem;
  }
  .me-xl-24 {
    margin-right: 7.5rem;
  }
  .me-xl-25 {
    margin-right: 7.8125rem;
  }
  .me-xl-26 {
    margin-right: 8.125rem;
  }
  .me-xl-27 {
    margin-right: 8.4375rem;
  }
  .me-xl-28 {
    margin-right: 8.75rem;
  }
  .me-xl-29 {
    margin-right: 9.0625rem;
  }
  .me-xl-30 {
    margin-right: 9.375rem;
  }
  .me-xl-31 {
    margin-right: 9.6875rem;
  }
  .me-xl-32 {
    margin-right: 10rem;
  }
  .me-xl-33 {
    margin-right: 10.3125rem;
  }
  .me-xl-34 {
    margin-right: 10.625rem;
  }
  .me-xl-35 {
    margin-right: 10.9375rem;
  }
  .me-xl-36 {
    margin-right: 11.25rem;
  }
  .me-xl-37 {
    margin-right: 11.5625rem;
  }
  .me-xl-38 {
    margin-right: 11.875rem;
  }
  .me-xl-39 {
    margin-right: 12.1875rem;
  }
  .me-xl-40 {
    margin-right: 12.5rem;
  }
  .me-xl-auto {
    margin-right: auto;
  }
  .mb-xl-0 {
    margin-bottom: 0rem;
  }
  .mb-xl-1 {
    margin-bottom: 0.3125rem;
  }
  .mb-xl-2 {
    margin-bottom: 0.625rem;
  }
  .mb-xl-3 {
    margin-bottom: 0.9375rem;
  }
  .mb-xl-4 {
    margin-bottom: 1.25rem;
  }
  .mb-xl-5 {
    margin-bottom: 1.5625rem;
  }
  .mb-xl-6 {
    margin-bottom: 1.875rem;
  }
  .mb-xl-7 {
    margin-bottom: 2.1875rem;
  }
  .mb-xl-8 {
    margin-bottom: 2.5rem;
  }
  .mb-xl-9 {
    margin-bottom: 2.8125rem;
  }
  .mb-xl-10 {
    margin-bottom: 3.125rem;
  }
  .mb-xl-11 {
    margin-bottom: 3.4375rem;
  }
  .mb-xl-12 {
    margin-bottom: 3.75rem;
  }
  .mb-xl-13 {
    margin-bottom: 4.0625rem;
  }
  .mb-xl-14 {
    margin-bottom: 4.375rem;
  }
  .mb-xl-15 {
    margin-bottom: 4.6875rem;
  }
  .mb-xl-16 {
    margin-bottom: 5rem;
  }
  .mb-xl-17 {
    margin-bottom: 5.3125rem;
  }
  .mb-xl-18 {
    margin-bottom: 5.625rem;
  }
  .mb-xl-19 {
    margin-bottom: 5.9375rem;
  }
  .mb-xl-20 {
    margin-bottom: 6.25rem;
  }
  .mb-xl-21 {
    margin-bottom: 6.5625rem;
  }
  .mb-xl-22 {
    margin-bottom: 6.875rem;
  }
  .mb-xl-23 {
    margin-bottom: 7.1875rem;
  }
  .mb-xl-24 {
    margin-bottom: 7.5rem;
  }
  .mb-xl-25 {
    margin-bottom: 7.8125rem;
  }
  .mb-xl-26 {
    margin-bottom: 8.125rem;
  }
  .mb-xl-27 {
    margin-bottom: 8.4375rem;
  }
  .mb-xl-28 {
    margin-bottom: 8.75rem;
  }
  .mb-xl-29 {
    margin-bottom: 9.0625rem;
  }
  .mb-xl-30 {
    margin-bottom: 9.375rem;
  }
  .mb-xl-31 {
    margin-bottom: 9.6875rem;
  }
  .mb-xl-32 {
    margin-bottom: 10rem;
  }
  .mb-xl-33 {
    margin-bottom: 10.3125rem;
  }
  .mb-xl-34 {
    margin-bottom: 10.625rem;
  }
  .mb-xl-35 {
    margin-bottom: 10.9375rem;
  }
  .mb-xl-36 {
    margin-bottom: 11.25rem;
  }
  .mb-xl-37 {
    margin-bottom: 11.5625rem;
  }
  .mb-xl-38 {
    margin-bottom: 11.875rem;
  }
  .mb-xl-39 {
    margin-bottom: 12.1875rem;
  }
  .mb-xl-40 {
    margin-bottom: 12.5rem;
  }
  .mb-xl-auto {
    margin-bottom: auto;
  }
  .ms-xl-0 {
    margin-left: 0rem;
  }
  .ms-xl-1 {
    margin-left: 0.3125rem;
  }
  .ms-xl-2 {
    margin-left: 0.625rem;
  }
  .ms-xl-3 {
    margin-left: 0.9375rem;
  }
  .ms-xl-4 {
    margin-left: 1.25rem;
  }
  .ms-xl-5 {
    margin-left: 1.5625rem;
  }
  .ms-xl-6 {
    margin-left: 1.875rem;
  }
  .ms-xl-7 {
    margin-left: 2.1875rem;
  }
  .ms-xl-8 {
    margin-left: 2.5rem;
  }
  .ms-xl-9 {
    margin-left: 2.8125rem;
  }
  .ms-xl-10 {
    margin-left: 3.125rem;
  }
  .ms-xl-11 {
    margin-left: 3.4375rem;
  }
  .ms-xl-12 {
    margin-left: 3.75rem;
  }
  .ms-xl-13 {
    margin-left: 4.0625rem;
  }
  .ms-xl-14 {
    margin-left: 4.375rem;
  }
  .ms-xl-15 {
    margin-left: 4.6875rem;
  }
  .ms-xl-16 {
    margin-left: 5rem;
  }
  .ms-xl-17 {
    margin-left: 5.3125rem;
  }
  .ms-xl-18 {
    margin-left: 5.625rem;
  }
  .ms-xl-19 {
    margin-left: 5.9375rem;
  }
  .ms-xl-20 {
    margin-left: 6.25rem;
  }
  .ms-xl-21 {
    margin-left: 6.5625rem;
  }
  .ms-xl-22 {
    margin-left: 6.875rem;
  }
  .ms-xl-23 {
    margin-left: 7.1875rem;
  }
  .ms-xl-24 {
    margin-left: 7.5rem;
  }
  .ms-xl-25 {
    margin-left: 7.8125rem;
  }
  .ms-xl-26 {
    margin-left: 8.125rem;
  }
  .ms-xl-27 {
    margin-left: 8.4375rem;
  }
  .ms-xl-28 {
    margin-left: 8.75rem;
  }
  .ms-xl-29 {
    margin-left: 9.0625rem;
  }
  .ms-xl-30 {
    margin-left: 9.375rem;
  }
  .ms-xl-31 {
    margin-left: 9.6875rem;
  }
  .ms-xl-32 {
    margin-left: 10rem;
  }
  .ms-xl-33 {
    margin-left: 10.3125rem;
  }
  .ms-xl-34 {
    margin-left: 10.625rem;
  }
  .ms-xl-35 {
    margin-left: 10.9375rem;
  }
  .ms-xl-36 {
    margin-left: 11.25rem;
  }
  .ms-xl-37 {
    margin-left: 11.5625rem;
  }
  .ms-xl-38 {
    margin-left: 11.875rem;
  }
  .ms-xl-39 {
    margin-left: 12.1875rem;
  }
  .ms-xl-40 {
    margin-left: 12.5rem;
  }
  .ms-xl-auto {
    margin-left: auto;
  }
  .p-xl-0 {
    padding: 0rem;
  }
  .p-xl-1 {
    padding: 0.3125rem;
  }
  .p-xl-2 {
    padding: 0.625rem;
  }
  .p-xl-3 {
    padding: 0.9375rem;
  }
  .p-xl-4 {
    padding: 1.25rem;
  }
  .p-xl-5 {
    padding: 1.5625rem;
  }
  .p-xl-6 {
    padding: 1.875rem;
  }
  .p-xl-7 {
    padding: 2.1875rem;
  }
  .p-xl-8 {
    padding: 2.5rem;
  }
  .p-xl-9 {
    padding: 2.8125rem;
  }
  .p-xl-10 {
    padding: 3.125rem;
  }
  .p-xl-11 {
    padding: 3.4375rem;
  }
  .p-xl-12 {
    padding: 3.75rem;
  }
  .p-xl-13 {
    padding: 4.0625rem;
  }
  .p-xl-14 {
    padding: 4.375rem;
  }
  .p-xl-15 {
    padding: 4.6875rem;
  }
  .p-xl-16 {
    padding: 5rem;
  }
  .p-xl-17 {
    padding: 5.3125rem;
  }
  .p-xl-18 {
    padding: 5.625rem;
  }
  .p-xl-19 {
    padding: 5.9375rem;
  }
  .p-xl-20 {
    padding: 6.25rem;
  }
  .p-xl-21 {
    padding: 6.5625rem;
  }
  .p-xl-22 {
    padding: 6.875rem;
  }
  .p-xl-23 {
    padding: 7.1875rem;
  }
  .p-xl-24 {
    padding: 7.5rem;
  }
  .p-xl-25 {
    padding: 7.8125rem;
  }
  .p-xl-26 {
    padding: 8.125rem;
  }
  .p-xl-27 {
    padding: 8.4375rem;
  }
  .p-xl-28 {
    padding: 8.75rem;
  }
  .p-xl-29 {
    padding: 9.0625rem;
  }
  .p-xl-30 {
    padding: 9.375rem;
  }
  .p-xl-31 {
    padding: 9.6875rem;
  }
  .p-xl-32 {
    padding: 10rem;
  }
  .p-xl-33 {
    padding: 10.3125rem;
  }
  .p-xl-34 {
    padding: 10.625rem;
  }
  .p-xl-35 {
    padding: 10.9375rem;
  }
  .p-xl-36 {
    padding: 11.25rem;
  }
  .p-xl-37 {
    padding: 11.5625rem;
  }
  .p-xl-38 {
    padding: 11.875rem;
  }
  .p-xl-39 {
    padding: 12.1875rem;
  }
  .p-xl-40 {
    padding: 12.5rem;
  }
  .px-xl-0 {
    padding-right: 0rem;
    padding-left: 0rem;
  }
  .px-xl-1 {
    padding-right: 0.3125rem;
    padding-left: 0.3125rem;
  }
  .px-xl-2 {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
  .px-xl-3 {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
  .px-xl-4 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .px-xl-5 {
    padding-right: 1.5625rem;
    padding-left: 1.5625rem;
  }
  .px-xl-6 {
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }
  .px-xl-7 {
    padding-right: 2.1875rem;
    padding-left: 2.1875rem;
  }
  .px-xl-8 {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .px-xl-9 {
    padding-right: 2.8125rem;
    padding-left: 2.8125rem;
  }
  .px-xl-10 {
    padding-right: 3.125rem;
    padding-left: 3.125rem;
  }
  .px-xl-11 {
    padding-right: 3.4375rem;
    padding-left: 3.4375rem;
  }
  .px-xl-12 {
    padding-right: 3.75rem;
    padding-left: 3.75rem;
  }
  .px-xl-13 {
    padding-right: 4.0625rem;
    padding-left: 4.0625rem;
  }
  .px-xl-14 {
    padding-right: 4.375rem;
    padding-left: 4.375rem;
  }
  .px-xl-15 {
    padding-right: 4.6875rem;
    padding-left: 4.6875rem;
  }
  .px-xl-16 {
    padding-right: 5rem;
    padding-left: 5rem;
  }
  .px-xl-17 {
    padding-right: 5.3125rem;
    padding-left: 5.3125rem;
  }
  .px-xl-18 {
    padding-right: 5.625rem;
    padding-left: 5.625rem;
  }
  .px-xl-19 {
    padding-right: 5.9375rem;
    padding-left: 5.9375rem;
  }
  .px-xl-20 {
    padding-right: 6.25rem;
    padding-left: 6.25rem;
  }
  .px-xl-21 {
    padding-right: 6.5625rem;
    padding-left: 6.5625rem;
  }
  .px-xl-22 {
    padding-right: 6.875rem;
    padding-left: 6.875rem;
  }
  .px-xl-23 {
    padding-right: 7.1875rem;
    padding-left: 7.1875rem;
  }
  .px-xl-24 {
    padding-right: 7.5rem;
    padding-left: 7.5rem;
  }
  .px-xl-25 {
    padding-right: 7.8125rem;
    padding-left: 7.8125rem;
  }
  .px-xl-26 {
    padding-right: 8.125rem;
    padding-left: 8.125rem;
  }
  .px-xl-27 {
    padding-right: 8.4375rem;
    padding-left: 8.4375rem;
  }
  .px-xl-28 {
    padding-right: 8.75rem;
    padding-left: 8.75rem;
  }
  .px-xl-29 {
    padding-right: 9.0625rem;
    padding-left: 9.0625rem;
  }
  .px-xl-30 {
    padding-right: 9.375rem;
    padding-left: 9.375rem;
  }
  .px-xl-31 {
    padding-right: 9.6875rem;
    padding-left: 9.6875rem;
  }
  .px-xl-32 {
    padding-right: 10rem;
    padding-left: 10rem;
  }
  .px-xl-33 {
    padding-right: 10.3125rem;
    padding-left: 10.3125rem;
  }
  .px-xl-34 {
    padding-right: 10.625rem;
    padding-left: 10.625rem;
  }
  .px-xl-35 {
    padding-right: 10.9375rem;
    padding-left: 10.9375rem;
  }
  .px-xl-36 {
    padding-right: 11.25rem;
    padding-left: 11.25rem;
  }
  .px-xl-37 {
    padding-right: 11.5625rem;
    padding-left: 11.5625rem;
  }
  .px-xl-38 {
    padding-right: 11.875rem;
    padding-left: 11.875rem;
  }
  .px-xl-39 {
    padding-right: 12.1875rem;
    padding-left: 12.1875rem;
  }
  .px-xl-40 {
    padding-right: 12.5rem;
    padding-left: 12.5rem;
  }
  .py-xl-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .py-xl-1 {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }
  .py-xl-2 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .py-xl-3 {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
  }
  .py-xl-4 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .py-xl-5 {
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem;
  }
  .py-xl-6 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }
  .py-xl-7 {
    padding-top: 2.1875rem;
    padding-bottom: 2.1875rem;
  }
  .py-xl-8 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .py-xl-9 {
    padding-top: 2.8125rem;
    padding-bottom: 2.8125rem;
  }
  .py-xl-10 {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
  }
  .py-xl-11 {
    padding-top: 3.4375rem;
    padding-bottom: 3.4375rem;
  }
  .py-xl-12 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
  .py-xl-13 {
    padding-top: 4.0625rem;
    padding-bottom: 4.0625rem;
  }
  .py-xl-14 {
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
  }
  .py-xl-15 {
    padding-top: 4.6875rem;
    padding-bottom: 4.6875rem;
  }
  .py-xl-16 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .py-xl-17 {
    padding-top: 5.3125rem;
    padding-bottom: 5.3125rem;
  }
  .py-xl-18 {
    padding-top: 5.625rem;
    padding-bottom: 5.625rem;
  }
  .py-xl-19 {
    padding-top: 5.9375rem;
    padding-bottom: 5.9375rem;
  }
  .py-xl-20 {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
  .py-xl-21 {
    padding-top: 6.5625rem;
    padding-bottom: 6.5625rem;
  }
  .py-xl-22 {
    padding-top: 6.875rem;
    padding-bottom: 6.875rem;
  }
  .py-xl-23 {
    padding-top: 7.1875rem;
    padding-bottom: 7.1875rem;
  }
  .py-xl-24 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .py-xl-25 {
    padding-top: 7.8125rem;
    padding-bottom: 7.8125rem;
  }
  .py-xl-26 {
    padding-top: 8.125rem;
    padding-bottom: 8.125rem;
  }
  .py-xl-27 {
    padding-top: 8.4375rem;
    padding-bottom: 8.4375rem;
  }
  .py-xl-28 {
    padding-top: 8.75rem;
    padding-bottom: 8.75rem;
  }
  .py-xl-29 {
    padding-top: 9.0625rem;
    padding-bottom: 9.0625rem;
  }
  .py-xl-30 {
    padding-top: 9.375rem;
    padding-bottom: 9.375rem;
  }
  .py-xl-31 {
    padding-top: 9.6875rem;
    padding-bottom: 9.6875rem;
  }
  .py-xl-32 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .py-xl-33 {
    padding-top: 10.3125rem;
    padding-bottom: 10.3125rem;
  }
  .py-xl-34 {
    padding-top: 10.625rem;
    padding-bottom: 10.625rem;
  }
  .py-xl-35 {
    padding-top: 10.9375rem;
    padding-bottom: 10.9375rem;
  }
  .py-xl-36 {
    padding-top: 11.25rem;
    padding-bottom: 11.25rem;
  }
  .py-xl-37 {
    padding-top: 11.5625rem;
    padding-bottom: 11.5625rem;
  }
  .py-xl-38 {
    padding-top: 11.875rem;
    padding-bottom: 11.875rem;
  }
  .py-xl-39 {
    padding-top: 12.1875rem;
    padding-bottom: 12.1875rem;
  }
  .py-xl-40 {
    padding-top: 12.5rem;
    padding-bottom: 12.5rem;
  }
  .pt-xl-0 {
    padding-top: 0rem;
  }
  .pt-xl-1 {
    padding-top: 0.3125rem;
  }
  .pt-xl-2 {
    padding-top: 0.625rem;
  }
  .pt-xl-3 {
    padding-top: 0.9375rem;
  }
  .pt-xl-4 {
    padding-top: 1.25rem;
  }
  .pt-xl-5 {
    padding-top: 1.5625rem;
  }
  .pt-xl-6 {
    padding-top: 1.875rem;
  }
  .pt-xl-7 {
    padding-top: 2.1875rem;
  }
  .pt-xl-8 {
    padding-top: 2.5rem;
  }
  .pt-xl-9 {
    padding-top: 2.8125rem;
  }
  .pt-xl-10 {
    padding-top: 3.125rem;
  }
  .pt-xl-11 {
    padding-top: 3.4375rem;
  }
  .pt-xl-12 {
    padding-top: 3.75rem;
  }
  .pt-xl-13 {
    padding-top: 4.0625rem;
  }
  .pt-xl-14 {
    padding-top: 4.375rem;
  }
  .pt-xl-15 {
    padding-top: 4.6875rem;
  }
  .pt-xl-16 {
    padding-top: 5rem;
  }
  .pt-xl-17 {
    padding-top: 5.3125rem;
  }
  .pt-xl-18 {
    padding-top: 5.625rem;
  }
  .pt-xl-19 {
    padding-top: 5.9375rem;
  }
  .pt-xl-20 {
    padding-top: 6.25rem;
  }
  .pt-xl-21 {
    padding-top: 6.5625rem;
  }
  .pt-xl-22 {
    padding-top: 6.875rem;
  }
  .pt-xl-23 {
    padding-top: 7.1875rem;
  }
  .pt-xl-24 {
    padding-top: 7.5rem;
  }
  .pt-xl-25 {
    padding-top: 7.8125rem;
  }
  .pt-xl-26 {
    padding-top: 8.125rem;
  }
  .pt-xl-27 {
    padding-top: 8.4375rem;
  }
  .pt-xl-28 {
    padding-top: 8.75rem;
  }
  .pt-xl-29 {
    padding-top: 9.0625rem;
  }
  .pt-xl-30 {
    padding-top: 9.375rem;
  }
  .pt-xl-31 {
    padding-top: 9.6875rem;
  }
  .pt-xl-32 {
    padding-top: 10rem;
  }
  .pt-xl-33 {
    padding-top: 10.3125rem;
  }
  .pt-xl-34 {
    padding-top: 10.625rem;
  }
  .pt-xl-35 {
    padding-top: 10.9375rem;
  }
  .pt-xl-36 {
    padding-top: 11.25rem;
  }
  .pt-xl-37 {
    padding-top: 11.5625rem;
  }
  .pt-xl-38 {
    padding-top: 11.875rem;
  }
  .pt-xl-39 {
    padding-top: 12.1875rem;
  }
  .pt-xl-40 {
    padding-top: 12.5rem;
  }
  .pe-xl-0 {
    padding-right: 0rem;
  }
  .pe-xl-1 {
    padding-right: 0.3125rem;
  }
  .pe-xl-2 {
    padding-right: 0.625rem;
  }
  .pe-xl-3 {
    padding-right: 0.9375rem;
  }
  .pe-xl-4 {
    padding-right: 1.25rem;
  }
  .pe-xl-5 {
    padding-right: 1.5625rem;
  }
  .pe-xl-6 {
    padding-right: 1.875rem;
  }
  .pe-xl-7 {
    padding-right: 2.1875rem;
  }
  .pe-xl-8 {
    padding-right: 2.5rem;
  }
  .pe-xl-9 {
    padding-right: 2.8125rem;
  }
  .pe-xl-10 {
    padding-right: 3.125rem;
  }
  .pe-xl-11 {
    padding-right: 3.4375rem;
  }
  .pe-xl-12 {
    padding-right: 3.75rem;
  }
  .pe-xl-13 {
    padding-right: 4.0625rem;
  }
  .pe-xl-14 {
    padding-right: 4.375rem;
  }
  .pe-xl-15 {
    padding-right: 4.6875rem;
  }
  .pe-xl-16 {
    padding-right: 5rem;
  }
  .pe-xl-17 {
    padding-right: 5.3125rem;
  }
  .pe-xl-18 {
    padding-right: 5.625rem;
  }
  .pe-xl-19 {
    padding-right: 5.9375rem;
  }
  .pe-xl-20 {
    padding-right: 6.25rem;
  }
  .pe-xl-21 {
    padding-right: 6.5625rem;
  }
  .pe-xl-22 {
    padding-right: 6.875rem;
  }
  .pe-xl-23 {
    padding-right: 7.1875rem;
  }
  .pe-xl-24 {
    padding-right: 7.5rem;
  }
  .pe-xl-25 {
    padding-right: 7.8125rem;
  }
  .pe-xl-26 {
    padding-right: 8.125rem;
  }
  .pe-xl-27 {
    padding-right: 8.4375rem;
  }
  .pe-xl-28 {
    padding-right: 8.75rem;
  }
  .pe-xl-29 {
    padding-right: 9.0625rem;
  }
  .pe-xl-30 {
    padding-right: 9.375rem;
  }
  .pe-xl-31 {
    padding-right: 9.6875rem;
  }
  .pe-xl-32 {
    padding-right: 10rem;
  }
  .pe-xl-33 {
    padding-right: 10.3125rem;
  }
  .pe-xl-34 {
    padding-right: 10.625rem;
  }
  .pe-xl-35 {
    padding-right: 10.9375rem;
  }
  .pe-xl-36 {
    padding-right: 11.25rem;
  }
  .pe-xl-37 {
    padding-right: 11.5625rem;
  }
  .pe-xl-38 {
    padding-right: 11.875rem;
  }
  .pe-xl-39 {
    padding-right: 12.1875rem;
  }
  .pe-xl-40 {
    padding-right: 12.5rem;
  }
  .pb-xl-0 {
    padding-bottom: 0rem;
  }
  .pb-xl-1 {
    padding-bottom: 0.3125rem;
  }
  .pb-xl-2 {
    padding-bottom: 0.625rem;
  }
  .pb-xl-3 {
    padding-bottom: 0.9375rem;
  }
  .pb-xl-4 {
    padding-bottom: 1.25rem;
  }
  .pb-xl-5 {
    padding-bottom: 1.5625rem;
  }
  .pb-xl-6 {
    padding-bottom: 1.875rem;
  }
  .pb-xl-7 {
    padding-bottom: 2.1875rem;
  }
  .pb-xl-8 {
    padding-bottom: 2.5rem;
  }
  .pb-xl-9 {
    padding-bottom: 2.8125rem;
  }
  .pb-xl-10 {
    padding-bottom: 3.125rem;
  }
  .pb-xl-11 {
    padding-bottom: 3.4375rem;
  }
  .pb-xl-12 {
    padding-bottom: 3.75rem;
  }
  .pb-xl-13 {
    padding-bottom: 4.0625rem;
  }
  .pb-xl-14 {
    padding-bottom: 4.375rem;
  }
  .pb-xl-15 {
    padding-bottom: 4.6875rem;
  }
  .pb-xl-16 {
    padding-bottom: 5rem;
  }
  .pb-xl-17 {
    padding-bottom: 5.3125rem;
  }
  .pb-xl-18 {
    padding-bottom: 5.625rem;
  }
  .pb-xl-19 {
    padding-bottom: 5.9375rem;
  }
  .pb-xl-20 {
    padding-bottom: 6.25rem;
  }
  .pb-xl-21 {
    padding-bottom: 6.5625rem;
  }
  .pb-xl-22 {
    padding-bottom: 6.875rem;
  }
  .pb-xl-23 {
    padding-bottom: 7.1875rem;
  }
  .pb-xl-24 {
    padding-bottom: 7.5rem;
  }
  .pb-xl-25 {
    padding-bottom: 7.8125rem;
  }
  .pb-xl-26 {
    padding-bottom: 8.125rem;
  }
  .pb-xl-27 {
    padding-bottom: 8.4375rem;
  }
  .pb-xl-28 {
    padding-bottom: 8.75rem;
  }
  .pb-xl-29 {
    padding-bottom: 9.0625rem;
  }
  .pb-xl-30 {
    padding-bottom: 9.375rem;
  }
  .pb-xl-31 {
    padding-bottom: 9.6875rem;
  }
  .pb-xl-32 {
    padding-bottom: 10rem;
  }
  .pb-xl-33 {
    padding-bottom: 10.3125rem;
  }
  .pb-xl-34 {
    padding-bottom: 10.625rem;
  }
  .pb-xl-35 {
    padding-bottom: 10.9375rem;
  }
  .pb-xl-36 {
    padding-bottom: 11.25rem;
  }
  .pb-xl-37 {
    padding-bottom: 11.5625rem;
  }
  .pb-xl-38 {
    padding-bottom: 11.875rem;
  }
  .pb-xl-39 {
    padding-bottom: 12.1875rem;
  }
  .pb-xl-40 {
    padding-bottom: 12.5rem;
  }
  .ps-xl-0 {
    padding-left: 0rem;
  }
  .ps-xl-1 {
    padding-left: 0.3125rem;
  }
  .ps-xl-2 {
    padding-left: 0.625rem;
  }
  .ps-xl-3 {
    padding-left: 0.9375rem;
  }
  .ps-xl-4 {
    padding-left: 1.25rem;
  }
  .ps-xl-5 {
    padding-left: 1.5625rem;
  }
  .ps-xl-6 {
    padding-left: 1.875rem;
  }
  .ps-xl-7 {
    padding-left: 2.1875rem;
  }
  .ps-xl-8 {
    padding-left: 2.5rem;
  }
  .ps-xl-9 {
    padding-left: 2.8125rem;
  }
  .ps-xl-10 {
    padding-left: 3.125rem;
  }
  .ps-xl-11 {
    padding-left: 3.4375rem;
  }
  .ps-xl-12 {
    padding-left: 3.75rem;
  }
  .ps-xl-13 {
    padding-left: 4.0625rem;
  }
  .ps-xl-14 {
    padding-left: 4.375rem;
  }
  .ps-xl-15 {
    padding-left: 4.6875rem;
  }
  .ps-xl-16 {
    padding-left: 5rem;
  }
  .ps-xl-17 {
    padding-left: 5.3125rem;
  }
  .ps-xl-18 {
    padding-left: 5.625rem;
  }
  .ps-xl-19 {
    padding-left: 5.9375rem;
  }
  .ps-xl-20 {
    padding-left: 6.25rem;
  }
  .ps-xl-21 {
    padding-left: 6.5625rem;
  }
  .ps-xl-22 {
    padding-left: 6.875rem;
  }
  .ps-xl-23 {
    padding-left: 7.1875rem;
  }
  .ps-xl-24 {
    padding-left: 7.5rem;
  }
  .ps-xl-25 {
    padding-left: 7.8125rem;
  }
  .ps-xl-26 {
    padding-left: 8.125rem;
  }
  .ps-xl-27 {
    padding-left: 8.4375rem;
  }
  .ps-xl-28 {
    padding-left: 8.75rem;
  }
  .ps-xl-29 {
    padding-left: 9.0625rem;
  }
  .ps-xl-30 {
    padding-left: 9.375rem;
  }
  .ps-xl-31 {
    padding-left: 9.6875rem;
  }
  .ps-xl-32 {
    padding-left: 10rem;
  }
  .ps-xl-33 {
    padding-left: 10.3125rem;
  }
  .ps-xl-34 {
    padding-left: 10.625rem;
  }
  .ps-xl-35 {
    padding-left: 10.9375rem;
  }
  .ps-xl-36 {
    padding-left: 11.25rem;
  }
  .ps-xl-37 {
    padding-left: 11.5625rem;
  }
  .ps-xl-38 {
    padding-left: 11.875rem;
  }
  .ps-xl-39 {
    padding-left: 12.1875rem;
  }
  .ps-xl-40 {
    padding-left: 12.5rem;
  }
  .text-xl-start {
    text-align: left;
  }
  .text-xl-end {
    text-align: right;
  }
  .text-xl-center {
    text-align: center;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left;
  }
  .float-xxl-end {
    float: right;
  }
  .float-xxl-none {
    float: none;
  }
  .d-xxl-inline {
    display: inline;
  }
  .d-xxl-inline-block {
    display: inline-block;
  }
  .d-xxl-block {
    display: block;
  }
  .d-xxl-grid {
    display: grid;
  }
  .d-xxl-table {
    display: table;
  }
  .d-xxl-table-row {
    display: table-row;
  }
  .d-xxl-table-cell {
    display: table-cell;
  }
  .d-xxl-flex {
    display: flex;
  }
  .d-xxl-inline-flex {
    display: inline-flex;
  }
  .d-xxl-none {
    display: none;
  }
  .flex-xxl-fill {
    flex: 1 1 auto;
  }
  .flex-xxl-row {
    flex-direction: row;
  }
  .flex-xxl-column {
    flex-direction: column;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .gap-xxl-0 {
    gap: 0rem;
  }
  .gap-xxl-1 {
    gap: 0.3125rem;
  }
  .gap-xxl-2 {
    gap: 0.625rem;
  }
  .gap-xxl-3 {
    gap: 0.9375rem;
  }
  .gap-xxl-4 {
    gap: 1.25rem;
  }
  .gap-xxl-5 {
    gap: 1.5625rem;
  }
  .gap-xxl-6 {
    gap: 1.875rem;
  }
  .gap-xxl-7 {
    gap: 2.1875rem;
  }
  .gap-xxl-8 {
    gap: 2.5rem;
  }
  .gap-xxl-9 {
    gap: 2.8125rem;
  }
  .gap-xxl-10 {
    gap: 3.125rem;
  }
  .gap-xxl-11 {
    gap: 3.4375rem;
  }
  .gap-xxl-12 {
    gap: 3.75rem;
  }
  .gap-xxl-13 {
    gap: 4.0625rem;
  }
  .gap-xxl-14 {
    gap: 4.375rem;
  }
  .gap-xxl-15 {
    gap: 4.6875rem;
  }
  .gap-xxl-16 {
    gap: 5rem;
  }
  .gap-xxl-17 {
    gap: 5.3125rem;
  }
  .gap-xxl-18 {
    gap: 5.625rem;
  }
  .gap-xxl-19 {
    gap: 5.9375rem;
  }
  .gap-xxl-20 {
    gap: 6.25rem;
  }
  .gap-xxl-21 {
    gap: 6.5625rem;
  }
  .gap-xxl-22 {
    gap: 6.875rem;
  }
  .gap-xxl-23 {
    gap: 7.1875rem;
  }
  .gap-xxl-24 {
    gap: 7.5rem;
  }
  .gap-xxl-25 {
    gap: 7.8125rem;
  }
  .gap-xxl-26 {
    gap: 8.125rem;
  }
  .gap-xxl-27 {
    gap: 8.4375rem;
  }
  .gap-xxl-28 {
    gap: 8.75rem;
  }
  .gap-xxl-29 {
    gap: 9.0625rem;
  }
  .gap-xxl-30 {
    gap: 9.375rem;
  }
  .gap-xxl-31 {
    gap: 9.6875rem;
  }
  .gap-xxl-32 {
    gap: 10rem;
  }
  .gap-xxl-33 {
    gap: 10.3125rem;
  }
  .gap-xxl-34 {
    gap: 10.625rem;
  }
  .gap-xxl-35 {
    gap: 10.9375rem;
  }
  .gap-xxl-36 {
    gap: 11.25rem;
  }
  .gap-xxl-37 {
    gap: 11.5625rem;
  }
  .gap-xxl-38 {
    gap: 11.875rem;
  }
  .gap-xxl-39 {
    gap: 12.1875rem;
  }
  .gap-xxl-40 {
    gap: 12.5rem;
  }
  .justify-content-xxl-start {
    justify-content: flex-start;
  }
  .justify-content-xxl-end {
    justify-content: flex-end;
  }
  .justify-content-xxl-center {
    justify-content: center;
  }
  .justify-content-xxl-between {
    justify-content: space-between;
  }
  .justify-content-xxl-around {
    justify-content: space-around;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly;
  }
  .align-items-xxl-start {
    align-items: flex-start;
  }
  .align-items-xxl-end {
    align-items: flex-end;
  }
  .align-items-xxl-center {
    align-items: center;
  }
  .align-items-xxl-baseline {
    align-items: baseline;
  }
  .align-items-xxl-stretch {
    align-items: stretch;
  }
  .align-content-xxl-start {
    align-content: flex-start;
  }
  .align-content-xxl-end {
    align-content: flex-end;
  }
  .align-content-xxl-center {
    align-content: center;
  }
  .align-content-xxl-between {
    align-content: space-between;
  }
  .align-content-xxl-around {
    align-content: space-around;
  }
  .align-content-xxl-stretch {
    align-content: stretch;
  }
  .align-self-xxl-auto {
    align-self: auto;
  }
  .align-self-xxl-start {
    align-self: flex-start;
  }
  .align-self-xxl-end {
    align-self: flex-end;
  }
  .align-self-xxl-center {
    align-self: center;
  }
  .align-self-xxl-baseline {
    align-self: baseline;
  }
  .align-self-xxl-stretch {
    align-self: stretch;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-last {
    order: 6;
  }
  .m-xxl-0 {
    margin: 0rem;
  }
  .m-xxl-1 {
    margin: 0.3125rem;
  }
  .m-xxl-2 {
    margin: 0.625rem;
  }
  .m-xxl-3 {
    margin: 0.9375rem;
  }
  .m-xxl-4 {
    margin: 1.25rem;
  }
  .m-xxl-5 {
    margin: 1.5625rem;
  }
  .m-xxl-6 {
    margin: 1.875rem;
  }
  .m-xxl-7 {
    margin: 2.1875rem;
  }
  .m-xxl-8 {
    margin: 2.5rem;
  }
  .m-xxl-9 {
    margin: 2.8125rem;
  }
  .m-xxl-10 {
    margin: 3.125rem;
  }
  .m-xxl-11 {
    margin: 3.4375rem;
  }
  .m-xxl-12 {
    margin: 3.75rem;
  }
  .m-xxl-13 {
    margin: 4.0625rem;
  }
  .m-xxl-14 {
    margin: 4.375rem;
  }
  .m-xxl-15 {
    margin: 4.6875rem;
  }
  .m-xxl-16 {
    margin: 5rem;
  }
  .m-xxl-17 {
    margin: 5.3125rem;
  }
  .m-xxl-18 {
    margin: 5.625rem;
  }
  .m-xxl-19 {
    margin: 5.9375rem;
  }
  .m-xxl-20 {
    margin: 6.25rem;
  }
  .m-xxl-21 {
    margin: 6.5625rem;
  }
  .m-xxl-22 {
    margin: 6.875rem;
  }
  .m-xxl-23 {
    margin: 7.1875rem;
  }
  .m-xxl-24 {
    margin: 7.5rem;
  }
  .m-xxl-25 {
    margin: 7.8125rem;
  }
  .m-xxl-26 {
    margin: 8.125rem;
  }
  .m-xxl-27 {
    margin: 8.4375rem;
  }
  .m-xxl-28 {
    margin: 8.75rem;
  }
  .m-xxl-29 {
    margin: 9.0625rem;
  }
  .m-xxl-30 {
    margin: 9.375rem;
  }
  .m-xxl-31 {
    margin: 9.6875rem;
  }
  .m-xxl-32 {
    margin: 10rem;
  }
  .m-xxl-33 {
    margin: 10.3125rem;
  }
  .m-xxl-34 {
    margin: 10.625rem;
  }
  .m-xxl-35 {
    margin: 10.9375rem;
  }
  .m-xxl-36 {
    margin: 11.25rem;
  }
  .m-xxl-37 {
    margin: 11.5625rem;
  }
  .m-xxl-38 {
    margin: 11.875rem;
  }
  .m-xxl-39 {
    margin: 12.1875rem;
  }
  .m-xxl-40 {
    margin: 12.5rem;
  }
  .m-xxl-auto {
    margin: auto;
  }
  .mx-xxl-0 {
    margin-right: 0rem;
    margin-left: 0rem;
  }
  .mx-xxl-1 {
    margin-right: 0.3125rem;
    margin-left: 0.3125rem;
  }
  .mx-xxl-2 {
    margin-right: 0.625rem;
    margin-left: 0.625rem;
  }
  .mx-xxl-3 {
    margin-right: 0.9375rem;
    margin-left: 0.9375rem;
  }
  .mx-xxl-4 {
    margin-right: 1.25rem;
    margin-left: 1.25rem;
  }
  .mx-xxl-5 {
    margin-right: 1.5625rem;
    margin-left: 1.5625rem;
  }
  .mx-xxl-6 {
    margin-right: 1.875rem;
    margin-left: 1.875rem;
  }
  .mx-xxl-7 {
    margin-right: 2.1875rem;
    margin-left: 2.1875rem;
  }
  .mx-xxl-8 {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }
  .mx-xxl-9 {
    margin-right: 2.8125rem;
    margin-left: 2.8125rem;
  }
  .mx-xxl-10 {
    margin-right: 3.125rem;
    margin-left: 3.125rem;
  }
  .mx-xxl-11 {
    margin-right: 3.4375rem;
    margin-left: 3.4375rem;
  }
  .mx-xxl-12 {
    margin-right: 3.75rem;
    margin-left: 3.75rem;
  }
  .mx-xxl-13 {
    margin-right: 4.0625rem;
    margin-left: 4.0625rem;
  }
  .mx-xxl-14 {
    margin-right: 4.375rem;
    margin-left: 4.375rem;
  }
  .mx-xxl-15 {
    margin-right: 4.6875rem;
    margin-left: 4.6875rem;
  }
  .mx-xxl-16 {
    margin-right: 5rem;
    margin-left: 5rem;
  }
  .mx-xxl-17 {
    margin-right: 5.3125rem;
    margin-left: 5.3125rem;
  }
  .mx-xxl-18 {
    margin-right: 5.625rem;
    margin-left: 5.625rem;
  }
  .mx-xxl-19 {
    margin-right: 5.9375rem;
    margin-left: 5.9375rem;
  }
  .mx-xxl-20 {
    margin-right: 6.25rem;
    margin-left: 6.25rem;
  }
  .mx-xxl-21 {
    margin-right: 6.5625rem;
    margin-left: 6.5625rem;
  }
  .mx-xxl-22 {
    margin-right: 6.875rem;
    margin-left: 6.875rem;
  }
  .mx-xxl-23 {
    margin-right: 7.1875rem;
    margin-left: 7.1875rem;
  }
  .mx-xxl-24 {
    margin-right: 7.5rem;
    margin-left: 7.5rem;
  }
  .mx-xxl-25 {
    margin-right: 7.8125rem;
    margin-left: 7.8125rem;
  }
  .mx-xxl-26 {
    margin-right: 8.125rem;
    margin-left: 8.125rem;
  }
  .mx-xxl-27 {
    margin-right: 8.4375rem;
    margin-left: 8.4375rem;
  }
  .mx-xxl-28 {
    margin-right: 8.75rem;
    margin-left: 8.75rem;
  }
  .mx-xxl-29 {
    margin-right: 9.0625rem;
    margin-left: 9.0625rem;
  }
  .mx-xxl-30 {
    margin-right: 9.375rem;
    margin-left: 9.375rem;
  }
  .mx-xxl-31 {
    margin-right: 9.6875rem;
    margin-left: 9.6875rem;
  }
  .mx-xxl-32 {
    margin-right: 10rem;
    margin-left: 10rem;
  }
  .mx-xxl-33 {
    margin-right: 10.3125rem;
    margin-left: 10.3125rem;
  }
  .mx-xxl-34 {
    margin-right: 10.625rem;
    margin-left: 10.625rem;
  }
  .mx-xxl-35 {
    margin-right: 10.9375rem;
    margin-left: 10.9375rem;
  }
  .mx-xxl-36 {
    margin-right: 11.25rem;
    margin-left: 11.25rem;
  }
  .mx-xxl-37 {
    margin-right: 11.5625rem;
    margin-left: 11.5625rem;
  }
  .mx-xxl-38 {
    margin-right: 11.875rem;
    margin-left: 11.875rem;
  }
  .mx-xxl-39 {
    margin-right: 12.1875rem;
    margin-left: 12.1875rem;
  }
  .mx-xxl-40 {
    margin-right: 12.5rem;
    margin-left: 12.5rem;
  }
  .mx-xxl-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-xxl-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .my-xxl-1 {
    margin-top: 0.3125rem;
    margin-bottom: 0.3125rem;
  }
  .my-xxl-2 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .my-xxl-3 {
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
  }
  .my-xxl-4 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .my-xxl-5 {
    margin-top: 1.5625rem;
    margin-bottom: 1.5625rem;
  }
  .my-xxl-6 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }
  .my-xxl-7 {
    margin-top: 2.1875rem;
    margin-bottom: 2.1875rem;
  }
  .my-xxl-8 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .my-xxl-9 {
    margin-top: 2.8125rem;
    margin-bottom: 2.8125rem;
  }
  .my-xxl-10 {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }
  .my-xxl-11 {
    margin-top: 3.4375rem;
    margin-bottom: 3.4375rem;
  }
  .my-xxl-12 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }
  .my-xxl-13 {
    margin-top: 4.0625rem;
    margin-bottom: 4.0625rem;
  }
  .my-xxl-14 {
    margin-top: 4.375rem;
    margin-bottom: 4.375rem;
  }
  .my-xxl-15 {
    margin-top: 4.6875rem;
    margin-bottom: 4.6875rem;
  }
  .my-xxl-16 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .my-xxl-17 {
    margin-top: 5.3125rem;
    margin-bottom: 5.3125rem;
  }
  .my-xxl-18 {
    margin-top: 5.625rem;
    margin-bottom: 5.625rem;
  }
  .my-xxl-19 {
    margin-top: 5.9375rem;
    margin-bottom: 5.9375rem;
  }
  .my-xxl-20 {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
  }
  .my-xxl-21 {
    margin-top: 6.5625rem;
    margin-bottom: 6.5625rem;
  }
  .my-xxl-22 {
    margin-top: 6.875rem;
    margin-bottom: 6.875rem;
  }
  .my-xxl-23 {
    margin-top: 7.1875rem;
    margin-bottom: 7.1875rem;
  }
  .my-xxl-24 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }
  .my-xxl-25 {
    margin-top: 7.8125rem;
    margin-bottom: 7.8125rem;
  }
  .my-xxl-26 {
    margin-top: 8.125rem;
    margin-bottom: 8.125rem;
  }
  .my-xxl-27 {
    margin-top: 8.4375rem;
    margin-bottom: 8.4375rem;
  }
  .my-xxl-28 {
    margin-top: 8.75rem;
    margin-bottom: 8.75rem;
  }
  .my-xxl-29 {
    margin-top: 9.0625rem;
    margin-bottom: 9.0625rem;
  }
  .my-xxl-30 {
    margin-top: 9.375rem;
    margin-bottom: 9.375rem;
  }
  .my-xxl-31 {
    margin-top: 9.6875rem;
    margin-bottom: 9.6875rem;
  }
  .my-xxl-32 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .my-xxl-33 {
    margin-top: 10.3125rem;
    margin-bottom: 10.3125rem;
  }
  .my-xxl-34 {
    margin-top: 10.625rem;
    margin-bottom: 10.625rem;
  }
  .my-xxl-35 {
    margin-top: 10.9375rem;
    margin-bottom: 10.9375rem;
  }
  .my-xxl-36 {
    margin-top: 11.25rem;
    margin-bottom: 11.25rem;
  }
  .my-xxl-37 {
    margin-top: 11.5625rem;
    margin-bottom: 11.5625rem;
  }
  .my-xxl-38 {
    margin-top: 11.875rem;
    margin-bottom: 11.875rem;
  }
  .my-xxl-39 {
    margin-top: 12.1875rem;
    margin-bottom: 12.1875rem;
  }
  .my-xxl-40 {
    margin-top: 12.5rem;
    margin-bottom: 12.5rem;
  }
  .my-xxl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-xxl-0 {
    margin-top: 0rem;
  }
  .mt-xxl-1 {
    margin-top: 0.3125rem;
  }
  .mt-xxl-2 {
    margin-top: 0.625rem;
  }
  .mt-xxl-3 {
    margin-top: 0.9375rem;
  }
  .mt-xxl-4 {
    margin-top: 1.25rem;
  }
  .mt-xxl-5 {
    margin-top: 1.5625rem;
  }
  .mt-xxl-6 {
    margin-top: 1.875rem;
  }
  .mt-xxl-7 {
    margin-top: 2.1875rem;
  }
  .mt-xxl-8 {
    margin-top: 2.5rem;
  }
  .mt-xxl-9 {
    margin-top: 2.8125rem;
  }
  .mt-xxl-10 {
    margin-top: 3.125rem;
  }
  .mt-xxl-11 {
    margin-top: 3.4375rem;
  }
  .mt-xxl-12 {
    margin-top: 3.75rem;
  }
  .mt-xxl-13 {
    margin-top: 4.0625rem;
  }
  .mt-xxl-14 {
    margin-top: 4.375rem;
  }
  .mt-xxl-15 {
    margin-top: 4.6875rem;
  }
  .mt-xxl-16 {
    margin-top: 5rem;
  }
  .mt-xxl-17 {
    margin-top: 5.3125rem;
  }
  .mt-xxl-18 {
    margin-top: 5.625rem;
  }
  .mt-xxl-19 {
    margin-top: 5.9375rem;
  }
  .mt-xxl-20 {
    margin-top: 6.25rem;
  }
  .mt-xxl-21 {
    margin-top: 6.5625rem;
  }
  .mt-xxl-22 {
    margin-top: 6.875rem;
  }
  .mt-xxl-23 {
    margin-top: 7.1875rem;
  }
  .mt-xxl-24 {
    margin-top: 7.5rem;
  }
  .mt-xxl-25 {
    margin-top: 7.8125rem;
  }
  .mt-xxl-26 {
    margin-top: 8.125rem;
  }
  .mt-xxl-27 {
    margin-top: 8.4375rem;
  }
  .mt-xxl-28 {
    margin-top: 8.75rem;
  }
  .mt-xxl-29 {
    margin-top: 9.0625rem;
  }
  .mt-xxl-30 {
    margin-top: 9.375rem;
  }
  .mt-xxl-31 {
    margin-top: 9.6875rem;
  }
  .mt-xxl-32 {
    margin-top: 10rem;
  }
  .mt-xxl-33 {
    margin-top: 10.3125rem;
  }
  .mt-xxl-34 {
    margin-top: 10.625rem;
  }
  .mt-xxl-35 {
    margin-top: 10.9375rem;
  }
  .mt-xxl-36 {
    margin-top: 11.25rem;
  }
  .mt-xxl-37 {
    margin-top: 11.5625rem;
  }
  .mt-xxl-38 {
    margin-top: 11.875rem;
  }
  .mt-xxl-39 {
    margin-top: 12.1875rem;
  }
  .mt-xxl-40 {
    margin-top: 12.5rem;
  }
  .mt-xxl-auto {
    margin-top: auto;
  }
  .me-xxl-0 {
    margin-right: 0rem;
  }
  .me-xxl-1 {
    margin-right: 0.3125rem;
  }
  .me-xxl-2 {
    margin-right: 0.625rem;
  }
  .me-xxl-3 {
    margin-right: 0.9375rem;
  }
  .me-xxl-4 {
    margin-right: 1.25rem;
  }
  .me-xxl-5 {
    margin-right: 1.5625rem;
  }
  .me-xxl-6 {
    margin-right: 1.875rem;
  }
  .me-xxl-7 {
    margin-right: 2.1875rem;
  }
  .me-xxl-8 {
    margin-right: 2.5rem;
  }
  .me-xxl-9 {
    margin-right: 2.8125rem;
  }
  .me-xxl-10 {
    margin-right: 3.125rem;
  }
  .me-xxl-11 {
    margin-right: 3.4375rem;
  }
  .me-xxl-12 {
    margin-right: 3.75rem;
  }
  .me-xxl-13 {
    margin-right: 4.0625rem;
  }
  .me-xxl-14 {
    margin-right: 4.375rem;
  }
  .me-xxl-15 {
    margin-right: 4.6875rem;
  }
  .me-xxl-16 {
    margin-right: 5rem;
  }
  .me-xxl-17 {
    margin-right: 5.3125rem;
  }
  .me-xxl-18 {
    margin-right: 5.625rem;
  }
  .me-xxl-19 {
    margin-right: 5.9375rem;
  }
  .me-xxl-20 {
    margin-right: 6.25rem;
  }
  .me-xxl-21 {
    margin-right: 6.5625rem;
  }
  .me-xxl-22 {
    margin-right: 6.875rem;
  }
  .me-xxl-23 {
    margin-right: 7.1875rem;
  }
  .me-xxl-24 {
    margin-right: 7.5rem;
  }
  .me-xxl-25 {
    margin-right: 7.8125rem;
  }
  .me-xxl-26 {
    margin-right: 8.125rem;
  }
  .me-xxl-27 {
    margin-right: 8.4375rem;
  }
  .me-xxl-28 {
    margin-right: 8.75rem;
  }
  .me-xxl-29 {
    margin-right: 9.0625rem;
  }
  .me-xxl-30 {
    margin-right: 9.375rem;
  }
  .me-xxl-31 {
    margin-right: 9.6875rem;
  }
  .me-xxl-32 {
    margin-right: 10rem;
  }
  .me-xxl-33 {
    margin-right: 10.3125rem;
  }
  .me-xxl-34 {
    margin-right: 10.625rem;
  }
  .me-xxl-35 {
    margin-right: 10.9375rem;
  }
  .me-xxl-36 {
    margin-right: 11.25rem;
  }
  .me-xxl-37 {
    margin-right: 11.5625rem;
  }
  .me-xxl-38 {
    margin-right: 11.875rem;
  }
  .me-xxl-39 {
    margin-right: 12.1875rem;
  }
  .me-xxl-40 {
    margin-right: 12.5rem;
  }
  .me-xxl-auto {
    margin-right: auto;
  }
  .mb-xxl-0 {
    margin-bottom: 0rem;
  }
  .mb-xxl-1 {
    margin-bottom: 0.3125rem;
  }
  .mb-xxl-2 {
    margin-bottom: 0.625rem;
  }
  .mb-xxl-3 {
    margin-bottom: 0.9375rem;
  }
  .mb-xxl-4 {
    margin-bottom: 1.25rem;
  }
  .mb-xxl-5 {
    margin-bottom: 1.5625rem;
  }
  .mb-xxl-6 {
    margin-bottom: 1.875rem;
  }
  .mb-xxl-7 {
    margin-bottom: 2.1875rem;
  }
  .mb-xxl-8 {
    margin-bottom: 2.5rem;
  }
  .mb-xxl-9 {
    margin-bottom: 2.8125rem;
  }
  .mb-xxl-10 {
    margin-bottom: 3.125rem;
  }
  .mb-xxl-11 {
    margin-bottom: 3.4375rem;
  }
  .mb-xxl-12 {
    margin-bottom: 3.75rem;
  }
  .mb-xxl-13 {
    margin-bottom: 4.0625rem;
  }
  .mb-xxl-14 {
    margin-bottom: 4.375rem;
  }
  .mb-xxl-15 {
    margin-bottom: 4.6875rem;
  }
  .mb-xxl-16 {
    margin-bottom: 5rem;
  }
  .mb-xxl-17 {
    margin-bottom: 5.3125rem;
  }
  .mb-xxl-18 {
    margin-bottom: 5.625rem;
  }
  .mb-xxl-19 {
    margin-bottom: 5.9375rem;
  }
  .mb-xxl-20 {
    margin-bottom: 6.25rem;
  }
  .mb-xxl-21 {
    margin-bottom: 6.5625rem;
  }
  .mb-xxl-22 {
    margin-bottom: 6.875rem;
  }
  .mb-xxl-23 {
    margin-bottom: 7.1875rem;
  }
  .mb-xxl-24 {
    margin-bottom: 7.5rem;
  }
  .mb-xxl-25 {
    margin-bottom: 7.8125rem;
  }
  .mb-xxl-26 {
    margin-bottom: 8.125rem;
  }
  .mb-xxl-27 {
    margin-bottom: 8.4375rem;
  }
  .mb-xxl-28 {
    margin-bottom: 8.75rem;
  }
  .mb-xxl-29 {
    margin-bottom: 9.0625rem;
  }
  .mb-xxl-30 {
    margin-bottom: 9.375rem;
  }
  .mb-xxl-31 {
    margin-bottom: 9.6875rem;
  }
  .mb-xxl-32 {
    margin-bottom: 10rem;
  }
  .mb-xxl-33 {
    margin-bottom: 10.3125rem;
  }
  .mb-xxl-34 {
    margin-bottom: 10.625rem;
  }
  .mb-xxl-35 {
    margin-bottom: 10.9375rem;
  }
  .mb-xxl-36 {
    margin-bottom: 11.25rem;
  }
  .mb-xxl-37 {
    margin-bottom: 11.5625rem;
  }
  .mb-xxl-38 {
    margin-bottom: 11.875rem;
  }
  .mb-xxl-39 {
    margin-bottom: 12.1875rem;
  }
  .mb-xxl-40 {
    margin-bottom: 12.5rem;
  }
  .mb-xxl-auto {
    margin-bottom: auto;
  }
  .ms-xxl-0 {
    margin-left: 0rem;
  }
  .ms-xxl-1 {
    margin-left: 0.3125rem;
  }
  .ms-xxl-2 {
    margin-left: 0.625rem;
  }
  .ms-xxl-3 {
    margin-left: 0.9375rem;
  }
  .ms-xxl-4 {
    margin-left: 1.25rem;
  }
  .ms-xxl-5 {
    margin-left: 1.5625rem;
  }
  .ms-xxl-6 {
    margin-left: 1.875rem;
  }
  .ms-xxl-7 {
    margin-left: 2.1875rem;
  }
  .ms-xxl-8 {
    margin-left: 2.5rem;
  }
  .ms-xxl-9 {
    margin-left: 2.8125rem;
  }
  .ms-xxl-10 {
    margin-left: 3.125rem;
  }
  .ms-xxl-11 {
    margin-left: 3.4375rem;
  }
  .ms-xxl-12 {
    margin-left: 3.75rem;
  }
  .ms-xxl-13 {
    margin-left: 4.0625rem;
  }
  .ms-xxl-14 {
    margin-left: 4.375rem;
  }
  .ms-xxl-15 {
    margin-left: 4.6875rem;
  }
  .ms-xxl-16 {
    margin-left: 5rem;
  }
  .ms-xxl-17 {
    margin-left: 5.3125rem;
  }
  .ms-xxl-18 {
    margin-left: 5.625rem;
  }
  .ms-xxl-19 {
    margin-left: 5.9375rem;
  }
  .ms-xxl-20 {
    margin-left: 6.25rem;
  }
  .ms-xxl-21 {
    margin-left: 6.5625rem;
  }
  .ms-xxl-22 {
    margin-left: 6.875rem;
  }
  .ms-xxl-23 {
    margin-left: 7.1875rem;
  }
  .ms-xxl-24 {
    margin-left: 7.5rem;
  }
  .ms-xxl-25 {
    margin-left: 7.8125rem;
  }
  .ms-xxl-26 {
    margin-left: 8.125rem;
  }
  .ms-xxl-27 {
    margin-left: 8.4375rem;
  }
  .ms-xxl-28 {
    margin-left: 8.75rem;
  }
  .ms-xxl-29 {
    margin-left: 9.0625rem;
  }
  .ms-xxl-30 {
    margin-left: 9.375rem;
  }
  .ms-xxl-31 {
    margin-left: 9.6875rem;
  }
  .ms-xxl-32 {
    margin-left: 10rem;
  }
  .ms-xxl-33 {
    margin-left: 10.3125rem;
  }
  .ms-xxl-34 {
    margin-left: 10.625rem;
  }
  .ms-xxl-35 {
    margin-left: 10.9375rem;
  }
  .ms-xxl-36 {
    margin-left: 11.25rem;
  }
  .ms-xxl-37 {
    margin-left: 11.5625rem;
  }
  .ms-xxl-38 {
    margin-left: 11.875rem;
  }
  .ms-xxl-39 {
    margin-left: 12.1875rem;
  }
  .ms-xxl-40 {
    margin-left: 12.5rem;
  }
  .ms-xxl-auto {
    margin-left: auto;
  }
  .p-xxl-0 {
    padding: 0rem;
  }
  .p-xxl-1 {
    padding: 0.3125rem;
  }
  .p-xxl-2 {
    padding: 0.625rem;
  }
  .p-xxl-3 {
    padding: 0.9375rem;
  }
  .p-xxl-4 {
    padding: 1.25rem;
  }
  .p-xxl-5 {
    padding: 1.5625rem;
  }
  .p-xxl-6 {
    padding: 1.875rem;
  }
  .p-xxl-7 {
    padding: 2.1875rem;
  }
  .p-xxl-8 {
    padding: 2.5rem;
  }
  .p-xxl-9 {
    padding: 2.8125rem;
  }
  .p-xxl-10 {
    padding: 3.125rem;
  }
  .p-xxl-11 {
    padding: 3.4375rem;
  }
  .p-xxl-12 {
    padding: 3.75rem;
  }
  .p-xxl-13 {
    padding: 4.0625rem;
  }
  .p-xxl-14 {
    padding: 4.375rem;
  }
  .p-xxl-15 {
    padding: 4.6875rem;
  }
  .p-xxl-16 {
    padding: 5rem;
  }
  .p-xxl-17 {
    padding: 5.3125rem;
  }
  .p-xxl-18 {
    padding: 5.625rem;
  }
  .p-xxl-19 {
    padding: 5.9375rem;
  }
  .p-xxl-20 {
    padding: 6.25rem;
  }
  .p-xxl-21 {
    padding: 6.5625rem;
  }
  .p-xxl-22 {
    padding: 6.875rem;
  }
  .p-xxl-23 {
    padding: 7.1875rem;
  }
  .p-xxl-24 {
    padding: 7.5rem;
  }
  .p-xxl-25 {
    padding: 7.8125rem;
  }
  .p-xxl-26 {
    padding: 8.125rem;
  }
  .p-xxl-27 {
    padding: 8.4375rem;
  }
  .p-xxl-28 {
    padding: 8.75rem;
  }
  .p-xxl-29 {
    padding: 9.0625rem;
  }
  .p-xxl-30 {
    padding: 9.375rem;
  }
  .p-xxl-31 {
    padding: 9.6875rem;
  }
  .p-xxl-32 {
    padding: 10rem;
  }
  .p-xxl-33 {
    padding: 10.3125rem;
  }
  .p-xxl-34 {
    padding: 10.625rem;
  }
  .p-xxl-35 {
    padding: 10.9375rem;
  }
  .p-xxl-36 {
    padding: 11.25rem;
  }
  .p-xxl-37 {
    padding: 11.5625rem;
  }
  .p-xxl-38 {
    padding: 11.875rem;
  }
  .p-xxl-39 {
    padding: 12.1875rem;
  }
  .p-xxl-40 {
    padding: 12.5rem;
  }
  .px-xxl-0 {
    padding-right: 0rem;
    padding-left: 0rem;
  }
  .px-xxl-1 {
    padding-right: 0.3125rem;
    padding-left: 0.3125rem;
  }
  .px-xxl-2 {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
  .px-xxl-3 {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
  .px-xxl-4 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .px-xxl-5 {
    padding-right: 1.5625rem;
    padding-left: 1.5625rem;
  }
  .px-xxl-6 {
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }
  .px-xxl-7 {
    padding-right: 2.1875rem;
    padding-left: 2.1875rem;
  }
  .px-xxl-8 {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .px-xxl-9 {
    padding-right: 2.8125rem;
    padding-left: 2.8125rem;
  }
  .px-xxl-10 {
    padding-right: 3.125rem;
    padding-left: 3.125rem;
  }
  .px-xxl-11 {
    padding-right: 3.4375rem;
    padding-left: 3.4375rem;
  }
  .px-xxl-12 {
    padding-right: 3.75rem;
    padding-left: 3.75rem;
  }
  .px-xxl-13 {
    padding-right: 4.0625rem;
    padding-left: 4.0625rem;
  }
  .px-xxl-14 {
    padding-right: 4.375rem;
    padding-left: 4.375rem;
  }
  .px-xxl-15 {
    padding-right: 4.6875rem;
    padding-left: 4.6875rem;
  }
  .px-xxl-16 {
    padding-right: 5rem;
    padding-left: 5rem;
  }
  .px-xxl-17 {
    padding-right: 5.3125rem;
    padding-left: 5.3125rem;
  }
  .px-xxl-18 {
    padding-right: 5.625rem;
    padding-left: 5.625rem;
  }
  .px-xxl-19 {
    padding-right: 5.9375rem;
    padding-left: 5.9375rem;
  }
  .px-xxl-20 {
    padding-right: 6.25rem;
    padding-left: 6.25rem;
  }
  .px-xxl-21 {
    padding-right: 6.5625rem;
    padding-left: 6.5625rem;
  }
  .px-xxl-22 {
    padding-right: 6.875rem;
    padding-left: 6.875rem;
  }
  .px-xxl-23 {
    padding-right: 7.1875rem;
    padding-left: 7.1875rem;
  }
  .px-xxl-24 {
    padding-right: 7.5rem;
    padding-left: 7.5rem;
  }
  .px-xxl-25 {
    padding-right: 7.8125rem;
    padding-left: 7.8125rem;
  }
  .px-xxl-26 {
    padding-right: 8.125rem;
    padding-left: 8.125rem;
  }
  .px-xxl-27 {
    padding-right: 8.4375rem;
    padding-left: 8.4375rem;
  }
  .px-xxl-28 {
    padding-right: 8.75rem;
    padding-left: 8.75rem;
  }
  .px-xxl-29 {
    padding-right: 9.0625rem;
    padding-left: 9.0625rem;
  }
  .px-xxl-30 {
    padding-right: 9.375rem;
    padding-left: 9.375rem;
  }
  .px-xxl-31 {
    padding-right: 9.6875rem;
    padding-left: 9.6875rem;
  }
  .px-xxl-32 {
    padding-right: 10rem;
    padding-left: 10rem;
  }
  .px-xxl-33 {
    padding-right: 10.3125rem;
    padding-left: 10.3125rem;
  }
  .px-xxl-34 {
    padding-right: 10.625rem;
    padding-left: 10.625rem;
  }
  .px-xxl-35 {
    padding-right: 10.9375rem;
    padding-left: 10.9375rem;
  }
  .px-xxl-36 {
    padding-right: 11.25rem;
    padding-left: 11.25rem;
  }
  .px-xxl-37 {
    padding-right: 11.5625rem;
    padding-left: 11.5625rem;
  }
  .px-xxl-38 {
    padding-right: 11.875rem;
    padding-left: 11.875rem;
  }
  .px-xxl-39 {
    padding-right: 12.1875rem;
    padding-left: 12.1875rem;
  }
  .px-xxl-40 {
    padding-right: 12.5rem;
    padding-left: 12.5rem;
  }
  .py-xxl-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .py-xxl-1 {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }
  .py-xxl-2 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .py-xxl-3 {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
  }
  .py-xxl-4 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .py-xxl-5 {
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem;
  }
  .py-xxl-6 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }
  .py-xxl-7 {
    padding-top: 2.1875rem;
    padding-bottom: 2.1875rem;
  }
  .py-xxl-8 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .py-xxl-9 {
    padding-top: 2.8125rem;
    padding-bottom: 2.8125rem;
  }
  .py-xxl-10 {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
  }
  .py-xxl-11 {
    padding-top: 3.4375rem;
    padding-bottom: 3.4375rem;
  }
  .py-xxl-12 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
  .py-xxl-13 {
    padding-top: 4.0625rem;
    padding-bottom: 4.0625rem;
  }
  .py-xxl-14 {
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
  }
  .py-xxl-15 {
    padding-top: 4.6875rem;
    padding-bottom: 4.6875rem;
  }
  .py-xxl-16 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .py-xxl-17 {
    padding-top: 5.3125rem;
    padding-bottom: 5.3125rem;
  }
  .py-xxl-18 {
    padding-top: 5.625rem;
    padding-bottom: 5.625rem;
  }
  .py-xxl-19 {
    padding-top: 5.9375rem;
    padding-bottom: 5.9375rem;
  }
  .py-xxl-20 {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
  .py-xxl-21 {
    padding-top: 6.5625rem;
    padding-bottom: 6.5625rem;
  }
  .py-xxl-22 {
    padding-top: 6.875rem;
    padding-bottom: 6.875rem;
  }
  .py-xxl-23 {
    padding-top: 7.1875rem;
    padding-bottom: 7.1875rem;
  }
  .py-xxl-24 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .py-xxl-25 {
    padding-top: 7.8125rem;
    padding-bottom: 7.8125rem;
  }
  .py-xxl-26 {
    padding-top: 8.125rem;
    padding-bottom: 8.125rem;
  }
  .py-xxl-27 {
    padding-top: 8.4375rem;
    padding-bottom: 8.4375rem;
  }
  .py-xxl-28 {
    padding-top: 8.75rem;
    padding-bottom: 8.75rem;
  }
  .py-xxl-29 {
    padding-top: 9.0625rem;
    padding-bottom: 9.0625rem;
  }
  .py-xxl-30 {
    padding-top: 9.375rem;
    padding-bottom: 9.375rem;
  }
  .py-xxl-31 {
    padding-top: 9.6875rem;
    padding-bottom: 9.6875rem;
  }
  .py-xxl-32 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .py-xxl-33 {
    padding-top: 10.3125rem;
    padding-bottom: 10.3125rem;
  }
  .py-xxl-34 {
    padding-top: 10.625rem;
    padding-bottom: 10.625rem;
  }
  .py-xxl-35 {
    padding-top: 10.9375rem;
    padding-bottom: 10.9375rem;
  }
  .py-xxl-36 {
    padding-top: 11.25rem;
    padding-bottom: 11.25rem;
  }
  .py-xxl-37 {
    padding-top: 11.5625rem;
    padding-bottom: 11.5625rem;
  }
  .py-xxl-38 {
    padding-top: 11.875rem;
    padding-bottom: 11.875rem;
  }
  .py-xxl-39 {
    padding-top: 12.1875rem;
    padding-bottom: 12.1875rem;
  }
  .py-xxl-40 {
    padding-top: 12.5rem;
    padding-bottom: 12.5rem;
  }
  .pt-xxl-0 {
    padding-top: 0rem;
  }
  .pt-xxl-1 {
    padding-top: 0.3125rem;
  }
  .pt-xxl-2 {
    padding-top: 0.625rem;
  }
  .pt-xxl-3 {
    padding-top: 0.9375rem;
  }
  .pt-xxl-4 {
    padding-top: 1.25rem;
  }
  .pt-xxl-5 {
    padding-top: 1.5625rem;
  }
  .pt-xxl-6 {
    padding-top: 1.875rem;
  }
  .pt-xxl-7 {
    padding-top: 2.1875rem;
  }
  .pt-xxl-8 {
    padding-top: 2.5rem;
  }
  .pt-xxl-9 {
    padding-top: 2.8125rem;
  }
  .pt-xxl-10 {
    padding-top: 3.125rem;
  }
  .pt-xxl-11 {
    padding-top: 3.4375rem;
  }
  .pt-xxl-12 {
    padding-top: 3.75rem;
  }
  .pt-xxl-13 {
    padding-top: 4.0625rem;
  }
  .pt-xxl-14 {
    padding-top: 4.375rem;
  }
  .pt-xxl-15 {
    padding-top: 4.6875rem;
  }
  .pt-xxl-16 {
    padding-top: 5rem;
  }
  .pt-xxl-17 {
    padding-top: 5.3125rem;
  }
  .pt-xxl-18 {
    padding-top: 5.625rem;
  }
  .pt-xxl-19 {
    padding-top: 5.9375rem;
  }
  .pt-xxl-20 {
    padding-top: 6.25rem;
  }
  .pt-xxl-21 {
    padding-top: 6.5625rem;
  }
  .pt-xxl-22 {
    padding-top: 6.875rem;
  }
  .pt-xxl-23 {
    padding-top: 7.1875rem;
  }
  .pt-xxl-24 {
    padding-top: 7.5rem;
  }
  .pt-xxl-25 {
    padding-top: 7.8125rem;
  }
  .pt-xxl-26 {
    padding-top: 8.125rem;
  }
  .pt-xxl-27 {
    padding-top: 8.4375rem;
  }
  .pt-xxl-28 {
    padding-top: 8.75rem;
  }
  .pt-xxl-29 {
    padding-top: 9.0625rem;
  }
  .pt-xxl-30 {
    padding-top: 9.375rem;
  }
  .pt-xxl-31 {
    padding-top: 9.6875rem;
  }
  .pt-xxl-32 {
    padding-top: 10rem;
  }
  .pt-xxl-33 {
    padding-top: 10.3125rem;
  }
  .pt-xxl-34 {
    padding-top: 10.625rem;
  }
  .pt-xxl-35 {
    padding-top: 10.9375rem;
  }
  .pt-xxl-36 {
    padding-top: 11.25rem;
  }
  .pt-xxl-37 {
    padding-top: 11.5625rem;
  }
  .pt-xxl-38 {
    padding-top: 11.875rem;
  }
  .pt-xxl-39 {
    padding-top: 12.1875rem;
  }
  .pt-xxl-40 {
    padding-top: 12.5rem;
  }
  .pe-xxl-0 {
    padding-right: 0rem;
  }
  .pe-xxl-1 {
    padding-right: 0.3125rem;
  }
  .pe-xxl-2 {
    padding-right: 0.625rem;
  }
  .pe-xxl-3 {
    padding-right: 0.9375rem;
  }
  .pe-xxl-4 {
    padding-right: 1.25rem;
  }
  .pe-xxl-5 {
    padding-right: 1.5625rem;
  }
  .pe-xxl-6 {
    padding-right: 1.875rem;
  }
  .pe-xxl-7 {
    padding-right: 2.1875rem;
  }
  .pe-xxl-8 {
    padding-right: 2.5rem;
  }
  .pe-xxl-9 {
    padding-right: 2.8125rem;
  }
  .pe-xxl-10 {
    padding-right: 3.125rem;
  }
  .pe-xxl-11 {
    padding-right: 3.4375rem;
  }
  .pe-xxl-12 {
    padding-right: 3.75rem;
  }
  .pe-xxl-13 {
    padding-right: 4.0625rem;
  }
  .pe-xxl-14 {
    padding-right: 4.375rem;
  }
  .pe-xxl-15 {
    padding-right: 4.6875rem;
  }
  .pe-xxl-16 {
    padding-right: 5rem;
  }
  .pe-xxl-17 {
    padding-right: 5.3125rem;
  }
  .pe-xxl-18 {
    padding-right: 5.625rem;
  }
  .pe-xxl-19 {
    padding-right: 5.9375rem;
  }
  .pe-xxl-20 {
    padding-right: 6.25rem;
  }
  .pe-xxl-21 {
    padding-right: 6.5625rem;
  }
  .pe-xxl-22 {
    padding-right: 6.875rem;
  }
  .pe-xxl-23 {
    padding-right: 7.1875rem;
  }
  .pe-xxl-24 {
    padding-right: 7.5rem;
  }
  .pe-xxl-25 {
    padding-right: 7.8125rem;
  }
  .pe-xxl-26 {
    padding-right: 8.125rem;
  }
  .pe-xxl-27 {
    padding-right: 8.4375rem;
  }
  .pe-xxl-28 {
    padding-right: 8.75rem;
  }
  .pe-xxl-29 {
    padding-right: 9.0625rem;
  }
  .pe-xxl-30 {
    padding-right: 9.375rem;
  }
  .pe-xxl-31 {
    padding-right: 9.6875rem;
  }
  .pe-xxl-32 {
    padding-right: 10rem;
  }
  .pe-xxl-33 {
    padding-right: 10.3125rem;
  }
  .pe-xxl-34 {
    padding-right: 10.625rem;
  }
  .pe-xxl-35 {
    padding-right: 10.9375rem;
  }
  .pe-xxl-36 {
    padding-right: 11.25rem;
  }
  .pe-xxl-37 {
    padding-right: 11.5625rem;
  }
  .pe-xxl-38 {
    padding-right: 11.875rem;
  }
  .pe-xxl-39 {
    padding-right: 12.1875rem;
  }
  .pe-xxl-40 {
    padding-right: 12.5rem;
  }
  .pb-xxl-0 {
    padding-bottom: 0rem;
  }
  .pb-xxl-1 {
    padding-bottom: 0.3125rem;
  }
  .pb-xxl-2 {
    padding-bottom: 0.625rem;
  }
  .pb-xxl-3 {
    padding-bottom: 0.9375rem;
  }
  .pb-xxl-4 {
    padding-bottom: 1.25rem;
  }
  .pb-xxl-5 {
    padding-bottom: 1.5625rem;
  }
  .pb-xxl-6 {
    padding-bottom: 1.875rem;
  }
  .pb-xxl-7 {
    padding-bottom: 2.1875rem;
  }
  .pb-xxl-8 {
    padding-bottom: 2.5rem;
  }
  .pb-xxl-9 {
    padding-bottom: 2.8125rem;
  }
  .pb-xxl-10 {
    padding-bottom: 3.125rem;
  }
  .pb-xxl-11 {
    padding-bottom: 3.4375rem;
  }
  .pb-xxl-12 {
    padding-bottom: 3.75rem;
  }
  .pb-xxl-13 {
    padding-bottom: 4.0625rem;
  }
  .pb-xxl-14 {
    padding-bottom: 4.375rem;
  }
  .pb-xxl-15 {
    padding-bottom: 4.6875rem;
  }
  .pb-xxl-16 {
    padding-bottom: 5rem;
  }
  .pb-xxl-17 {
    padding-bottom: 5.3125rem;
  }
  .pb-xxl-18 {
    padding-bottom: 5.625rem;
  }
  .pb-xxl-19 {
    padding-bottom: 5.9375rem;
  }
  .pb-xxl-20 {
    padding-bottom: 6.25rem;
  }
  .pb-xxl-21 {
    padding-bottom: 6.5625rem;
  }
  .pb-xxl-22 {
    padding-bottom: 6.875rem;
  }
  .pb-xxl-23 {
    padding-bottom: 7.1875rem;
  }
  .pb-xxl-24 {
    padding-bottom: 7.5rem;
  }
  .pb-xxl-25 {
    padding-bottom: 7.8125rem;
  }
  .pb-xxl-26 {
    padding-bottom: 8.125rem;
  }
  .pb-xxl-27 {
    padding-bottom: 8.4375rem;
  }
  .pb-xxl-28 {
    padding-bottom: 8.75rem;
  }
  .pb-xxl-29 {
    padding-bottom: 9.0625rem;
  }
  .pb-xxl-30 {
    padding-bottom: 9.375rem;
  }
  .pb-xxl-31 {
    padding-bottom: 9.6875rem;
  }
  .pb-xxl-32 {
    padding-bottom: 10rem;
  }
  .pb-xxl-33 {
    padding-bottom: 10.3125rem;
  }
  .pb-xxl-34 {
    padding-bottom: 10.625rem;
  }
  .pb-xxl-35 {
    padding-bottom: 10.9375rem;
  }
  .pb-xxl-36 {
    padding-bottom: 11.25rem;
  }
  .pb-xxl-37 {
    padding-bottom: 11.5625rem;
  }
  .pb-xxl-38 {
    padding-bottom: 11.875rem;
  }
  .pb-xxl-39 {
    padding-bottom: 12.1875rem;
  }
  .pb-xxl-40 {
    padding-bottom: 12.5rem;
  }
  .ps-xxl-0 {
    padding-left: 0rem;
  }
  .ps-xxl-1 {
    padding-left: 0.3125rem;
  }
  .ps-xxl-2 {
    padding-left: 0.625rem;
  }
  .ps-xxl-3 {
    padding-left: 0.9375rem;
  }
  .ps-xxl-4 {
    padding-left: 1.25rem;
  }
  .ps-xxl-5 {
    padding-left: 1.5625rem;
  }
  .ps-xxl-6 {
    padding-left: 1.875rem;
  }
  .ps-xxl-7 {
    padding-left: 2.1875rem;
  }
  .ps-xxl-8 {
    padding-left: 2.5rem;
  }
  .ps-xxl-9 {
    padding-left: 2.8125rem;
  }
  .ps-xxl-10 {
    padding-left: 3.125rem;
  }
  .ps-xxl-11 {
    padding-left: 3.4375rem;
  }
  .ps-xxl-12 {
    padding-left: 3.75rem;
  }
  .ps-xxl-13 {
    padding-left: 4.0625rem;
  }
  .ps-xxl-14 {
    padding-left: 4.375rem;
  }
  .ps-xxl-15 {
    padding-left: 4.6875rem;
  }
  .ps-xxl-16 {
    padding-left: 5rem;
  }
  .ps-xxl-17 {
    padding-left: 5.3125rem;
  }
  .ps-xxl-18 {
    padding-left: 5.625rem;
  }
  .ps-xxl-19 {
    padding-left: 5.9375rem;
  }
  .ps-xxl-20 {
    padding-left: 6.25rem;
  }
  .ps-xxl-21 {
    padding-left: 6.5625rem;
  }
  .ps-xxl-22 {
    padding-left: 6.875rem;
  }
  .ps-xxl-23 {
    padding-left: 7.1875rem;
  }
  .ps-xxl-24 {
    padding-left: 7.5rem;
  }
  .ps-xxl-25 {
    padding-left: 7.8125rem;
  }
  .ps-xxl-26 {
    padding-left: 8.125rem;
  }
  .ps-xxl-27 {
    padding-left: 8.4375rem;
  }
  .ps-xxl-28 {
    padding-left: 8.75rem;
  }
  .ps-xxl-29 {
    padding-left: 9.0625rem;
  }
  .ps-xxl-30 {
    padding-left: 9.375rem;
  }
  .ps-xxl-31 {
    padding-left: 9.6875rem;
  }
  .ps-xxl-32 {
    padding-left: 10rem;
  }
  .ps-xxl-33 {
    padding-left: 10.3125rem;
  }
  .ps-xxl-34 {
    padding-left: 10.625rem;
  }
  .ps-xxl-35 {
    padding-left: 10.9375rem;
  }
  .ps-xxl-36 {
    padding-left: 11.25rem;
  }
  .ps-xxl-37 {
    padding-left: 11.5625rem;
  }
  .ps-xxl-38 {
    padding-left: 11.875rem;
  }
  .ps-xxl-39 {
    padding-left: 12.1875rem;
  }
  .ps-xxl-40 {
    padding-left: 12.5rem;
  }
  .text-xxl-start {
    text-align: left;
  }
  .text-xxl-end {
    text-align: right;
  }
  .text-xxl-center {
    text-align: center;
  }
}
@media (min-width: 1720px) {
  .float-xxxl-start {
    float: left;
  }
  .float-xxxl-end {
    float: right;
  }
  .float-xxxl-none {
    float: none;
  }
  .d-xxxl-inline {
    display: inline;
  }
  .d-xxxl-inline-block {
    display: inline-block;
  }
  .d-xxxl-block {
    display: block;
  }
  .d-xxxl-grid {
    display: grid;
  }
  .d-xxxl-table {
    display: table;
  }
  .d-xxxl-table-row {
    display: table-row;
  }
  .d-xxxl-table-cell {
    display: table-cell;
  }
  .d-xxxl-flex {
    display: flex;
  }
  .d-xxxl-inline-flex {
    display: inline-flex;
  }
  .d-xxxl-none {
    display: none;
  }
  .flex-xxxl-fill {
    flex: 1 1 auto;
  }
  .flex-xxxl-row {
    flex-direction: row;
  }
  .flex-xxxl-column {
    flex-direction: column;
  }
  .flex-xxxl-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-xxxl-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-xxxl-grow-0 {
    flex-grow: 0;
  }
  .flex-xxxl-grow-1 {
    flex-grow: 1;
  }
  .flex-xxxl-shrink-0 {
    flex-shrink: 0;
  }
  .flex-xxxl-shrink-1 {
    flex-shrink: 1;
  }
  .flex-xxxl-wrap {
    flex-wrap: wrap;
  }
  .flex-xxxl-nowrap {
    flex-wrap: nowrap;
  }
  .flex-xxxl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .gap-xxxl-0 {
    gap: 0rem;
  }
  .gap-xxxl-1 {
    gap: 0.3125rem;
  }
  .gap-xxxl-2 {
    gap: 0.625rem;
  }
  .gap-xxxl-3 {
    gap: 0.9375rem;
  }
  .gap-xxxl-4 {
    gap: 1.25rem;
  }
  .gap-xxxl-5 {
    gap: 1.5625rem;
  }
  .gap-xxxl-6 {
    gap: 1.875rem;
  }
  .gap-xxxl-7 {
    gap: 2.1875rem;
  }
  .gap-xxxl-8 {
    gap: 2.5rem;
  }
  .gap-xxxl-9 {
    gap: 2.8125rem;
  }
  .gap-xxxl-10 {
    gap: 3.125rem;
  }
  .gap-xxxl-11 {
    gap: 3.4375rem;
  }
  .gap-xxxl-12 {
    gap: 3.75rem;
  }
  .gap-xxxl-13 {
    gap: 4.0625rem;
  }
  .gap-xxxl-14 {
    gap: 4.375rem;
  }
  .gap-xxxl-15 {
    gap: 4.6875rem;
  }
  .gap-xxxl-16 {
    gap: 5rem;
  }
  .gap-xxxl-17 {
    gap: 5.3125rem;
  }
  .gap-xxxl-18 {
    gap: 5.625rem;
  }
  .gap-xxxl-19 {
    gap: 5.9375rem;
  }
  .gap-xxxl-20 {
    gap: 6.25rem;
  }
  .gap-xxxl-21 {
    gap: 6.5625rem;
  }
  .gap-xxxl-22 {
    gap: 6.875rem;
  }
  .gap-xxxl-23 {
    gap: 7.1875rem;
  }
  .gap-xxxl-24 {
    gap: 7.5rem;
  }
  .gap-xxxl-25 {
    gap: 7.8125rem;
  }
  .gap-xxxl-26 {
    gap: 8.125rem;
  }
  .gap-xxxl-27 {
    gap: 8.4375rem;
  }
  .gap-xxxl-28 {
    gap: 8.75rem;
  }
  .gap-xxxl-29 {
    gap: 9.0625rem;
  }
  .gap-xxxl-30 {
    gap: 9.375rem;
  }
  .gap-xxxl-31 {
    gap: 9.6875rem;
  }
  .gap-xxxl-32 {
    gap: 10rem;
  }
  .gap-xxxl-33 {
    gap: 10.3125rem;
  }
  .gap-xxxl-34 {
    gap: 10.625rem;
  }
  .gap-xxxl-35 {
    gap: 10.9375rem;
  }
  .gap-xxxl-36 {
    gap: 11.25rem;
  }
  .gap-xxxl-37 {
    gap: 11.5625rem;
  }
  .gap-xxxl-38 {
    gap: 11.875rem;
  }
  .gap-xxxl-39 {
    gap: 12.1875rem;
  }
  .gap-xxxl-40 {
    gap: 12.5rem;
  }
  .justify-content-xxxl-start {
    justify-content: flex-start;
  }
  .justify-content-xxxl-end {
    justify-content: flex-end;
  }
  .justify-content-xxxl-center {
    justify-content: center;
  }
  .justify-content-xxxl-between {
    justify-content: space-between;
  }
  .justify-content-xxxl-around {
    justify-content: space-around;
  }
  .justify-content-xxxl-evenly {
    justify-content: space-evenly;
  }
  .align-items-xxxl-start {
    align-items: flex-start;
  }
  .align-items-xxxl-end {
    align-items: flex-end;
  }
  .align-items-xxxl-center {
    align-items: center;
  }
  .align-items-xxxl-baseline {
    align-items: baseline;
  }
  .align-items-xxxl-stretch {
    align-items: stretch;
  }
  .align-content-xxxl-start {
    align-content: flex-start;
  }
  .align-content-xxxl-end {
    align-content: flex-end;
  }
  .align-content-xxxl-center {
    align-content: center;
  }
  .align-content-xxxl-between {
    align-content: space-between;
  }
  .align-content-xxxl-around {
    align-content: space-around;
  }
  .align-content-xxxl-stretch {
    align-content: stretch;
  }
  .align-self-xxxl-auto {
    align-self: auto;
  }
  .align-self-xxxl-start {
    align-self: flex-start;
  }
  .align-self-xxxl-end {
    align-self: flex-end;
  }
  .align-self-xxxl-center {
    align-self: center;
  }
  .align-self-xxxl-baseline {
    align-self: baseline;
  }
  .align-self-xxxl-stretch {
    align-self: stretch;
  }
  .order-xxxl-first {
    order: -1;
  }
  .order-xxxl-0 {
    order: 0;
  }
  .order-xxxl-1 {
    order: 1;
  }
  .order-xxxl-2 {
    order: 2;
  }
  .order-xxxl-3 {
    order: 3;
  }
  .order-xxxl-4 {
    order: 4;
  }
  .order-xxxl-5 {
    order: 5;
  }
  .order-xxxl-last {
    order: 6;
  }
  .m-xxxl-0 {
    margin: 0rem;
  }
  .m-xxxl-1 {
    margin: 0.3125rem;
  }
  .m-xxxl-2 {
    margin: 0.625rem;
  }
  .m-xxxl-3 {
    margin: 0.9375rem;
  }
  .m-xxxl-4 {
    margin: 1.25rem;
  }
  .m-xxxl-5 {
    margin: 1.5625rem;
  }
  .m-xxxl-6 {
    margin: 1.875rem;
  }
  .m-xxxl-7 {
    margin: 2.1875rem;
  }
  .m-xxxl-8 {
    margin: 2.5rem;
  }
  .m-xxxl-9 {
    margin: 2.8125rem;
  }
  .m-xxxl-10 {
    margin: 3.125rem;
  }
  .m-xxxl-11 {
    margin: 3.4375rem;
  }
  .m-xxxl-12 {
    margin: 3.75rem;
  }
  .m-xxxl-13 {
    margin: 4.0625rem;
  }
  .m-xxxl-14 {
    margin: 4.375rem;
  }
  .m-xxxl-15 {
    margin: 4.6875rem;
  }
  .m-xxxl-16 {
    margin: 5rem;
  }
  .m-xxxl-17 {
    margin: 5.3125rem;
  }
  .m-xxxl-18 {
    margin: 5.625rem;
  }
  .m-xxxl-19 {
    margin: 5.9375rem;
  }
  .m-xxxl-20 {
    margin: 6.25rem;
  }
  .m-xxxl-21 {
    margin: 6.5625rem;
  }
  .m-xxxl-22 {
    margin: 6.875rem;
  }
  .m-xxxl-23 {
    margin: 7.1875rem;
  }
  .m-xxxl-24 {
    margin: 7.5rem;
  }
  .m-xxxl-25 {
    margin: 7.8125rem;
  }
  .m-xxxl-26 {
    margin: 8.125rem;
  }
  .m-xxxl-27 {
    margin: 8.4375rem;
  }
  .m-xxxl-28 {
    margin: 8.75rem;
  }
  .m-xxxl-29 {
    margin: 9.0625rem;
  }
  .m-xxxl-30 {
    margin: 9.375rem;
  }
  .m-xxxl-31 {
    margin: 9.6875rem;
  }
  .m-xxxl-32 {
    margin: 10rem;
  }
  .m-xxxl-33 {
    margin: 10.3125rem;
  }
  .m-xxxl-34 {
    margin: 10.625rem;
  }
  .m-xxxl-35 {
    margin: 10.9375rem;
  }
  .m-xxxl-36 {
    margin: 11.25rem;
  }
  .m-xxxl-37 {
    margin: 11.5625rem;
  }
  .m-xxxl-38 {
    margin: 11.875rem;
  }
  .m-xxxl-39 {
    margin: 12.1875rem;
  }
  .m-xxxl-40 {
    margin: 12.5rem;
  }
  .m-xxxl-auto {
    margin: auto;
  }
  .mx-xxxl-0 {
    margin-right: 0rem;
    margin-left: 0rem;
  }
  .mx-xxxl-1 {
    margin-right: 0.3125rem;
    margin-left: 0.3125rem;
  }
  .mx-xxxl-2 {
    margin-right: 0.625rem;
    margin-left: 0.625rem;
  }
  .mx-xxxl-3 {
    margin-right: 0.9375rem;
    margin-left: 0.9375rem;
  }
  .mx-xxxl-4 {
    margin-right: 1.25rem;
    margin-left: 1.25rem;
  }
  .mx-xxxl-5 {
    margin-right: 1.5625rem;
    margin-left: 1.5625rem;
  }
  .mx-xxxl-6 {
    margin-right: 1.875rem;
    margin-left: 1.875rem;
  }
  .mx-xxxl-7 {
    margin-right: 2.1875rem;
    margin-left: 2.1875rem;
  }
  .mx-xxxl-8 {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }
  .mx-xxxl-9 {
    margin-right: 2.8125rem;
    margin-left: 2.8125rem;
  }
  .mx-xxxl-10 {
    margin-right: 3.125rem;
    margin-left: 3.125rem;
  }
  .mx-xxxl-11 {
    margin-right: 3.4375rem;
    margin-left: 3.4375rem;
  }
  .mx-xxxl-12 {
    margin-right: 3.75rem;
    margin-left: 3.75rem;
  }
  .mx-xxxl-13 {
    margin-right: 4.0625rem;
    margin-left: 4.0625rem;
  }
  .mx-xxxl-14 {
    margin-right: 4.375rem;
    margin-left: 4.375rem;
  }
  .mx-xxxl-15 {
    margin-right: 4.6875rem;
    margin-left: 4.6875rem;
  }
  .mx-xxxl-16 {
    margin-right: 5rem;
    margin-left: 5rem;
  }
  .mx-xxxl-17 {
    margin-right: 5.3125rem;
    margin-left: 5.3125rem;
  }
  .mx-xxxl-18 {
    margin-right: 5.625rem;
    margin-left: 5.625rem;
  }
  .mx-xxxl-19 {
    margin-right: 5.9375rem;
    margin-left: 5.9375rem;
  }
  .mx-xxxl-20 {
    margin-right: 6.25rem;
    margin-left: 6.25rem;
  }
  .mx-xxxl-21 {
    margin-right: 6.5625rem;
    margin-left: 6.5625rem;
  }
  .mx-xxxl-22 {
    margin-right: 6.875rem;
    margin-left: 6.875rem;
  }
  .mx-xxxl-23 {
    margin-right: 7.1875rem;
    margin-left: 7.1875rem;
  }
  .mx-xxxl-24 {
    margin-right: 7.5rem;
    margin-left: 7.5rem;
  }
  .mx-xxxl-25 {
    margin-right: 7.8125rem;
    margin-left: 7.8125rem;
  }
  .mx-xxxl-26 {
    margin-right: 8.125rem;
    margin-left: 8.125rem;
  }
  .mx-xxxl-27 {
    margin-right: 8.4375rem;
    margin-left: 8.4375rem;
  }
  .mx-xxxl-28 {
    margin-right: 8.75rem;
    margin-left: 8.75rem;
  }
  .mx-xxxl-29 {
    margin-right: 9.0625rem;
    margin-left: 9.0625rem;
  }
  .mx-xxxl-30 {
    margin-right: 9.375rem;
    margin-left: 9.375rem;
  }
  .mx-xxxl-31 {
    margin-right: 9.6875rem;
    margin-left: 9.6875rem;
  }
  .mx-xxxl-32 {
    margin-right: 10rem;
    margin-left: 10rem;
  }
  .mx-xxxl-33 {
    margin-right: 10.3125rem;
    margin-left: 10.3125rem;
  }
  .mx-xxxl-34 {
    margin-right: 10.625rem;
    margin-left: 10.625rem;
  }
  .mx-xxxl-35 {
    margin-right: 10.9375rem;
    margin-left: 10.9375rem;
  }
  .mx-xxxl-36 {
    margin-right: 11.25rem;
    margin-left: 11.25rem;
  }
  .mx-xxxl-37 {
    margin-right: 11.5625rem;
    margin-left: 11.5625rem;
  }
  .mx-xxxl-38 {
    margin-right: 11.875rem;
    margin-left: 11.875rem;
  }
  .mx-xxxl-39 {
    margin-right: 12.1875rem;
    margin-left: 12.1875rem;
  }
  .mx-xxxl-40 {
    margin-right: 12.5rem;
    margin-left: 12.5rem;
  }
  .mx-xxxl-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-xxxl-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .my-xxxl-1 {
    margin-top: 0.3125rem;
    margin-bottom: 0.3125rem;
  }
  .my-xxxl-2 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .my-xxxl-3 {
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
  }
  .my-xxxl-4 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .my-xxxl-5 {
    margin-top: 1.5625rem;
    margin-bottom: 1.5625rem;
  }
  .my-xxxl-6 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }
  .my-xxxl-7 {
    margin-top: 2.1875rem;
    margin-bottom: 2.1875rem;
  }
  .my-xxxl-8 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .my-xxxl-9 {
    margin-top: 2.8125rem;
    margin-bottom: 2.8125rem;
  }
  .my-xxxl-10 {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }
  .my-xxxl-11 {
    margin-top: 3.4375rem;
    margin-bottom: 3.4375rem;
  }
  .my-xxxl-12 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }
  .my-xxxl-13 {
    margin-top: 4.0625rem;
    margin-bottom: 4.0625rem;
  }
  .my-xxxl-14 {
    margin-top: 4.375rem;
    margin-bottom: 4.375rem;
  }
  .my-xxxl-15 {
    margin-top: 4.6875rem;
    margin-bottom: 4.6875rem;
  }
  .my-xxxl-16 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .my-xxxl-17 {
    margin-top: 5.3125rem;
    margin-bottom: 5.3125rem;
  }
  .my-xxxl-18 {
    margin-top: 5.625rem;
    margin-bottom: 5.625rem;
  }
  .my-xxxl-19 {
    margin-top: 5.9375rem;
    margin-bottom: 5.9375rem;
  }
  .my-xxxl-20 {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
  }
  .my-xxxl-21 {
    margin-top: 6.5625rem;
    margin-bottom: 6.5625rem;
  }
  .my-xxxl-22 {
    margin-top: 6.875rem;
    margin-bottom: 6.875rem;
  }
  .my-xxxl-23 {
    margin-top: 7.1875rem;
    margin-bottom: 7.1875rem;
  }
  .my-xxxl-24 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }
  .my-xxxl-25 {
    margin-top: 7.8125rem;
    margin-bottom: 7.8125rem;
  }
  .my-xxxl-26 {
    margin-top: 8.125rem;
    margin-bottom: 8.125rem;
  }
  .my-xxxl-27 {
    margin-top: 8.4375rem;
    margin-bottom: 8.4375rem;
  }
  .my-xxxl-28 {
    margin-top: 8.75rem;
    margin-bottom: 8.75rem;
  }
  .my-xxxl-29 {
    margin-top: 9.0625rem;
    margin-bottom: 9.0625rem;
  }
  .my-xxxl-30 {
    margin-top: 9.375rem;
    margin-bottom: 9.375rem;
  }
  .my-xxxl-31 {
    margin-top: 9.6875rem;
    margin-bottom: 9.6875rem;
  }
  .my-xxxl-32 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .my-xxxl-33 {
    margin-top: 10.3125rem;
    margin-bottom: 10.3125rem;
  }
  .my-xxxl-34 {
    margin-top: 10.625rem;
    margin-bottom: 10.625rem;
  }
  .my-xxxl-35 {
    margin-top: 10.9375rem;
    margin-bottom: 10.9375rem;
  }
  .my-xxxl-36 {
    margin-top: 11.25rem;
    margin-bottom: 11.25rem;
  }
  .my-xxxl-37 {
    margin-top: 11.5625rem;
    margin-bottom: 11.5625rem;
  }
  .my-xxxl-38 {
    margin-top: 11.875rem;
    margin-bottom: 11.875rem;
  }
  .my-xxxl-39 {
    margin-top: 12.1875rem;
    margin-bottom: 12.1875rem;
  }
  .my-xxxl-40 {
    margin-top: 12.5rem;
    margin-bottom: 12.5rem;
  }
  .my-xxxl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-xxxl-0 {
    margin-top: 0rem;
  }
  .mt-xxxl-1 {
    margin-top: 0.3125rem;
  }
  .mt-xxxl-2 {
    margin-top: 0.625rem;
  }
  .mt-xxxl-3 {
    margin-top: 0.9375rem;
  }
  .mt-xxxl-4 {
    margin-top: 1.25rem;
  }
  .mt-xxxl-5 {
    margin-top: 1.5625rem;
  }
  .mt-xxxl-6 {
    margin-top: 1.875rem;
  }
  .mt-xxxl-7 {
    margin-top: 2.1875rem;
  }
  .mt-xxxl-8 {
    margin-top: 2.5rem;
  }
  .mt-xxxl-9 {
    margin-top: 2.8125rem;
  }
  .mt-xxxl-10 {
    margin-top: 3.125rem;
  }
  .mt-xxxl-11 {
    margin-top: 3.4375rem;
  }
  .mt-xxxl-12 {
    margin-top: 3.75rem;
  }
  .mt-xxxl-13 {
    margin-top: 4.0625rem;
  }
  .mt-xxxl-14 {
    margin-top: 4.375rem;
  }
  .mt-xxxl-15 {
    margin-top: 4.6875rem;
  }
  .mt-xxxl-16 {
    margin-top: 5rem;
  }
  .mt-xxxl-17 {
    margin-top: 5.3125rem;
  }
  .mt-xxxl-18 {
    margin-top: 5.625rem;
  }
  .mt-xxxl-19 {
    margin-top: 5.9375rem;
  }
  .mt-xxxl-20 {
    margin-top: 6.25rem;
  }
  .mt-xxxl-21 {
    margin-top: 6.5625rem;
  }
  .mt-xxxl-22 {
    margin-top: 6.875rem;
  }
  .mt-xxxl-23 {
    margin-top: 7.1875rem;
  }
  .mt-xxxl-24 {
    margin-top: 7.5rem;
  }
  .mt-xxxl-25 {
    margin-top: 7.8125rem;
  }
  .mt-xxxl-26 {
    margin-top: 8.125rem;
  }
  .mt-xxxl-27 {
    margin-top: 8.4375rem;
  }
  .mt-xxxl-28 {
    margin-top: 8.75rem;
  }
  .mt-xxxl-29 {
    margin-top: 9.0625rem;
  }
  .mt-xxxl-30 {
    margin-top: 9.375rem;
  }
  .mt-xxxl-31 {
    margin-top: 9.6875rem;
  }
  .mt-xxxl-32 {
    margin-top: 10rem;
  }
  .mt-xxxl-33 {
    margin-top: 10.3125rem;
  }
  .mt-xxxl-34 {
    margin-top: 10.625rem;
  }
  .mt-xxxl-35 {
    margin-top: 10.9375rem;
  }
  .mt-xxxl-36 {
    margin-top: 11.25rem;
  }
  .mt-xxxl-37 {
    margin-top: 11.5625rem;
  }
  .mt-xxxl-38 {
    margin-top: 11.875rem;
  }
  .mt-xxxl-39 {
    margin-top: 12.1875rem;
  }
  .mt-xxxl-40 {
    margin-top: 12.5rem;
  }
  .mt-xxxl-auto {
    margin-top: auto;
  }
  .me-xxxl-0 {
    margin-right: 0rem;
  }
  .me-xxxl-1 {
    margin-right: 0.3125rem;
  }
  .me-xxxl-2 {
    margin-right: 0.625rem;
  }
  .me-xxxl-3 {
    margin-right: 0.9375rem;
  }
  .me-xxxl-4 {
    margin-right: 1.25rem;
  }
  .me-xxxl-5 {
    margin-right: 1.5625rem;
  }
  .me-xxxl-6 {
    margin-right: 1.875rem;
  }
  .me-xxxl-7 {
    margin-right: 2.1875rem;
  }
  .me-xxxl-8 {
    margin-right: 2.5rem;
  }
  .me-xxxl-9 {
    margin-right: 2.8125rem;
  }
  .me-xxxl-10 {
    margin-right: 3.125rem;
  }
  .me-xxxl-11 {
    margin-right: 3.4375rem;
  }
  .me-xxxl-12 {
    margin-right: 3.75rem;
  }
  .me-xxxl-13 {
    margin-right: 4.0625rem;
  }
  .me-xxxl-14 {
    margin-right: 4.375rem;
  }
  .me-xxxl-15 {
    margin-right: 4.6875rem;
  }
  .me-xxxl-16 {
    margin-right: 5rem;
  }
  .me-xxxl-17 {
    margin-right: 5.3125rem;
  }
  .me-xxxl-18 {
    margin-right: 5.625rem;
  }
  .me-xxxl-19 {
    margin-right: 5.9375rem;
  }
  .me-xxxl-20 {
    margin-right: 6.25rem;
  }
  .me-xxxl-21 {
    margin-right: 6.5625rem;
  }
  .me-xxxl-22 {
    margin-right: 6.875rem;
  }
  .me-xxxl-23 {
    margin-right: 7.1875rem;
  }
  .me-xxxl-24 {
    margin-right: 7.5rem;
  }
  .me-xxxl-25 {
    margin-right: 7.8125rem;
  }
  .me-xxxl-26 {
    margin-right: 8.125rem;
  }
  .me-xxxl-27 {
    margin-right: 8.4375rem;
  }
  .me-xxxl-28 {
    margin-right: 8.75rem;
  }
  .me-xxxl-29 {
    margin-right: 9.0625rem;
  }
  .me-xxxl-30 {
    margin-right: 9.375rem;
  }
  .me-xxxl-31 {
    margin-right: 9.6875rem;
  }
  .me-xxxl-32 {
    margin-right: 10rem;
  }
  .me-xxxl-33 {
    margin-right: 10.3125rem;
  }
  .me-xxxl-34 {
    margin-right: 10.625rem;
  }
  .me-xxxl-35 {
    margin-right: 10.9375rem;
  }
  .me-xxxl-36 {
    margin-right: 11.25rem;
  }
  .me-xxxl-37 {
    margin-right: 11.5625rem;
  }
  .me-xxxl-38 {
    margin-right: 11.875rem;
  }
  .me-xxxl-39 {
    margin-right: 12.1875rem;
  }
  .me-xxxl-40 {
    margin-right: 12.5rem;
  }
  .me-xxxl-auto {
    margin-right: auto;
  }
  .mb-xxxl-0 {
    margin-bottom: 0rem;
  }
  .mb-xxxl-1 {
    margin-bottom: 0.3125rem;
  }
  .mb-xxxl-2 {
    margin-bottom: 0.625rem;
  }
  .mb-xxxl-3 {
    margin-bottom: 0.9375rem;
  }
  .mb-xxxl-4 {
    margin-bottom: 1.25rem;
  }
  .mb-xxxl-5 {
    margin-bottom: 1.5625rem;
  }
  .mb-xxxl-6 {
    margin-bottom: 1.875rem;
  }
  .mb-xxxl-7 {
    margin-bottom: 2.1875rem;
  }
  .mb-xxxl-8 {
    margin-bottom: 2.5rem;
  }
  .mb-xxxl-9 {
    margin-bottom: 2.8125rem;
  }
  .mb-xxxl-10 {
    margin-bottom: 3.125rem;
  }
  .mb-xxxl-11 {
    margin-bottom: 3.4375rem;
  }
  .mb-xxxl-12 {
    margin-bottom: 3.75rem;
  }
  .mb-xxxl-13 {
    margin-bottom: 4.0625rem;
  }
  .mb-xxxl-14 {
    margin-bottom: 4.375rem;
  }
  .mb-xxxl-15 {
    margin-bottom: 4.6875rem;
  }
  .mb-xxxl-16 {
    margin-bottom: 5rem;
  }
  .mb-xxxl-17 {
    margin-bottom: 5.3125rem;
  }
  .mb-xxxl-18 {
    margin-bottom: 5.625rem;
  }
  .mb-xxxl-19 {
    margin-bottom: 5.9375rem;
  }
  .mb-xxxl-20 {
    margin-bottom: 6.25rem;
  }
  .mb-xxxl-21 {
    margin-bottom: 6.5625rem;
  }
  .mb-xxxl-22 {
    margin-bottom: 6.875rem;
  }
  .mb-xxxl-23 {
    margin-bottom: 7.1875rem;
  }
  .mb-xxxl-24 {
    margin-bottom: 7.5rem;
  }
  .mb-xxxl-25 {
    margin-bottom: 7.8125rem;
  }
  .mb-xxxl-26 {
    margin-bottom: 8.125rem;
  }
  .mb-xxxl-27 {
    margin-bottom: 8.4375rem;
  }
  .mb-xxxl-28 {
    margin-bottom: 8.75rem;
  }
  .mb-xxxl-29 {
    margin-bottom: 9.0625rem;
  }
  .mb-xxxl-30 {
    margin-bottom: 9.375rem;
  }
  .mb-xxxl-31 {
    margin-bottom: 9.6875rem;
  }
  .mb-xxxl-32 {
    margin-bottom: 10rem;
  }
  .mb-xxxl-33 {
    margin-bottom: 10.3125rem;
  }
  .mb-xxxl-34 {
    margin-bottom: 10.625rem;
  }
  .mb-xxxl-35 {
    margin-bottom: 10.9375rem;
  }
  .mb-xxxl-36 {
    margin-bottom: 11.25rem;
  }
  .mb-xxxl-37 {
    margin-bottom: 11.5625rem;
  }
  .mb-xxxl-38 {
    margin-bottom: 11.875rem;
  }
  .mb-xxxl-39 {
    margin-bottom: 12.1875rem;
  }
  .mb-xxxl-40 {
    margin-bottom: 12.5rem;
  }
  .mb-xxxl-auto {
    margin-bottom: auto;
  }
  .ms-xxxl-0 {
    margin-left: 0rem;
  }
  .ms-xxxl-1 {
    margin-left: 0.3125rem;
  }
  .ms-xxxl-2 {
    margin-left: 0.625rem;
  }
  .ms-xxxl-3 {
    margin-left: 0.9375rem;
  }
  .ms-xxxl-4 {
    margin-left: 1.25rem;
  }
  .ms-xxxl-5 {
    margin-left: 1.5625rem;
  }
  .ms-xxxl-6 {
    margin-left: 1.875rem;
  }
  .ms-xxxl-7 {
    margin-left: 2.1875rem;
  }
  .ms-xxxl-8 {
    margin-left: 2.5rem;
  }
  .ms-xxxl-9 {
    margin-left: 2.8125rem;
  }
  .ms-xxxl-10 {
    margin-left: 3.125rem;
  }
  .ms-xxxl-11 {
    margin-left: 3.4375rem;
  }
  .ms-xxxl-12 {
    margin-left: 3.75rem;
  }
  .ms-xxxl-13 {
    margin-left: 4.0625rem;
  }
  .ms-xxxl-14 {
    margin-left: 4.375rem;
  }
  .ms-xxxl-15 {
    margin-left: 4.6875rem;
  }
  .ms-xxxl-16 {
    margin-left: 5rem;
  }
  .ms-xxxl-17 {
    margin-left: 5.3125rem;
  }
  .ms-xxxl-18 {
    margin-left: 5.625rem;
  }
  .ms-xxxl-19 {
    margin-left: 5.9375rem;
  }
  .ms-xxxl-20 {
    margin-left: 6.25rem;
  }
  .ms-xxxl-21 {
    margin-left: 6.5625rem;
  }
  .ms-xxxl-22 {
    margin-left: 6.875rem;
  }
  .ms-xxxl-23 {
    margin-left: 7.1875rem;
  }
  .ms-xxxl-24 {
    margin-left: 7.5rem;
  }
  .ms-xxxl-25 {
    margin-left: 7.8125rem;
  }
  .ms-xxxl-26 {
    margin-left: 8.125rem;
  }
  .ms-xxxl-27 {
    margin-left: 8.4375rem;
  }
  .ms-xxxl-28 {
    margin-left: 8.75rem;
  }
  .ms-xxxl-29 {
    margin-left: 9.0625rem;
  }
  .ms-xxxl-30 {
    margin-left: 9.375rem;
  }
  .ms-xxxl-31 {
    margin-left: 9.6875rem;
  }
  .ms-xxxl-32 {
    margin-left: 10rem;
  }
  .ms-xxxl-33 {
    margin-left: 10.3125rem;
  }
  .ms-xxxl-34 {
    margin-left: 10.625rem;
  }
  .ms-xxxl-35 {
    margin-left: 10.9375rem;
  }
  .ms-xxxl-36 {
    margin-left: 11.25rem;
  }
  .ms-xxxl-37 {
    margin-left: 11.5625rem;
  }
  .ms-xxxl-38 {
    margin-left: 11.875rem;
  }
  .ms-xxxl-39 {
    margin-left: 12.1875rem;
  }
  .ms-xxxl-40 {
    margin-left: 12.5rem;
  }
  .ms-xxxl-auto {
    margin-left: auto;
  }
  .p-xxxl-0 {
    padding: 0rem;
  }
  .p-xxxl-1 {
    padding: 0.3125rem;
  }
  .p-xxxl-2 {
    padding: 0.625rem;
  }
  .p-xxxl-3 {
    padding: 0.9375rem;
  }
  .p-xxxl-4 {
    padding: 1.25rem;
  }
  .p-xxxl-5 {
    padding: 1.5625rem;
  }
  .p-xxxl-6 {
    padding: 1.875rem;
  }
  .p-xxxl-7 {
    padding: 2.1875rem;
  }
  .p-xxxl-8 {
    padding: 2.5rem;
  }
  .p-xxxl-9 {
    padding: 2.8125rem;
  }
  .p-xxxl-10 {
    padding: 3.125rem;
  }
  .p-xxxl-11 {
    padding: 3.4375rem;
  }
  .p-xxxl-12 {
    padding: 3.75rem;
  }
  .p-xxxl-13 {
    padding: 4.0625rem;
  }
  .p-xxxl-14 {
    padding: 4.375rem;
  }
  .p-xxxl-15 {
    padding: 4.6875rem;
  }
  .p-xxxl-16 {
    padding: 5rem;
  }
  .p-xxxl-17 {
    padding: 5.3125rem;
  }
  .p-xxxl-18 {
    padding: 5.625rem;
  }
  .p-xxxl-19 {
    padding: 5.9375rem;
  }
  .p-xxxl-20 {
    padding: 6.25rem;
  }
  .p-xxxl-21 {
    padding: 6.5625rem;
  }
  .p-xxxl-22 {
    padding: 6.875rem;
  }
  .p-xxxl-23 {
    padding: 7.1875rem;
  }
  .p-xxxl-24 {
    padding: 7.5rem;
  }
  .p-xxxl-25 {
    padding: 7.8125rem;
  }
  .p-xxxl-26 {
    padding: 8.125rem;
  }
  .p-xxxl-27 {
    padding: 8.4375rem;
  }
  .p-xxxl-28 {
    padding: 8.75rem;
  }
  .p-xxxl-29 {
    padding: 9.0625rem;
  }
  .p-xxxl-30 {
    padding: 9.375rem;
  }
  .p-xxxl-31 {
    padding: 9.6875rem;
  }
  .p-xxxl-32 {
    padding: 10rem;
  }
  .p-xxxl-33 {
    padding: 10.3125rem;
  }
  .p-xxxl-34 {
    padding: 10.625rem;
  }
  .p-xxxl-35 {
    padding: 10.9375rem;
  }
  .p-xxxl-36 {
    padding: 11.25rem;
  }
  .p-xxxl-37 {
    padding: 11.5625rem;
  }
  .p-xxxl-38 {
    padding: 11.875rem;
  }
  .p-xxxl-39 {
    padding: 12.1875rem;
  }
  .p-xxxl-40 {
    padding: 12.5rem;
  }
  .px-xxxl-0 {
    padding-right: 0rem;
    padding-left: 0rem;
  }
  .px-xxxl-1 {
    padding-right: 0.3125rem;
    padding-left: 0.3125rem;
  }
  .px-xxxl-2 {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
  .px-xxxl-3 {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
  .px-xxxl-4 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .px-xxxl-5 {
    padding-right: 1.5625rem;
    padding-left: 1.5625rem;
  }
  .px-xxxl-6 {
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }
  .px-xxxl-7 {
    padding-right: 2.1875rem;
    padding-left: 2.1875rem;
  }
  .px-xxxl-8 {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .px-xxxl-9 {
    padding-right: 2.8125rem;
    padding-left: 2.8125rem;
  }
  .px-xxxl-10 {
    padding-right: 3.125rem;
    padding-left: 3.125rem;
  }
  .px-xxxl-11 {
    padding-right: 3.4375rem;
    padding-left: 3.4375rem;
  }
  .px-xxxl-12 {
    padding-right: 3.75rem;
    padding-left: 3.75rem;
  }
  .px-xxxl-13 {
    padding-right: 4.0625rem;
    padding-left: 4.0625rem;
  }
  .px-xxxl-14 {
    padding-right: 4.375rem;
    padding-left: 4.375rem;
  }
  .px-xxxl-15 {
    padding-right: 4.6875rem;
    padding-left: 4.6875rem;
  }
  .px-xxxl-16 {
    padding-right: 5rem;
    padding-left: 5rem;
  }
  .px-xxxl-17 {
    padding-right: 5.3125rem;
    padding-left: 5.3125rem;
  }
  .px-xxxl-18 {
    padding-right: 5.625rem;
    padding-left: 5.625rem;
  }
  .px-xxxl-19 {
    padding-right: 5.9375rem;
    padding-left: 5.9375rem;
  }
  .px-xxxl-20 {
    padding-right: 6.25rem;
    padding-left: 6.25rem;
  }
  .px-xxxl-21 {
    padding-right: 6.5625rem;
    padding-left: 6.5625rem;
  }
  .px-xxxl-22 {
    padding-right: 6.875rem;
    padding-left: 6.875rem;
  }
  .px-xxxl-23 {
    padding-right: 7.1875rem;
    padding-left: 7.1875rem;
  }
  .px-xxxl-24 {
    padding-right: 7.5rem;
    padding-left: 7.5rem;
  }
  .px-xxxl-25 {
    padding-right: 7.8125rem;
    padding-left: 7.8125rem;
  }
  .px-xxxl-26 {
    padding-right: 8.125rem;
    padding-left: 8.125rem;
  }
  .px-xxxl-27 {
    padding-right: 8.4375rem;
    padding-left: 8.4375rem;
  }
  .px-xxxl-28 {
    padding-right: 8.75rem;
    padding-left: 8.75rem;
  }
  .px-xxxl-29 {
    padding-right: 9.0625rem;
    padding-left: 9.0625rem;
  }
  .px-xxxl-30 {
    padding-right: 9.375rem;
    padding-left: 9.375rem;
  }
  .px-xxxl-31 {
    padding-right: 9.6875rem;
    padding-left: 9.6875rem;
  }
  .px-xxxl-32 {
    padding-right: 10rem;
    padding-left: 10rem;
  }
  .px-xxxl-33 {
    padding-right: 10.3125rem;
    padding-left: 10.3125rem;
  }
  .px-xxxl-34 {
    padding-right: 10.625rem;
    padding-left: 10.625rem;
  }
  .px-xxxl-35 {
    padding-right: 10.9375rem;
    padding-left: 10.9375rem;
  }
  .px-xxxl-36 {
    padding-right: 11.25rem;
    padding-left: 11.25rem;
  }
  .px-xxxl-37 {
    padding-right: 11.5625rem;
    padding-left: 11.5625rem;
  }
  .px-xxxl-38 {
    padding-right: 11.875rem;
    padding-left: 11.875rem;
  }
  .px-xxxl-39 {
    padding-right: 12.1875rem;
    padding-left: 12.1875rem;
  }
  .px-xxxl-40 {
    padding-right: 12.5rem;
    padding-left: 12.5rem;
  }
  .py-xxxl-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .py-xxxl-1 {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }
  .py-xxxl-2 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .py-xxxl-3 {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
  }
  .py-xxxl-4 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .py-xxxl-5 {
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem;
  }
  .py-xxxl-6 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }
  .py-xxxl-7 {
    padding-top: 2.1875rem;
    padding-bottom: 2.1875rem;
  }
  .py-xxxl-8 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .py-xxxl-9 {
    padding-top: 2.8125rem;
    padding-bottom: 2.8125rem;
  }
  .py-xxxl-10 {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
  }
  .py-xxxl-11 {
    padding-top: 3.4375rem;
    padding-bottom: 3.4375rem;
  }
  .py-xxxl-12 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
  .py-xxxl-13 {
    padding-top: 4.0625rem;
    padding-bottom: 4.0625rem;
  }
  .py-xxxl-14 {
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
  }
  .py-xxxl-15 {
    padding-top: 4.6875rem;
    padding-bottom: 4.6875rem;
  }
  .py-xxxl-16 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .py-xxxl-17 {
    padding-top: 5.3125rem;
    padding-bottom: 5.3125rem;
  }
  .py-xxxl-18 {
    padding-top: 5.625rem;
    padding-bottom: 5.625rem;
  }
  .py-xxxl-19 {
    padding-top: 5.9375rem;
    padding-bottom: 5.9375rem;
  }
  .py-xxxl-20 {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
  .py-xxxl-21 {
    padding-top: 6.5625rem;
    padding-bottom: 6.5625rem;
  }
  .py-xxxl-22 {
    padding-top: 6.875rem;
    padding-bottom: 6.875rem;
  }
  .py-xxxl-23 {
    padding-top: 7.1875rem;
    padding-bottom: 7.1875rem;
  }
  .py-xxxl-24 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .py-xxxl-25 {
    padding-top: 7.8125rem;
    padding-bottom: 7.8125rem;
  }
  .py-xxxl-26 {
    padding-top: 8.125rem;
    padding-bottom: 8.125rem;
  }
  .py-xxxl-27 {
    padding-top: 8.4375rem;
    padding-bottom: 8.4375rem;
  }
  .py-xxxl-28 {
    padding-top: 8.75rem;
    padding-bottom: 8.75rem;
  }
  .py-xxxl-29 {
    padding-top: 9.0625rem;
    padding-bottom: 9.0625rem;
  }
  .py-xxxl-30 {
    padding-top: 9.375rem;
    padding-bottom: 9.375rem;
  }
  .py-xxxl-31 {
    padding-top: 9.6875rem;
    padding-bottom: 9.6875rem;
  }
  .py-xxxl-32 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .py-xxxl-33 {
    padding-top: 10.3125rem;
    padding-bottom: 10.3125rem;
  }
  .py-xxxl-34 {
    padding-top: 10.625rem;
    padding-bottom: 10.625rem;
  }
  .py-xxxl-35 {
    padding-top: 10.9375rem;
    padding-bottom: 10.9375rem;
  }
  .py-xxxl-36 {
    padding-top: 11.25rem;
    padding-bottom: 11.25rem;
  }
  .py-xxxl-37 {
    padding-top: 11.5625rem;
    padding-bottom: 11.5625rem;
  }
  .py-xxxl-38 {
    padding-top: 11.875rem;
    padding-bottom: 11.875rem;
  }
  .py-xxxl-39 {
    padding-top: 12.1875rem;
    padding-bottom: 12.1875rem;
  }
  .py-xxxl-40 {
    padding-top: 12.5rem;
    padding-bottom: 12.5rem;
  }
  .pt-xxxl-0 {
    padding-top: 0rem;
  }
  .pt-xxxl-1 {
    padding-top: 0.3125rem;
  }
  .pt-xxxl-2 {
    padding-top: 0.625rem;
  }
  .pt-xxxl-3 {
    padding-top: 0.9375rem;
  }
  .pt-xxxl-4 {
    padding-top: 1.25rem;
  }
  .pt-xxxl-5 {
    padding-top: 1.5625rem;
  }
  .pt-xxxl-6 {
    padding-top: 1.875rem;
  }
  .pt-xxxl-7 {
    padding-top: 2.1875rem;
  }
  .pt-xxxl-8 {
    padding-top: 2.5rem;
  }
  .pt-xxxl-9 {
    padding-top: 2.8125rem;
  }
  .pt-xxxl-10 {
    padding-top: 3.125rem;
  }
  .pt-xxxl-11 {
    padding-top: 3.4375rem;
  }
  .pt-xxxl-12 {
    padding-top: 3.75rem;
  }
  .pt-xxxl-13 {
    padding-top: 4.0625rem;
  }
  .pt-xxxl-14 {
    padding-top: 4.375rem;
  }
  .pt-xxxl-15 {
    padding-top: 4.6875rem;
  }
  .pt-xxxl-16 {
    padding-top: 5rem;
  }
  .pt-xxxl-17 {
    padding-top: 5.3125rem;
  }
  .pt-xxxl-18 {
    padding-top: 5.625rem;
  }
  .pt-xxxl-19 {
    padding-top: 5.9375rem;
  }
  .pt-xxxl-20 {
    padding-top: 6.25rem;
  }
  .pt-xxxl-21 {
    padding-top: 6.5625rem;
  }
  .pt-xxxl-22 {
    padding-top: 6.875rem;
  }
  .pt-xxxl-23 {
    padding-top: 7.1875rem;
  }
  .pt-xxxl-24 {
    padding-top: 7.5rem;
  }
  .pt-xxxl-25 {
    padding-top: 7.8125rem;
  }
  .pt-xxxl-26 {
    padding-top: 8.125rem;
  }
  .pt-xxxl-27 {
    padding-top: 8.4375rem;
  }
  .pt-xxxl-28 {
    padding-top: 8.75rem;
  }
  .pt-xxxl-29 {
    padding-top: 9.0625rem;
  }
  .pt-xxxl-30 {
    padding-top: 9.375rem;
  }
  .pt-xxxl-31 {
    padding-top: 9.6875rem;
  }
  .pt-xxxl-32 {
    padding-top: 10rem;
  }
  .pt-xxxl-33 {
    padding-top: 10.3125rem;
  }
  .pt-xxxl-34 {
    padding-top: 10.625rem;
  }
  .pt-xxxl-35 {
    padding-top: 10.9375rem;
  }
  .pt-xxxl-36 {
    padding-top: 11.25rem;
  }
  .pt-xxxl-37 {
    padding-top: 11.5625rem;
  }
  .pt-xxxl-38 {
    padding-top: 11.875rem;
  }
  .pt-xxxl-39 {
    padding-top: 12.1875rem;
  }
  .pt-xxxl-40 {
    padding-top: 12.5rem;
  }
  .pe-xxxl-0 {
    padding-right: 0rem;
  }
  .pe-xxxl-1 {
    padding-right: 0.3125rem;
  }
  .pe-xxxl-2 {
    padding-right: 0.625rem;
  }
  .pe-xxxl-3 {
    padding-right: 0.9375rem;
  }
  .pe-xxxl-4 {
    padding-right: 1.25rem;
  }
  .pe-xxxl-5 {
    padding-right: 1.5625rem;
  }
  .pe-xxxl-6 {
    padding-right: 1.875rem;
  }
  .pe-xxxl-7 {
    padding-right: 2.1875rem;
  }
  .pe-xxxl-8 {
    padding-right: 2.5rem;
  }
  .pe-xxxl-9 {
    padding-right: 2.8125rem;
  }
  .pe-xxxl-10 {
    padding-right: 3.125rem;
  }
  .pe-xxxl-11 {
    padding-right: 3.4375rem;
  }
  .pe-xxxl-12 {
    padding-right: 3.75rem;
  }
  .pe-xxxl-13 {
    padding-right: 4.0625rem;
  }
  .pe-xxxl-14 {
    padding-right: 4.375rem;
  }
  .pe-xxxl-15 {
    padding-right: 4.6875rem;
  }
  .pe-xxxl-16 {
    padding-right: 5rem;
  }
  .pe-xxxl-17 {
    padding-right: 5.3125rem;
  }
  .pe-xxxl-18 {
    padding-right: 5.625rem;
  }
  .pe-xxxl-19 {
    padding-right: 5.9375rem;
  }
  .pe-xxxl-20 {
    padding-right: 6.25rem;
  }
  .pe-xxxl-21 {
    padding-right: 6.5625rem;
  }
  .pe-xxxl-22 {
    padding-right: 6.875rem;
  }
  .pe-xxxl-23 {
    padding-right: 7.1875rem;
  }
  .pe-xxxl-24 {
    padding-right: 7.5rem;
  }
  .pe-xxxl-25 {
    padding-right: 7.8125rem;
  }
  .pe-xxxl-26 {
    padding-right: 8.125rem;
  }
  .pe-xxxl-27 {
    padding-right: 8.4375rem;
  }
  .pe-xxxl-28 {
    padding-right: 8.75rem;
  }
  .pe-xxxl-29 {
    padding-right: 9.0625rem;
  }
  .pe-xxxl-30 {
    padding-right: 9.375rem;
  }
  .pe-xxxl-31 {
    padding-right: 9.6875rem;
  }
  .pe-xxxl-32 {
    padding-right: 10rem;
  }
  .pe-xxxl-33 {
    padding-right: 10.3125rem;
  }
  .pe-xxxl-34 {
    padding-right: 10.625rem;
  }
  .pe-xxxl-35 {
    padding-right: 10.9375rem;
  }
  .pe-xxxl-36 {
    padding-right: 11.25rem;
  }
  .pe-xxxl-37 {
    padding-right: 11.5625rem;
  }
  .pe-xxxl-38 {
    padding-right: 11.875rem;
  }
  .pe-xxxl-39 {
    padding-right: 12.1875rem;
  }
  .pe-xxxl-40 {
    padding-right: 12.5rem;
  }
  .pb-xxxl-0 {
    padding-bottom: 0rem;
  }
  .pb-xxxl-1 {
    padding-bottom: 0.3125rem;
  }
  .pb-xxxl-2 {
    padding-bottom: 0.625rem;
  }
  .pb-xxxl-3 {
    padding-bottom: 0.9375rem;
  }
  .pb-xxxl-4 {
    padding-bottom: 1.25rem;
  }
  .pb-xxxl-5 {
    padding-bottom: 1.5625rem;
  }
  .pb-xxxl-6 {
    padding-bottom: 1.875rem;
  }
  .pb-xxxl-7 {
    padding-bottom: 2.1875rem;
  }
  .pb-xxxl-8 {
    padding-bottom: 2.5rem;
  }
  .pb-xxxl-9 {
    padding-bottom: 2.8125rem;
  }
  .pb-xxxl-10 {
    padding-bottom: 3.125rem;
  }
  .pb-xxxl-11 {
    padding-bottom: 3.4375rem;
  }
  .pb-xxxl-12 {
    padding-bottom: 3.75rem;
  }
  .pb-xxxl-13 {
    padding-bottom: 4.0625rem;
  }
  .pb-xxxl-14 {
    padding-bottom: 4.375rem;
  }
  .pb-xxxl-15 {
    padding-bottom: 4.6875rem;
  }
  .pb-xxxl-16 {
    padding-bottom: 5rem;
  }
  .pb-xxxl-17 {
    padding-bottom: 5.3125rem;
  }
  .pb-xxxl-18 {
    padding-bottom: 5.625rem;
  }
  .pb-xxxl-19 {
    padding-bottom: 5.9375rem;
  }
  .pb-xxxl-20 {
    padding-bottom: 6.25rem;
  }
  .pb-xxxl-21 {
    padding-bottom: 6.5625rem;
  }
  .pb-xxxl-22 {
    padding-bottom: 6.875rem;
  }
  .pb-xxxl-23 {
    padding-bottom: 7.1875rem;
  }
  .pb-xxxl-24 {
    padding-bottom: 7.5rem;
  }
  .pb-xxxl-25 {
    padding-bottom: 7.8125rem;
  }
  .pb-xxxl-26 {
    padding-bottom: 8.125rem;
  }
  .pb-xxxl-27 {
    padding-bottom: 8.4375rem;
  }
  .pb-xxxl-28 {
    padding-bottom: 8.75rem;
  }
  .pb-xxxl-29 {
    padding-bottom: 9.0625rem;
  }
  .pb-xxxl-30 {
    padding-bottom: 9.375rem;
  }
  .pb-xxxl-31 {
    padding-bottom: 9.6875rem;
  }
  .pb-xxxl-32 {
    padding-bottom: 10rem;
  }
  .pb-xxxl-33 {
    padding-bottom: 10.3125rem;
  }
  .pb-xxxl-34 {
    padding-bottom: 10.625rem;
  }
  .pb-xxxl-35 {
    padding-bottom: 10.9375rem;
  }
  .pb-xxxl-36 {
    padding-bottom: 11.25rem;
  }
  .pb-xxxl-37 {
    padding-bottom: 11.5625rem;
  }
  .pb-xxxl-38 {
    padding-bottom: 11.875rem;
  }
  .pb-xxxl-39 {
    padding-bottom: 12.1875rem;
  }
  .pb-xxxl-40 {
    padding-bottom: 12.5rem;
  }
  .ps-xxxl-0 {
    padding-left: 0rem;
  }
  .ps-xxxl-1 {
    padding-left: 0.3125rem;
  }
  .ps-xxxl-2 {
    padding-left: 0.625rem;
  }
  .ps-xxxl-3 {
    padding-left: 0.9375rem;
  }
  .ps-xxxl-4 {
    padding-left: 1.25rem;
  }
  .ps-xxxl-5 {
    padding-left: 1.5625rem;
  }
  .ps-xxxl-6 {
    padding-left: 1.875rem;
  }
  .ps-xxxl-7 {
    padding-left: 2.1875rem;
  }
  .ps-xxxl-8 {
    padding-left: 2.5rem;
  }
  .ps-xxxl-9 {
    padding-left: 2.8125rem;
  }
  .ps-xxxl-10 {
    padding-left: 3.125rem;
  }
  .ps-xxxl-11 {
    padding-left: 3.4375rem;
  }
  .ps-xxxl-12 {
    padding-left: 3.75rem;
  }
  .ps-xxxl-13 {
    padding-left: 4.0625rem;
  }
  .ps-xxxl-14 {
    padding-left: 4.375rem;
  }
  .ps-xxxl-15 {
    padding-left: 4.6875rem;
  }
  .ps-xxxl-16 {
    padding-left: 5rem;
  }
  .ps-xxxl-17 {
    padding-left: 5.3125rem;
  }
  .ps-xxxl-18 {
    padding-left: 5.625rem;
  }
  .ps-xxxl-19 {
    padding-left: 5.9375rem;
  }
  .ps-xxxl-20 {
    padding-left: 6.25rem;
  }
  .ps-xxxl-21 {
    padding-left: 6.5625rem;
  }
  .ps-xxxl-22 {
    padding-left: 6.875rem;
  }
  .ps-xxxl-23 {
    padding-left: 7.1875rem;
  }
  .ps-xxxl-24 {
    padding-left: 7.5rem;
  }
  .ps-xxxl-25 {
    padding-left: 7.8125rem;
  }
  .ps-xxxl-26 {
    padding-left: 8.125rem;
  }
  .ps-xxxl-27 {
    padding-left: 8.4375rem;
  }
  .ps-xxxl-28 {
    padding-left: 8.75rem;
  }
  .ps-xxxl-29 {
    padding-left: 9.0625rem;
  }
  .ps-xxxl-30 {
    padding-left: 9.375rem;
  }
  .ps-xxxl-31 {
    padding-left: 9.6875rem;
  }
  .ps-xxxl-32 {
    padding-left: 10rem;
  }
  .ps-xxxl-33 {
    padding-left: 10.3125rem;
  }
  .ps-xxxl-34 {
    padding-left: 10.625rem;
  }
  .ps-xxxl-35 {
    padding-left: 10.9375rem;
  }
  .ps-xxxl-36 {
    padding-left: 11.25rem;
  }
  .ps-xxxl-37 {
    padding-left: 11.5625rem;
  }
  .ps-xxxl-38 {
    padding-left: 11.875rem;
  }
  .ps-xxxl-39 {
    padding-left: 12.1875rem;
  }
  .ps-xxxl-40 {
    padding-left: 12.5rem;
  }
  .text-xxxl-start {
    text-align: left;
  }
  .text-xxxl-end {
    text-align: right;
  }
  .text-xxxl-center {
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 3.125rem;
  }
  .fs-2 {
    font-size: 2.5rem;
  }
  .fs-3 {
    font-size: 2.1875rem;
  }
  .fs-4 {
    font-size: 1.875rem;
  }
  .fs-5 {
    font-size: 1.5625rem;
  }
}
@media print {
  .d-print-inline {
    display: inline;
  }
  .d-print-inline-block {
    display: inline-block;
  }
  .d-print-block {
    display: block;
  }
  .d-print-grid {
    display: grid;
  }
  .d-print-table {
    display: table;
  }
  .d-print-table-row {
    display: table-row;
  }
  .d-print-table-cell {
    display: table-cell;
  }
  .d-print-flex {
    display: flex;
  }
  .d-print-inline-flex {
    display: inline-flex;
  }
  .d-print-none {
    display: none;
  }
}